import styled from 'styled-components';

import { fsSmol } from '../../../core-ui/ui/typography';
import unstyledButton from '../../../core-ui/components/unstyled-button';
import storageModel from 'utils/models/StorageModel';
import { SiteDefaults } from 'context/useThemeContext';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const ShareAssetButton = styled.button`
	${unstyledButton};
	${fsSmol};
	display: inline-block;
	color: ${defaults?.secondary};

	&:hover {
		text-decoration: underline;
		color: ${defaults?.secondaryHighlighted};
	}
`;
