import { useThemeContext } from 'context/useThemeContext';
import { getRandomInt } from 'utils/common';
import { startCase } from 'lodash';
import React from 'react';

import { SketchPicker } from 'react-color';
import {
	Col,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	UncontrolledTooltip,
} from 'reactstrap';
export interface SketchColor {
	rgb: {
		r: string;
		b: string;
		g: string;
	};
	hex: string;
}
export const CustomSketchPicker = ({
	color,
	onChange,
	label,
	hideTextbox,
}: {
	color: string;
	onChange: (color: { hex: string; rgb: any }) => void;
	label: string;
	hideTextbox?: boolean;
}) => {
	const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
	const handleClick = () => {
		setDisplayColorPicker(!displayColorPicker);
	};
	const { defaults } = useThemeContext();

	const handleClose = () => {
		setDisplayColorPicker(false);
	};

	const styles = {
		default: {
			color: {
				width: '36px',
				height: '24px',
				borderRadius: '2px',
				border: `1px solid ${defaults?.primary}`,
				background: `${color}`,
			},
			swatch: {
				padding: '5px',
				background: '#fff',
				borderRadius: '1px',
				boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
				display: 'inline-block',
				cursor: 'pointer',
			},
			popover: {
				zIndex: 2,
			},
			cover: {
				position: 'fixed',
				top: '0',
				right: '0',
				bottom: '0',
				left: '0',
			},
		},
	};
	const tipId = `tooltip${getRandomInt(9999)}`;
	return (
		<>
			<Col xl={5}>
				<Label style={{ fontSize: 13.75 }} className="mr-2 mt-2">
					{startCase(label)}
				</Label>
			</Col>
			<Col xl={6}>
				<FormGroup inline>
					<InputGroup>
						<UncontrolledTooltip target={tipId}>
							To Edit, click the color picker to the right
						</UncontrolledTooltip>
						{!hideTextbox && (
							<Input
								id={tipId}
								style={{ maxWidth: 160, background: '#fff' }}
								readOnly
								value={color}
							/>
						)}
						<InputGroupAddon addonType="append">
							<div style={styles.default.swatch} onClick={handleClick}>
								<div style={styles.default.color} />
							</div>
						</InputGroupAddon>
					</InputGroup>
					{displayColorPicker && (
						<div style={{ position: 'absolute', zIndex: 2 }}>
							<div
								style={{
									position: 'fixed',
									left: 0,
									right: 0,
									top: 0,
									bottom: 0,
								}}
								onClick={handleClose}
							/>
							<SketchPicker color={color} onChange={onChange} />
						</div>
					)}
				</FormGroup>
			</Col>
		</>
	);
};
