import { faDownload, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate, useMatch } from '@reach/router';
import { RomeSwal } from 'components/alert';
import { isArray } from 'lodash';
import React, { useCallback, useState } from 'react';
import Moment from 'react-moment';
import { Badge, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import { Maybe } from 'types/globals';
import { useAuthContext } from 'utils/auth';
import { humanReadableFileSizeSI } from 'utils/common';
import { RoleContext } from '../../../../context/PermissionsContext';
import { useWorkflowContext } from '../../../../context/useWorkflowStore';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { useAxios } from '../../../../hooks';
import { _logError } from '../../../../utils/common/log';
import { useModalCloser, useModalCreator } from '../../../../utils/ModalStack';
import { SecondaryButton } from '../../../buttons.styled-components';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
} from '../../../entity-details.styled-components';
import { NotificationsContext } from '../../../notifications';
import {
	emptyMetadataTemplate,
	flattenStages,
} from '../../../workflow/workflows/helpers/workflowStage.helpers';
import {
	AssetVersion,
	EntityMetadata,
	InputSlot,
	Stage,
	Workflow,
	WorkflowTemplate,
} from '../../../workflow/workflows/types/workflow.types';
import DeleteAssetDialog from '../delete-asset-dialog.component';
import { useAssetHelper } from '../helpers/useAssetHelper';
import { AssetTabsProps } from './asset-details-tab-set.component';
import { FullWidthButton } from './asset-details-tab-set.styled-components';
import AssetMetadataProps from './asset-metadata-props.component';
import AssetWorkflowProps from './asset-workflow-props.component';
const DownloadButton = styled<any>(FullWidthButton)`
	white-space: nowrap;
	padding: 0.5rem;
	width: 150px;
`;

const assetTerm = themeStore._.asset.toLowerCase();
function workflowsReducer(
	state: { workflows: Workflow[]; templates: WorkflowTemplate[] },
	action: {
		type: 'setWorkflows' | 'setTemplates';
		payload: Workflow[] | WorkflowTemplate[];
	}
) {
	switch (action.type) {
		case 'setWorkflows':
			return { ...state, workflows: action.payload as Workflow[] };
		case 'setTemplates':
			return {
				...state,
				templates: action.payload as WorkflowTemplate[],
			};
		default:
			return state;
	}
}
export const findVersionInWorkflow = (
	asset: AssetVersion | string,
	workflow: Workflow
) => {
	const assetId = typeof asset === 'string' ? asset : asset._id;
	return flattenStages(workflow)?.find(
		(stage) =>
			stage.inputSlots &&
			stage?.inputSlots?.some(
				(slot) =>
					slot &&
					slot.versions &&
					slot.versions?.some(
						(version) => version._id.toString() === assetId.toString()
					)
			)
	);
};
const InfoPanel = ({
	asset: assetVersion,
	workflowOwner,
	isAssetVersion,
	isEditedByCreatorOnly,
	damAsset,
	onDelete,
}: AssetTabsProps) => {
	const asset = React.useMemo(() => assetVersion ?? damAsset, [
		assetVersion,
		damAsset,
	]);
	const wfMatch = useMatch(
		'/admin/workflow/workflows/:workflowId/assets/:assetId'
	);
	const { workflow: assetWorkflow } = useWorkflowContext();
	const [workflow, setWorkflow] = React.useState<Workflow>();
	React.useEffect(() => {
		if (wfMatch) {
			setWorkflow(assetWorkflow as Workflow);
		}
	}, [wfMatch, assetWorkflow]);

	const { info, error: showError } = React.useContext(NotificationsContext);
	const { canViewByRole } = React.useContext(RoleContext);
	const { allTemplates } = useWorkflowContext();
	const {
		deleteOne: deleteAsset,
		downloadAssetFile,
		findOne,
	} = useAssetHelper();
	const { updateOne, entities } = useWorkflowContext();
	const [state, dispatch] = React.useReducer(workflowsReducer, {
		workflows: [],
		templates: [],
	});
	const [archived] = useState(damAsset?.archived ?? asset?.archived ?? false);
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const wfAssetMatch = useMatch('/admin/workflow/workflows/:workflowId/assets');
	const [metadata] = useState(
		// @ts-ignore
		(damAsset?.metadata || asset?.metadata) ?? emptyMetadataTemplate
	);
	React.useEffect(() => {
		if (!state.templates.length && allTemplates) {
			dispatch({ type: 'setTemplates', payload: allTemplates });
		}
	}, [state.templates.length, allTemplates]);

	const downloadAsset = async () => {
		await downloadAssetFile(asset).catch(() => {
			showError(
				`An issue occurred while downloading the ${assetTerm}. Please try again later.`
			);
		});
	};

	const routeToEdit = (damAsset: AssetVersion) => {
		modalCloser.closeModal();
		return navigate(`/admin/dam/assets/${damAsset._id}/edit`);
	};

	const renderEditLink = (damAsset?: AssetVersion) => {
		if (
			damAsset &&
			canViewByRole('damEditable') &&
			!wfMatch &&
			!isAssetVersion
		) {
			const routeToEditCb = (e: any) => routeToEdit(damAsset);
			return (
				<Col className={'col-xl-6 mt-2'}>
					<SecondaryButton onClick={routeToEditCb}>
						<FontAwesomeIcon icon={faEdit} className="mr-2" />
						Edit asset
					</SecondaryButton>
				</Col>
			);
		} else {
			return null;
		}
	};

	const assetStore = useAxios<AssetVersion>('assets');

	/**
	 * Deletes the relevant asset from both algolia and the database
	 * by utilizing the asset store which hits the API
	 * @param wf: Workflow to delete asset from
	 */
	const deleteAssetWithoutWorkflowSubmit = useCallback(
		async (asset: AssetVersion) => {
			try {
				await assetStore.deleteOne(
					asset.versionId ? (asset.versionId as string) : asset._id
				);
				if (onDelete) onDelete();
				modalCloser.closeModal();
				modalCloser.closeModal();
				if (!onDelete)
					info(
						`${themeStore._.asset} ${asset.title} deleted from DAM succesfully.`
					);
				return;
			} catch (error) {
				_logError(error);

				showError(
					`An issue occurred while deleting ${assetTerm.toLowerCase()} ${
						asset.title
					}. Please try again.`
				);
			}
			return false;
		},
		[modalCloser, showError, assetStore, info, onDelete]
	);
	/**
	 * Callback function that will open up the delete modal
	 * allowing user chance to confirm before calling the API to delete asset
	 */
	const openDeleteForNonWorkflowAsset = useCallback(() => {
		modalStack.addModal(
			<DeleteAssetDialog
				asset={asset}
				onDelete={async () => await deleteAssetWithoutWorkflowSubmit(asset)}
			/>
		);
	}, [asset, modalStack, deleteAssetWithoutWorkflowSubmit]);

	const insertStage = (arr: Array<Stage>, index: number, newItem: any) =>
		[...arr.slice(0, index), newItem, ...arr.slice(index)] as Stage[];

	/**
	 * Callback function that will open up the delete modal
	 * allowing user chance to confirm before calling the API to delete asset
	 */
	const openDeleteModal = useCallback(
		(wf?: Workflow) => {
			if (isEditedByCreatorOnly && workflowOwner !== currentUser._id) {
				RomeSwal.fire({
					icon: 'warning',
					title: 'Permission Denied',
					text: 'You do not have permission to delete this asset.',
				});
				return;
			}
			const onDelete = async (workflow?: Workflow) => {
				const assetStage: Maybe<Stage> = findVersionInWorkflow(
					asset,
					workflow as Workflow
				);

				if (
					workflow &&
					workflow.templateUsed &&
					assetStage &&
					assetStage.inputSlots
				) {
					const updatedStage = {
						...(assetStage as Stage),
						inputSlots:
							assetStage && assetStage !== undefined
								? assetStage?.inputSlots?.map((slot: any) => {
										return {
											...slot,
											versions: [
												...slot.versions.filter(
													(version: any) => version._id !== asset._id
												),
											],
										};
								  })
								: [],
					} as Stage;

					const updatedStageIndex =
						workflow?.stages?.findIndex(
							(wfStage) => updatedStage._id === wfStage._id
						) ?? flattenStages(workflow)?.indexOf(updatedStage);

					let updatedWf = {
						...workflow,
						stages: [
							...workflow?.stages?.filter(
								(wfStage: Stage) => wfStage?.title !== updatedStage?.title
							),
						],
					} as Workflow;

					updatedWf.stages = insertStage(
						updatedWf?.stages as Stage[],
						updatedStageIndex as number,
						updatedStage
					) as Stage[];

					try {
						// in the event that they are deleting an asset from a workflow,
						// this fine one checks to see if the asset is a DAM Asset (in the database),
						// or not
						if (await findOne(asset._id)) {
							await deleteAsset(asset._id);
						}

						await updateOne(updatedWf._id, updatedWf);
					} catch (e) {
						showError(
							`An issue occurred while deleting ${assetTerm.toLowerCase()} ${
								asset.title
							}. Please try again.`
						);
					}
					modalCloser.closeModal();
					modalCloser.closeModal();
					info(
						`${themeStore._.asset} ${asset.title} deleted from Stage: ${updatedStage.title} successfully`
					);
					return navigate(`/admin/workflow/workflows/${workflow._id}`);
				} else {
					showError(
						`An issue occurred while deleting ${assetTerm.toLowerCase()} ${
							asset.title
						}. Please try again.`
					);
					modalCloser.closeModal();
				}
			};

			modalStack.addModal(
				<DeleteAssetDialog
					asset={asset as AssetVersion}
					onDelete={async () => await onDelete(wf)}
				/>
			);
		},
		//eslint-disable-next-line
		[
			asset,
			modalStack,
			deleteAsset,
			findOne,
			info,
			modalCloser,
			showError,
			updateOne,
		]
	);
	const { currentUser, entities: users } = useAuthContext();
	const getCreatorName = (createdBy: any) => {
		if (typeof createdBy !== 'string')
			return createdBy.givenName + ' ' + createdBy.familyName;
		const creator = users?.find(({ _id }) => _id === createdBy);
		return creator?.givenName + ' ' + creator?.familyName;
	};
	const renderComponent = (wf?: Workflow) => {
		const archivedClassName = archived ? 'badge-danger' : 'badge-info';
		return (
			<>
				<EntityPropLabel>
					Asset Status
					<Badge className={`mb-2 ml-2 ${archivedClassName}`}>
						{archived ? 'Archived' : 'Active'}
					</Badge>
				</EntityPropLabel>
				<EntityPropList>
					<EntityPropListItem>
						<EntityPropLabel>File name</EntityPropLabel>
						<p>{asset?.fileName}</p>
					</EntityPropListItem>

					{asset?.title ? (
						<EntityPropListItem>
							<EntityPropLabel>Display name</EntityPropLabel>
							<p>{asset?.title}</p>
						</EntityPropListItem>
					) : null}

					{asset?.createdBy ? (
						<EntityPropListItem>
							<EntityPropLabel>Added by</EntityPropLabel>
							<p>{getCreatorName(asset.createdBy)}</p>
						</EntityPropListItem>
					) : null}

					<EntityPropListItem>
						<EntityPropLabel>Added on</EntityPropLabel>
						<Moment format="MM/DD/YYYY" date={asset?.createdAt} />
					</EntityPropListItem>

					<AssetWorkflowProps workflow={wf} asset={asset} />

					<EntityPropListItem>
						<EntityPropLabel>File size</EntityPropLabel>
						<p>{humanReadableFileSizeSI(asset?.fileSizeBytes)}</p>
					</EntityPropListItem>

					<EntityPropListItem>
						<EntityPropLabel>File type</EntityPropLabel>
						<p>{asset?.type}</p>
					</EntityPropListItem>

					<AssetMetadataProps metadata={metadata as EntityMetadata} />
				</EntityPropList>

				<Row className="mt-1 py-3">
					<Col className={'col-xl-6 mt-2'}>
						<DownloadButton onClick={downloadAsset}>
							<FontAwesomeIcon icon={faDownload} className="mr-2" />
							Download {assetTerm}
						</DownloadButton>
					</Col>

					{renderEditLink(damAsset)}
					{canViewByRole('damEditable') && !!wfAssetMatch && (
						<Col className={'col-xl-6 mt-2'}>
							<SecondaryButton onClick={() => openDeleteModal(wf)}>
								Delete
							</SecondaryButton>
						</Col>
					)}

					{canViewByRole('damEditable') && asset?.path?.includes('dam/assets') && (
						<Col className="mt-2">
							<SecondaryButton onClick={() => openDeleteForNonWorkflowAsset()}>
								Delete
							</SecondaryButton>
						</Col>
					)}
				</Row>
			</>
		);
	};
	const extractWorkflow = () => {
		if (workflow) return workflow;
		if (asset && asset.path && asset.path.includes('workflow')) {
			const wfId = asset.path
				.substring(
					asset.path.indexOf('workflow_'),
					asset.path.indexOf('/stage_')
				)
				.replace('workflow_', '');
			// @ts-ignore
			const wf = (entities || []).find((m) => m._id === wfId);
			return wf;
		}
		if (!!entities?.length) {
			return state.workflows.find(
				(workflow: Workflow) =>
					!!flattenStages(workflow)?.find((stage: Stage | Stage[]) =>
						isArray(stage)
							? !!stage.some((sub) =>
									sub?.substages?.length
										? !!sub.substages
												.flatMap((s) => s)
												?.some(
													(substage: Stage) =>
														!!substage.inputSlots?.some(
															(slot: InputSlot) =>
																!!slot?.versions?.some(
																	(version: AssetVersion) =>
																		version.fileName === asset.fileName
																)
														)
												)
										: !!sub.inputSlots?.some(
												(slot: InputSlot) =>
													!!slot.versions?.some(
														(version) => version.fileName === asset.fileName
													)
										  )
							  )
							: stage.inputSlots?.some(
									(slot: InputSlot) =>
										!!slot.versions?.some(
											(version) => version.fileName === asset.fileName
										)
							  )
					)
			) as Workflow;
		}
	};
	const render = () => {
		// TODO: make this less ghetto
		const wf = extractWorkflow();
		return <Container className="p-md-4 py-3">{renderComponent(wf)}</Container>;
	};
	return render();
};

export default InfoPanel;
