import { omit, pick } from 'lodash';
import { RoleContext } from 'context/PermissionsContext';
import React from 'react';
import { useTemplateContext } from 'context/useTemplateContext';
import { WorkflowTemplate } from 'components/workflow/workflows/types';
import { TemplateHeading } from '../../../../ui';
import { SelectedTemplateStage } from '../../template.contexts';
import EditTemplateForm from '../edit-template-form.component';
import TemplateActions from '../template-actions.component';
import { TemplateEditorWrapper, TemplateHeader } from './Template.styles';
import { TemplateBaseFinalDetails } from './TemplateDetails';

type Props = {
	isSidebarMinimized: boolean;
	toggleSidebar: () => void;
};

export const TemplateEditor = (props: Props) => {
	const {
		template,
		currentStage,
		setCurrentStage,
		saveTemplate,
		lastAction,
	} = useTemplateContext();

	const permissions = React.useContext(RoleContext);

	const editable =
		permissions.canViewByRole('wfTemplatesEditable') &&
		template &&
		!['archived', 'final'].some((a) => a === template.editingState);

	const onSubmit = async (_: any) => {};
	const onChange = async (newTemplateData: WorkflowTemplate) => {
		const updated = pick(newTemplateData, [
			'metadata',
			'phases',
			'followers',
			'useSKUs',
			'skuMetadata',
			'title',
			'metadataTemplateUsed',
			'isEditedByCreatorOnly',
			'skuMetadata',
		]);
		await saveTemplate({
			...(updated as WorkflowTemplate),
			...omit(template, [
				'metadata',
				'phases',
				'followers',
				'useSKUs',
				'skuMetadata',
				'title',
				'metadataTemplateUsed',
				'isEditedByCreatorOnly',
				'skuMetadata',
			]),
		});
	};

	if (!template) return null;
	return (
		<SelectedTemplateStage.Provider value={[currentStage, setCurrentStage]}>
			<TemplateEditorWrapper>
				<TemplateHeader className="template-header">
					<div>
						<TemplateHeading className="template-heading">
							{template?.title}
						</TemplateHeading>
					</div>
				</TemplateHeader>

				<div className="last-actions">
					{editable && <TemplateActions template={template} />}
				</div>
				<div className="d-block w-100 text-right px-4">
					<small>{lastAction}</small>
				</div>
				{editable && (
					<div className="edit-form">
						<EditTemplateForm
							buttonLabel=""
							selectedTemplate={template}
							onSubmit={onSubmit}
							onChange={onChange}
						/>
					</div>
				)}
				{!editable && <TemplateBaseFinalDetails />}
				<br />
				<div style={{ width: '100%', borderBottom: '1px solid darkgrey' }} />
			</TemplateEditorWrapper>
		</SelectedTemplateStage.Provider>
	);
};
