import styled from 'styled-components';
import { Input } from 'reactstrap';

import { focus } from '../../core-ui/ui/ui';
import storageModel from 'utils/models/StorageModel';
import { SiteDefaults } from 'context/useThemeContext';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const StyledInput = styled(Input)`
	border: none !important;
	color: var(--darkGrey);
`;

export const SearchButton = styled.button`
	background-color: var(--white);
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border: none;
	color: ${defaults?.secondary};

	&:focus {
		${focus};
	}
`;
