import { navigate, useParams } from '@reach/router';
import BackLink from 'components/back-link/back-link.component';
import { SecondaryButton } from 'components/buttons.styled-components';
import { Heading, Subheading } from 'components/index';
import RenderWhen from 'components/render-when.component';
import React from 'react';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import { useWorkflowContext } from '../../../context/useWorkflowStore';
import {
	EntityMetadata,
	SKUMetadata,
	Workflow,
	WorkflowTemplate,
} from './types';

export const SKUDetail = (props: { skuMetadata: SKUMetadata }) => {
	// hooks
	const { rowId } = useParams();
	const { workflow, updateOne } = useWorkflowContext();

	const { skuMetadata } = props;
	const [rows, setRows] = React.useState<any[]>(skuMetadata?.rows ?? []);
	const row = {
		...rows.find((row) => row._id === rowId),
		values: rows?.find((row) => row?._id === rowId)?.values ?? {},
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [checkedMap, setCheckedMap] = React.useState();

	const metadata = React.useMemo(() => {
		if (workflow) {
			return (workflow?.templateUsed as WorkflowTemplate)
				?.skuMetadata as EntityMetadata;
		}
		return {} as EntityMetadata;
	}, [workflow]);

	const { fields, fieldOptions, fieldTypes } = metadata;

	const updateRow = (key: string, value: string | string[], rowId: string) => {
		const updatedRow = rows.find((m) => m._id === rowId);
		if (!updatedRow.values) {
			updatedRow.values = {} as Record<string, string | string[]>;
		}
		const rowIndex = rows.findIndex((m) => m._id === rowId);
		updatedRow.values[key] = value;
		const insert = (arr: any, index: any, newItem: any) => [
			// part of the array before the specified index
			...arr.slice(0, index),
			// inserted item
			newItem,
			// part of the array after the specified index
			...arr.slice(index),
		];
		const updatedRows = insert(
			rows.filter((a) => a._id !== updatedRow._id),
			rowIndex,
			updatedRow
		);
		setRows(updatedRows);
		saveRow();
	};

	function renderOptions(fieldKey: string) {
		const options: JSX.Element[] = [];
		if (
			fieldOptions !== undefined &&
			!!(
				fieldOptions![fieldKey] &&
				!!(fieldOptions as Record<
					string,
					{ field: string; options: string[] }
				>)[fieldKey]?.options
			)
		) {
			(fieldOptions as Record<string, { field: string; options: string[] }>)[
				fieldKey
			]?.options?.map((opt) =>
				options.push(
					<option key={opt} value={opt}>
						{opt}
					</option>
				)
			);
		}
		return options;
	}
	const saveRow = async () => {
		const updatedWorkflow = {
			...workflow,
			skuMetadata: { rows: rows },
		} as Workflow;

		await updateOne(updatedWorkflow._id, updatedWorkflow);
	};

	const getValue = (key: string) => {
		if (!row.values) return '';

		if (row.values && row.values[key]) {
			return row.values![key];
		}
	};
	return (
		<Container>
			<Card>
				<CardHeader>
					<BackLink
						link={`/admin/workflow/workflows/${workflow?._id}/skus`}
						title="SKU's"
					/>
					<div className="w-100">
						<Subheading>{workflow?.title}</Subheading>
						<Heading>SKU Details</Heading>
					</div>
				</CardHeader>
				<CardBody>
					<Row>
						{fields?.map((key) => {
							const fieldType = fieldTypes?.find((ft) => ft.field === key)
								?.fieldType as string;
							return (
								<Col xl={12}>
									<FormGroup>
										<Label>{key}</Label>
										<RenderWhen
											when={['singleSelect', 'multiSelect'].includes(fieldType)}
										>
											{'multiSelect' === fieldType ? (
												<select
													multiple
													className="form-control"
													name="value"
													defaultValue={getValue(key)}
													onChange={(e) =>
														updateRow(
															key,
															Array.from(
																e.target.selectedOptions,
																(option: { value: string }) => option.value
															),
															rowId
														)
													}
												>
													<option key={''} value={''}>
														Please select...
													</option>
													{renderOptions(key)}
												</select>
											) : (
												<select
													className="form-control"
													name="value"
													defaultValue={
														!Array.isArray(getValue(key))
															? getValue(key)
															: getValue(key)[0]
													}
													onChange={(e) =>
														updateRow(key, e.target.value, row._id)
													}
												>
													<option key={''} value={''}>
														Please select...
													</option>
													{renderOptions(key)}
												</select>
											)}
										</RenderWhen>
										<RenderWhen when={'numeric' === fieldType}>
											<Input
												type={'number'}
												className="form-control"
												defaultValue={getValue(key)}
												onChange={(e) =>
													updateRow(key, e.target.value, row._id)
												}
											/>
										</RenderWhen>
										<RenderWhen when={'date' === fieldType}>
											<Input
												type={'date'}
												placeholder="mm/dd/yyyy"
												pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
												className="form-control"
												defaultValue={getValue(key)}
												onChange={(e) =>
													updateRow(key, e.target.value, row._id)
												}
											/>
										</RenderWhen>
										<RenderWhen
											when={'openType' === fieldType || fieldType === undefined}
										>
											<Input
												type="text"
												name="value"
												defaultValue={getValue(key)}
												onChange={(event) => {
													updateRow(key, event.target.value, row._id);
												}}
											/>
										</RenderWhen>
									</FormGroup>
								</Col>
							);
						})}
					</Row>
				</CardBody>
				<CardFooter>
					<SecondaryButton
						onClick={async () => {
							await saveRow();
							return await navigate(
								`/admin/workflow/workflows/${workflow?._id}/skus`
							);
						}}
					>
						Update SKU Details
					</SecondaryButton>
				</CardFooter>
			</Card>
		</Container>
	);
};
