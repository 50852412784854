import * as axios from 'axios';
import React from 'react';
import { Maybe } from 'types/globals';
import { User } from 'utils/auth';
import { authProvider } from 'utils/core';
export type FileShareInstance = {
	_id: string;
	createdBy: User;
	createdAt: Date;
	recalled: boolean;
	recipients: Array<{ _id: string; email: string; link: string }>;
	sent: Date;
	sendReceipt: boolean;
	attachments: Array<{
		_id: string;
		preview: string;
		path: string;
		metadata: {
			filename: string;
			fileSizeBytes: number;
			ext: string;
			type: string;
		};
	}>;
	subject: string;
	message: string;
	validUntil: Date | string;
	useCC?: boolean;
};
export type FileShareAuditDto = {
	_id: string;
	file_share_id: string;
	action: 'download' | 'view' | 'share';
	createdBy: string;
	createdAt: Date;
	recipient_id: string;
	recipient_email: string;
	preview: string;
	file_id: string;
	metadata?: {
		filename: string;
		fileSizeBytes: number;
		ext: string;
		type: string;
	};
};
type FileShareContextType = {
	shareFiles: (
		dto: {
			to: Array<string>;
			sendReceipt: boolean;
			message: string;
			validUntil: string;
			subject: string;
			useCc: boolean;
		},
		files: File[]
	) => Promise<any>;
	getFileShareInstance: (id: string) => Promise<FileShareInstance>;
	downloadFileShareFile: (id: string) => Promise<boolean | string>;
	generateFileShareFileShareLink: (path: string) => Promise<string>;
	retrieveShareData: () => Promise<Maybe<payload>>;
	audit: (dto: FileShareAuditDto) => Promise<FileShareAuditDto>;
	killExpiredShare: (id: string) => Promise<FileShareInstance>;
	recallSharedFiles: (fileShareId: string) => Promise<FileShareInstance>;
	getStats: (
		fileId: string
	) => Promise<{
		downloads: FileShareAuditDto[];
		shares: FileShareAuditDto[];
		views: FileShareAuditDto[];
		sharedAudits: FileShareAuditDto[];
	}>;
};

const FileShareContext = React.createContext<FileShareContextType>({
	shareFiles: (
		dto: {
			to: Array<string>;
			sendReceipt: boolean;
			message: string;
			subject: string;
			validUntil: string;
			useCc: boolean;
		},
		files: File[]
	) => Promise.resolve({}),
	getFileShareInstance: (id: string) =>
		Promise.resolve<FileShareInstance>({} as FileShareInstance),
	downloadFileShareFile: (id: string) => Promise.resolve(false),
	retrieveShareData: () => Promise.resolve({} as payload),
	generateFileShareFileShareLink: (path: string) => Promise.resolve(''),
	audit: (dto: FileShareAuditDto) => Promise.resolve({} as FileShareAuditDto),
	killExpiredShare: (id: string) =>
		Promise.resolve<FileShareInstance>({} as FileShareInstance),
	recallSharedFiles: (fileShareId: string) =>
		Promise.resolve({} as FileShareInstance),
	getStats: (id: string) => Promise.resolve({} as any),
});
type payload = {
	received: FileShareInstance[];
	sent: FileShareInstance[];
	audits: FileShareAuditDto[];
};
export const FileShareContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const hasToken = React.useMemo(() => {
		if (
			localStorage.getItem('rome_auth') &&
			JSON.parse(localStorage.getItem('rome_auth') as string) !== null
		) {
			return !!JSON.parse(localStorage.getItem('rome_auth') as string)
				.accessToken
				? true
				: false;
		}
		//eslint-disable-next-line
	}, [localStorage.getItem('rome_auth')]);

	const config = React.useMemo(
		() =>
			!!hasToken
				? {
						headers: {
							Authorization: `Bearer ${
								JSON.parse(localStorage.getItem('rome_auth') as string)
									.accessToken
							}`,
						},
				  }
				: { headers: { Authorization: `Bearer ${authProvider?.accessToken}` } },
		[hasToken]
	);

	const recallSharedFiles = async (fileShareId: string) => {
		let authHeaders = config;
		if (
			!!authHeaders.headers.Authorization.includes('null') ||
			!authHeaders?.headers?.Authorization
		) {
			authHeaders = {
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
					}`,
				},
			};
		}
		const response = await axios.default.put<FileShareInstance>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/recall/${fileShareId}`,
			{},
			authHeaders
		);
		return response.data;
	};

	const killExpiredShare = async (id: string) => {
		const response = await axios.default.get<FileShareInstance>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/expired/${id}`
		);
		return response.data;
	};

	const retrieveShareData = async () => {
		let authHeaders = config;
		if (
			!!authHeaders.headers.Authorization.includes('null') ||
			!authHeaders?.headers?.Authorization
		) {
			authHeaders = {
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
					}`,
				},
			};
		}
		const defaults = await axios.default.get<payload>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing`,
			authHeaders
		);
		return defaults.data;
	};

	const audit = async (dto: FileShareAuditDto) => {
		const response = await axios.default.put<FileShareAuditDto>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/audit`,
			dto
		);
		return response.data;
	};

	const getStats = async (fileId: string) => {
		const response = await axios.default.get<{
			downloads: FileShareAuditDto[];
			views: FileShareAuditDto[];
			shares: FileShareAuditDto[];
			sharedAudits: FileShareAuditDto[];
		}>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/stats/${fileId}`
		);
		return response.data;
	};

	const getFileShareInstance = async (id: string) => {
		const response = await axios.default.get<FileShareInstance>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/${id}`
		);
		return response.data;
	};
	/**
	 * @param path File Share PATH to fetch from API for signed URL for download
	 * @returns {string} signed URL
	 */
	const getForRedirect = async (path: string): Promise<string> => {
		const response = await axios.default.get(path, config);
		if (response.data) return new URL(response.data).toJSON();
		throw new Error('Getting for a redirect, but none was provided');
	};

	const generateFileShareFileShareLink = async (id: string) => {
		const signedURL = await getForRedirect(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/downloadFile/${id}`
		);
		return signedURL;
	};

	const downloadFileShareFile = async (id: string) => {
		try {
			const signedURL = await getForRedirect(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/file-sharing/downloadFile/${id}`
			);
			return signedURL;
		} catch (err) {
			console.error(err);
			return false;
		}
	};

	const callPutShareMultipleFiles = async (
		files: File[],
		data?: {
			to: Array<string>;
			sendReceipt: boolean;
			message: string;
			subject: string;
			useCc: boolean;
		}
	) => {
		let authHeaders = config;
		if (
			!!authHeaders.headers.Authorization.includes('null') ||
			!authHeaders?.headers?.Authorization
		) {
			authHeaders = {
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
					}`,
				},
			};
		}
		const formBody = new FormData();
		files?.forEach((file) => {
			formBody.append(`photos[]`, file, file.name);
		});
		if (data) {
			formBody.set('data', JSON.stringify(data));
		}
		const res = await axios.default.post(
			process.env.REACT_APP_ROME_API_ENDPOINT + '/file-sharing/share',
			formBody,
			authHeaders
		);

		return res.data;
	};

	const shareFiles = async (
		dto: {
			to: Array<string>;
			sendReceipt: boolean;
			message: string;
			subject: string;
			validUntil: string;
			useCc: boolean;
		},
		files: File[]
	) => {
		return await callPutShareMultipleFiles(files, dto);
	};

	return (
		<FileShareContext.Provider
			value={{
				retrieveShareData,
				shareFiles,
				getFileShareInstance,
				downloadFileShareFile,
				killExpiredShare,
				generateFileShareFileShareLink,
				audit,
				recallSharedFiles,
				getStats,
			}}
		>
			{children}
		</FileShareContext.Provider>
	);
};

export const useFileShare = () => {
	const context = React.useContext(FileShareContext);
	if (context === undefined)
		throw new Error('Expected to be n a file share context but was not');

	return context;
};
