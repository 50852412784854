import styled from 'styled-components';
import { Link as LinkBase } from '@reach/router';

import { Heading } from '../../../ui';
import tabButton from '../../../../core-ui/components/tab-button';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const WorkflowTitle = styled(Heading)`
	margin-bottom: 0;
	padding-bottom: 0;
`;

export const Link = styled(LinkBase)`
	${tabButton};
	color: ${defaults?.secondary};

	&:hover {
		color: ${defaults?.secondaryHighlighted};
	}

	&.selected {
		border-bottom: 2px solid ${defaults?.primary} !important;
	}
`;
