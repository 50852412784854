import _, { isObject, last, values } from 'lodash';
import { IKeyValueMap } from 'mobx';
import {
	getIdentifier,
	getParent,
	getParentOfType,
	getPath,
	getPathParts,
	getSnapshot,
	getType,
	hasParentOfType,
	IAnyModelType,
	IMSTArray,
	IMSTMap,
	Instance,
	isArrayType,
	ISimpleType,
	IStateTreeNode,
	IType,
	resolveIdentifier,
	SnapshotOut,
	types,
} from 'mobx-state-tree';
import {
	IAnyComplexType,
	IAnyStateTreeNode,
	STNValue,
} from 'mobx-state-tree/dist/internal';
import { Maybe, MaybeNull, Predicate } from 'types/globals';
import { getClient } from '../core';
import { parseValidInt, throwErr } from './index';
import { _logError } from './log';
import { ensureStartingSlash } from './string.utils';

/**
 * Generate a BSON ObjectID
 * Normally the 12-byte ObjectId value consists of:
 * a 4-byte value representing the seconds since the Unix epoch,
 * a 5-byte random value, and
 * a 3-byte counter, starting with a random value.
 *
 * We keep the 4-byte timestamp, but the last 8 bytes are completely random.
 */
export function generateID() {
	const hex = (x: number) => (~~x).toString(16);
	const randomByte = () => hex(Math.random() * 16);
	return `${hex(Date.now() / 1000)}${' '.repeat(16).replace(/./g, randomByte)}`;
}

export function dateFromId(id: string): Date {
	const dateSection = id.slice(0, 8);
	const unixTimestamp = parseValidInt(dateSection, 16);
	return new Date(unixTimestamp * 1000);
}

export type IdentifiableNode = STNValue<
	Identifiable,
	ISimpleType<Identifiable>
>;

export function convertToMapSnapshot<T extends Identifiable>(
	items: readonly T[]
): IKeyValueMap<T> {
	return items.reduce((acc, x) => {
		acc[x._id] = x;
		return acc;
	}, {} as Record<string, T>);
}

export const getId = <T extends Identifiable>(x: OrID<T>): string => {
	if (!x) {
		return throwErr('Cannot get ID of falsy value');
	}
	if (typeof x === 'string') {
		return x;
	}
	if (typeof x?._id === 'string') {
		return x._id;
	}

	return '';
};

export const withId = <T extends Identifiable>(
	idToMatch: OrID<T>
): Predicate<T> => {
	const targetId = getId(idToMatch);
	return ({ _id }) => _id === targetId;
};

export const getName = (x: MaybeNull<Displayable>): string => {
	if (!x) {
		return '';
	}
	if (typeof x === 'string') {
		return x;
	}
	if (`givenName` in x && `familyName` in x) {
		return `${x.givenName} ${x.familyName}`;
	}
	if ('name' in x) {
		return x.name;
	}
	if ('title' in x) {
		return x.title;
	}
	return '';
};

export const shouldShow = <T>(x: Maybe<T>): x is T => !!x;
export const shouldShowAll = <T extends IAnyStateTreeNode>(
	...xs: readonly Maybe<T>[]
): boolean => xs.every(shouldShow);

/**
 * Note: `types` order matters!
 * If `node` has multiple parents with type in `types`,
 * The one matching the first type in `types` will be returned.
 */
export function getParentWithTypeIn<T extends IAnyComplexType>(
	node: IAnyStateTreeNode,
	types: readonly T[]
) {
	const parentType = types.find((t) => hasParentOfType(node, t));
	if (!parentType) {
		throw new Error('Failed to find the parent of a given type');
	}
	return getParentOfType(node, parentType);
}

export function loadIdentifier<T extends IAnyModelType>(
	model: T,
	node: IAnyStateTreeNode,
	id: string
): Instance<typeof model> {
	return (
		resolveIdentifier(model, node, id) ||
		throwErr(`${model.name} with identifier ${id} could not be found.`)
	);
}

/**
 * Note: `types` order matters!
 * If `node` has multiple parents with type in `types`,
 * The one matching the first type in `types` will be returned.
 */
export function resolveIdentifierWithTypeIn<T extends IAnyModelType>(
	types: readonly T[],
	node: IAnyStateTreeNode,
	id: string
): Maybe<Instance<T>> {
	for (let type of types) {
		const resolved = resolveIdentifier(type, node, id);
		if (resolved) {
			return resolved;
		}
	}
}

type IdentifiableType<M extends Identifiable> = IType<any, any, M>;
export type LazyReference<T extends Identifiable> = IType<string, string, T>;

export const isIdentifiableLoaded = <T extends Identifiable>(
	o: Maybe<T>
): o is T => !!o && o._id !== '$loading';

export const isArchived = (archived: boolean) => <T extends Archivable>(
	o: Maybe<T>
): o is T => o?.archived === archived;

interface LazyReferenceOptions<M extends Identifiable> {
	model: IdentifiableType<M> & IAnyComplexType;
	getter: (identifier: string, parent: IAnyStateTreeNode) => M;
}

export function lazyReference<M extends Identifiable>(
	options: LazyReferenceOptions<M>
): LazyReference<M> {
	const lazyReferenceType = types.late(() =>
		types.reference(options.model, {
			get(identifier: string, parent): M {
				if (!parent) {
					throw new Error('Cannot resolve a reference outside of a tree');
				}
				if (identifier === '$loading') {
					return resolveIdentifier(
						options.model as IAnyModelType,
						parent,
						identifier
					);
				}
				return options.getter(identifier, parent);
			},
			set(value) {
				if (value) {
					return getId(value);
				}
				return '';
			},
		})
	);
	return types.snapshotProcessor(lazyReferenceType, {
		preProcessor: (x) => {
			// Handle a full object (with _id) being passed instead of just a string ID.
			if (isObject(x)) {
				return getId(x);
			}
			// This could actually be undefined – `lazyReference` does not know if it is wrapped in a `types.maybe`.
			// Return it as is.
			return x;
		},
	});
}

export interface EntityStore<M extends IAnyModelType, T = never> {
	addOne(x: Instance<M>): void;

	addMany(x: ReadonlyArray<Instance<M>>): void;

	addPage(page: PaginateResult<Instance<M>>): void;

	entities: IMSTMap<Instance<M>>;

	pages: PagesState<Instance<M>>;

	readonly currentPage: readonly Instance<M>[];

	/**
	 * @deprecated
	 */
	all?: readonly Instance<M>[];
}

export interface EntityFetcher<T extends Identifiable> {
	// Fetch many
	(): void;

	// Fetch one
	(id: string): void;

	// Fetch page
	(query: EntityQuery<T>): void;
}

const allEntities: unique symbol = Symbol('All Entities');
type AllEntities = typeof allEntities;
type Fetchable<T extends object = object> =
	| string
	| EntityQuery<T>
	| AllEntities;

const isFetchAll = (x: Fetchable): x is AllEntities => x === allEntities;
const isPaginateResult = <T>(
	x: T | T[] | PaginateResult<T>
): x is PaginateResult<T> => !!(x as PaginateResult<T>).docs;

// Allow stale results for 1 minute.
const staleResultsMs = 60 * 1000;

export function makeEntityFetcherFor<
	M extends IAnyModelType,
	T extends Instance<M> & Identifiable
>(
	node: IStateTreeNode<M> &
		Pick<EntityStore<M>, 'addOne' | 'addPage' | 'addMany'>,
	baseRoute: string,
	useAll = false
): EntityFetcher<T> {
	const inFlight = new Set<Fetchable>();
	baseRoute = ensureStartingSlash(baseRoute);

	let nextFetchAll = -1;

	const shouldFetch = (id: Fetchable) => {
		if (id === '$loading') {
			return false;
		}
		if (isFetchAll(id)) {
			return Date.now() >= nextFetchAll;
		}
		if (typeof id === 'object') {
			return true;
		}
		return !inFlight.has(id);
	};

	const onFetch = (resBody: T | T[] | PaginateResult<T>): void => {
		if (Array.isArray(resBody)) {
			node.addMany(resBody);
			nextFetchAll = Date.now() + staleResultsMs;
		} else if (isPaginateResult(resBody)) {
			return node.addPage(resBody);
		} else {
			return node.addOne(resBody);
		}
	};

	return (id: Fetchable = allEntities): void => {
		if (shouldFetch(id)) {
			let route: string;

			if (isFetchAll(id)) {
				route = useAll ? `${baseRoute}/all` : baseRoute;
			} else if (typeof id === 'string') {
				route = `${baseRoute}/${id}`;
			} else {
				route = `${baseRoute}/?${_.map(id, (v, k) => `${k}=${v}`).join('&')}`;
			}

			if (!values(inFlight).some((inflight) => inflight === id)) {
				inFlight.add(id);

				getClient(node)
					.get(route)
					.then(onFetch)
					.catch(_logError)
					.finally(() => inFlight.delete(id));
			}
		}
	};
}

export async function makeAsyncEntityFetcherFor<
	M extends IAnyModelType,
	T extends Instance<M> & Identifiable
>(
	node: IStateTreeNode<M> &
		Pick<EntityStore<M>, 'addOne' | 'addPage' | 'addMany'>,
	baseRoute: string,
	useAll = false
): Promise<EntityFetcher<T>> {
	const inFlight = new Set<Fetchable>();
	baseRoute = ensureStartingSlash(baseRoute);

	let nextFetchAll = -1;

	const shouldFetch = (id: Fetchable) => {
		if (id === '$loading') {
			return false;
		}
		if (isFetchAll(id)) {
			return Date.now() >= nextFetchAll;
		}
		if (typeof id === 'object') {
			return true;
		}
		return !inFlight.has(id);
	};

	const onFetch = (resBody: T | T[] | PaginateResult<T>): void => {
		if (Array.isArray(resBody)) {
			node.addMany(resBody);
			nextFetchAll = Date.now() + staleResultsMs;
		} else if (isPaginateResult(resBody)) {
			return node.addPage(resBody);
		} else {
			return node.addOne(resBody);
		}
	};

	return async (id: Fetchable = allEntities): Promise<void> => {
		if (shouldFetch(id)) {
			let route: string;

			if (isFetchAll(id)) {
				route = useAll ? `${baseRoute}/all` : baseRoute;
			} else if (typeof id === 'string') {
				route = `${baseRoute}/${id}`;
			} else {
				route = `${baseRoute}/?${_.map(id, (v, k) => `${k}=${v}`).join('&')}`;
			}

			if (!values(inFlight).some((inflight) => inflight === id)) {
				inFlight.add(id);

				const response = await getClient(node).get(route);
				onFetch(response);
				inFlight.delete(id);
			}
		}
	};
}

export function nodeGuid(node: IAnyStateTreeNode): string {
	if (!node) {
		return 'NULL_NODE';
	}
	let nodeType: { name: string };
	try {
		nodeType = getType(node);
	} catch (err) {
		nodeType = { name: 'INVALID_NODE' };
	}
	const nodeId = getIdentifier(node);
	return `${nodeType.name}_${nodeId ?? 'UNIDENTIFIED'}`;
}

export function getArrayParent<T extends object>(
	node: STNValue<T, IType<unknown, unknown, T>>
): IMSTArray<IType<unknown, unknown, T>> {
	const parent = getParent(node);
	if (isArrayType(getType(parent))) {
		return parent as IMSTArray<IType<unknown, unknown, T>>;
	} else {
		return throwErr('Node is not in an array');
	}
}

export const getArrayIndex = (node: IAnyStateTreeNode): number =>
	parseValidInt(last(getPathParts(node)));

export const hasPathKey = (node: IAnyStateTreeNode, key: string): boolean =>
	getPath(node).endsWith(key);

export const isFirstInArray = (node: IAnyStateTreeNode) =>
	hasPathKey(node, '0');

export const isLastInArray = (node: IAnyStateTreeNode) =>
	hasPathKey(node, `${getArrayParent(node).length - 1}`);

export function previousArrayItem<T extends object>(
	node: STNValue<T, IType<unknown, unknown, T>>
): Maybe<T> {
	const arr = getArrayParent(node);
	const idx = arr.indexOf(node);
	if (idx > 0) {
		return arr[idx - 1];
	}
}

export function asValidSnapshot<T extends IAnyModelType>(
	input: object,
	type: T
): Maybe<SnapshotOut<T>> {
	return type.is(input) ? getSnapshot(type.create(input)) : undefined;
}
