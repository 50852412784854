import React from 'react';
import { _logError } from '../../../../../utils/common/log';
import { NotificationsContext } from '../../../../notifications';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import { Workflow } from '../../types/workflow.types';
import { StatusEnum } from '../../types/workflowStatus.types';
import StageDialog from '../stage-actions/stage-dialog.component';

interface Props {
	workflow?: Workflow | null;
	status: StatusEnum;
	onUpdated: (wf: Workflow) => void;
}

const UpdateWorkflowStatusDialog = (props: Props) => {
	const { workflow, status } = props;
	const { updateStatus } = useWorkflowContext();
	const { info, warn } = React.useContext(NotificationsContext);
	const onSubmit = async (message: string) => {
		try {
			const updatedworkflow = (await updateStatus(
				status,
				workflow as Workflow,
				message
			)) as Workflow;
			props.onUpdated(updatedworkflow);
			info(`Successfully updated ${workflow?.title} to ${status}`);
		} catch (error) {
			_logError(error);
			warn(
				'An error occurred while updating the workflow status. Please try again later.'
			);
		}
	};

	return (
		<StageDialog
			header={`Changing status to ${status}`}
			context="Please provide some context about the status change."
			successMessage="Status updated!"
			onClick={onSubmit}
		/>
	);
};

export default UpdateWorkflowStatusDialog;
