import { SnapshotOrInstance, types } from 'mobx-state-tree';

export const EventTypeModel = types.enumeration('EventType', [
	'comment',
	'statusChange',
	'assetUpload',
	'backwardTransition',
	'setOwner',
]);
export type EventType = SnapshotOrInstance<typeof EventTypeModel>;
export const EventType: Record<EventType, EventType> = {
	comment: 'comment',
	statusChange: 'statusChange',
	assetUpload: 'assetUpload',
	backwardTransition: 'backwardTransition',
	setOwner: 'setOwner',
};
