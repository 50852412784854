import { NavItem } from 'reactstrap';
import styled, { StyledComponent } from 'styled-components';

import { focus } from '../../core-ui/ui/ui';
import { fsNormal } from '../../core-ui/ui/typography';
import anchor from '../../core-ui/components/anchor';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const FilterNavItem = styled(NavItem)`
	margin-top: 1em;

	&:first-of-type {
		margin-top: 0;
	}

	&:last-of-type {
		margin-bottom: 2em;
	}
`;

export const FilterNavButton: StyledComponent<'button', {}> = styled.button`
	${anchor}
	${fsNormal}
	border-bottom: 1px solid transparent;

	&:focus {
		${focus}
	}

	&:hover {
		border-bottom: 1px solid ${defaults?.primaryHighlighted};
		text-decoration: none;
	}

	&.active {
		border-bottom: 1px solid ${defaults?.primaryHighlighted};
		color: ${defaults?.primaryHighlighted};

		&:hover {
			border-bottom: 1px solid transparent;
		}
	}
`;
