import * as d3 from 'd3';
export interface ChartInterface {
	create: () => void;
	setData: (data: any[]) => void;
	setElement: (element: HTMLElement) => void;
	setTickInterval: (tickinterval: number) => void;
	setOptions: (options: { [name: string]: any }) => void;
}

export class BarChart implements ChartInterface {
	private element: HTMLElement;
	private selector: string = '';
	private data: any[];
	private svg: any;
	private options: { [name: string]: any } = {
		colors: {
			highlight: '#b11adc',
			highlightLight: '#d975f6',
			text: '#777',
			xAxis: '#eee',
			yAxis: '#999',
		},
		height: 200,
		width: 400,
		margin: {
			top: 10,
			left: 30,
			right: 10,
			bottom: 20,
		},
		animationDuration: 300,
		ticksInterval: 10,
	};

	constructor(element: any, data: any[], selector: string) {
		this.element = element;
		this.data = data;
		this.selector = selector;
	}
	private yScale: any;
	private xScale: any;
	private tip: any;

	public create() {
		this.initValues();
		this.initScales();
		this.initChart();
		this.createAxis();
		this.createBars();
	}

	public setTickInterval(tickInterval: number) {
		this.options.ticksInterval = tickInterval;
	}

	public setData(data: any[]) {
		this.data = data;
	}

	public setElement(element: HTMLElement) {
		this.element = element;
	}

	public setOptions(options: { [name: string]: any }) {
		this.options = {
			...this.options,
			...options,
		};
	}

	private initChart() {
		const el = d3.select(this.selector);
		el.select('svg').remove();
		this.svg = el.append('svg').attr('viewBox', `0 0 ${360} ${200}`);
	}

	private initValues() {}

	private initScales() {
		const minValue = Number(d3.min(this.data.map((el) => el.value)));
		const maxValue = Number(d3.max(this.data.map((el) => el.value)));
		this.yScale = d3
			.scaleLinear()
			.domain([minValue, maxValue])
			.range([200 - 20, 20]);

		this.xScale = d3
			.scaleBand<number>()
			.domain(d3.range(this.data.length))
			.range([40, 360 - 10])
			.paddingInner(this.calculatePadding(this.data.length));
	}

	private getBarMaxWidth() {
		const width = window.innerWidth;
		return width <= 768 ? (width <= 200 ? 10 : 15) : 20;
	}

	private getBarColor(object: any, change: number = 0) {
		return object.color;
	}
	// Define the div for the tooltip

	private createBars() {
		const bars = this.svg
			.selectAll('.bar')
			.data(this.data)
			.enter()
			.append('rect')
			.classed('bar', true)
			.style('fill', 'transparent')
			.style('display', (d: any) =>
				typeof d.value === 'number' ? 'initial' : 'none'
			)
			.attr('x', (d: any, i: any) => this.xScale(i))
			.attr('y', this.yScale(0))
			.attr('width', this.xScale.bandwidth())
			.attr('height', 0);

		bars
			.transition()
			.style('fill', (d: any, i: any) => this.getBarColor(d))
			.duration(this.options.animationDuration)
			.delay((d: any, i: any) => (i * this.options.animationDuration) / 3)
			.attr('x', (d: any, i: any) => this.xScale(i))
			.attr('y', (d: any, i: any) =>
				d.value < 0 ? this.yScale(0) : this.yScale(d.value)
			)
			.attr('width', this.xScale.bandwidth())
			.attr('height', (d: any, i: any) =>
				d.value < 0
					? this.yScale(d.value) - this.yScale(0)
					: this.yScale(0) - this.yScale(d.value)
			);

		bars
			.on('mouseover touchstart', (d: any, i: any, nodes: any) => {
				const tick = d3.select(
					`.x-axis.${this.selector.replace('#', '')} .tick:nth-child(${
						this.data.indexOf(i) + 1
					}`
				);
				const color = i.color;

				// @ts-ignore
				d3.select(d.path[0]).transition().style('fill', color);
				d3.select(d.path[0])
					.append('title')
					.text((i: any) => `${i.label}: ${Math.round(i.value) + '%'}`);
				// @ts-ignore
				tick.select('line').transition().style('stroke', color);
				// @ts-ignore
				tick.select('text').transition().style('fill', color);
			})
			.on('mouseout touchend', (d: any, i: any, nodes: any) => {
				const tick = d3.select(
					`.x-axis.${this.selector.replace('#', '')} .tick:nth-child(${
						this.data.indexOf(i) + 1
					}`
				);
				const color = i.color;

				d3.select(d.path[0])
					.select('tick')
					.attr('title', `${i.label}: ${Math.round(i.value) + '%'}`);
				// @ts-ignore
				d3.select(d.path[0]).transition().style('fill', color);

				tick
					.select('line')
					// @ts-ignore
					.transition()
					.style('stroke', this.options.colors.xAxis);

				// @ts-ignore
				tick.select('text').transition().style('fill', '#777');
			});
	}

	private createAxis() {
		const xAxis = d3
			.axisBottom(this.xScale)
			.tickSize(-360)
			.tickFormat((d: any, i: any) => this.data[i].label.toUpperCase());

		const yAxis = d3
			.axisLeft(this.yScale)
			.tickSize(-360)
			.tickFormat((d: any) => `${d} %`)
			.ticks(this.options.ticksInterval);

		this.svg
			.append('g')
			.attr('class', 'axis x-axis ' + this.selector.replace('#', ''))
			.attr('transform', `translate(0, ${200 - 10})`)
			.call(xAxis);

		this.svg
			.append('g')
			.attr('class', 'axis y-axis ' + this.selector.replace('#', ''))
			.attr('transform', `translate(${+30}, 0)`)
			.call(yAxis);

		this.svg.selectAll('.axis .domain').remove();
		this.svg
			.selectAll('.axis.' + this.selector.replace('#', '') + ' .tick text')
			.style('fill', this.options.colors.text);
		this.svg
			.selectAll('.y-axis.' + this.selector.replace('#', '') + ' .tick line')
			.style('stroke', this.options.colors.yAxis);
		this.svg
			.selectAll('.x-axis.' + this.selector.replace('#', '') + ' .tick line')
			.style('stroke', this.options.colors.xAxis);
		this.svg.selectAll('text').style('font-size', '6px');
	}

	private calculatePadding(barCount: number) {
		return 1.05 - (barCount * this.getBarMaxWidth()) / 360;
	}
}

export class ChartRenderer {
	private chart: ChartInterface;
	private data: any[] = [];
	private element?: HTMLElement = undefined;

	constructor(chart: ChartInterface) {
		this.chart = chart;
		this.initResize();
	}

	setTickInterval(tickInterval: number) {
		this.chart.setTickInterval(tickInterval);
	}

	setData(data: any[]) {
		this.data = data;
	}

	setElement(element: HTMLElement) {
		this.element = element;
	}

	initResize() {
		let resizeTimer: any;
		const resizeListener = () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				this.render();
			}, 200);
		};
		window.removeEventListener('resize', resizeListener);
		window.addEventListener('resize', resizeListener);
	}

	render() {
		const options = {
			width: this.element?.offsetWidth,
			height: this.element?.offsetWidth! / 1.6,
		};
		this.chart.setElement(this.element!);
		this.chart.setData(this.data);
		this.chart.setOptions(options);
		this.chart.create();
	}
}

export const getRandomData = (limit: number): any[] => {
	const chars = 'abcdefghijklmnopqrstuvwxyz';
	const getValue = () => {
		const value = Math.floor(Math.random() * 101);
		if (value < 0) return value * -1;
		return value === 0 || value === -1 || value === 1
			? 10
			: value < 0
			? value * -1
			: value;
	};
	return chars
		.substr(0, limit)
		.split('')
		.map((el) => {
			let value = getValue();
			if (value < 0) {
				value = value * -1;
			}
			return {
				value: value,
				label: el,
			};
		});
};
