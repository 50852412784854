import { useLocalStorage } from './usePersistedState';

const useViewType = () => {
	const VIEW_TYPE_STORAGE_KEY = 'viewType';
	const [viewType, setViewType] = useLocalStorage(
		VIEW_TYPE_STORAGE_KEY,
		'list'
	);

	return [viewType, setViewType];
};

export default useViewType;
