import React, { useCallback, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Container, Row } from 'reactstrap';

import AssetCard from '../../../../dam-assets/components/asset-card.component';
import AssetSelectionToolbar, {
	AssetSelectionTool,
	AssetSelectionToolbarClickEvent,
} from '../../../../dam-assets/components/asset-selection-toolbar.component';
import themeStore from '../../../../../core-ui/models/ThemeStore';

import { flatten, isArray, orderBy } from 'lodash';
import { SortOrder } from '../../../../dam-asset-collections/components/facet-tree-custom-hits.component';
import { flattenStages } from '../../helpers/workflowStage.helpers';
import {
	InputSlot,
	Workflow,
	AssetVersion,
	Stage,
} from '../../types/workflow.types';

const assetTerm = themeStore._.asset;

const tools = [
	AssetSelectionTool.VIEW,
	AssetSelectionTool.COMPARE,
	AssetSelectionTool.DOWNLOAD,
	AssetSelectionTool.SHARE,
];

// components
const renderNoResults = () => (
	<Col md={4} className="mb-4">
		<p>
			<em>No {assetTerm.toLowerCase()}s yet</em>
		</p>
	</Col>
);
type Props = {
	workflow: Workflow;
};
const AssetsPanel = (props: Props) => {
	const { workflow } = props;
	const inputSlots = flattenStages(workflow as Workflow)?.map(
		(stage: Stage | Stage[]) => {
			if (isArray(stage)) {
				return [...stage.flatMap((stage) => stage?.inputSlots)] as InputSlot[];
			}
			return stage?.inputSlots as InputSlot[];
		}
	);
	const [currentSort, dispatchCurrentSort] = React.useReducer(
		function (
			currentSortOrder: SortOrder,
			action: { type: 'asc' | 'desc'; payload?: string }
		) {
			switch (action.type) {
				case 'asc':
					return { sort: action.type, field: action.payload } as SortOrder;
				case 'desc':
					return { sort: action.type, field: action.payload } as SortOrder;
			}
		},
		{ sort: 'asc', field: 'fileName' } as SortOrder
	);

	const [isSelectingAssets, setSelectingAssets] = useState(false);
	const [selectedAssets, setSelectedAssets] = useState<Array<AssetVersion>>([]);
	const isCardSelected = (asset: AssetVersion) => {
		if (selectedAssets) {
			return selectedAssets.includes(asset);
		} else {
			return false;
		}
	};

	// callbacks
	const toolbarCallBack = useCallback(
		(event: AssetSelectionToolbarClickEvent) => {
			if (event.tool === AssetSelectionTool.SELECTION) {
				const isSelecting: boolean = event.value;
				setSelectingAssets(isSelecting);
				if (!isSelecting) {
					// empty selected assets if selecting is turned off
					setSelectedAssets([]);
				}
			}
		},
		[setSelectingAssets]
	);

	const assetSelectionCallBack = useCallback(
		(asset: AssetVersion) => {
			const index = selectedAssets.indexOf(asset);
			if (index > -1) {
				// remove from selection list
				setSelectedAssets((selectedAssets) =>
					selectedAssets.filter((a) => a._id !== asset._id)
				);
			} else {
				// add to selection list
				setSelectedAssets((selectedAssets) => selectedAssets.concat(asset));
			}
		},
		[selectedAssets]
	);

	// render methods
	const renderCards = (versions: AssetVersion[], workflow: Workflow) =>
		versions.map((version, idx) => (
			<Col lg={3} md={6} key={`wfAsset${idx}`} className="mt-1 mb-4">
				<AssetCard
					showUploadedDate
					type={'workflow'}
					workflowId={workflow._id}
					workflowOwner={workflow.createdBy._id}
					isEditedByCreatorOnly={workflow.isEditedByCreatorOnly}
					assetVersion={version}
					isLinkEnabled={!isSelectingAssets}
					isSelected={isCardSelected(version)}
					onClick={assetSelectionCallBack}
				/>
			</Col>
		));

	const renderAssetCards = (slot: InputSlot) => {
		if (slot?.versions?.length) {
			return renderCards(
				orderBy(
					slot.versions,
					(version) => version[currentSort.field],
					currentSort.sort
				),
				workflow
			);
		} else {
			return renderNoResults();
		}
	};
	return (
		<Container fluid>
			<AssetSelectionToolbar
				tools={tools}
				className="workflow-assets-toolbar"
				onClick={toolbarCallBack}
				selectedAssets={selectedAssets}
				dispatchCurrentSort={dispatchCurrentSort}
			/>
			<Row>
				{flatten(inputSlots)?.map(
					(slot, idx) =>
						slot?.label && (
							<Col md={12} key={`wfInputSlot${idx}`} className="mt-4">
								<h2>
									{assetTerm} Uploads for "{slot?.label}":
								</h2>
								<Row>{renderAssetCards(slot)}</Row>
							</Col>
						)
				)}
			</Row>
		</Container>
	);
};

export default AssetsPanel;
