import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { Button, UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

import { StyledCircleButton } from '../../../circle-button.component';
import themeStore from '../../../../core-ui/models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
// main component
export const ActionButtonContainer = styled.div`
	display: inline-block;
	margin-left: 1em;
`;

export const ActionButton = styled(Button)`
	&& {
		border: 1px solid ${colors.darkestGrey};
	}

	&:disabled {
		cursor: not-allowed;
	}

	&.btn-secondary {
		&& {
			background-color: ${defaults?.secondary};
			border: 1px solid ${defaults?.secondary};

			&:hover {
				background-color: ${defaults?.secondaryHighlighted};
				&& {
					border: 1px solid ${defaults?.secondaryHighlighted};
				}
			}
		}
	}

	&.reverse {
		&:hover {
			color: #ffffff !important;
		}
	}

	&.bypass-stage-button {
		color: var(--text-color);
	}

	&.success:hover {
		background-color: ${colors.success};
		&& {
			border: 1px solid ${colors.success};
			color: var(--white);
		}
	}

	&.danger:hover {
		background-color: ${defaults?.danger};
		&& {
			border: 1px solid ${defaults?.danger};
			color: #ffffff !important;
		}
	}

	&.solid:hover {
		background-color: ${colors.darkestGrey};

		&& {
			border: 1px solid ${colors.darkestGrey};
			color: var(--white);
		}
	}
`;

// other components
export const IconWithRightMargin = styled(FontAwesomeIcon)`
	margin-right: 8px;

	&.completed {
		color: ${colors.success};
	}
`;

export const OutlinedCircleButton = styled(StyledCircleButton)`
	&& {
		border: 1px solid ${colors.darkestGrey};

		&:hover {
			color: var(--darkGrey);
		}
	}

	&.roadblocked {
		background-color: ${defaults?.danger};
		border: 1px solid ${defaults?.danger} !important;
		color: var(--white);
		width: max-content;
		&& {
			border-radius: 20px;
		}
	}
`;

export const StyledTooltip = styled(UncontrolledTooltip)`
	.tooltip {
		pointer-events: none;
	}
`;
