import { EntityMetadataTemplate } from 'components/workflow/workflows/types';
import { useAxios } from 'hooks';
import React from 'react';
import { Maybe } from 'types/globals';
import { useAuthContext } from 'utils/auth';

type MetadataContextType = {
	templates: EntityMetadataTemplate[];
	updateTemplate: (
		id: string,
		updatedTemplate: EntityMetadataTemplate
	) => Promise<Maybe<EntityMetadataTemplate>>;
};

const defaultState = {
	templates: [],
	updateTemplate: () =>
		Promise.resolve<EntityMetadataTemplate>({} as EntityMetadataTemplate),
};
const MetadataContext = React.createContext<MetadataContextType>(defaultState);
export const MetadataContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const metadataTemplateStore = useAxios<EntityMetadataTemplate>(
		'metadata-template'
	);
	const auth = useAuthContext();
	const [templates, setTemplates] = React.useState<EntityMetadataTemplate[]>();
	React.useEffect(() => {
		if (auth.currentUser && auth.currentUser?._id) {
			metadataTemplateStore.findAll().then(setTemplates);
		}
		//eslint-disable-next-line
	}, [auth.currentUser]);

	const updateTemplate = async (
		id: string,
		updatedTemplate: EntityMetadataTemplate
	) => {
		const response = (await metadataTemplateStore.updateOne(
			id,
			updatedTemplate
		)) as EntityMetadataTemplate;

		const updated = [
			...(templates || []).filter((template) => template._id !== response._id),
			response,
		];
		setTemplates(updated);
		return response;
	};

	return (
		<MetadataContext.Provider
			value={{
				templates: templates as EntityMetadataTemplate[],
				updateTemplate,
			}}
		>
			{children}
		</MetadataContext.Provider>
	);
};
export const useMetadataContext = () => {
	const context = React.useContext(MetadataContext);
	if (!context)
		throw new Error('Expected to be in a metadata context but wasnt.');
	return context;
};
