export type Stub<T> = {
	(): T;
};

export const identity = <T>(x: T): T => x;
export const stub = <T>(x: T): Stub<T> => () => x;
export const noop = stub(void 0);
export const stubFalse = stub(false);
export const stubTrue = stub(true);

export const noopFor = <Args, Ret extends void = void>() => (x: Args) =>
	void 0 as Ret;

export function throwErr(message: string): never {
	throw new Error(message);
}

export const isThenable = (x: unknown): x is PromiseLike<unknown> =>
	x && typeof (x as any).then === 'function';

export const coerceToPromise = <T>(x: T | Promise<T>): Promise<T> =>
	x instanceof Promise ? x : Promise.resolve(x);
