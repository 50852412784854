import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledLink } from 'components/dam-assets/components/asset-card.styled-components';
import { Heading, Loading, PageSubheading } from '../../components/index';
import { useWorkflowContext } from 'context/useWorkflowStore';
import {
	ReportStats,
	ReportTemplate,
} from 'components/workflows/models/useWorkflowStore';
import ThemeStore from 'core-ui/models/ThemeStore';
import Moment from 'moment';
import React, { useEffect } from 'react';
import {
	Badge,
	Card,
	CardFooter,
	CardHeader,
	Col,
	Container,
	Row,
	Table,
} from 'reactstrap';

export const ReportsTable = () => {
	const theme = ThemeStore.selectedTheme;
	const { fetchAvailableReports } = useWorkflowContext();
	const [loading, setLoading] = React.useState(false);
	const [reportData, setReportData] = React.useState<{
		reports: ReportTemplate[];
		stats: ReportStats;
	}>();

	useEffect(() => {
		const finishLoad = () => setLoading(false);
		setLoading(true);
		fetchAvailableReports().then(setReportData).finally(finishLoad);
		//eslint-disable-next-line
	}, []);

	return (
		<Container>
			<PageSubheading text={'ROME'} />
			<Heading>Reports</Heading>
			<Row className="py-3">
				<Col xl={12}>
					<Card>
						<CardHeader
							style={{
								color: theme.colors.secondary,
							}}
						>
							Recently Run Reports
						</CardHeader>
						<Table>
							<thead>
								<tr className="tabular-list-header">
									<th>Report</th>
									<th>Last Run On</th>
									<th>View Count</th>
								</tr>
							</thead>
							<tbody>
								{reportData?.reports?.map((report, index) => (
									<tr key={index}>
										<td>
											<StyledLink
												to={`/admin/reports/${report.title}/${report._id}`}
											>
												{report.title}
											</StyledLink>
										</td>
										<td>
											{Moment(reportData.stats[index].timestamp).format(
												'MMMM D, YYYY'
											)}
										</td>
										<td>
											<Badge color={'info'}>
												{reportData.stats[index].views} VIEWS
											</Badge>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<CardFooter>
							<div className="d-flex justify-content-end">
								<StyledLink to="/admin/report-builder">
									View Report Builder{' '}
									<FontAwesomeIcon icon={faChevronCircleRight} />
								</StyledLink>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Row>
			<Card>
				<CardHeader
					style={{
						color: theme.colors.secondary,
					}}
				>
					Available Reports
				</CardHeader>
				<Table>
					<thead>
						<tr>
							<th>Report</th>
						</tr>
					</thead>

					{loading === false && (
						<tbody>
							{reportData?.reports?.map((report) => (
								<tr key={report._id}>
									<td>
										<StyledLink
											to={`/admin/reports/${report.title}/${report._id}`}
										>
											{report.title}
										</StyledLink>
									</td>
								</tr>
							))}
						</tbody>
					)}
				</Table>
				{!!loading && (
					<Loading
						justifyContent="start"
						alignItems="center"
						label="Loading reports..."
					/>
				)}
				<CardFooter>
					<div className="d-flex justify-content-end">
						<StyledLink to="/admin/report-builder">
							View Report Builder{' '}
							<FontAwesomeIcon icon={faChevronCircleRight} />
						</StyledLink>
					</div>
				</CardFooter>
			</Card>
		</Container>
	);
};
