import { DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import dropDownMenuItem from '../core-ui/components/drop-down-menu';
import { fsNormal } from '../core-ui/ui/typography';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

const ActionsDropdownToggle = styled(DropdownToggle)`
	${dropDownMenuItem}

	&& {
		${fsNormal}
		border-color: ${defaults?.secondary};
		color: ${defaults?.secondary};
		padding: 8px 16px;
	}

	&:hover {
		&& {
			border-color: ${defaults?.secondary};
			background-color: ${defaults?.secondary} !important;
			color: var(--white);
		}
	}
`;

export default ActionsDropdownToggle;
