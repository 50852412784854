import { faFastForward } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback } from 'react';

import { buildClassList } from '../../../../../utils/common';
import { StageStatus } from '../../../../../utils/models/StageStatusModel';
import { useModalCreator } from '../../../../../utils/ModalStack';

import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';

import ForceActivateStageDialog from './force-activate-stage-dialog.component';
import { Stage } from '../../types/workflow.types';
import { useAuthContext, useGroupContext } from 'utils/auth';
import { useWorkflowContext } from 'context/useWorkflowStore';

interface Props {
	className?: string;
	stage: Stage;
}

export const ForceActivateButton = (props: Props) => {
	const { className, stage } = props;
	const { workflow } = useWorkflowContext();
	const modalStack = useModalCreator();
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	const canForceActivateStage = (stage: Stage) => {
		return (
			workflow?.createdBy?._id === currentUser._id ||
			(groupsForCurrentUser &&
				groupsForCurrentUser?.some((group) =>
					stage.owners.some((owner) => owner._id === group._id)
				)) ||
			(stage.owners && stage.owners.some((m) => m._id === currentUser._id)) ||
			currentUser.isAdmin
		);
	};
	const openForcedActivationModal = useCallback(() => {
		modalStack.addModal(<ForceActivateStageDialog stage={stage} />);
	}, [stage, modalStack]);

	if (
		!canForceActivateStage(stage) ||
		stage.status === StageStatus.active ||
		stage.status === StageStatus.roadblocked
	) {
		return null;
	}

	const buttonId = `forceActivateStage${stage._id}`;
	return (
		<ActionButtonContainer
			className={buildClassList('stage-action force-activate-stage', className)}
		>
			<StyledTooltip target={buttonId}>
				Forcibly set "{stage.title}" status to "active"
			</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				className="action-button force-activate-stage-button solid"
				onClick={openForcedActivationModal}
			>
				<IconWithRightMargin icon={faFastForward} />
				Force activate
			</ActionButton>
		</ActionButtonContainer>
	);
};
