import React from 'react';
import { HelpBlock } from './HelpBlock.styled';
import { FormGroup } from 'reactstrap';
import { EditPreferencesToggleSwitch } from './EditPreferencesToggleSwitch';
import { UserAssetViewType } from '../../../components/accounts/models/UserStatus.model';

type Props = {
	setEnabled: (bool: boolean) => void;
	preferenceStore: { setAssetViewPreference: (string: string) => void };
	preferencesEnabled: boolean;
	viewPreference: string;
};

export const AssetViewPreferences = (props: Props) => {
	const toSentenceCase = (type: string) =>
		`${type.toString()[0].toUpperCase()}${type
			.substring(1, type.length)
			.replace('View', ' View')}`;

	const toggleAssetViewPreference = (isEnabled: boolean) => {
		props.setEnabled(isEnabled);
		props.preferenceStore.setAssetViewPreference(
			isEnabled ? UserAssetViewType.searchView : UserAssetViewType.folderView
		);
	};

	return (
		<FormGroup style={{ fontSize: 14 }}>
			<HelpBlock style={{ fontSize: 16 }}>Asset View Preference</HelpBlock>
			<EditPreferencesToggleSwitch
				preferencesEnabled={props.preferencesEnabled}
				enabled={props.preferencesEnabled}
				onStateChanged={toggleAssetViewPreference}
				label={toSentenceCase(props.viewPreference)}
			/>
		</FormGroup>
	);
};
