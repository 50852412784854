import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
export const FileSharingInboxTbody = styled.tbody`
	background: #fff;
`;
export const CustomFontAwesomeIcon = styled(FontAwesomeIcon)`
	height: 1.15em;
	cursor: pointer;

	:hover {
		box-shadow: 0 0 0.15rem rgb(17 17 17 / 10%), 0 0 1.55rem rgb(17 17 17 / 10%);
	}
`;
