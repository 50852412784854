import {
	faArchive,
	faTimes,
	faTrashRestore,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteDefaults } from 'context/useThemeContext';
import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';
import { Maybe } from 'types/globals';
import storageModel from 'utils/models/StorageModel';
import { secondaryButton } from '../../../../core-ui/components/secondary-button';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { useAxios } from '../../../../hooks';
import { capitalizeFirstLetter, generateID } from '../../../../utils/common';
import { _logError } from '../../../../utils/common/log';
import { useModalCreator } from '../../../../utils/ModalStack';
import ConfirmationDialog from '../../../modals/confirmation-dialog.component';
import { NotificationsContext } from '../../../notifications';
import { WorkflowTemplate } from '../../workflows/types/workflow.types';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
// styled components
export const Button = styled.button`
	${secondaryButton};
	background-color: transparent !important;
	color: ${defaults?.primary} !important;
	height: 24px;
	line-height: 0;
	padding: 0;
	vertical-align: middle;
	width: 24px;

	&:hover {
		background-color: ${defaults?.danger} !important;
		color: ${colors.white} !important;
	}
`;

// interface
interface DeleteTemplateButtonProps {
	template: WorkflowTemplate;
	onDelete: (wt: WorkflowTemplate) => void;
	onUpdate: (wt: WorkflowTemplate) => void;
}

interface TemplateStateChangeMessages {
	buttonId: string;
	tooltip: string;
	heading: string;
	warning: string;
	icon: IconDefinition;
}

const messagesForAction = (action: string, icon: IconDefinition) => ({
	buttonId: `${action}TemplateButton`,
	tooltip: `${capitalizeFirstLetter(action)} template`,
	heading: `${capitalizeFirstLetter(action)} Template`,
	warning: `Are you certain you want to ${action} this template?`,
	icon: icon,
});

const messagesForState: Record<string, TemplateStateChangeMessages> = {
	draft: messagesForAction('delete', faTimes),
	final: messagesForAction('archive', faArchive),
	archived: messagesForAction('unarchive', faTrashRestore),
};

// component
const DeleteTemplateButton = (props: DeleteTemplateButtonProps) => {
	const modalStack = useModalCreator();
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const { info, error } = React.useContext(NotificationsContext);
	const msgs = messagesForState[props.template.editingState as string];
	const buttonId = msgs.buttonId + props?.template?._id ?? generateID();
	const { template } = props;

	// callbacks
	const showModal = useCallback(
		() =>
			modalStack.addModal(
				<ConfirmationDialog
					header={msgs.heading}
					onConfirm={async () => {
						let templateState: Maybe<string>,
							shouldDelete: boolean = false;
						try {
							const templateChangeStatus = msgs.buttonId.replace(
								'TemplateButton',
								''
							);
							if (templateChangeStatus === 'unarchive') templateState = 'draft';
							if (templateChangeStatus === 'archive')
								templateState = 'archived';
							if (templateChangeStatus === 'delete') shouldDelete = true;

							if (!shouldDelete && !!templateState) {
								const updatedTemplate = await templateStore.updateOne(
									props.template._id,
									{
										...props.template,
										editingState: templateState,
									}
								);
								if (updatedTemplate) props.onUpdate(updatedTemplate);
								info('Template saved as ' + templateState?.toString());
								return;
							} else {
								await templateStore.deleteOne(template._id);
								props.onDelete(template);
								info('Successfully deleted ' + template.title);
							}
						} catch (e) {
							_logError(e);
							error(
								`An issue occurred while saving as Draft. Please try again later.`
							);
						}
					}}
				>
					<p>{msgs.warning}</p>
				</ConfirmationDialog>
			),
		[error, info, template, templateStore, msgs, modalStack, props]
	);

	// render methods
	const renderButton = () => (
		<Button id={buttonId} onClick={showModal}>
			{document.getElementById(buttonId) && (
				<UncontrolledTooltip target={buttonId}>
					{msgs.tooltip}
				</UncontrolledTooltip>
			)}
			<FontAwesomeIcon icon={msgs.icon} />
		</Button>
	);

	const render = () => renderButton();

	return render();
};

export default DeleteTemplateButton;
