import { faTag, faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from '@reach/router';
import { User } from 'utils/auth';
import { capitalizeFirstLetter, humanReadableFileSizeSI } from 'utils/common';
import { Divider, Heading, Loading, Subheading } from 'components/index';
import {
	EntityPropLabel,
	EntityPropList,
	EntityPropListItem,
	TagBadge,
} from 'components/entity-details.styled-components';
import { useAxios } from 'hooks';
import { isEqual, map } from 'lodash';
import React, { useEffect } from 'react';
import LineTo from 'react-lineto';
import Moment from 'react-moment';
import {
	Badge,
	CardBody,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row,
} from 'reactstrap';
import styled from 'styled-components';
import { AssetVersion } from 'components/workflow/workflows/types';
import { extractPreviewURL } from '../asset-card.component';
import ZoomComponent from '../asset-preview/Zoom.component';

const CompareHeader = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-flow: column wrap;
	margin-top: 0.25rem;
	margin-bottom: 0.75rem;
	padding: 20px;
`;

const CompareBody = styled.div`
	width: 100%;
	display: block;
	align-items: center;
	margin-top: 0.25rem;
	margin-bottom: 1rem;
	padding: 20px;
`;

const DifferencesCounter = styled.div`
	position: absolute;
	top: 310px;
	z-index: 19;
	left: 45%;
	color: #fff !important;
`;

export const CompareAssetsView = () => {
	const loader = useAxios<AssetVersion>('assets');
	const { assetAId, assetBId } = useParams();
	const [assetA, setAssetA] = React.useState({} as AssetVersion);
	const [assetB, setAssetB] = React.useState({} as AssetVersion);
	const [highlightDifferences, setHighlightDifferences] = React.useState(false);
	const isLoaded = React.useMemo(() => !!assetA._id && !!assetB._id, [
		assetA,
		assetB,
	]);

	const colorA = `rgb(30 71 107)`,
		colorB = `rgb(255 214 98)`;
	const labelA = `#222`,
		labelB = `#fff`;

	useEffect(() => {
		if (assetAId && !assetA?._id) {
			loader.findOne(assetAId).then(setAssetA);
		}
	}, [assetAId, loader, assetA]);

	useEffect(() => {
		if (assetBId && !assetB?._id) {
			loader.findOne(assetBId).then(setAssetB);
		}
	}, [assetBId, loader, assetB]);

	const getCreatorName = (createdBy: User) => {
		return (
			<>
				{createdBy.givenName} {createdBy.familyName}
			</>
		);
	};

	const CompareText = styled.p<{
		index: number;
		border?: string;
		highlightForeground?: string;
		highlightBackground?: string;
	}>`
        color: ${(props) => (props.index === 1 ? colorA : colorB)};
        border-bottom: ${(props) => highlightDifferences && props.border};
        font-size: ${(props) =>
					highlightDifferences && props.border ? '16px' : '14px'};
        font-weight: ${(props) =>
					highlightDifferences && props.border ? 'bold' : 'normal'};
        font-family: Roboto, sans-serif;
        width:fit-content;
        zIndex
    `;

	const AssetLabel = styled<any>(EntityPropLabel)`
		color: ${(props) => props.color};
		font-size: ${(props) => props.border && highlightDifferences && '16px'};
	`;

	const DifferenceLabel = ({
		children,
		isEqual,
		color,
	}: {
		children: React.ReactNode[] | React.ReactNode;
		isEqual: boolean;
		color: string;
	}) => {
		return (
			<AssetLabel color={color}>
				{children}{' '}
				{highlightDifferences && !isEqual && (
					<FontAwesomeIcon icon={faThumbtack} className={'text-danger'} />
				)}
			</AssetLabel>
		);
	};

	const getTextDecoration = (key: keyof AssetVersion, assetIdx: number) => {
		const assetKey = !key.includes('.')
			? key
			: key.substring(key.indexOf('.') + 1, key.length);
		const objAssetA = key.includes('.')
				? (assetA.metadata.values as Record<string, string[] | string>)
				: (assetA as AssetVersion),
			objAssetB = key.includes('.')
				? (assetB.metadata.values as Record<string, string[] | string>)
				: assetB;
		if (objAssetA && objAssetB) {
			const areEqual = isEqual(
				objAssetA[assetKey as keyof AssetVersion] as any,
				objAssetB[assetKey as keyof AssetVersion]
			);
			return areEqual
				? ''
				: {
						border: `1px solid ${assetIdx === 1 ? colorA : colorB}`,
						highlightBackground: `${assetIdx === 1 ? colorA : colorB}`,
						highlightForeground: `${assetIdx === 1 ? colorB : colorA}`,
				  };
		} else {
			return '';
		}
	};

	const keys = [
		'archived',
		'title',
		'fileName',
		'createdBy',
		'createdAt',
		'fileSizeBytes',
		'type',
	];

	const totalDifferences = React.useMemo(() => {
		const assetFieldDifferences = keys.filter(
			(key: any) =>
				!isEqual(
					assetA[key as keyof AssetVersion],
					assetB[key as keyof AssetVersion]
				)
		).length;
		return assetFieldDifferences;
	}, [assetA, assetB, keys]);

	const differentiatorLines = () =>
		assetA?.metadata?.values &&
		assetB?.metadata?.values &&
		highlightDifferences ? (
			<>
				{/* Asset property difference lines */}
				{keys
					.filter(
						(key: any) =>
							!isEqual(
								assetA[key as keyof AssetVersion],
								assetB[key as keyof AssetVersion]
							)
					)
					.map((key, index) => (
						<LineTo
							delay={1}
							zIndex={1}
							fromAnchor={'100, 50'}
							toAnchor={'0, 50'}
							key={key}
							to={`${assetB._id}-${key}`}
							from={`${assetA._id}-${key}`}
						/>
					))}
				{/* Asset metadata difference lines */}

				{Object.keys(assetA?.metadata?.values)
					.filter(
						(k) =>
							!isEqual(
								assetA?.metadata?.values![k as any],
								assetB?.metadata?.values![k as any]
							)
					)
					.map((key) => (
						<LineTo
							delay={1}
							zIndex={1}
							fromAnchor={'100, 50'}
							toAnchor={'0, 50'}
							key={key}
							to={`${assetB._id}-metadata-${key}`}
							from={`${assetA._id}-metadata-${key}`}
						/>
					))}
			</>
		) : null;

	return (
		<>
			<Container fluid>
				<Heading>Compare Assets</Heading>

				<FormGroup className="mx-3">
					<Label check>
						<Input
							type={'checkbox'}
							checked={highlightDifferences}
							onChange={(e) => setHighlightDifferences(e.target.checked)}
						/>
						Highlight differences
					</Label>
				</FormGroup>
				<Divider />
				<Row>
					{!isLoaded && <Loading label="Loading assets to compare..." />}
					{isLoaded && (
						<>
							{highlightDifferences && (
								<DifferencesCounter>
									<Badge color={'danger'} className="d-block">
										{totalDifferences} Differences found between assets.
									</Badge>
								</DifferencesCounter>
							)}

							{[assetA, assetB].map((asset, i) => {
								const archivedClassName = asset.archived
									? 'badge-danger'
									: 'badge-info';

								return (
									<Col
										className="card"
										style={{
											borderLeft:
												i === 0 ? `1px solid ${colorA}` : `1px solid ${colorB}`,
											backgroundColor:
												i === 0
													? `${colorA} !important`
													: `${colorB} !important`,
										}}
										xl={6}
										key={asset._id}
									>
										<CompareHeader>
											<Subheading
												style={{
													padding: 20,
													color: i === 0 ? colorB : colorA,
													fontSize: 32,
												}}
											>
												{asset.title}
											</Subheading>
											<CardBody>
												<div style={{ maxHeight: 400, height: 400 }}>
													<ZoomComponent
														className={'img-fluid'}
														alt={asset?.fileName}
														src={`${extractPreviewURL(asset)}`}
													/>
												</div>
											</CardBody>
										</CompareHeader>
										<CompareBody>
											<EntityPropList>
												<EntityPropListItem>
													<DifferenceLabel
														color={i === 0 ? labelB : labelA}
														isEqual={isEqual(assetA.archived, assetB.archived)}
													>
														Asset Status
													</DifferenceLabel>
													<Badge
														className={` ${asset._id}-archived ${archivedClassName}`}
													>
														{asset.archived ? 'Archived' : 'Active'}
													</Badge>
												</EntityPropListItem>

												<EntityPropListItem>
													<DifferenceLabel
														color={i === 0 ? labelB : labelA}
														isEqual={isEqual(assetA.fileName, assetB.fileName)}
													>
														File name
													</DifferenceLabel>
													<CompareText
														className={`${asset._id}-fileName`}
														{...getTextDecoration('fileName', i)}
														index={i}
													>
														{asset?.fileName}
													</CompareText>
												</EntityPropListItem>

												{asset?.title ? (
													<EntityPropListItem>
														<DifferenceLabel
															color={i === 0 ? labelB : labelA}
															isEqual={isEqual(assetA.title, assetB.title)}
														>
															Display Name
														</DifferenceLabel>
														<CompareText
															className={`${asset._id}-title`}
															{...getTextDecoration('title', i)}
															index={i}
														>
															{asset?.title}
														</CompareText>
													</EntityPropListItem>
												) : null}

												{asset?.createdBy ? (
													<EntityPropListItem>
														<DifferenceLabel
															color={i === 0 ? labelB : labelA}
															isEqual={isEqual(
																assetA.createdBy,
																assetB.createdBy
															)}
														>
															Added by
														</DifferenceLabel>
														<CompareText
															className={`${asset._id}-createdBy`}
															{...getTextDecoration('createdBy', i)}
															index={i}
														>
															{getCreatorName(asset.createdBy)}
														</CompareText>
													</EntityPropListItem>
												) : null}

												<EntityPropListItem>
													<DifferenceLabel
														color={i === 0 ? labelB : labelA}
														isEqual={isEqual(
															assetA.createdAt,
															assetB.createdAt
														)}
													>
														Created at
													</DifferenceLabel>
													<CompareText
														className={`${asset._id}-createdAt`}
														{...getTextDecoration('createdAt', i)}
														index={i}
													>
														<Moment
															format="MM/DD/YYYY"
															date={asset?.createdAt}
														/>
													</CompareText>
												</EntityPropListItem>

												<EntityPropListItem>
													<DifferenceLabel
														color={i === 0 ? labelB : labelA}
														isEqual={isEqual(
															assetA.fileSizeBytes,
															assetB.fileSizeBytes
														)}
													>
														File Size
													</DifferenceLabel>
													<CompareText
														className={`${asset._id}-fileSizeBytes`}
														{...getTextDecoration('fileSizeBytes', i)}
														index={i}
													>
														{humanReadableFileSizeSI(asset?.fileSizeBytes)}
													</CompareText>
												</EntityPropListItem>

												<EntityPropListItem>
													<DifferenceLabel
														color={i === 0 ? labelB : labelA}
														isEqual={isEqual(assetA.type, assetB.type)}
													>
														File Type
													</DifferenceLabel>
													<CompareText
														className={`${asset._id}-type`}
														{...getTextDecoration('type', i)}
														index={i}
													>
														{asset?.type}
													</CompareText>
												</EntityPropListItem>

												{asset.metadata &&
													asset.metadata.values &&
													map(
														asset.metadata.values,
														(value: string | string[], key: any) => (
															<EntityPropListItem
																className={`${asset._id}-metadata-${key}`}
																key={key}
															>
																<DifferenceLabel
																	color={i === 0 ? labelB : labelA}
																	isEqual={isEqual(
																		assetA?.metadata?.values![key],
																		assetB?.metadata?.values![key]
																	)}
																>
																	{capitalizeFirstLetter(key)}
																</DifferenceLabel>
																<CompareText
																	className={`${asset._id}-metadata-${key}`}
																	{...getTextDecoration(
																		`metadata.${key}` as any,
																		i
																	)}
																	index={i}
																>
																	{Array.isArray(value)
																		? value.join(', ')
																		: value}
																</CompareText>
															</EntityPropListItem>
														)
													)}
												{asset.metadata &&
												asset.metadata.tags &&
												asset.metadata?.tags?.length ? (
													<EntityPropListItem>
														<EntityPropLabel>
															<FontAwesomeIcon icon={faTag} /> Tags
														</EntityPropLabel>
														{asset.metadata.tags.map((tag: any) => (
															<TagBadge key={tag}>{tag}</TagBadge>
														))}
													</EntityPropListItem>
												) : null}
											</EntityPropList>
										</CompareBody>
									</Col>
								);
							})}
							{differentiatorLines()}
						</>
					)}
				</Row>
			</Container>
		</>
	);
};
