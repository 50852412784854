import { StageStatus } from 'utils/models/StageStatusModel';
import React from 'react';
import Moment from 'react-moment';
import { flattenStages } from 'components/workflow/workflows/helpers/workflowStage.helpers';
import * as H from '../helpers/workflowDate.helpers';

type Props = {
	workflow: any;
};

export const WorkflowDueDate = ({ workflow }: Props) => {
	if (
		[StageStatus.active, StageStatus.completed, StageStatus.roadblocked].some(
			(m) => m === workflow?.stages[0]?.status
		) &&
		!flattenStages(workflow)
			?.flatMap((s) => s)
			.every((stage) => stage.status === 'completed')
	)
		return <Moment format="MMM DD" date={H.getDueDate(workflow)} />;

	return <span>TBD</span>;
};
