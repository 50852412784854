import { useLocalStorage } from 'utils/core/hooks/usePersistedState';

export const useSearchHistory = () => {
	const SEARCH_HANDLER_STORAGE_KEY = 'searchHistory';
	const [searchHistory, setSearchHistory] = useLocalStorage(
		SEARCH_HANDLER_STORAGE_KEY,
		{
			refinements: [],
			searchTerm: '',
		}
	);

	return [searchHistory, setSearchHistory];
};
