import React, { useContext } from 'react';
import { ModalBody } from 'reactstrap';
import CommonAssetVersionDisplay from './components/common-asset-version-display.component';
import LoadingAssetState from './components/loading-asset-state.component';
import DialogModal from '../modals/dialog-modal.component';
import styled from 'styled-components';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import {
	AssetVersion,
	InputSlot,
} from '../workflow/workflows/types/workflow.types';
import { NotificationsContext } from 'components/notifications';

interface AssetPreviewDialogProps {
	assetId?: string;
	workflowId?: string;
	workflowOwner?: string;
	isEditedByCreatorOnly?: boolean;
	assetToPreview?: AssetVersion; // if they pass in a DAM Asset
	slot?: InputSlot;
	// in workflows, assets uploaded to stages are not going to be inserted into the database yet,
	// this input will determine if we should attempt to fetch the asset's data
	shouldFetch?: boolean;

	onDelete: () => void;
}

const XlDialog = styled(DialogModal)`
	max-width: calc(100% - 15rem) !important;
`;

function assetReducer(
	state: { asset: AssetVersion | null },
	action: { type: 'set'; payload: AssetVersion }
) {
	switch (action.type) {
		case 'set':
			return { ...state, asset: action.payload };
		default:
			return state;
	}
}

export const AssetDetailsDialog = (props: AssetPreviewDialogProps) => {
	const { assetToPreview, shouldFetch, assetId } = props;
	const { error: showError } = useContext(NotificationsContext);
	const { findOne: findAsset } = useAssetHelper();
	const [assetVersion, dispatch] = React.useReducer(assetReducer, {
		asset: assetToPreview as AssetVersion,
	});

	React.useEffect(() => {
		const showErrorMsg = () =>
			showError(
				'An issue occurred while retrieving the asset. Please try again later.'
			);
		if (!assetVersion.asset && !!assetToPreview) {
			dispatch({ type: 'set', payload: assetToPreview as AssetVersion });
		} else if (!assetVersion.asset && assetId && shouldFetch) {
			findAsset(assetId as string)
				.then((asset) => {
					dispatch({ type: 'set', payload: asset as AssetVersion });
				})
				.catch(showErrorMsg);
		}
	}, [
		assetVersion.asset,
		findAsset,
		assetId,
		shouldFetch,
		showError,
		assetToPreview,
	]);

	const render = () => {
		const { asset } = assetVersion;

		if (asset) {
			// dam/assets is the new assets that are not attached to a workflow
			return (
				<XlDialog modalSize="xl" header={asset.fileName}>
					<ModalBody className="w-100">
						<CommonAssetVersionDisplay
							setAsset={(asset) => dispatch({ type: 'set', payload: asset })}
							onDelete={props.onDelete}
							workflowId={props?.workflowId || (asset?.workflowId as string)}
							workflowOwner={props.workflowOwner}
							isEditedByCreatorOnly={props.isEditedByCreatorOnly}
							hideBackLink={true}
							version={asset as AssetVersion}
							damAsset={asset}
						/>
					</ModalBody>
				</XlDialog>
			);
		} else return <LoadingAssetState />;
	};
	return render();
};
export default AssetDetailsDialog;
