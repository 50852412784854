import React, { useEffect } from 'react';
import './LoadingIndicator.scss';

interface LoadingIndicatorProps {
	dismiss: boolean;
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
	useEffect(() => {
		console.log('LoadingIndicator DISMISS', props.dismiss);
		if (props.dismiss) {
			const loader_nodes = Array.from(
				document.getElementsByClassName('no-freeze-spinner')
			);
			if (loader_nodes) {
				console.log('>>> DISMISS SPINNERS');
				loader_nodes.forEach((node) => node.classList.add('dismiss'));
				setTimeout(() => {
					console.log('>>> REMOVE SPINNERS');
					loader_nodes.forEach((e) => {
						//@ts-ignore
						e.style.display = 'none';
					});
				}, 1000);
			}
		}
	}, [props.dismiss]);

	return (
		<div className="no-freeze-spinner">
			<div id="no-freeze-spinner">
				<div>
					<i className="material-icons">account_tree</i>
					<i className="material-icons">photo_library</i>
					<i className="material-icons">description</i>
					<i className="material-icons">account_circle</i>
					<div></div>
				</div>
			</div>
		</div>
	);
};

export default LoadingIndicator;
