import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import LoadingIndicator from 'core-ui/components/LoadingIndicator';
import { AssetVersion } from '../workflow/workflows/types/workflow.types';
import { FullWidthButton } from './components/asset-details-sidebar/asset-details-tab-set.styled-components';
import styled from 'styled-components';
import { useFetching } from 'hooks/useFetching';
import { NotificationsContext } from 'components/notifications';

const Button = styled<any>(FullWidthButton)`
	white-space: nowrap;
	padding: 0.5rem;
	width: 170px;
`;

const AssetTest = () => {
	const [Assets, setAssets] = useState<AssetVersion[]>([]);
	const [isLoaded, setIsLoaded] = useState(false);

	const { runAssetTest, updateAssetMeta } = useAssetHelper();
	const { isFetching, beginFetching, finishFetching } = useFetching();
	const { info } = React.useContext(NotificationsContext);

	const AssetReport = async () => {
		beginFetching();
		runAssetTest()
			.then((e) => setAssets(e.data))
			.finally(finishFetching);

		setIsLoaded(true);
		return true;
	};

	const updateAssetMetadata = async () => {
		updateAssetMeta();
		runAssetTest()
			.then((e) => setAssets(e.data))
			.finally(finishFetching);
		info('Finished re-indexing asset metadata');
		return;
	};

	const downloadFile = async () => {
		const arr = [];
		for (const asset of Assets) {
			arr.push(asset.fileName + '\n');
		}

		const url = window.URL.createObjectURL(new Blob(arr));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'missing-assets'); //or any other extension
		document.body.appendChild(link);
		link.click();

		link.remove();
		return true;
	};

	return (
		<Container>
			{isFetching && <LoadingIndicator dismiss={false} />}
			<div>
				<h1>Asset Tester</h1>
				<p hidden={!isLoaded}>
					DAM assets listed in database, but asset file not found in storage:{' '}
					{Assets.length}
				</p>
				<Button hidden={Assets?.length !== 0} onClick={AssetReport}>
					Run Asset Report
				</Button>

				<Button hidden={Assets?.length === 0} onClick={downloadFile}>
					Download Filename List
				</Button>

				<span className={'px-2'}>
					<Button onClick={updateAssetMetadata}>Update Asset Metadata</Button>
				</span>
				<hr />

				<div>
					<ol>
						{Assets?.map((asset) => (
							<li>
								Storage Path:{' '}
								<a href={asset._id} target="_asset">
									{asset.path}
								</a>
							</li>
						))}
					</ol>
				</div>
			</div>
		</Container>
	);
};

export default AssetTest;
