import {
	faFileDownload,
	faTimes,
	faUserCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	getItemsOnPage,
	getTotalPages,
	Loading,
	PaginationContainer,
	ScrollTable,
	searchObject,
} from 'components/index';
import { FileShareInstance, useFileShare } from 'context/useFileShare';
import {
	CustomFontAwesomeIcon,
	FileSharingInboxTbody,
} from 'components/file-sharing/styled';
import { orderBy } from 'lodash';
import { NotificationsContext } from 'components/notifications';
import React, { useEffect } from 'react';
import {
	Alert,
	Badge,
	Button,
	Card,
	Input,
	ListGroup,
	ListGroupItem,
	ListGroupItemText,
	NavLink,
	PopoverBody,
	PopoverHeader,
	UncontrolledPopover,
	UncontrolledTooltip,
} from 'reactstrap';
import { FileSharingRecallDialog } from './FileSharingRecallDIalog';
import Moment from 'react-moment';
import './file-sharing.css';
import windowModel from 'utils/models/WindowModel';

const FileSharingHistory = () => {
	//state
	const [currentPage, setCurrentPage] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState('');
	const token = localStorage.getItem('rome_auth');
	const [sent, setSent] = React.useState<FileShareInstance[]>();
	const [loading, setLoading] = React.useState(false);
	const [isRecalling, setShowRecall] = React.useState(false);
	const [recallingInstance, setRecallingInstance] = React.useState<
		FileShareInstance
	>();

	const refs = React.useRef<Map<number, UncontrolledPopover>>(
		new Map<number, UncontrolledPopover>()
	);

	// hooks
	const { downloadFileShareFile, retrieveShareData } = useFileShare();
	const { error: showError, info } = React.useContext(NotificationsContext);

	const hasToken = React.useMemo(() => {
		if (token && JSON.parse(token) !== null) {
			return !!JSON.parse(token).accessToken ? true : false;
		}
	}, [token]);

	useEffect(() => {
		if (hasToken) {
			setLoading(true);
			retrieveShareData()
				.then((shareData) => {
					setSent(shareData?.sent);
				})
				.finally(() => setLoading(false));
		}
	}, [hasToken, retrieveShareData]);

	// helpers
	const getRecipient = (recipients: Array<{ email: string; _id: string }>) => {
		return recipients[0];
	};

	const getRecipientLink = (fileShareId: string, recipients: any[]) => {
		const recipient = getRecipient(recipients);
		return `/filesharing/${fileShareId}/${recipient?._id}?doNotAudit=true`;
	};

	const downloadFor = async (data: FileShareInstance) => {
		if (getIsExpired(data)) return;
		try {
			// not auditing these because these are the senders downloads
			data.attachments.forEach(async (attached) => {
				const downloadURL = await downloadFileShareFile(attached._id);
				windowModel.openInNewWindow(downloadURL as string, attached._id);
			});

			info('Downloaded file share files successfully');
		} catch (err) {
			console.error('FAILED TO DOWNLOAD FILE SHARE FILES ' + err);
			showError(
				'An error occurred while downloading your files. Please try again later.'
			);
		}
	};

	const getTooltipId = (type: 'recall' | 'download', data: FileShareInstance) =>
		`${type}${data._id}`;

	const getIsExpired = (fileShareData: FileShareInstance) => {
		if (fileShareData) {
			const now = new Date();
			return now > new Date(fileShareData?.validUntil?.toString());
		}
	};

	const getTooltipMsg = (
		type: 'recall' | 'download',
		data: FileShareInstance
	) => {
		const isExpired = getIsExpired(data);

		if (type === 'download' && isExpired)
			return `Cannot download files that have expired.`;
		if (type === 'download' && !isExpired) return `Download shared files`;

		if (type === 'recall') return `Recall shared files`;
	};

	const showRecallDialog = (data: FileShareInstance) => {
		setShowRecall(true);
		setRecallingInstance(data);
	};

	const hideRecallDialog = () => {
		setShowRecall(false);
		setRecallingInstance(undefined);
	};

	const searchHistory = (searchTerm: string) => {
		return sent?.filter((inboxItem) => searchObject(inboxItem, searchTerm));
	};

	// markup
	return loading ? (
		<Loading label="Loading..." alignItems="center" justifyContent="center" />
	) : (
		<>
			{isRecalling && recallingInstance && (
				<FileSharingRecallDialog
					onClosed={hideRecallDialog}
					fileShareInstance={recallingInstance}
				/>
			)}
			<Card>
				<ScrollTable style={{ fontSize: 14 }} className="listing-table">
					<thead>
						<tr>
							<th>SUBJECT</th>
							<th>TO</th>
							<th>SENT</th>
							<th>EXPIRES</th>
							<th>STATUS</th>
							<th>ACTIONS</th>
						</tr>
					</thead>
					<FileSharingInboxTbody>
						<tr>
							<td colSpan={6}>
								<Input
									onChange={(e) => {
										setSearchTerm(e.target.value);
										setCurrentPage(1);
									}}
									type="text"
									className="form-control"
									placeholder="Search history..."
								/>
							</td>
						</tr>
						{orderBy(
							getItemsOnPage(
								orderBy(searchHistory(searchTerm)!, (r) => r.sent, 'desc'),
								currentPage
							)
						).map((data, index) => (
							<tr key={data._id}>
								<td>
									<a
										className={'share-link'}
										href={getRecipientLink(data._id, data.recipients)}
										target="_shareLink"
									>
										{data.subject}
									</a>
								</td>
								<td>
									<UncontrolledPopover
										ref={(r) => {
											refs.current.set(index, r as any);
										}}
										trigger={'click'}
										target={`recipients${data._id}`}
									>
										<PopoverHeader>
											Recipients
											<Button
												style={{ background: 'transparent' }}
												className="close"
												onClick={() =>
													refs.current.get(index)?.setState({ isOpen: false })
												}
											>
												&times;
											</Button>
										</PopoverHeader>
										<PopoverBody>
											<ListGroup>
												{data.recipients.map((recipient: any) => (
													<ListGroupItem key={recipient._id}>
														<ListGroupItemText>
															{recipient.email}
														</ListGroupItemText>
													</ListGroupItem>
												))}
												{data.recipients.length === 0 && (
													<ListGroupItem>No recipients</ListGroupItem>
												)}
											</ListGroup>
										</PopoverBody>
									</UncontrolledPopover>
									<NavLink className="p-0" id={`recipients${data._id}`}>
										<FontAwesomeIcon icon={faUserCircle} />
										&nbsp;View Recipients
									</NavLink>
								</td>
								<td>
									<Moment date={data.sent} format="llll" />
								</td>
								<td>
									<span className={getIsExpired(data) ? 'inactive' : ''}>
										<Moment date={data.validUntil} format="llll" />
									</span>
								</td>

								<td>
									{getIsExpired(data) && (
										<Badge color={'danger'}>EXPIRED</Badge>
									)}
								</td>

								<td>
									<div className="d-flex align-items-center">
										<UncontrolledTooltip
											target={getTooltipId('download', data)}
										>
											{getTooltipMsg('download', data)}
										</UncontrolledTooltip>
										<span
											id={getTooltipId('download', data)}
											className="action"
											onClick={() => downloadFor(data)}
										>
											<CustomFontAwesomeIcon
												className={getIsExpired(data) ? 'inactive' : ''}
												icon={faFileDownload}
											/>
										</span>
										<UncontrolledTooltip target={getTooltipId('recall', data)}>
											{getTooltipMsg('recall', data)}
										</UncontrolledTooltip>
										<span
											id={getTooltipId('recall', data)}
											className="action"
											onClick={() => showRecallDialog(data)}
										>
											<CustomFontAwesomeIcon icon={faTimes} />
										</span>
									</div>
								</td>
							</tr>
						))}
						{!sent?.length && (
							<tr>
								<td colSpan={6}>
									<Alert color="warning">No history found.</Alert>
								</td>
							</tr>
						)}
					</FileSharingInboxTbody>
				</ScrollTable>
				{!!sent && (
					<div className="d-flex justify-content-center">
						<PaginationContainer
							totalPages={getTotalPages(searchHistory(searchTerm)!) as number}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					</div>
				)}
			</Card>
		</>
	);
};

export default FileSharingHistory;
