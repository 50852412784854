import React, { useCallback } from 'react';
import DownshiftSelect from './downshift-select.component';
import Downshift, {
	ControllerStateAndHelpers,
	GetInputPropsOptions,
} from 'downshift';
import {
	DownshiftBaseProps,
	DownshiftReducer,
	ItemRenderer,
	SingleSelectionState,
} from './downshift.interfaces';
import { DownshiftSingleInputField } from './downshift-single-input.component';

// interface
export interface DownshiftSingleSelectProps<
	Item extends Displayable,
	Option extends Displayable = Item
> extends DownshiftBaseProps<Item, Option> {
	selectionState: SingleSelectionState<Item, Option>;
	renderItem?: ItemRenderer<Item>;
}

// component
const DownshiftSingleSelect = <
	Item extends Displayable,
	Option extends Displayable = Item
>(
	props: DownshiftSingleSelectProps<Item, Option>
) => {
	const buildPropOptions = useCallback(
		(downshift: ControllerStateAndHelpers<Option>) => {
			const handleInputKeyDown = (
				event: React.KeyboardEvent<HTMLInputElement>
			) => {
				if (props.selectionState.selection && event.key === 'Backspace') {
					props.selectionActions.select(undefined);
					downshift.setState({ inputValue: '' });
				}
			};

			const handleClick = (
				event: React.MouseEvent<HTMLElement, MouseEvent>
			) => {
				downshift.setState({ isOpen: true });
			};

			return {
				onKeyDown: handleInputKeyDown,
				onClick: handleClick,
			} as GetInputPropsOptions;
		},
		[props]
	);

	const stateReducer: DownshiftReducer<Option> = useCallback(
		(state, changes) => {
			switch (changes.type) {
				case Downshift.stateChangeTypes.keyDownEnter:
				case Downshift.stateChangeTypes.clickItem:
					if (changes.selectedItem) {
						props.selectionActions.select(changes.selectedItem);

						return {
							...changes,
							isOpen: false,
							inputValue: changes.inputValue,
						};
					} else {
						return {
							...changes,
							inputValue: '',
						};
					}

				case Downshift.stateChangeTypes.mouseUp:
					if (!changes.isOpen) {
						// Clear input when clicking away.
						// Default behavior is to bring back the last value.
						if (!changes.selectedItem) {
							return { ...changes, inputValue: '' };
						}
					}
					return changes;

				default:
					return changes;
			}
		},
		[props]
	);

	return (
		<DownshiftSelect
			{...props}
			stateReducer={stateReducer}
			renderInput={() => (
				<DownshiftSingleInputField
					{...props}
					getInputOptions={buildPropOptions}
				/>
			)}
		/>
	);
};

export default DownshiftSingleSelect;
