import styled from 'styled-components';
import { Input } from 'reactstrap';

import { Avatar as AvatarBase } from '../../../avatar.component';

export const CommentContainer = styled.div`
	display: flex;
	background-color: var(--card-background-color);
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	bottom: 0;
	box-shadow: 0px -3px 14px rgba(1, 1, 1, 0.075), 0 0 5px rgba(1, 1, 1, 0.075);
	left: 0;
	padding: 1em;
	right: 0;
`;

export const Avatar = styled(AvatarBase)`
	// height: 30px;
	// width: 32px;d
	margin-right: 1em;
`;

export const CommentInput = styled(Input)`
	max-height: 100px;
	min-height: 30px;
`;
