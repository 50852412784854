import { Link as LinkBase } from '@reach/router';
import { Card, Table } from 'reactstrap';
import styled from 'styled-components';
import themeStore from '../core-ui/models/ThemeStore';

import { ffBold, ffRegular } from '../core-ui/ui/typography';
import { focus, heading1, heading2 } from '../core-ui/ui/ui';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { Avatar } from './avatar.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sanitizeForDOMElementId } from 'utils/common';
import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StyledTooltip } from 'components/workflow/workflows/components/action-button.styled-components';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const Heading = styled.h1`
	${heading1};
	padding-bottom: 1em;
	word-break: break-word;
`;

export const TemplateHeading = styled.h1`
	${heading1};
	margin-top: 1rem;
	padding-bottom: 1rem;
	word-break: break-word;
	text-overflow: ellipsis;
	width: 100%;
`;

export const Subtitle = styled.h2`
	${heading2};
	font-size: 18px;
`;

export const Subheading = styled.div`
	${ffRegular};
	font-size: 10px;
	letter-spacing: 2px;
	text-transform: uppercase;
`;

export const BoldText = styled.span`
	${ffBold};
`;

export const SmallText = styled.small`
	display: block;
	margin-bottom: 0.5em;
	font-style: italic;
`;

export const SmallTextMuted = styled<any>(SmallText)`
	color: ${colors.darkerGrey};
`;

export const Divider = styled.hr`
	border: 0.5px solid var(--darkGrey);
	margin: 0 0 1em;
	width: 100%;
`;

export const Link = styled(LinkBase)`
	color: ${defaults?.secondary};
	text-decoration: none;

	&:hover {
		color: ${defaults?.secondaryHighlighted};
		text-decoration: none;
	}

	&:focus {
		${focus};
	}
`;

export const CollaboratorAvatar = styled(Avatar)`
	border: 2px solid var(--white);
	margin-right: -0.5em;
	flex: none;
	display: flex;
`;

export const CollaboratorAvatarXs = styled<any>(CollaboratorAvatar)`
	border-width: 1px;
	width: 0.5em;
	height: 0.5em;
	margin-right: -0.5em;
	display: flex;
`;

export const CollaboratorAvatarSm = styled<any>(CollaboratorAvatar)`
	border-width: 1px;
	width: 1em;
	height: 1em;
	margin-right: -0.5em;
`;

export const CollaboratorAvatarMd = styled<any>(CollaboratorAvatar)`
	border-width: 1px;
	width: 1.5em;
	height: 1.5em;
	margin-right: -0.5em;
`;

export const CollaboratorAvatarLg = styled<any>(CollaboratorAvatar)`
	border-width: 2px;
	width: 2em;
	height: 2em;
	margin-right: -0.5em;
`;

export const ViewWrapper = styled.div`
	display: block;
	min-height: 100vh;
	overflow: hidden;
	padding-bottom: 10em;
	position: relative;
`;

export const ScrollCard = styled(Card)`
	overflow-y: scroll;
`;

export const ScrollTable = styled(Table)`
	overflow-y: scroll;
`;

export const InteractiveFontAwesomeIcon = ({
	icon,
	onClick,
	tooltip,
	color,
	id,
}: {
	icon: IconProp;
	id: string;
	onClick?: (e: any) => void;
	tooltip?: string;
	color?: string;
}) => {
	const Element = styled<any>(FontAwesomeIcon)`
		cursor: pointer;
		color: ${color ? color : 'inherit'};
		font-size: 14px;
	`;
	const target = React.useMemo(() => sanitizeForDOMElementId('tooltip' + id), [
		id,
	]);
	return (
		<React.Fragment>
			{tooltip && <StyledTooltip target={target}>{tooltip}</StyledTooltip>}
			<Element
				title={tooltip ? tooltip : ''}
				className={target}
				onClick={onClick}
				id={target}
				icon={icon}
			/>
		</React.Fragment>
	);
};
