import React, { FormEvent, useCallback, useEffect } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { generateID } from '../../../utils/common';
import { LabeledInput, SubmitButton } from '../../forms';
import EntityMetadataForm from '../../metadata/components/entity-metadata-form.component';
import themeStore from '../../../core-ui/models/ThemeStore';
import CollectionAssetSelect from './collection-asset-select.component';
import { navigate } from '@reach/router';
import {
	AssetCollection,
	BaseWorkflowOwner,
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';
import { useAuthContext } from '../../../utils/auth';
import { buildEmptyAssetMetadata } from '../../dam-assets/components/helpers/useAssetHelper';
import { useAxios } from '../../../hooks';

interface NewCollectionWithAssetFormProps {
	assetIds: string;
}

const NewCollectionWithAssetForm = (props: NewCollectionWithAssetFormProps) => {
	const { assetIds } = props;
	const { currentUser } = useAuthContext();
	const emptyCollection = {
		assets: assetIds ? [...assetIds.split(',')] : [],
		createdAt: new Date(),
		_id: generateID(),
		metadata: buildEmptyAssetMetadata,
		owners: [
			{
				_id: currentUser._id,
				type: 'AccountUser',
			} as BaseWorkflowOwner,
		],
		title: '',
		createdBy: currentUser,
	} as AssetCollection;
	const [collectionRef, setCollectionRef] = React.useState(emptyCollection);
	const collectionStore = useAxios<AssetCollection>('collections');
	const addCollection = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdCollection = (await collectionStore.createOne({
				...collectionRef,
			} as AssetCollection)) as any;
			return navigate(`/admin/dam/collections/${createdCollection?._id}`);
		},
		[collectionStore, collectionRef]
	);
	useEffect(() => {
		if (!collectionRef.assets.length && !!assetIds) {
			setCollectionRef({ ...collectionRef, assets: [...assetIds.split(',')] });
		}
	}, [assetIds, collectionRef]);

	const isFormValid = () => {
		return collectionRef.title !== '';
	};

	return (
		<Form>
			<Row form>
				<Col md={12}>
					<LabeledInput
						label="Title*"
						type="text"
						name="title"
						id="title"
						value={collectionRef.title}
						onChange={(e) =>
							setCollectionRef({ ...collectionRef, title: e.target.value })
						}
						required
						inputValid={collectionRef.title !== ''}
						errorMsg="Title is required"
					/>
				</Col>
				<Col>
					<FormGroup>
						<CollectionAssetSelect
							onChange={(updated) => {
								setCollectionRef(updated);
							}}
							collection={collectionRef}
						/>
					</FormGroup>
				</Col>
			</Row>

			<EntityMetadataForm
				update={(update) => {
					setCollectionRef({ ...collectionRef, metadata: update.payload });
				}}
				onChange={(template?: EntityMetadataTemplate | undefined) => {
					if (template) {
						setCollectionRef({
							...collectionRef,
							metadata: template as EntityMetadata,
						});
					}
					return template as EntityMetadata;
				}}
				metadata={collectionRef.metadata as EntityMetadata}
			/>

			<SubmitButton
				onClick={addCollection}
				label={`Save ${themeStore._.assetCollection.toLowerCase()}`}
				disabled={!isFormValid()}
			/>
		</Form>
	);
};

export default NewCollectionWithAssetForm;
