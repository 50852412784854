import { faEdit, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
	Col,
	Input,
	InputGroup,
	InputGroupAddon,
	Row,
	UncontrolledTooltip,
} from 'reactstrap';
import CircleButton from '../../circle-button.component';
import RenderWhen from '../../render-when.component';
import {
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';
import { EventWithValue } from '../../workflow/workflows/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditFieldDialog } from './EditFieldValueDialog';
import { useMetadataContext } from 'context/useMetadataContext';
import { LabeledInput } from 'components/index';
import { CircleButtonContainer } from './entity-metadata-form.styled-components';
export const useMetadataEditor = (metadata: EntityMetadata) => {
	const [state, setMeta] = React.useReducer(
		function metaReducer(
			state: { metadata: EntityMetadata },
			action: { type: 'update'; payload: EntityMetadata }
		) {
			switch (action.type) {
				case 'update':
					return { ...state, metadata: action.payload };
				default:
					return state;
			}
		},
		{ metadata }
	);

	const setFieldOptions = (fieldOptions: {
		field: string;
		options: string[];
	}) => {
		const map: Record<string, { field: string; options: string[] }> = {};
		if (
			state.metadata.fieldOptions &&
			!!Object.keys(
				state?.metadata?.fieldOptions as Record<
					string,
					{ field: string; options: string[] }
				>
			).length
		) {
			state.metadata.fieldOptions &&
				Object.keys(
					state?.metadata?.fieldOptions as Record<
						string,
						{ field: string; options: string[] }
					>
				).forEach((key) => {
					map[key] = (state.metadata?.fieldOptions as Record<
						string,
						{ field: string; options: string[] }
					>)[key];
				});
		}
		map[fieldOptions?.field] = {
			field: fieldOptions.field,
			options: fieldOptions.options,
		};
		const updated = {
			...state.metadata,
			fieldOptions: map,
		};
		setMeta({ type: 'update', payload: updated });
		return updated;
	};

	const addFieldType = (fieldType: string, field: string) => {
		const updated = {
			...state.metadata,
			fieldTypes: [...getMetadata().fieldTypes, { fieldType, field }],
		};
		setMeta({ type: 'update', payload: updated });
		return updated;
	};

	const setKvPair = (key: string, value: string) => {
		let updated = state.metadata;
		if (!state?.metadata?.fields?.includes(key)) {
			updated = {
				...state.metadata,
				fields: state.metadata.fields?.length
					? [...state?.metadata?.fields, key]
					: [key],
			};
		}
		const map: { [key: string]: string | string[] } = {};
		if (state?.metadata && !!state?.metadata?.values) {
			Object.keys(state?.metadata?.values).forEach((key, idx) => {
				if (state?.metadata?.values && state?.metadata?.values[key])
					map[key] = state?.metadata?.values[key];
			});
		}
		map[key] = value;
		updated.values = map;

		setMeta({ type: 'update', payload: { ...updated } });
		return updated;
	};

	const removeField = (field: string) => {
		const fieldIndex = state?.metadata?.fields?.indexOf(field) as number;
		state?.metadata?.fields?.splice(fieldIndex, 1);
		const map: Record<string, string | string[]> = {};
		if (state?.metadata && !!state?.metadata?.values) {
			Object.keys(state?.metadata?.values).forEach((key, idx) => {
				if (key !== field && state?.metadata?.values) {
					map[key] = state?.metadata?.values[key];
				}
			});
		}
		setMeta({ type: 'update', payload: { ...state?.metadata, values: map } });
		return { ...state?.metadata, values: map } as EntityMetadata;
	};

	const replaceField = (field: string, newField: string) => {
		const fieldToReplace = state?.metadata?.fields?.includes(field)
			? field
			: newField;
		const newFieldToUse = fieldToReplace === field ? newField : field;
		const newFields = state.metadata.fields?.map((f) => {
			return f === fieldToReplace ? newFieldToUse : f;
		});
		const newFieldOptions: Record<
			string,
			{ field: string; options: string[] }
		> = {};

		const map: Record<string, string | string[]> = {};

		const newFieldTypes = state.metadata.fieldTypes?.map((ft) => ({
			...ft,
			field: ft.field === fieldToReplace ? newFieldToUse : ft.field,
		}));
		if (state.metadata?.fieldOptions)
			Object.keys(state.metadata?.fieldOptions)
				.filter(
					(m) => state.metadata.fieldOptions![m]?.field !== fieldToReplace
				)
				.forEach((fieldOption) => {
					newFieldOptions[fieldOption] = {
						options: state.metadata.fieldOptions![fieldOption].options,
						field: fieldOption,
					};
				});

		newFieldOptions[newFieldToUse] = {
			field: newFieldToUse,
			options:
				state.metadata.fieldOptions && state.metadata.fieldOptions[field]
					? state.metadata.fieldOptions[field].options
					: [],
		};

		if (state?.metadata && !!state?.metadata?.values) {
			Object.keys(state?.metadata?.values).forEach((key, idx) => {
				if (state?.metadata?.values) {
					if (newFields?.includes(key) && key !== fieldToReplace) {
						map[key] = state?.metadata?.values[key];
					}
				}
			});
		}
		map[newFieldToUse] =
			state?.metadata?.values && state.metadata?.values[fieldToReplace]
				? state.metadata.values[fieldToReplace]
				: '';
		setMeta({
			type: 'update',
			payload: { ...state?.metadata, fields: newFields, values: map },
		});
		console.log({
			...state?.metadata,
			fields: newFields,
			fieldTypes: newFieldTypes,
			values: map,
			fieldOptions: newFieldOptions,
		});
		return {
			...state?.metadata,
			fields: newFields,
			fieldTypes: newFieldTypes,
			values: map,
			fieldOptions: newFieldOptions,
		} as EntityMetadata;
	};

	const getMetadata = () => {
		return state.metadata;
	};
	return {
		setKvPair,
		removeField,
		getMetadata,
		setMeta,
		addFieldType,
		replaceField,
		setFieldOptions,
	};
};
export const ExistingMetadataFields = ({
	metadata,
	onChange,
	assetId,
	showAddField,
}: {
	metadata: EntityMetadata;
	onChange?: (metadata: EntityMetadata) => void;
	assetId: string;
	showAddField?: boolean;
}) => {
	const [editedMetadata, setEditedMetadata] = React.useState(metadata);
	const { templates: allTemplates } = useMetadataContext();
	const [editingField, setEditingField] = useState(false);
	const [editedField, setEditedField] = useState('');
	useEffect(() => {
		if (window.location.hash) {
			const idArray = window.location.hash.replace('#', '').split(',');
			if (idArray[1] === assetId) {
				const templateId = idArray[0];
				const templateMeta = {
					...allTemplates.find((m) => m._id === templateId),
				} as EntityMetadata;
				if (templateMeta) {
					setEditedMetadata(templateMeta);
					setMeta({ type: 'update', payload: templateMeta });
					if (onChange)
						onChange({
							...templateMeta,
						});
				}
				window.location.hash = '';
			}
		}
		//	eslint-disable-next-line
	}, [window.location.hash]);

	const {
		setKvPair,
		removeField,
		getMetadata,
		setMeta,
		replaceField,
	} = useMetadataEditor(editedMetadata);

	React.useEffect(() => {
		if (!isEqual(metadata, getMetadata())) {
			setMeta({ type: 'update', payload: metadata });
			setEditedMetadata(metadata);
		}
	}, [metadata, getMetadata, setMeta]);

	const handleRemoveField = (field: string) => {
		const updated = removeField(field);
		setEditedMetadata(updated);
		setMeta({ type: 'update', payload: updated });

		if (onChange) onChange(updated);
	};

	const updateMetadataValue = (e: any, fieldKey: string) => {
		let updatedField;
		if (e.target.selectedOptions) {
			updatedField = Array.from(
				e.target.selectedOptions,
				(option: any) => option.value
			);
		} else updatedField = e.target.value;
		const edited = setKvPair(fieldKey, updatedField);
		setEditedMetadata(edited);
		setMeta({ type: 'update', payload: edited });
		if (onChange) onChange(edited);
	};

	function renderOptions(fieldKey: string) {
		const options: JSX.Element[] = [];
		if (
			editedMetadata?.fieldOptions !== undefined &&
			!!editedMetadata?.fieldOptions![fieldKey] &&
			!!(editedMetadata.fieldOptions as Record<
				string,
				{ field: string; options: string[] }
			>)[fieldKey]?.options
		) {
			(editedMetadata.fieldOptions as Record<
				string,
				{ field: string; options: string[] }
			>)[fieldKey]?.options?.map((opt) =>
				options.push(
					<option key={opt} value={opt}>
						{opt}
					</option>
				)
			);
		}
		return options;
	}

	const inputRef = useRef<HTMLInputElement>();

	const handleUpdateField = (newField: string, oldField: string) => {
		const updated = replaceField(oldField, newField);
		setEditedMetadata(updated);
		setMeta({ type: 'update', payload: updated });
		if (onChange) onChange(updated);
	};

	const getDefaultValue = (fieldKey: string) => {
		if (metadata && metadata.values && metadata.values[fieldKey]) {
			return metadata.values[fieldKey];
		}
		return '';
	};

	const [valid, setValid] = useState(true);

	const handleAddField = () => {
		const { key, value } = newKvPair;
		if (
			!!(
				key !== '' &&
				(!metadata.fields?.length || !metadata?.fields?.includes(key))
			)
		) {
			const updated = setKvPair(key, value);
			if (onChange) onChange(updated as EntityMetadataTemplate);
			setEditedMetadata(updated);
			setMeta({ type: 'update', payload: updated });
			setNewKvPair({ key: '', value: '' });
			setValid(true);
		} else {
			setValid(false);
		}
	};

	const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = target;
		setNewKvPair({ ...newKvPair, [name]: value });
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			handleAddField();
		}
	};
	const [newKvPair, setNewKvPair] = useState({ key: '', value: '' });

	return (
		<>
			{editingField && (
				<EditFieldDialog
					isOpen={editingField}
					oldValue={editedField}
					afterSubmit={(updatedField: string) => {
						handleUpdateField(updatedField, editedField);
						setEditingField(false);
						setEditedField('');
					}}
					onCancel={() => {
						setEditingField(false);
						setEditedField('');
					}}
				/>
			)}
			{editedMetadata.fields?.map((fieldKey: string) => {
				const fieldType = editedMetadata?.fieldTypes?.find(
					(ft) => ft.field === fieldKey
				)?.fieldType as string;
				return (
					<Row key={fieldKey} className="mb-3 mb-md-0">
						<Col md={4} xs={10}>
							<InputGroup>
								<input
									ref={(ref) => (inputRef.current = ref as HTMLInputElement)}
									className={'form-control'}
									readOnly
									type="text"
									name="key"
									defaultValue={fieldKey}
								/>

								<InputGroupAddon
									addonType="append"
									onClick={() => {
										setEditingField(true);
										setEditedField(fieldKey);
									}}
								>
									<UncontrolledTooltip target="editFieldTooltip">
										Edit metadata field
									</UncontrolledTooltip>
									<span className="input-group-text">
										<FontAwesomeIcon icon={faEdit} id="editFieldTooltip" />
									</span>
								</InputGroupAddon>
							</InputGroup>
						</Col>
						<Col md={5} xs={10}>
							<RenderWhen
								when={['singleSelect', 'multiSelect'].includes(fieldType)}
							>
								{'multiSelect' === fieldType ? (
									<select
										multiple={'multiSelect' === fieldType}
										className="form-control"
										name="value"
										defaultValue={getDefaultValue(fieldKey)}
										onChange={(event: EventWithValue<any>) =>
											updateMetadataValue(event, fieldKey)
										}
									>
										<option key={''} value={''}>
											Please select...
										</option>
										{renderOptions(fieldKey)}
									</select>
								) : (
									<select
										className="form-control"
										name="value"
										defaultValue={getDefaultValue(fieldKey)}
										onChange={(event: EventWithValue<string>) =>
											updateMetadataValue(event, fieldKey)
										}
									>
										<option key={''} value={''}>
											Please select...
										</option>
										{renderOptions(fieldKey)}
									</select>
								)}
							</RenderWhen>
							<RenderWhen when={'numeric' === fieldType}>
								<Input
									type={'number'}
									className="form-control"
									defaultValue={getDefaultValue(fieldKey)}
									onChange={(e) => updateMetadataValue(e, fieldKey)}
								/>
							</RenderWhen>
							<RenderWhen when={'date' === fieldType}>
								<Input
									type={'date'}
									placeholder="mm/dd/yyyy"
									pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
									className="form-control"
									defaultValue={getDefaultValue(fieldKey)}
									onChange={(e) => updateMetadataValue(e, fieldKey)}
								/>
							</RenderWhen>
							<RenderWhen
								when={'openType' === fieldType || fieldType === undefined}
							>
								<Input
									type="text"
									name="value"
									defaultValue={getDefaultValue(fieldKey)}
									onChange={(event) => {
										const edited = setKvPair(fieldKey, event.target.value);
										setEditedMetadata(edited);
										if (onChange) onChange(edited);
									}}
								/>
							</RenderWhen>
						</Col>
						<Col md={3} xs={2}>
							<CircleButton
								className="sm"
								color="danger"
								id="removeMetadataItem"
								icon={faMinus}
								onClick={() => handleRemoveField(fieldKey)}
								tooltip="Remove metadata entry"
							/>
						</Col>
					</Row>
				);
			})}
			{showAddField && (
				<Row className="mt-3">
					<Col md={4} xs={10}>
						<LabeledInput
							label="Field name"
							type="text"
							name="key"
							id="metadataKey"
							value={newKvPair.key}
							onChange={handleInputChange}
							onKeyDown={handleInputKeyDown}
							inputValid={valid}
							errorMsg={
								newKvPair.key
									? 'This field was already added'
									: 'This field is required'
							}
						/>
					</Col>
					<Col md={5} xs={10}>
						<LabeledInput
							label="Field value"
							type="text"
							name="value"
							id="metadataValue"
							value={newKvPair.value}
							onChange={handleInputChange}
							onKeyDown={handleInputKeyDown}
						/>
					</Col>
					<Col md={3} xs={2}>
						<CircleButtonContainer>
							<CircleButton
								id="addMetadataItem"
								className="sm"
								icon={faPlus}
								onClick={handleAddField}
								tooltip="Add metadata entry"
							/>
						</CircleButtonContainer>
					</Col>
				</Row>
			)}
		</>
	);
};

export default ExistingMetadataFields;
