import { ModalBody } from 'reactstrap';
import React from 'react';

import AsyncDispatcher from '../../../async-dispatcher.component';
import DialogModal from '../../../modals/dialog-modal.component';
import themeStore from '../../../../core-ui/models/ThemeStore';

import ShareDialogBody from './share-dialog-body.component';
import {
	AssetVersion,
	HasAssetVersion,
} from '../../../workflow/workflows/types/workflow.types';
import { useAssetHelper } from '../helpers/useAssetHelper';

const assetTerm = themeStore._.asset;

const assetShareTitle = (asset: AssetVersion) => {
	if (asset?.fileName) {
		return `Share "${asset?.fileName}"`;
	} else {
		return `Share this ${assetTerm?.toLowerCase()}`;
	}
};

const renderErrorState = () => (
	<>
		<p>
			We're currently unable to obtain a link which can be used to share this{' '}
			{assetTerm?.toLowerCase()}.
		</p>
		<p>
			Please try again later, or contact a system administrator if the issue
			persists.
		</p>
	</>
);

const ShareSingleAssetDialog = (props: HasAssetVersion) => {
	const { asset } = props;
	const { getNewSignedUrl } = useAssetHelper();
	return (
		<DialogModal header={assetShareTitle(asset)}>
			<ModalBody>
				<AsyncDispatcher loader={() => getNewSignedUrl(asset)}>
					{{
						success: (filePath: string) => (
							<ShareDialogBody asset={asset} link={filePath} />
						),
						error: renderErrorState,
					}}
				</AsyncDispatcher>
			</ModalBody>
		</DialogModal>
	);
};

export default ShareSingleAssetDialog;
