import { navigate } from '@reach/router';
import React, { FormEvent, useCallback } from 'react';
import { useAuthContext } from '../../../../utils/auth';
import { generateID } from '../../../../utils/common';
import { buildEmptyAssetMetadata } from '../../../dam-assets/components/helpers/useAssetHelper';
import { useAxios } from '../../../../hooks';
import {
	BaseWorkflowOwner,
	WorkflowTemplate,
} from '../../workflows/types/workflow.types';
import EditTemplateForm from './edit-template-form.component';

const AddTemplateForm = () => {
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const { currentUser } = useAuthContext();
	const [templateRef, setTemplateRef] = React.useState<WorkflowTemplate>({
		metadata: buildEmptyAssetMetadata,
		phases: [],
		stages: [],
		account: '',
		_id: generateID(),
		owners: [
			{ _id: currentUser._id, type: 'AccountUser' },
		] as BaseWorkflowOwner[],
		createdAt: new Date(),
		createdBy: currentUser._id,
		creationTime: new Date().toTimeString(),
		editingState: 'draft',
		followers: [],
		title: '',
		updatedAt: new Date(),
		isEditedByCreatorOnly: false,
	} as WorkflowTemplate);

	const addTemplate = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const templateAfterCreation = await templateStore.createOne({
				...templateRef,
			});

			return navigate(
				`/admin/workflow/templates/${templateAfterCreation?._id}/`
			);
		},
		[templateStore, templateRef]
	);

	return (
		<EditTemplateForm
			onChange={(updated) => {
				setTemplateRef(updated);
			}}
			selectedTemplate={templateRef}
			onSubmit={addTemplate}
			buttonLabel="Next step →"
		/>
	);
};
export default AddTemplateForm;
