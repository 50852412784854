import React from 'react';
import styled from 'styled-components';
import { secondaryButton } from '../../core-ui/components/secondary-button';

type ButtonElement = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

type Props = {
	children: string;
} & ButtonElement;

export const FormSubmitButton = styled(({ children, ...props }: Props) => (
	<button {...props}>{children}</button>
))`
	${secondaryButton};
`;
