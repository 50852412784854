import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { PaginationWrapper } from './pagination.styled.components';
export interface Props {
	page: number;
	totalPages: number;
	handlePagination: (page: number) => void;
}
export const PaginationComponent: React.FC<Props> = ({
	page,
	totalPages,
	handlePagination,
}) => {
	return (
		<PaginationWrapper fluid>
			{totalPages > 0 && (
				<div className={'wrapper'}>
					{page !== 1 && (
						<button
							onClick={() => handlePagination(page - 1)}
							type="button"
							className={'page-item sides'}
						>
							<FontAwesomeIcon icon={faChevronLeft} />
						</button>
					)}
					{totalPages > 1 && (
						<button
							onClick={() => handlePagination(1)}
							type="button"
							className={`page-item ${page === 1 ? 'active' : ''}`}
						>
							{1}
						</button>
					)}
					{page > 3 && <div className={'seperator'}>...</div>}
					{page === totalPages && totalPages > 3 && (
						<button
							onClick={() => handlePagination(page - 2)}
							type="button"
							className={'page-item'}
						>
							{page - 2}
						</button>
					)}
					{page > 2 && (
						<button
							onClick={() => handlePagination(page - 1)}
							type="button"
							className={'page-item'}
						>
							{page - 1}
						</button>
					)}
					{page !== 1 && page !== totalPages && (
						<button
							onClick={() => handlePagination(page)}
							type="button"
							className={['page-item', 'active'].join(' ')}
						>
							{page}
						</button>
					)}
					{page < totalPages - 1 && (
						<button
							onClick={() => handlePagination(page + 1)}
							type="button"
							className={'page-item'}
						>
							{page + 1}
						</button>
					)}
					{page === 1 && totalPages > 3 && (
						<button
							onClick={() => handlePagination(page + 2)}
							type="button"
							className={'page-item'}
						>
							{page + 2}
						</button>
					)}
					{totalPages > 1 && page < totalPages - 2 && (
						<div className={'seperator'}>...</div>
					)}
					<button
						onClick={() => handlePagination(totalPages)}
						type="button"
						className={`page-item ${page === totalPages ? 'active' : ''}`}
					>
						{totalPages}
					</button>
					{page !== totalPages && (
						<button
							onClick={() => handlePagination(page + 1)}
							type="button"
							className={'page-item sides'}
						>
							<FontAwesomeIcon icon={faChevronRight} />
						</button>
					)}
				</div>
			)}
		</PaginationWrapper>
	);
};
