import { Card, Table } from 'reactstrap';
import * as R from 'ramda';
import Moment from 'react-moment';
import React from 'react';

import { buildStageUrl } from '../../../../../utils/common/links';
import { Cell, CellLink } from '../../../../index';
import { Stage, Workflow } from '../../types';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { useAuthContext } from 'utils/auth';
import * as H from '../../helpers';
import { RoleContext } from 'context/PermissionsContext';

const workflowTerm = themeStore._.workflow;

interface Props {
	stages: Stage[];
	workflows: Workflow[];
	isFetching: boolean;
}

export const RoadblocksTable = ({ stages, workflows, isFetching }: Props) => (
	<Card>
		<Table>
			<thead>
				<tr>
					<th>Stage</th>
					<th>{workflowTerm}</th>
					<th>Reported by</th>
					<th>Reported on</th>
					<th>Message</th>
				</tr>
			</thead>
			<tbody>
				{stages && !!stages.length ? (
					stages.map((stage) => (
						<RoadblockTableRow
							key={stage._id}
							stage={stage}
							workflows={workflows}
						/>
					))
				) : (
					<tr>
						<td colSpan={3}>
							<p>{isFetching ? 'Loading...' : 'No results found.'}</p>
						</td>
					</tr>
				)}
			</tbody>
		</Table>
	</Card>
);

type RowProps = {
	stage: Stage;
	workflows: Workflow[];
};
const RoadblockTableRow = ({ stage, workflows }: RowProps) => {
	const { entities: users } = useAuthContext();
	const permissions = React.useContext(RoleContext);

	const editable = permissions.canViewByRole('canEditRoadblocks');
	// const workflow = H.getWork
	const event = stage?.events
		?.filter(R.propEq('newStatus', 'roadblocked'))
		.pop();

	const stageWorkflow = workflows?.find((workflow) =>
		H.flattenStages(workflow)?.some(R.propEq('_id', stage._id))
	);
	const getCreatorName = (createdBy: any) => {
		if (typeof createdBy !== 'string')
			return createdBy.givenName + ' ' + createdBy.familyName;
		const creator = users?.find(R.propEq('_id', createdBy));
		return creator?.givenName + ' ' + creator?.familyName;
	};

	return (
		<tr key={event?._id}>
			<Cell>
				{editable && (
					<CellLink to={buildStageUrl(stageWorkflow, stage)}>
						{stage.title}
					</CellLink>
				)}
				{editable === false && <>{stage.title}</>}
			</Cell>
			<Cell>{stageWorkflow?.title}</Cell>
			<Cell>{getCreatorName(event?.createdBy)}</Cell>
			<Cell>
				<Moment format="MMM DD, YYYY" date={event?.createdAt} />
			</Cell>
			<Cell>{event?.message}</Cell>
		</tr>
	);
};

export default RoadblocksTable;
