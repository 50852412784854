import React from 'react';
import styled from 'styled-components';
import { UserGroup } from './accounts/types';
import { GroupOwner } from './workflow/workflows/types/workflow.types';

// interfaces
interface GroupAvatarCircleProps {
	background: string;
	size: number;
}

interface GroupAvatarProps {
	group: GroupOwner | UserGroup;
	size: 'xs' | 'sm' | 'md' | 'lg';
}

// styled components
const GroupAvatarCircle = styled.div<GroupAvatarCircleProps>`
	display: inline-flex;
	min-width: ${(props) => (props.size ? props.size : 2.5)}em;
	height: ${(props) => (props.size ? props.size : 2.5)}em;
	border-radius: 4px;
	background-color: ${(props) => props.background};
	color: white;
	font-family: 'Roboto Slab', serif;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--white);
	margin-right: 0;
	cursor: arrow;
`;

const GroupAvatarCircleLg = styled<any>(GroupAvatarCircle)`
	width: 2.5em;
	height: 2.5em;
	margin-right: 0;
	font-family: 'Roboto Slab', serif;
`;

// components
const GroupAvatar = (props: GroupAvatarProps) => {
	const getSize = () => {
		if (!props.size) return 1.5;
		if (props.size === 'xs') return 1;
		if (props.size === 'sm') return 1.25;
		if (props.size === 'md') return 2.5;
		if (props.size === 'lg') return 2.5;
		if (props.size === 'xl') return 3.5;
		return 2.5;
	};
	if (props.group && props.group.color)
		return (
			<GroupAvatarCircle
				size={getSize()}
				background={props.group.color}
				title={props.group.title}
			>
				{props.group.title.charAt(0)}
			</GroupAvatarCircle>
		);
	return null;
};
export const GroupAvatarLg = (props: GroupAvatarProps) => {
	if (props.group && props.group.color)
		return (
			<GroupAvatarCircleLg
				background={props.group.color}
				title={props.group.title}
			>
				{props.group.title.charAt(0)}
			</GroupAvatarCircleLg>
		);
	return null;
};
export default GroupAvatar;
