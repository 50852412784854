import {
	faShareAltSquare,
	faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'context/useThemeContext';
import { generateID, humanReadableFileSizeSI } from 'utils/common';
import { Subheading } from 'components/index';
import {
	PrimaryButton,
	SecondaryButton,
} from 'components/buttons.styled-components';
import DialogModal from 'components/modals/dialog-modal.component';
import ShareFileDialog from 'components/dam-assets/components/share-dialogs/share-single-fileshare-dialog.component';
import { FileShareAuditDto, useFileShare } from 'context/useFileShare';
import { NotificationsContext } from 'components/notifications';
import React, { useEffect } from 'react';
import {
	Badge,
	ButtonGroup,
	Col,
	Container,
	ModalBody,
	ModalFooter,
	Row,
} from 'reactstrap';
import styled from 'styled-components';
const XlDialog = styled(DialogModal)``;
export type SharedFile = {
	_id: string;
	path: string;
	preview: string;
	metadata: {
		filename: string;
		fileSizeBytes: number;
		ext: string;
		type: string;
	};
};
type Stats = {
	downloads: FileShareAuditDto[];
	views: FileShareAuditDto[];
	shares: FileShareAuditDto[];
};
export const FileSharingFileDialog = ({
	file,
	onClosed,
	recipient_id,
	file_share_id,
	recipient_email,
	setAllEvents,
	shouldAudit,
}: {
	file: SharedFile;
	onClosed: () => void;
	recipient_id: string;
	file_share_id: string;
	recipient_email: string;
	setAllEvents: any;
	shouldAudit?: boolean;
}) => {
	// hooks
	const { downloadFileShareFile, audit, getStats } = useFileShare();
	const { defaults } = useThemeContext();
	const { info, error: showError } = React.useContext(NotificationsContext);
	const [stats, setStats] = React.useState<Stats>();
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		setLoading(true);
		getStats(file._id)
			.then((stats) => setStats(stats as Stats))
			.finally(() => setLoading(false));
		//eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (!!shouldAudit) {
			//audit the view event with file_id and preview_populated
			//meaning the viewed an individual file
			const auditDto: FileShareAuditDto = {
				_id: generateID(),
				action: 'view',
				createdAt: new Date(),
				createdBy: recipient_id,
				file_share_id,
				file_id: file._id,
				preview: file.preview,
				metadata: file.metadata,
				recipient_email: recipient_email,
				recipient_id: recipient_id,
			};
			audit(auditDto).then((audit) => {
				setAllEvents((events: FileShareAuditDto[]) => [...events, audit]);
				console.info(`Generated audit ${audit._id}`);
			});
		}
		// run the effect to audit views on every `open` of individual file modals
		//eslint-disable-next-line
	}, []);

	// state
	const [sharing, setSharing] = React.useState(false);
	const [downloadSrc, setDownloadSrc] = React.useState<string>('');
	// events
	const onDownloadClick = async () => {
		try {
			// audit the download event,
			const auditDto: FileShareAuditDto = {
				_id: generateID(),
				action: 'download',
				createdAt: new Date(),
				createdBy: recipient_id,
				file_share_id,
				file_id: file._id,
				preview: file.preview,
				metadata: file.metadata,
				recipient_email: recipient_email,
				recipient_id: recipient_id,
			};

			//
			const auditObj = await audit(auditDto);
			setAllEvents((events: FileShareAuditDto[]) => [...events, auditObj]);

			// and then download the file
			const result = await downloadFileShareFile(file._id);

			if (result) {
				setDownloadSrc(result as string);
				info('Successfully downloaded ' + file.metadata.filename);
			} else {
				showError(
					'Error occurred while downloading shared file. Please try again later.'
				);
			}
		} catch (err) {
			console.error(err);
			showError(
				'Error occurred while downloading shared file. Please try again later.'
			);
		}
	};

	const onShareClick = () => {
		setSharing(true);
	};

	// helpers
	const getPreviewFileURL = (file: any) => {
		return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/f/${
			file?.preview?.split('/')[file?.preview?.split('/').length - 2]
		}/${file.metadata.filename}`;
	};

	// markup
	// use this to download file without opening a new window
	const HiddenIframe = ({ src }: { src: string }) => {
		return <iframe title={src} src={src} style={{ display: 'none' }} />;
	};

	return (
		<>
			<HiddenIframe src={downloadSrc} />
			{sharing && (
				<ShareFileDialog
					setAllEvents={setAllEvents}
					onClosed={() => setSharing(false)}
					shouldAudit={shouldAudit}
					file={file}
					file_share_id={file_share_id}
					recipient_email={recipient_email}
					recipient_id={recipient_id}
				/>
			)}
			<XlDialog
				onClosed={onClosed}
				modalSize="md"
				header={file.metadata.filename}
			>
				<ModalBody className="w-100">
					<Row className="mb-2">
						<Col xl={12}>
							<img
								alt={file.metadata.filename}
								className="img-fluid img-thumbnail"
								src={getPreviewFileURL(file)}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={12} xl={6}>
							<Subheading>Name</Subheading>
							<p>{file.metadata.filename}</p>
							<Subheading>Extension</Subheading>
							<p>{file.metadata.ext}</p>
						</Col>
						<Col sm={12} xl={6}>
							<Subheading>Size</Subheading>
							<p>{humanReadableFileSizeSI(file.metadata.fileSizeBytes)}</p>
							<Subheading>Type</Subheading>
							<p>{file.metadata.type}</p>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Container>
						<Row className="my-3 p-0">
							<Col sm={12} xl={4}>
								<Subheading>
									{loading === false && (
										<Badge style={{ fontSize: 14 }} color={'info'}>
											{stats?.views?.length}
										</Badge>
									)}{' '}
									VIEWS
								</Subheading>
								<p>{loading && <>Loading stats...</>}</p>
							</Col>
							<Col sm={12} xl={4}>
								<Subheading>
									{loading === false && (
										<Badge style={{ fontSize: 14 }} color={'info'}>
											{stats?.downloads?.length}
										</Badge>
									)}{' '}
									DOWNLOADS
								</Subheading>
								<p>{loading && <>Loading stats...</>}</p>
							</Col>
							<Col sm={12} xl={4}>
								<Subheading>
									{loading === false && (
										<Badge style={{ fontSize: 14 }} color={'info'}>
											{stats?.shares?.length}
										</Badge>
									)}{' '}
									SHARES
								</Subheading>
								<p>{loading && <>Loading stats...</>}</p>
							</Col>
						</Row>
						<hr />
						<div className="d-flex align-items-end justify-content-end">
							<ButtonGroup>
								<PrimaryButton
									style={{ backgroundColor: defaults?.primary, color: `#fff` }}
									onClick={onShareClick}
								>
									<FontAwesomeIcon icon={faShareAltSquare} />
									&nbsp;Share
								</PrimaryButton>
								<SecondaryButton
									style={{
										backgroundColor: defaults?.secondary,
										color: `#fff`,
									}}
									onClick={onDownloadClick}
									className="ml-2"
								>
									<FontAwesomeIcon icon={faFileDownload} />
									&nbsp;Download
								</SecondaryButton>
							</ButtonGroup>
						</div>
					</Container>
				</ModalFooter>
			</XlDialog>
		</>
	);
};
