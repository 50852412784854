import { RefObject, useEffect } from 'react';
import { filter, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';

// This List is Not Exhaustive, but Native Keyboard Event
// does not display a specific type
// prettier-ignore
type key = '/'| 'Escape' | 'Enter' | 'Control' | 'Alt' | 'Option' | 'Command' | 'ArrowLeft' | 'ArrowRight' | 'ArrowDown' | 'ArrowUp' | 'Tab' | 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k' | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x' | 'y' | 'z'

/**
 * Calls a Function when a key or series of keys are pressed - Optionally scoped to a single component
 * @param hotkey key of hotkey
 * @param onPress function to call when hotkey is pressed
 * @param ref useRef object(optional)
 *
 * @example
 * const ref = useRef<HTMLDivElement>(null)
 * useHotkey('Tab', onPress, ref)
 * const onPress = () =>
 */
export const useKeybind = (
	hotkey: key,
	onPress: () => void,
	ref?: RefObject<HTMLElement>
) => {
	useEffect(() => {
		const key$ = fromEvent<KeyboardEvent>(ref?.current || document, 'keydown')
			.pipe(
				filter(({ key }) => key === hotkey),
				tap((e) => e.preventDefault()),
				tap((e) => e.stopPropagation()),
				tap(onPress)
			)
			.subscribe();

		return () => {
			key$.unsubscribe();
		};
	});
};
