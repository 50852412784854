import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { Link } from '@reach/router';
import { Avatar } from 'components/avatar.component';
import { Cell, ScrollCard, StageStatusPill } from 'components/index';
import { useWorkflowContext } from 'context/useWorkflowStore';
import * as R from 'ramda';
import React from 'react';
import { Table } from 'reactstrap';
import { useAuthContext, User } from 'utils/auth';
import { StageStatus } from 'utils/models/StageStatusModel';
import { formatDuration } from '../../../../../utils/common';
import { buildStageUrl } from '../../../../../utils/common/links';
import { flattenStages, getStageDueDate } from '../../helpers';
import {
	stageIsOverdue,
	useSubsequent,
} from '../../helpers/workflowStage.helpers';
import { Stage, Workflow } from '../../types';

interface Props {
	stages: Stage[];
}

export const MyAssignmentsTable = ({ stages }: Props) => {
	const { entities: workflows } = useWorkflowContext();
	if (R.isEmpty(stages))
		return (
			<table>
				<tbody>
					<tr>
						<Cell className="pt-4"></Cell>
					</tr>
				</tbody>
			</table>
		);

	return (
		<ScrollCard>
			<Table>
				<thead>
					<tr>
						<th>Stage Status</th>
						<th>Workflow</th>
						<th>Stage</th>
						<th>Expected Duration</th>
						<th>Due Date</th>
					</tr>
				</thead>
				<tbody>
					{stages &&
						stages.map((stage, index) => (
							<AssignmentTableRow
								stageWorkflow={workflows?.find((workflow) =>
									flattenStages(workflow, true)?.some(
										(s) => s?._id === stage?._id
									)
								)}
								key={`${index}_${stage._id}`}
								stage={stage}
							/>
						))}
				</tbody>
			</Table>
		</ScrollCard>
	);
};

type AssignmentTableProps = {
	stage: Stage;
	stageWorkflow?: Workflow;
};
const AssignmentTableRow = ({ stage, stageWorkflow }: AssignmentTableProps) => {
	const { currentUser } = useAuthContext();
	const userIsProxying = (stage: Stage) =>
		currentUser?.proxyingFor &&
		currentUser?.proxyingFor?.picture &&
		stage?.owners?.some((owner) => owner._id === currentUser?.proxyingFor?._id);
	return (
		<tr key={stage._id}>
			<td>
				{' '}
				<StageStatusPill status={(stage.status as StageStatus) || '???'} />{' '}
				{stageIsOverdue(stage) && (
					<AccessTimeFilledIcon color="error" titleAccess="Overdue" />
				)}
			</td>
			{stageWorkflow?.title && <td>{stageWorkflow?.title}</td>}
			{userIsProxying(stage) && (
				<td className="h-100 p-0 m-0">
					<div className="mt-2 h-100 w-100 d-flex align-items-center justify-content-center">
						<Avatar user={currentUser?.proxyingFor as User} />
					</div>
				</td>
			)}
			<td className="mb-0">
				<div className="d-flex align-items-center justify-content-start">
					<Link className="ml-2" to={buildStageUrl(stageWorkflow, stage)}>
						{stage.title}
					</Link>
				</div>
			</td>
			<td>
				{formatDuration(
					useSubsequent(stage)
						? stage?.expectedDurations?.subsequent || 0
						: stage?.expectedDurationHrs || 0
				)}
			</td>
			<td>{getStageDueDate(stage) || 'TBD'}</td>
		</tr>
	);
};
