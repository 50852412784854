import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../../back-link/back-link.component';
import { Heading } from '../../ui';
import themeStore from '../../../core-ui/models/ThemeStore';

import AddWorkflowForm from './components/add-workflow-form.component';

const WorkflowCreationView = () => {
	const label = themeStore._.workflow;

	return (
		<Container>
			<Row className="justify-content-center">
				<Col lg={8}>
					<BackLink link=".." title={`${label}s`} />
					<div className="d-flex justify-content-between">
						<Heading>Add a new {label?.toLowerCase()}</Heading>
					</div>
				</Col>
				<Col lg={8}>
					<AddWorkflowForm />
				</Col>
			</Row>
		</Container>
	);
};

export default WorkflowCreationView;
