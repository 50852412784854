import { faChartBar } from '@fortawesome/free-regular-svg-icons';
import {
	faCheckCircle,
	faFileExcel,
	faFilter,
	faStepForward,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'context/useThemeContext';
import { useAuthContext, User } from 'utils/auth';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import { useAxios } from 'hooks';
import { last, orderBy, startCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, FormText, Row } from 'reactstrap';
import { useWorkflowContext } from 'context/useWorkflowStore';
import {
	AssetVersion,
	Workflow,
	WorkflowTemplate,
} from 'components/workflow/workflows/types';
import { ReportBuilderStepOne } from './ReportBuilderStepOne';
import { ReportBuilderStepThree } from './ReportBuilderStepThree';
import { ReportBuilderStepFour } from './ReportBuilderStepFour';
import { useReportBuilder } from './hooks/useReportBuilder';
import './styles/ReportBuilderLanding.scss';
import { generateID } from '../../utils/common';
import { ReportBuilderInstance } from './types';
import { navigate, useParams } from '@reach/router';
import { NotificationsContext } from 'components/notifications';
interface Loader<T> {
	load: () => Promise<T[]>;
}
export const ReportBuilderLanding = () => {
	const { entities: users, currentUser } = useAuthContext();
	const { entities: workflows } = useWorkflowContext();
	const { reportId } = useParams();
	const { defaults } = useThemeContext();
	const { info } = useContext(NotificationsContext);
	const [instance, setInstance] = useState<ReportBuilderInstance>();
	const { saveReport, findById, updateReport } = useReportBuilder();
	useEffect(() => {
		findById(reportId).then(setInstance);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (instance) {
			setSteps((steps) => [
				{
					...steps.find((a) => a.index === 0)!,
					state: {
						selectedPrimaryEntity: instance.selectedPrimaryEntity,
					},
					completed: true,
				},
				{
					...steps.find((a) => a.index === 1)!,
					completed: true,
				},
				{
					...steps.find((a) => a.index === 2)!,
					state: {
						columns: instance.columns,
						addedFilters: instance.filters,
						formatters: instance.formatters,
					},
				},
				{
					...steps.find((a) => a.index === 3)!,
				},
			]);
		}
		// eslint-disable-next-line
	}, [instance]);

	const assetStore = useAxios<AssetVersion>('assets');
	const assetLoader: Loader<AssetVersion> = {
		load: async () => await assetStore.findAll(),
	};

	const templateStore = useAxios<WorkflowTemplate>('templates');
	const templateLoader: Loader<WorkflowTemplate> = {
		load: async () => await templateStore.findAll(),
	};

	const [assets, setAssets] = useState<AssetVersion[]>();
	const [templates, setTemplates] = useState<WorkflowTemplate[]>();
	const [selectedPrimaryEntity, setSelectedPrimaryEntity] = useState('');
	const [steps, setSteps] = useState([
		{
			stepName: 'Select primary entity',
			completed: false,
			state: {
				selectedPrimaryEntity: '',
			},
			index: 0,
			header: () => (
				<CardHeader>
					Step 1 <FontAwesomeIcon icon={faStepForward} />
					<FormText>Select a primary entity.</FormText>
				</CardHeader>
			),
		},
		{
			stepName: 'Select filters',
			completed: false,
			state: {},
			index: 1,
			header: () => (
				<CardHeader>
					Step 2 <FontAwesomeIcon icon={faFilter} />
					<FormText>Select entity filters.</FormText>
				</CardHeader>
			),
		},
		{
			stepName: 'Select columns',
			completed: false,
			state: {
				columns: Array<{ key: string; header: string; index: number }>(),
				formatters: Array<{ type: string; value: string }>(),
				addedFilters: Array<{
					key: string;
					type:
						| 'greater-than'
						| 'less-than'
						| 'equals'
						| 'contains'
						| 'between';
					metadata?: {
						start: string;
						end: string;
					};
					value: string;
				}>(),
			},
			index: 2,
			header: () => (
				<CardHeader>
					Step 3 <FontAwesomeIcon icon={faChartBar} />
					<FormText>Select report columns for {selectedPrimaryEntity}</FormText>
				</CardHeader>
			),
		},
		{
			stepName: 'Export or save for later',
			completed: false,
			state: {},
			index: 3,
			header: () => (
				<CardHeader>
					Step 4 <FontAwesomeIcon icon={faFileExcel} />
					<FormText>Select report export and save.</FormText>
				</CardHeader>
			),
		},
	]);
	// load up all assets
	useEffect(() => {
		assetLoader.load().then(setAssets);
		//eslint-disable-next-line
	}, []);

	// load up all templates
	useEffect(() => {
		templateLoader.load().then(setTemplates);
		//eslint-disable-next-line
	}, []);

	const getActiveStep = () => {
		return steps.filter((a) => !a.completed)[0];
	};

	const setEntityCallback = (entity: string) => {
		setSelectedPrimaryEntity(entity);
		setSteps((steps) =>
			orderBy(
				[
					...steps.filter((a) => a.index !== 0),
					{
						...steps.find((a) => a.index === 0)!,
						completed: true,
						state: { selectedPrimaryEntity: entity },
					},
				],
				(a) => a.index,
				'asc'
			)
		);
	};

	const createReport = async (
		reportTitle: string,
		exportOptions: Array<{ type: string }>
	) => {
		const reportInstance: ReportBuilderInstance = {
			_id: generateID(),
			columns: steps.find((a) => a.index === 2)!.state.columns!,
			formatters: steps.find((a) => a.index === 2)!.state.formatters!,
			selectedPrimaryEntity: selectedPrimaryEntity as any,
			createdAt: new Date(),
			// need to add filters
			createdBy: currentUser,
			filters: steps.find((a) => a.index === 2)!.state!.addedFilters!,
			title: reportTitle,
			updatedAt: new Date(),
			exportOptions: exportOptions,
		};

		// call api to save report.
		await saveReport(reportInstance);
	};

	return (
		<>
			<div className="container-fluid h-100vh">
				<div className="stepper">
					<div className="steps">
						{steps.map((step, index) => (
							<React.Fragment key={index}>
								<AnchorSpan
									style={{
										cursor:
											step.completed ||
											getActiveStep()?.stepName === step?.stepName
												? 'pointer'
												: 'not-allowed',
									}}
									onClick={() =>
										setSteps([
											...steps.map((a) =>
												a.index >= step.index ? { ...a, completed: false } : a
											),
										])
									}
									className="step"
								>
									{step.completed && <FontAwesomeIcon icon={faCheckCircle} />}
									{step.completed === false && (
										<div
											style={{
												textAlign: 'center',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: '2rem',
												height: '2rem',
												borderRadius: '50%',
												background: '#ccc',
											}}
										>
											{startCase((index + 1).toString())}
										</div>
									)}
									<span
										className="label"
										style={{
											color:
												getActiveStep()?.stepName === step.stepName
													? defaults?.primary
													: '',
										}}
									>
										{step.stepName}
									</span>
								</AnchorSpan>
								{last(steps)?.index !== step.index && (
									<div className="step-divider"></div>
								)}
							</React.Fragment>
						))}
					</div>
				</div>
				<Card className="my-3">
					{getActiveStep()?.header()}
					<CardBody>
						<Row>
							{getActiveStep()?.index === 0 && (
								<ReportBuilderStepOne
									selectedPrimaryEntity={selectedPrimaryEntity}
									setSelectedPrimaryEntity={setEntityCallback}
								/>
							)}

							{/* skip through this step in UI */}
							{getActiveStep()?.index === 1 &&
								setSteps((steps) =>
									orderBy(
										[
											...steps.filter((a) => a.index !== 1),
											{
												...steps.find((a) => a.index === 1)!,
												completed: true,
											},
										],
										(a) => a.index,
										'asc'
									)
								)}

							{getActiveStep()?.index === 2 && (
								<ReportBuilderStepThree
									setSelectedPrimaryEntity={setSelectedPrimaryEntity}
									onChange={(updatedState) => {
										setSteps((steps) =>
											orderBy(
												[
													...steps.filter((a) => a.index !== 2),
													{
														...steps.find((a) => a.index === 2)!,
														state: {
															addedFilters: steps.find((a) => a.index === 2)!
																.state.addedFilters!,
															columns: updatedState,
															formatters: steps.find((a) => a.index === 2)!
																.state!.formatters!,
														},
													},
												],
												(a) => a.index,
												'asc'
											)
										);
									}}
									instance={instance}
									users={users as User[]}
									currentUser={currentUser}
									assets={assets as AssetVersion[]}
									workflows={workflows as Workflow[]}
									templates={templates as WorkflowTemplate[]}
									selectedFilters={[]}
									selectedPrimaryEntity={selectedPrimaryEntity}
									onAdvance={(columns, addedFilters, dateFormat) => {
										setSteps((steps) =>
											orderBy(
												[
													...steps.filter((a) => a.index !== 2),
													{
														...steps.find((a) => a.index === 2)!,
														completed: true,
														state: {
															columns,
															addedFilters,
															formatters: dateFormat
																? [
																		{
																			type: 'date',
																			value: dateFormat,
																		},
																  ]
																: [],
														},
													},
												],
												(a) => a.index,
												'asc'
											)
										);
									}}
								/>
							)}

							{getActiveStep()?.index === 3 && (
								<ReportBuilderStepFour
									exportOptions={instance?.exportOptions}
									onComplete={(data) => {
										if (instance) {
											const { exportOptions, reportTitle } = data;
											const reportInstance: ReportBuilderInstance = {
												_id: generateID(),
												columns: steps.find((a) => a.index === 2)!.state
													.columns!,
												formatters: steps.find((a) => a.index === 2)!.state
													.formatters!,
												selectedPrimaryEntity: selectedPrimaryEntity
													? (selectedPrimaryEntity as any)
													: instance.selectedPrimaryEntity,
												createdAt: new Date(),
												// need to add filters
												createdBy: currentUser,
												filters: steps.find((a) => a.index === 2)!.state!
													.addedFilters!,
												title: reportTitle,
												updatedAt: new Date(),
												exportOptions: exportOptions,
											};
											updateReport(instance._id, reportInstance).then(() =>
												navigate('/admin/report-builder').then(() =>
													info(
														'Successfully updated report ' + data.reportTitle
													)
												)
											);
										} else {
											createReport(
												data.reportTitle,
												data.exportOptions
											).then(() =>
												navigate('/admin/report-builder').then(() =>
													info(
														'Successfully created report ' + data.reportTitle
													)
												)
											);
										}
									}}
									title={instance?.title}
									selectedPrimaryEntity={selectedPrimaryEntity}
								/>
							)}
						</Row>
					</CardBody>
				</Card>
			</div>
		</>
	);
};
