import React from 'react';
import { capitalize } from 'lodash';
import { FormGroup } from 'reactstrap';

import { StyledInput } from './RegisterStyles';

type Props = {
	name: string;
	placeholder?: string;
	onChange: any;
	optional?: boolean;
	type?: string;
};

export const RegisterInput = (props: Props) => {
	return (
		<FormGroup className="mb-3">
			<label htmlFor={props.name}></label>
			<StyledInput
				autoComplete="on"
				name={props.name}
				placeholder={
					props.placeholder ? props.placeholder : capitalize(props.name)
				}
				type={props.type || 'text'}
				required={props.optional ? false : true}
				ref={props.onChange}
			/>
		</FormGroup>
	);
};
