import React, { useState } from 'react';
import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';

interface DropdownProperties {
	items: Array<{ label: string; value: string }>;
	onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	direction?: 'left' | 'right' | 'up' | 'down';
	label?: string | JSX.Element;
}
const CustomDropdown = styled(UncontrolledDropdown)`
	* {
		border: none !important;
	}
	.dropdown-menu.show {
		background: #fff !important;
	}
	.dropdown-toggle {
		border: none !important;
		background-color: transparent;
	}
`;
const CustomDropdownToggle = styled(DropdownToggle)`
	background: transparent;
	padding: 0;
	height: auto;
	border: none;
	position: relative;
	&:focus,
	&:hover {
		background: transparent !important;
		// border: none !important;
		border-color: red;
		box-shadow: none !important;
	}

	img {
		position: relative;
		top: -2px;
	}
`;
const DropDownToggleMenu = ({
	items,
	onClick,
	direction = 'up',
	label,
}: DropdownProperties) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<CustomDropdown
			className="p-0 background-light"
			onToggle={onClick}
			direction={direction}
			isOpen={dropdownOpen}
			toggle={toggle}
		>
			<CustomDropdownToggle color="white">
				<>{label}</>
			</CustomDropdownToggle>
			<DropdownMenu>
				{items.map((item: { label: string; value: string }) => (
					<DropdownItem
						onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
							onClick(event)
						}
						key={item.label}
						eventkey={item.value}
					>
						{item.label}
					</DropdownItem>
				))}
			</DropdownMenu>
		</CustomDropdown>
	);
};

export default DropDownToggleMenu;
