import React from 'react';
import { PaginationComponent } from './Pagination';
import { PaginationWrapper } from './pagination.styled.components';

type Props = {
	totalPages: number;
	setCurrentPage: (page: number) => void;
	currentPage: number;
	isFetching?: boolean;
};

export const PaginationContainer = (props: Props) => {
	const { currentPage, totalPages, setCurrentPage } = props;
	const handlePages = (updatePage: number) => {
		setCurrentPage(updatePage);
	};
	return (
		<div className="container-fluid mt-5">
			<PaginationWrapper fluid>
				<PaginationComponent
					page={currentPage}
					totalPages={totalPages}
					handlePagination={handlePages}
				/>
			</PaginationWrapper>
		</div>
	);
};
