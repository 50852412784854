import { last } from 'lodash';
import React, { useState } from 'react';
import { useTemplateContext } from 'context/useTemplateContext';
import {
	generateID,
	getDifferentPastel,
	stubTrue,
} from '../../../../utils/common';
import DownshiftMultiFreeform from '../../../downshift-select/downshift-multi-freeform.component';
import { SelectionActions } from '../../../downshift-select/downshift.interfaces';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { TemplatePhase, WorkflowTemplate } from '../types/workflow.types';

const phaseTerm = themeStore._.phase;

// interface
interface Props {
	className?: string;
	template: WorkflowTemplate;
	onChange: (temp: WorkflowTemplate) => void;
}

// component
const PhaseInput = ({ template, className, onChange }: Props) => {
	const { template: wfTemplate } = useTemplateContext();
	const [phases, setPhases] = useState(
		wfTemplate?.phases ? wfTemplate?.phases : []
	);
	const render = React.useMemo(() => {
		const addPhase = (title: string): void => {
			const lastPhase = last(template?.phases);
			const lastPhaseColor = lastPhase ? lastPhase?.color : null;
			const updated = {
				...template,
				phases: [
					...(template?.phases ? template?.phases : []),
					{
						color: getDifferentPastel(lastPhaseColor),
						title: title,
						_id: generateID(),
					},
				],
			} as WorkflowTemplate;
			setPhases(updated.phases);
			onChange(updated);
		};

		const removePhase = (phase: TemplatePhase): void => {
			const updated = {
				...template!,
				phases: template!.phases.filter(
					(p) => phase.title !== p.title && p._id !== phase._id
				),
			};
			setPhases(updated.phases);
			onChange(updated);
		};

		const selectionActions: SelectionActions<TemplatePhase, string> = {
			select: addPhase,
			unselect: removePhase,
		};

		const selectionState = {
			selection: phases,
			options: [],
			searchPredicate: stubTrue,
		};

		return (
			<div className={`phase-input ${className}`}>
				<DownshiftMultiFreeform
					label={`${phaseTerm}s`}
					placeholder={`Add a ${phaseTerm.toLowerCase()}...`}
					selectionState={selectionState}
					selectionActions={selectionActions}
				/>
			</div>
		);
		//eslint-disable-next-line
	}, [wfTemplate, template]);

	return render;
};

export default PhaseInput;
