import { navigate } from '@reach/router';
import React, { FormEvent, useContext, useState } from 'react';

import { _logError } from '../../../utils/common/log';
import themeStore from '../../../core-ui/models/ThemeStore';

import AssetCollectionForm from './asset-collection-form.component';
import { AssetCollection } from '../../workflow/workflows/types/workflow.types';
import { useAxios } from '../../../hooks';
import { NotificationsContext } from '../../notifications';

const collectionTerm = themeStore._.assetCollection;

interface Props {
	collection: AssetCollection;
}

const EditAssetCollectionForm = (props: Props) => {
	const { collection } = props;
	const { info, error: showError } = useContext(NotificationsContext);
	const collectionStore = useAxios<AssetCollection>('collections');
	const [editedCollection, setEditedCollection] = useState(collection);

	const onSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedCollection = (await collectionStore.updateOne(
				editedCollection._id,
				{ ...editedCollection }
			)) as AssetCollection;
			setEditedCollection(updatedCollection);

			info(`${collectionTerm} saved!`);

			return navigate(`/admin/dam/collections/${updatedCollection?._id}`);
		} catch (error) {
			_logError(error);

			showError(
				`An issue occurred while updating ${collectionTerm.toLowerCase()}. ` +
					`Please try again later.`
			);
		}
	};

	return (
		<AssetCollectionForm
			onChange={(updated) => {
				setEditedCollection(updated);
			}}
			selectedCollection={editedCollection}
			onSubmit={onSubmit}
		/>
	);
};

export default EditAssetCollectionForm;
