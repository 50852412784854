import { BaseWorkflowOwner } from 'components/workflow/workflows/types';
import { User } from '../../../utils/auth';
export type GroupMember = BaseWorkflowOwner | User;
export type UserGroup = {
	_id: string;
	title: string;
	members: Array<GroupMember>;
	color: string;
	account?: string;
	viewableDAMFolders: string[];
} & PermissionsTypes;
export const getViewableDamFolders = (group: UserGroup) => {
	return [
		...group.ArchivedPermissions,
		...group.BrandPermissions,
		...group.CategoryPermissions,
		...group.CountPermissions,
		...group.CountryPermissions,
		...group.DielinePermissions,
		...group.FileTypePermissions,
		...group.PackageTypePermissions,
		...group.PrinterPermissions,
		...group.VarietyPermissions,
	];
};

export type PermissionsTypes = {
	ArchivedPermissions: string[];
	CountPermissions: string[];
	CountryPermissions: string[];
	BrandPermissions: string[];
	CategoryPermissions: string[];
	DielinePermissions: string[];
	FileTypePermissions: string[];
	PackageTypePermissions: string[];
	PrinterPermissions: string[];
	VarietyPermissions: string[];
};
