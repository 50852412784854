import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'context/useThemeContext';
import { useAuthContext } from 'utils/auth';
import React from 'react';
import ConfirmationDialog from '../../components/modals/confirmation-dialog.component';
import { useModalCreator } from '../ModalStack';
import { authProvider, AuthProvider } from '../../context/AuthProvider';
export const throttle = (delay: any, fn: (...args: any) => void) => {
	let lastCall = 0;
	return function (...args: any) {
		const now = new Date().getTime();
		if (now - lastCall < delay) {
			return;
		}
		lastCall = now;
		return fn(...args);
	};
};

export const SessionTimeout = () => {
	const LOGOUT_COUNTDOWN_START = 60;
	const { currentUser } = useAuthContext();
	const isLoggedIn = React.useMemo(() => !!currentUser?._id, [currentUser]);
	const [, setLastActivity] = React.useState(0);
	const authSvc = authProvider;
	const cycle = 5 * 1000; // - 5 secs
	const idleAfter = 120 * 1000 * 60; // - 1 minutes or 2 hours
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);

	const activityInterval = React.useRef<any>(0);
	const logoutInterval = React.useRef<any>(0);
	const [isIdle, setIsIdle] = React.useState(false);
	const setActive = () => setIsIdle(false);
	const hideModal = () => {
		setIsModalVisible(false);
		setIsIdle(false);
		if (logoutInterval.current) {
			clearInterval(logoutInterval.current);
		}
	};

	const showModal = React.useCallback(() => {
		setIsIdle(true);
		setIsModalVisible(true);
		let logoutCountdown = LOGOUT_COUNTDOWN_START;
		logoutInterval.current = setInterval(() => {
			const logoutElm = document.getElementById('logoutCountdown');
			const currentCount = logoutCountdown--;
			if (logoutElm)
				logoutElm.innerHTML = `Redirecting in ${currentCount} ${
					currentCount === 1 ? 'second' : 'seconds'
				}`;
			if ((logoutCountdown as number) < 1) {
				hideModal();
				authSvc.signOut();
			}
		}, 1000);
	}, [authSvc]);

	const startCycle = React.useCallback((): void => {
		const lastActivity = Date.now();
		setLastActivity(lastActivity);
		if (activityInterval.current) clearInterval(activityInterval.current);
		activityInterval.current = setInterval(() => {
			const duration = Date.now() - lastActivity;

			if (isLoggedIn) {
				const updatedIsIdle = duration >= idleAfter;

				if (updatedIsIdle) showModal();
				setIsIdle(updatedIsIdle);
			}
		}, cycle);
	}, [cycle, idleAfter, isLoggedIn, showModal, activityInterval]);

	const logActivity = React.useCallback((): void => {
		if (isIdle) setActive();
		startCycle();
	}, [isIdle, startCycle]);

	const mountDomEvents = React.useCallback((): void => {
		window.addEventListener('visibilitychange', () => {
			const { visibilityState } = document;
			if (visibilityState === 'visible') logActivity();
		});

		const events = ['click', 'keypress'];

		const logActiv = () => logActivity();
		events.forEach((evt) => {
			document.body.addEventListener(evt, logActiv);
		});

		window.addEventListener('scroll', throttle(100, logActiv));
	}, [logActivity]);

	const renewSession = () => {
		authSvc.renewSession();
		setIsModalVisible(false);

		if (logoutInterval.current) {
			clearInterval(logoutInterval.current);
			logoutInterval.current = null;
		}
	};

	const { defaults } = useThemeContext();

	React.useEffect(() => {
		if (isLoggedIn) {
			mountDomEvents();
			logActivity();
		}
	}, [isLoggedIn, logActivity, mountDomEvents]);

	const renderModal = () => {
		if (isModalVisible || logoutInterval.current) {
			return (
				<ConfirmationDialog
					cancelText={'Logout'}
					confirmText={'Continue'}
					onCancel={authSvc.signOut}
					shouldWait={true}
					header={'Session is about to expire'}
					onConfirm={renewSession}
				>
					<div className="d-flex justify-content-between">
						<p>
							{' '}
							<FontAwesomeIcon
								style={{ color: defaults?.secondary }}
								icon={faUserClock}
							/>{' '}
							&nbsp; Your session is about to expire. Continue?{' '}
						</p>
					</div>
					<span id="logoutCountdown"></span>
				</ConfirmationDialog>
			);
		}

		return null;
	};

	return <>{renderModal()}</>;
};

export class SessionTimeoutService {
	private logoutCounterRef: React.RefObject<
		HTMLSpanElement
	> = React.createRef();
	public isIdle: boolean = false;
	public modalStack = useModalCreator();
	private activityInterval = 0; // - setInterval id
	private lastActivity = 0;

	private cycle = 5 * 1000; // - 5 secs
	private idleAfter = 120 * 1000 * 60; // - 120 minutes or 2 hours

	constructor(private readonly authSvc: AuthProvider) {
		this.mountDomEvents();
		this.logActivity();
	}

	/**
	 * @description
	 * Sets up event listeners that mounts to the Document and will track whether a user is idle or not
	 */
	private mountDomEvents(): void {
		window.addEventListener('visibilitychange', () => {
			const { visibilityState } = document;
			if (visibilityState === 'visible') this.logActivity();
		});

		const events = ['click', 'keypress'];

		const logActivity = () => this.logActivity();
		events.forEach((evt) => {
			document.body.addEventListener(evt, logActivity);
		});

		window.addEventListener('scroll', throttle(100, logActivity));
	}

	/**
	 * @description
	 * Flags the session timeout service as active; meaning the user is not idle
	 */
	private setActive(): void {
		this.isIdle = false;
	}

	/**
	 * @description
	 * Starts the cycle of the Session Timeout Counter; tracking users click and keypress events to track
	 * when a user goes idle
	 */
	private logActivity(): void {
		if (this.isIdle) this.setActive();
		this.startCycle();
	}

	private startCycle(): void {
		this.lastActivity = Date.now();
		if (this.activityInterval) clearInterval(this.activityInterval);
		// @ts-ignore
		this.activityInterval = setInterval(() => {
			const duration = Date.now() - this.lastActivity;
			if (this.isLoggedIn) {
				this.isIdle = duration >= this.idleAfter;

				if (this.isIdle) this.showModal();
			}
		}, this.cycle);
	}

	// --- Modal Methods ---

	private LOGOUT_COUNTDOWN_START = 60;

	private isModalVisible: boolean = false;

	private logoutInterval: any; // - setInterval id
	logoutCountdown: number | undefined;

	/**
	 * @description
	 * Getter that will return if the user is authenticated via the auth service
	 */
	get isLoggedIn(): boolean {
		return this.authSvc?.isAuthenticated || (false as boolean);
	}

	/**
	 * @description
	 * Method to show the session time out modal indicating a user's session is about to expire
	 * Also controls showing a counter that counts down from 60 seconds; and if a user doesn't continue thier session,
	 * Will log them out and redirect to auth page
	 */
	showModal(): void {
		if (this.isModalVisible) return;

		this.logoutCountdown = this.LOGOUT_COUNTDOWN_START;
		this.modalStack.addModal(
			<ConfirmationDialog
				cancelText={'Logout'}
				confirmText={'Continue'}
				onCancel={this.authSvc.signOut}
				shouldWait={true}
				header={'Session is about to expire'}
				onConfirm={() => this.hideModal()}
			>
				<p>Your session is about to expire. Continue? </p>
				<span ref={this.logoutCounterRef} id="logoutCountdown"></span>
			</ConfirmationDialog>
		);

		this.isIdle = true;
		this.isModalVisible = true;

		this.logoutInterval = setInterval(async () => {
			(this.logoutCountdown as number)--;
			if (this.logoutCounterRef.current)
				this.logoutCounterRef.current.innerHTML = `Redirecting in ${this.logoutCountdown}..`;

			if ((this.logoutCountdown as number) < 1) {
				this.hideModal();
				await this.authSvc.signOut();
			}
		}, 1000);
	}

	/**
	 * @description
	 * Method to hide the session timeout modal indicating a users session is about to expire
	 */
	hideModal(): void {
		this.isModalVisible = false;
		this.isIdle = false;
		if (this.logoutInterval) {
			clearInterval(this.logoutInterval);
		}
	}
}
