import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { WorkflowTemplate } from 'components/workflow/workflows/types';
import { WorkflowsIndexView } from 'components/workflow/workflows/WorkflowIndexPage';
import { WorkflowTemplatesTable } from '../components/tables/WorkflowTemplatesTable';
import { useAuthContext } from 'utils/auth';
import { isEqual, orderBy, uniqBy } from 'lodash';
import { useAxios } from 'hooks';
import { useFetching } from 'hooks/useFetching';

export const WorkflowTemplates = () => {
	const [allTemplates, setAllTemplates] = useState<WorkflowTemplate[]>([]);
	const [templates, setTemplates] = useState<WorkflowTemplate[]>();
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [defaultFilter, setDefaultFilter] = useState(['All']);
	const { currentUser } = useAuthContext();
	const { isFetching, beginFetching, finishFetching } = useFetching();

	useEffect(() => {
		if (!templates) {
			beginFetching();
			templateStore
				.findAll()
				.then((templates) => {
					setAllTemplates(templates as WorkflowTemplate[]);
					setTemplates(templates as WorkflowTemplate[]);
				})
				.finally(finishFetching);
		}
		// eslint-disable-next-line
	}, []);

	const onDelete = React.useCallback((wt: WorkflowTemplate) => {
		setAllTemplates((a) => [
			...a?.filter((template) => template._id !== wt._id),
		]);
		setTemplates((t) => [
			...(t || [])?.filter((template) => template._id !== wt._id),
		]);
	}, []);

	const onUpdate = React.useCallback((wt: WorkflowTemplate) => {
		setAllTemplates((a) => [
			...a?.map((template) =>
				template._id === wt._id
					? { ...template, editingState: wt.editingState }
					: template
			),
		]);
		setTemplates((t) => [
			...(t || [])?.map((template) =>
				template._id === wt._id
					? { ...template, editingState: wt.editingState }
					: template
			),
		]);
	}, []);

	// handleSorting
	const sortingOptions = [
		'A - Z',
		'Z - A',
		'Newest - Oldest',
		'Oldest - Newest',
	].map((val) => ({
		label: val,
		value: val,
	}));

	// Sort Handler
	const handleSort = (sorter: string) => {
		if (sorter === 'A - Z')
			setTemplates(orderBy(templates, (e) => e.title, 'asc'));
		if (sorter === 'Z - A')
			setTemplates(orderBy(templates, (e) => e.title, 'desc'));
		if (sorter === 'Newest - Oldest')
			setTemplates(orderBy(templates, (a) => a.createdAt, 'desc'));
		if (sorter === 'Oldest - Newest')
			setTemplates(orderBy(templates, (a) => a.createdAt));
	};

	// prettier-ignore
	const filterOptions = ['All', 'Archived', 'Final', 'Draft', 'Created By Me'].map((option) => ({
		label: option,
		value: option,
	}));
	const handleFilter = (filter: string | string[]) => {
		if (filter.length > 0) {
			if (Array.isArray(filter)) {
				const filteredTemplates: WorkflowTemplate[] = [];
				setDefaultFilter(filter);

				filter.forEach((filterOption) => {
					if (filterOption === 'All') filteredTemplates.push(...allTemplates);
					if (filterOption === 'Archived')
						filteredTemplates.push(
							...allTemplates.filter(R.propEq('editingState', 'archived'))
						);
					if (filterOption === 'Final')
						filteredTemplates.push(
							...allTemplates.filter(R.propEq('editingState', 'final'))
						);
					if (filterOption === 'Draft')
						filteredTemplates.push(
							...allTemplates.filter(R.propEq('editingState', 'draft'))
						);
					if (filterOption === 'Created By Me')
						filteredTemplates.push(
							...allTemplates.filter(
								(template: any) => template.createdBy?._id === currentUser._id
							)
						);
				});

				const uniqueFiltered = uniqBy(filteredTemplates, (a) => a._id);
				setTemplates(uniqueFiltered);
			} else {
				if (filter === 'All' && !isEqual(templates, allTemplates))
					setTemplates(allTemplates);
				if (filter === 'Archived')
					setTemplates(
						allTemplates.filter(R.propEq('editingState', 'archived'))
					);
				if (filter === 'Final')
					setTemplates(allTemplates.filter(R.propEq('editingState', 'final')));
				if (filter === 'Draft')
					setTemplates(allTemplates.filter(R.propEq('editingState', 'draft')));
				if (filter === 'Created By Me')
					setTemplates(
						allTemplates.filter(
							(template: any) => template.createdBy?._id === currentUser._id
						)
					);
			}
		} else {
			setDefaultFilter(['All']);
			setTemplates(allTemplates);
		}
	};

	const views = {
		table: (templates: WorkflowTemplate[]) => (
			<WorkflowTemplatesTable
				onUpdate={onUpdate}
				onDelete={onDelete}
				templates={templates}
			/>
		),
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filters = {
		options: filterOptions,
		fn: handleFilter,
	};

	return (
		<WorkflowsIndexView
			title="Templates"
			collection={templates as WorkflowTemplate[]}
			views={views}
			filters={filters}
			sorting={sorting}
			defaultFilter={defaultFilter}
			create
			isFetching={isFetching}
		/>
	);
};
