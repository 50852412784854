import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import { isEqual, isObject, isString, uniqBy, uniqWith } from 'lodash';
import React, { useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

type Props = {
	objectArray: Array<Object>;
	objectTitle: string;
	type: 'equals' | 'contains';
	onSelect: (key: string, value: string) => void;
	onClose: () => void;
};

export const SelectObjectPropertyDialog = (props: Props) => {
	const { objectArray, objectTitle, type, onSelect, onClose } = props;
	const allOptions = React.useMemo(() => {
		const allKeys: string[] = [];
		const uniqueObjects = uniqBy(
			objectArray.filter((f) => isObject(f)),
			(a) => Object.keys(a)
		);
		uniqueObjects.forEach((object) => {
			Object.keys(object)
				.filter((key) => isString(object[key as keyof object]))
				.forEach((key) => {
					if (!allKeys.includes(key) && !!key) allKeys.push(key);
				});
		});
		return uniqWith(allKeys, isEqual);
	}, [objectArray]);

	const [selectedProperty, setSelectedProperty] = useState('');
	const [selectedPropertyValue, setSelectedPropertyValue] = useState('');
	const onConfirm = () => {
		onSelect(selectedProperty, selectedPropertyValue);
		onClose();
	};

	const allPropertyOptions = React.useMemo(() => {
		if (selectedProperty) {
			const allKeys: string[] = [];
			const uniqueObjects = uniqBy(
				objectArray.filter(isObject),
				(a) => a[selectedProperty as keyof object]
			);
			uniqueObjects.forEach((object) => {
				if (
					!allKeys.includes(object[selectedProperty as keyof object]) &&
					!!object[selectedProperty as keyof object]
				)
					allKeys.push(object[selectedProperty as keyof object]);
			});
			return uniqWith(allKeys, isEqual);
		}
		return [];
	}, [selectedProperty, objectArray]);

	return (
		<ConfirmationDialog
			header={`Filter by a ${objectTitle} property`}
			isDisabled={selectedProperty === '' || selectedPropertyValue === ''}
			confirmText="Add filter"
			onConfirm={onConfirm}
			onCancel={onClose}
		>
			<FormGroup>
				<Label inline>Select {objectTitle} property</Label>
				<select
					className="form-control"
					onChange={(e) => setSelectedProperty(e.target.value)}
				>
					<option>Please select..</option>
					{allOptions.map((key) => (
						<option key={key} value={key}>
							{key}
						</option>
					))}
				</select>
			</FormGroup>

			{!!selectedProperty && type === 'equals' && (
				<FormGroup>
					<Label inline>Select {objectTitle} property value</Label>
					<select
						className="form-control"
						onChange={(e) => setSelectedPropertyValue(e.target.value)}
					>
						<option>Please select..</option>
						{allPropertyOptions.map((key) => (
							<option key={key} value={key}>
								{key}
							</option>
						))}
					</select>
				</FormGroup>
			)}

			{!!selectedProperty && type === 'contains' && (
				<FormGroup>
					<Label inline>
						Specify {objectTitle} {selectedProperty} includes value
					</Label>
					<Input
						type={'text'}
						onChange={(e) => setSelectedPropertyValue(e.target.value)}
					/>
				</FormGroup>
			)}
		</ConfirmationDialog>
	);
};
