import { Context, createContext, useContext } from 'react';
import { Maybe, ReadonlyXContext, XContext } from 'types/globals';
import { noopFor } from './index';

export class NullableContext<
	T,
	Pctx extends XContext<T> = XContext<T>,
	Mctx extends XContext<Maybe<T>> = XContext<Maybe<T>>
> {
	public static mutable<T>(name: string) {
		return new NullableContext<T>(name);
	}

	public static immutable<T>(name: string) {
		return new NullableContext<
			T,
			ReadonlyXContext<T>,
			ReadonlyXContext<Maybe<T>>
		>(name);
	}

	private ctx: Context<Pctx | Mctx> = createContext(this.emptyContext());

	public get presentValue(): Pctx[0] | null {
		return this.usePresent()?.length ? (this.usePresent() as T[])[0] : null;
	}

	public get possibleValue(): Mctx[0] {
		return this.useMaybe()[0];
	}

	public get setter(): Pctx[1] {
		return this.useMaybe()[1];
	}

	public get Provider() {
		return this.ctx.Provider;
	}

	constructor(public readonly name: string) {}

	public asReadonly(value: T): ReadonlyXContext<T> {
		return [value, this.throwReadonly];
	}

	public useMaybe(): Mctx {
		return useContext(this.ctx) as Mctx;
	}

	public usePresent(): Pctx | null {
		const ctx = this.useMaybe();
		return this.hasValue(ctx) ? ctx : null;
	}

	private emptyContext(): Pctx | Mctx {
		return [undefined, noopFor<T>()] as Mctx;
	}

	private hasValue(ctx: Pctx | Mctx): ctx is Pctx {
		return !!ctx[0];
	}

	private throwReadonly(): never {
		throw new Error(`Cannot set a value on a readonly ${this.name} context.`);
	}

	// private throwExpectedContext(): never {
	// 	throw new Error(`Expected to be in a ${ this.name } context, but was not.`);
	// }
}
