export const useCsvBuilder = () => {
	const empty = '';
	const toCSVCell = (s: string) =>
		!s ? `"${empty.replace(/"/g, '""')}"` : `"${s?.replace(/"/g, '""')}"`;

	const buildCsvFile = (rows: any[], headers: string[]) => {
		const header = headers.map((c) => toCSVCell(c)).join(',');

		const reportRows = rows.map((layer) => {
			return Object.keys(layer)
				.map((column: string) => {
					let parsedColumn: string = '';
					if (
						layer &&
						layer[column] &&
						layer[column]?.toString() === '[object Object]'
					) {
						parsedColumn =
							column !== 'metadata' && layer?.hasOwnProperty('title')
								? layer['title']
								: '';
					} else if (layer && layer[column]) {
						parsedColumn = layer[column]
							?.toString()
							?.replace('"', '')
							.replace('"', '') as string;
					}

					// the commented lines below check for date but are too broad, need to narrow it down
					return toCSVCell(parsedColumn.replace('"', '').replace('"', ''));
					// return moment(column.replace('"', '').replace('"', '')).isValid()
					// 	? toCSVCell(moment(column).toISOString())
					// 	: toCSVCell(column.replace('"', '').replace('"', ''));
				})
				.join(',');
		});
		return [header, ...reportRows].join('\n');
	};
	const exportToCsv = (rows: any[], headers: string[], reportName: string) => {
		var blob = new Blob([buildCsvFile(rows, headers)], { type: 'text/csv' });
		if (navigator.msSaveBlob) {
			// IE 10+
			navigator.msSaveBlob(blob, reportName);
		} else {
			var link = document.createElement('a');
			// Browsers that support HTML5 download attribute
			var url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', reportName);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};
	return { exportToCsv, toCSVCell };
};
