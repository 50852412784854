import {
	faChartLine,
	faFile,
	faFilePdf,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, CardBody } from 'reactstrap';

export const ReportBuilderStepOne = ({
	selectedPrimaryEntity,
	setSelectedPrimaryEntity,
}: {
	selectedPrimaryEntity: string;
	setSelectedPrimaryEntity: (str: string) => void;
}) => {
	const getButtonStyle = (
		type: 'workflows' | 'templates' | 'assets' | 'users'
	) => {
		return selectedPrimaryEntity === type
			? {
					boxShadow: '0 0 0 0.2rem rgb(130 138 145 / 50%)',
					fontWeight: 600,
			  }
			: {};
	};
	return (
		<CardBody>
			<div className="d-flex justify-content-stretch">
				<Button
					style={getButtonStyle('users')}
					className="mr-2"
					onClick={() => setSelectedPrimaryEntity('users')}
				>
					<span className="txt">
						<FontAwesomeIcon icon={faUser} /> USER GROUPS AND USERS
					</span>
					{/* <p>Run complex reports over user groups and user iterations</p> */}
				</Button>

				<Button
					style={getButtonStyle('workflows')}
					className="mr-2"
					onClick={() => setSelectedPrimaryEntity('workflows')}
				>
					<span className="txt">
						<FontAwesomeIcon icon={faChartLine} /> WORKFLOWS
					</span>
					{/* <p> Run up-to-date reports on the latest workflows with a variety of filters </p> */}
				</Button>

				<Button
					style={getButtonStyle('templates')}
					className="mr-2"
					onClick={() => setSelectedPrimaryEntity('templates')}
				>
					<span className="txt">
						<FontAwesomeIcon icon={faFilePdf} /> WORKFLOW TEMPLATES
						{/* <p> Run complex reports on workflow templates and workflow iterations using templates </p> */}
					</span>
				</Button>

				<Button
					style={getButtonStyle('assets')}
					onClick={() => setSelectedPrimaryEntity('assets')}
				>
					<span className="txt">
						<FontAwesomeIcon icon={faFile} /> ASSETS
						{/* <p> Organize and get detailed reports regarding the latest brands, assets, and facets </p> */}
					</span>
				</Button>
			</div>
		</CardBody>
	);
};
