import { RouteComponentProps } from '@reach/router';
import { useWorkflowContext } from 'context/useWorkflowStore';
import React from 'react';
import { Container } from 'reactstrap';
import { Heading } from '../ui';
import { findVersionInWorkflow } from './components/asset-details-sidebar/info-panel.component';
import { AssetPreviewDiff } from './components/comparison-preview.component';
import { AssetComparisonTable } from './components/comparison-props-table.component';

// component
const VersionsComparisonView = (
	props: RouteComponentProps<{
		workflowAId: string;
		versionAId: string;
		workflowBId: string;
		versionBId: string;
	}>
) => {
	const { entities: workflows } = useWorkflowContext();

	const render = () => {
		if (!props.versionAId || !props.versionBId) return null;
		// @ts-ignore
		const workflowA = workflows.find((w) => w._id === props.workflowAId);
		// @ts-ignore
		const workflowB = workflows.find((w) => w._id === props.workflowBId);

		if (!workflowA || !workflowB) {
			return null;
		}

		const versionA = findVersionInWorkflow(props.versionAId, workflowA)
			?.inputSlots?.find((slot) =>
				slot.versions?.some((version) => version._id === props.versionAId)
			)
			?.versions?.find((m) => m._id === props.versionAId);
		const versionB = findVersionInWorkflow(props.versionBId, workflowB)
			?.inputSlots?.find((slot) =>
				slot.versions?.some((version) => version._id === props.versionBId)
			)
			?.versions?.find((m) => m._id === props.versionBId);

		if (!versionA || !versionB) {
			return null;
		}

		return (
			<Container>
				<div className="d-block">
					<Heading>Versions comparison</Heading>
				</div>
				<div className="d-block">
					<AssetPreviewDiff versionA={versionA} versionB={versionB} />
				</div>
				<div className="d-block">
					<AssetComparisonTable
						workflowA={workflowA}
						versionA={versionA}
						workflowB={workflowB}
						versionB={versionB}
					/>
				</div>
			</Container>
		);
	};

	return render();
};

export default VersionsComparisonView;
