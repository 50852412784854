import { Col, Container, Row } from 'reactstrap';
import React, { useEffect } from 'react';
import { SelectedWorkflowStage } from '../../workflow.contexts';
import StageTable from '../stage-table.component';
import WorkflowDisabledMask from './workflow-disabled-mask.component';
import StageDetailCard from './StageDetailsCard';
import { Flattenable, Workflow } from '../../types/workflow.types';
import { Stage } from '../../types/workflow.types';
import { flattenStages } from '../../helpers/workflowStage.helpers';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { StageStatus } from 'utils/models/StageStatusModel';

function stageReducer(
	state: { selectedStage: Stage },
	action: {
		type: 'setStage';
		payload: Stage;
	}
) {
	switch (action.type) {
		case 'setStage':
			return { ...state, selectedStage: action.payload as Stage };
		default:
			return state;
	}
}
type Props = {
	workflow?: Workflow;
};
const StageIndexPanel = React.memo((props: Props) => {
	const {
		stage: selectedStage,
		setStage: setSelectedStage,
		workflow,
	} = useWorkflowContext();
	const [state, dispatch] = React.useReducer(stageReducer, {
		selectedStage: selectedStage as Stage,
	});

	useEffect(() => {
		// if they are coming into the route with a selected stage, set it
		const hash = window.location.hash.replace('#', '');
		if (hash) {
			const stage = flattenStages(workflow as Flattenable, true)?.find(
				(m) => m._id === hash
			);
			if (stage) {
				window.history.replaceState(
					null,
					document.title,
					window.location.pathname
				);
				dispatch({ type: 'setStage', payload: stage });
				setSelectedStage(stage);
			}
		} // otherwise, if the workflow doesn't contain the selected stage, reset selected stage to initial
		else if (
			flattenStages(workflow as Flattenable, true).some(
				(a) => a._id === selectedStage?._id
			)
		) {
			const active = flattenStages(workflow as Flattenable, true).find(
				(a) => a._id === selectedStage?._id
			) as Stage;
			setSelectedStage(active);
		} else if (
			flattenStages(workflow as Flattenable, true)?.every(
				({ _id }) => _id !== selectedStage?._id
			) &&
			workflow?.stages
		) {
			const firstActive = flattenStages(workflow as Flattenable)?.find((s) =>
				[StageStatus.active, StageStatus.roadblocked].some(
					(status) => s.status === status
				)
			) as Stage;
			setSelectedStage(firstActive ?? workflow?.stages[0]);
		}
	}, [state, workflow, selectedStage, setSelectedStage]);

	return (
		<Container fluid>
			<Row>
				<Col lg={6}>
					<StageTable
						dispatch={(action) => {
							dispatch({ type: 'setStage', payload: action.payload });
							setSelectedStage(action.payload);
						}}
					/>
				</Col>
				<Col lg={6}>
					<SelectedWorkflowStage.Provider
						value={[state.selectedStage, setSelectedStage]}
					>
						{workflow && (
							<>
								<WorkflowDisabledMask workflow={workflow} />
								<StageDetailCard id={workflow._id} />
							</>
						)}
					</SelectedWorkflowStage.Provider>
				</Col>
			</Row>
		</Container>
	);
});

export default StageIndexPanel;
