import styled from 'styled-components';
import { Card } from 'reactstrap';

import { StyledChip } from '../../../chip/chip.styled-components';
import themeStore from '../../../../core-ui/models/ThemeStore';

const { colors } = themeStore.selectedTheme;

export const InputChip = styled<any>(StyledChip)`
	display: flex;
	background-color: var(--white);
	border: 1px solid #ddd;
	color: #ddd;
	justify-content: space-between;

	&:first-child {
		margin-top: 1em;
	}

	&:last-child {
		margin-bottom: 1.5em;
	}
`;

export const InputSlotCard = styled(Card)`
	border: 1px dashed var(--darkGrey) !important;
	margin-bottom: 1.5rem;

	&& {
		z-index: 0;
	}
`;

export const InputSlotImage = styled.div`
	display: flex;
	align-items: center;
	background-color: ${colors.lighterGrey};
	color: var(--darkGrey);
	font-size: 26px;
	height: 50px;
	justify-content: center;
	margin: 0.5em 0.5em 0;
`;
