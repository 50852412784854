import React, { useEffect, useState } from 'react';
import {
	Flattenable,
	WorkflowCollection,
} from 'components/workflow/workflows/types';
import { WorkflowsIndexView } from 'components/workflow/workflows/WorkflowIndexPage';
import { useAuthContext } from '../../../../utils/auth';
import { useGroupContext } from '../../../../utils/auth';
import * as H from '../helpers';
import { useWorkflowCollectionContext } from 'components/workflow/workflows/hooks/useWorkflowCollectionStore';
import FilteredWorkflowCollectionList from 'components/workflow/workflow-collections/components/filtered-workflow-collection-list.component';
import WorkflowCollectionListTable from 'components/workflow/workflow-collections/components/workflow-collection-list-table.component';
import { flattenStages, userIsOwner } from '../helpers';
import { uniqBy } from 'lodash';
import LoadingIndicator from 'core-ui/components/LoadingIndicator';
import { useFetching } from 'hooks/useFetching';

export const Projects = () => {
	const { entities } = useWorkflowCollectionContext();
	const { groupsForCurrentUser } = useGroupContext();
	const { currentUser } = useAuthContext();
	const { isFetching, beginFetching, finishFetching } = useFetching();
	const [projects, setProjects] = useState<WorkflowCollection[]>([]);

	useEffect(() => {
		if (!entities) return;
		beginFetching();
		setProjects(H.alphabetizeBy('title', entities));
		finishFetching();
	}, [entities]);

	const [defaultFilter, setDefaultFilter] = useState(['All Projects']);

	// handleSorting
	const sortingOptions = ['A - Z', 'Z - A'].map((val) => ({
		label: val,
		value: val,
	}));
	// Sort Handler
	const handleSort = (sorter: string) => {
		if (sorter === 'A - Z') setProjects(H.alphabetizeBy('title', entities));
		if (sorter === 'Z - A') setProjects(H.reverseAlphabetizeBy('title'));
	};

	// filterOptions
	const filterOptions = [
		'All Projects',
		'Created By Me',
		'Following',
	].map((option) => ({ label: option, value: option }));
	// handle Filtering
	const handleFilter = (filter: string | string[]) => {
		if (filter.length > 0) {
			const filteredProjects: WorkflowCollection[] = [];
			if (Array.isArray(filter)) {
				setDefaultFilter(filter);

				filter.forEach((filters) => {
					if (filters === 'All Projects') filteredProjects.push(...entities);
					if (filters === 'Created By Me')
						filteredProjects.push(
							...entities.filter(
								(project) => project.createdBy === currentUser._id
							)
						);
					if (filters === 'Following')
						filteredProjects.push(
							...entities.filter((wc) => {
								return wc?.workflows?.some((wf) => {
									return flattenStages(wf as Flattenable)?.some((stage) =>
										userIsOwner(stage, currentUser, groupsForCurrentUser)
									);
								});
							})
						);
				});
				setProjects(uniqBy(filteredProjects, (project) => project._id));
			} else if (Array.isArray(filter) && !filter.length) {
				setDefaultFilter(['All Projects']);

				const filteredProjects: WorkflowCollection[] = [];
				filteredProjects.push(...entities);
				setProjects(uniqBy(filteredProjects, (project) => project._id));
			}
		} else {
			setDefaultFilter(['All Projects']);

			const filteredProjects: WorkflowCollection[] = [];
			filteredProjects.push(...entities);
			setProjects(uniqBy(filteredProjects, (project) => project._id));
		}
	};

	const views = {
		cards: (projects: WorkflowCollection[]) => (
			<FilteredWorkflowCollectionList collections={projects} />
		),
		table: (projects: WorkflowCollection[]) => (
			<WorkflowCollectionListTable collections={projects} />
		),
	};
	const sorting = {
		options: sortingOptions,
		fn: handleSort,
	};
	const filters = {
		options: filterOptions,
		fn: handleFilter,
	};

	return (
		<>
			{!entities && <LoadingIndicator dismiss={false} />}
			<WorkflowsIndexView
				defaultFilter={defaultFilter}
				defaultSort={'title'}
				title="Projects"
				collection={projects}
				sorting={sorting}
				filters={filters}
				views={views}
				isFetching={isFetching}
				// saveQuery
				create
			/>
		</>
	);
};
