import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { ButtonElement, C } from 'types/globals';
import useOption from './useOption';

interface OptionProps extends ButtonElement {
	value: any;
	label: string;
	selected?: boolean;
	checkbox?: boolean;
	onSelect?: (value: any) => void;
}

export const OptionComponent = ({ value, label, ...props }: C<OptionProps>) => {
	const buttonRef = useRef<HTMLButtonElement>(null);
	const { onSelect = () => {} } = props;

	useOption(buttonRef, () => handleSelect());

	const handleSelect = () => {
		onSelect(value);
	};

	return (
		<button
			ref={buttonRef}
			type="button"
			tabIndex={0}
			aria-label={label}
			disabled={props.disabled}
			className={props.className}
			onClick={onSelect}
			{...props}
		>
			{props.children ? props.children : label}
		</button>
	);
};

export const Option = styled(OptionComponent)`
	background: #fff;
	border: none;
	outline: none;
	text-align: left;
	transition: all 0.2s ease-in-out;

	:focus,
	:hover {
		outline: none;
		border: none;
		background: lightgrey;
	}
	:active {
		border: none;
		outline: none;
	}

	${({ selected }) =>
		selected &&
		css`
			background: #416c65;
			color: white;
			/* margin: 0px 8px; */
			/* border-radius: 5px; */

			:hover,
			:focus {
				background: #6b9e96;
			}
		`};
`;
