import { useHeaders } from 'utils/auth';
import { useState, useEffect } from 'react';
//the function to call the push server: https://github.com/Spyna/push-notification-demo/blob/master/front-end-react/src/utils/http.js
import * as axios from 'axios';
import {
	isPushNotificationSupported,
	askUserPermission,
	registerServiceWorker,
	createNotificationSubscription,
	getUserSubscription,
} from './push-notifications';
//import all the function created to manage the push notifications

const pushNotificationSupported = isPushNotificationSupported();
//first thing to do: check if the push notifications are supported by the browser

export default function usePushNotifications() {
	const { getHeaders } = useHeaders();
	const [userConsent, setSuserConsent] = useState(Notification.permission);
	//to manage the user consent: Notification.permission is a JavaScript native function that return the current state of the permission
	//We initialize the userConsent with that value
	const [
		userSubscription,
		setUserSubscription,
	] = useState<PushSubscription | null>(null);
	//to manage the use push notification subscription
	const [pushServerSubscriptionId, setPushServerSubscriptionId] = useState<
		string | undefined
	>('');
	//to manage the push server subscription
	const [error, setError] = useState<
		{ name: string; message: string; code: number } | boolean
	>(false);
	//to manage errors
	const [loading, setLoading] = useState(true);
	//to manage async actions

	useEffect(() => {
		if (pushNotificationSupported) {
			setLoading(true);
			setError(false);
			registerServiceWorker().then(() => {
				setLoading(false);
			});
		}
	}, []);
	//if the push notifications are supported, registers the service worker
	//this effect runs only the first render

	useEffect(() => {
		setLoading(true);
		setError(false);
		const getExixtingSubscription = async () => {
			const existingSubscription = await getUserSubscription();
			setUserSubscription(existingSubscription);
			if (existingSubscription) {
				const subscription = await axios.default.get(
					`${process.env.REACT_APP_ROME_API_ENDPOINT}/pushsubscription/currentuser`,
					getHeaders()
				);
				console.log('subscription data', subscription.data);
				if (subscription.data && subscription.data._id)
					setPushServerSubscriptionId(subscription.data._id);
			}
			setLoading(false);
		};
		getExixtingSubscription();
		//eslint-disable-next-line
	}, []);
	//Retrieve if there is any push notification subscription for the registered service worker
	// this use effect runs only in the first render

	/**
	 * define a click handler that asks the user permission,
	 * it uses the setSuserConsent state, to set the consent of the user
	 * If the user denies the consent, an error is created with the setError hook
	 */
	const onClickAskUserPermission = () => {
		setLoading(true);
		setError(false);
		return new Promise((resolve, reject) => {
			askUserPermission().then((consent) => {
				setSuserConsent(consent);
				if (consent !== 'granted') {
					resolve(false);
					setError({
						name: 'Consent denied',
						message: 'You denied the consent to receive notifications',
						code: 0,
					});
				}
				setLoading(false);
				resolve(true);
			});
		});
	};
	//

	/**
	 * define a click handler that creates a push notification subscription.
	 * Once the subscription is created, it uses the setUserSubscription hook
	 */
	const onClickSusbribeToPushNotification = () => {
		const callback = () => {
			setLoading(true);
			setError(false);
			createNotificationSubscription()
				.then(function (subscrition) {
					setUserSubscription(subscrition);
					onClickSendSubscriptionToPushServer();
					setLoading(false);
				})
				.catch((err) => {
					console.error(
						"Couldn't create the notification subscription",
						err,
						'name:',
						err.name,
						'message:',
						err.message,
						'code:',
						err.code
					);
					setError(err);
					setLoading(false);
				});
		};
		if (userConsent !== 'granted') {
			onClickAskUserPermission().then((value) => {
				if (value) callback();
			});
		} else {
			callback();
		}
	};
	/**
	 * define a click handler that sends the push susbcribtion to the push server.
	 * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
	 */
	const onClickSendSubscriptionToPushServer = () => {
		setLoading(true);
		setError(false);
		axios.default
			.post(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/pushsubscription`,
				userSubscription,
				getHeaders()
			)
			.then(function (response) {
				setPushServerSubscriptionId(response.data._id);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setError(err);
			});
	};

	/**
	 * define a click handler that request the push server to send a notification, passing the id of the saved subscription
	 */
	const onClickSendNotification = async () => {
		setLoading(true);
		setError(false);
		await axios.default
			.get(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/pushsubscription/${pushServerSubscriptionId}`,
				getHeaders()
			)
			.catch((err) => {
				setLoading(false);
				setError(err);
			});
		setLoading(false);
	};

	/**
	 * Define a click handler to delete the subscription when the user toggles off
	 */
	const onClickUnsubscribeToPushNotification = async () => {
		setLoading(true);
		setError(false);
		await axios.default
			.delete(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/pushsubscription/${pushServerSubscriptionId}`,
				getHeaders()
			)
			.catch((err) => {
				setLoading(false);
				setError(err);
			});
		setPushServerSubscriptionId(undefined);
	};

	/**
	 * returns all the stuff needed by a Component
	 */
	return {
		onClickAskUserPermission,
		onClickSusbribeToPushNotification,
		onClickSendSubscriptionToPushServer,
		pushServerSubscriptionId,
		onClickSendNotification,
		userConsent,
		pushNotificationSupported,
		userSubscription,
		error,
		loading,
		onClickUnsubscribeToPushNotification,
	};
}
