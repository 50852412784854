import * as axios from 'axios';
import { buildEmptyAssetMetadata } from 'components/dam-assets/components/helpers/useAssetHelper';
import {
	InputSlot,
	Stage,
	// StageTransition,
	WorkflowTemplate,
} from 'components/workflow/workflows/types/workflow.types';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { useAxios } from 'hooks';
import { useFetching } from 'hooks/useFetching';
import React from 'react';
import { Maybe } from 'types/globals';
import { useHeaders } from 'utils/auth';
import { generateID } from 'utils/common';
// import { flattenStages } from 'workflows/helpers/workflowStage.helpers';
// import { flatten } from 'lodash';
import { _logError } from 'utils/common/log';
type TemplateContext = {
	template?: WorkflowTemplate | undefined;
	currentStage: Stage | undefined;
	setLastAction: (action: string) => void;
	setCurrentStage: (stage: Stage | undefined) => void;
	addInputSlot: (stage: Stage) => Promise<Stage>;
	saveTemplate: (
		template: WorkflowTemplate
	) => Promise<WorkflowTemplate | undefined>;
	lastAction: string;
	loadTemplate: (id: string) => Promise<WorkflowTemplate | undefined>;
	updateTemplateStatus: (
		template: WorkflowTemplate,
		newStatus: string
	) => Promise<Maybe<WorkflowTemplate>>;
	cloneTemplate: (
		template: WorkflowTemplate
	) => Promise<Maybe<WorkflowTemplate>>;

	templates: WorkflowTemplate[];
	addStage: (type: string, fromStageId: string) => Promise<WorkflowTemplate>;
};

/* export const getTemplateTransitions = (
	template: WorkflowTemplate
): StageTransition[] | undefined =>
	flatten(
		flattenStages(template)?.map((stage: Stage[] | Stage) =>
			Array.isArray(stage)
				? [
						...(flatten(
							stage.map(({ transitions }) => transitions)
						) as StageTransition[]),
				  ]
				: (stage?.transitions as StageTransition[]) || ([] as StageTransition[])
		)
	) as StageTransition[]; */

export const WfTemplateContext = React.createContext<TemplateContext>({
	addInputSlot: (stage) => Promise.resolve<Stage>({} as Stage),
	addStage: () => Promise.resolve<WorkflowTemplate>({} as WorkflowTemplate),
	lastAction: '',
	setLastAction: (action: string) => {
		return;
	},
	currentStage: undefined,
	setCurrentStage: (stage) => {},
	template: undefined,
	loadTemplate: (id: string) =>
		Promise.resolve<WorkflowTemplate>({} as WorkflowTemplate),
	saveTemplate: (template: WorkflowTemplate) =>
		Promise.resolve<WorkflowTemplate>({} as WorkflowTemplate),
	updateTemplateStatus: () =>
		Promise.resolve<WorkflowTemplate>({} as WorkflowTemplate),
	cloneTemplate: () =>
		Promise.resolve<WorkflowTemplate>({} as WorkflowTemplate),
	templates: [],
});

function templateReducer(
	state: { template: WorkflowTemplate | undefined; lastAction: string },
	action: {
		type: 'SET' | 'UPDATE_LAST_ACTION';
		payload: WorkflowTemplate | string;
	}
) {
	switch (action.type) {
		case 'SET':
			return { ...state, template: action.payload as WorkflowTemplate };
		case 'UPDATE_LAST_ACTION':
			return { ...state, lastAction: action.payload as string };
		default:
			return state;
	}
}

function stageReducer(
	state: { currentStage: Stage | undefined },
	action: { type: 'SET'; payload: Stage | undefined }
) {
	switch (action.type) {
		case 'SET':
			return { ...state, currentStage: action.payload };
		default:
			return state;
	}
}

export const WorkflowTemplateProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const endpoint = `${process.env.REACT_APP_ROME_API_ENDPOINT}`;
	const { getHeaders } = useHeaders();
	const { allTemplates, updateTemplateContext } = useWorkflowContext();

	const addInputSlot = async (stage: Stage) => {
		const updatedStage = {
			...stage,
			inputSlots:
				stage?.inputSlots && stage?.inputSlots?.length
					? [
							...(stage.inputSlots || []),
							{
								_id: generateID(),
								label: 'Unlabeled slot',
								uploadToDAM: false,
								optional: false,
								versions: [],
								metadata: buildEmptyAssetMetadata,
							} as InputSlot,
					  ]
					: [
							{
								_id: generateID(),
								label: 'Unlabeled slot',
								uploadToDAM: false,
								optional: false,
								versions: [],
								metadata: buildEmptyAssetMetadata,
							} as InputSlot,
					  ],
		};
		const updated = {
			...state.template,
			stages: state.template?.stages.flatMap((stg) => {
				if (stg?._id === stage?._id)
					return {
						...updatedStage,
						transitions:
							state?.template?.stages[0]?._id === updatedStage?._id &&
							state.template.stages?.length > 1
								? [
										{
											_id: generateID(),
											type: 'forward',
											targetStage: state?.template?.stages[1]._id,
										},
								  ]
								: [],
					};
				else
					return {
						...stg,
						transitions:
							state.template?.stages[state?.template?.stages?.length - 1]
								._id === stg?._id
								? []
								: [
										{
											_id: generateID(),
											type: 'forward',
											targetStage: state?.template?.stages[1]._id,
										},
								  ],
					};
			}),
		} as WorkflowTemplate;
		const newTemplate = (await saveTemplate(updated)) as WorkflowTemplate;
		setTemplate(newTemplate);
		return updatedStage as Stage;
	};

	const templateService = useAxios<WorkflowTemplate>('templates');

	const { isFetching, beginFetching, finishFetching } = useFetching();

	const loadTemplate = async (id: string) => {
		if (!getHeaders() || !getHeaders()?.headers?.Authorization) return;
		beginFetching();
		if (!id) throw new Error('Null Template ID Provided');
		const template = await templateService.findOne(id);
		setLastAction(template.lastAction || '');
		finishFetching();
		setTemplate(template);
		return template as WorkflowTemplate;
	};

	const saveTemplate = async (template: WorkflowTemplate) => {
		if (!getHeaders() || !getHeaders()?.headers?.Authorization) return;
		if (!template) throw new Error('Null template Provided');
		if (isFetching) return;

		try {
			beginFetching();

			const updated = (await templateService.updateOne(template._id, {
				...template,
				lastAction: `Saved at: ${new Date()
					.toLocaleDateString()
					.substring(0, 11)} ${new Date().toLocaleTimeString()}`,
			})) as WorkflowTemplate;
			finishFetching();
			setLastAction(
				`Saved at: ${new Date(
					updated.updatedAt
				).toLocaleDateString()} ${new Date(
					updated.updatedAt
				).toLocaleTimeString()}`
			);
			updateTemplateContext(template);
			setTemplate(updated);
			return updated;
		} catch (e) {
			_logError(e);
			setLastAction(
				`Error saving template, ${new Date()
					.toLocaleDateString()
					.substring(0, 11)} ${new Date().toLocaleTimeString()}`
			);
		}
	};

	const updateTemplateStatus = async (
		template: WorkflowTemplate,
		newStatus: string
	) => {
		const updatedTemplate = { ...template, editingState: newStatus };
		if (!template) throw new Error('Null template Provided');
		if (isFetching) return;

		try {
			beginFetching();
			const updated = (await templateService.updateOne(template._id, {
				...updatedTemplate,
				lastAction: `Saved at:  ${new Date()
					.toLocaleDateString()
					.substring(0, 11)} ${new Date().toLocaleTimeString()}`,
			})) as WorkflowTemplate;
			finishFetching();
			setLastAction(
				`Saved at: ${new Date().toLocaleDateString()} ${new Date(
					updated.updatedAt
				).toLocaleTimeString()}`
			);
			setTemplate(updated);
			return updated;
		} catch (e) {
			_logError(e);
			setLastAction(
				`Error saving template, ${new Date().toLocaleTimeString()}`
			);
		}
	};

	const cloneTemplate = async (template: WorkflowTemplate) => {
		if (isFetching) return;
		beginFetching();
		const response = await axios.default
			.post<WorkflowTemplate>(
				endpoint + `/templates/${template._id}/clone`,
				null,
				{ ...getHeaders() }
			)
			.finally(finishFetching);
		return response.data;
	};

	const addStage = async (type: string, fromStageId: string) => {
		const updatedTemplate = await axios.default.post<WorkflowTemplate>(
			endpoint + `/templates/${state?.template?._id}/stages`,
			{ from: fromStageId, type },
			{ ...getHeaders() }
		);
		setLastAction(
			`Saved at: ${new Date().toLocaleDateString()} ${new Date(
				updatedTemplate.data.updatedAt
			).toLocaleTimeString()}`
		);
		dispatch({ type: 'SET', payload: updatedTemplate.data });
		setTemplate(updatedTemplate.data);
		return updatedTemplate.data;
	};

	const setTemplate = (updatedTemplate: WorkflowTemplate) =>
		dispatch({ type: 'SET', payload: updatedTemplate });

	const [state, dispatch] = React.useReducer(templateReducer, {
		template: undefined,
		lastAction: '',
	});

	const [stageState, dispatchStage] = React.useReducer(stageReducer, {
		currentStage: undefined,
	});

	const setCurrentStage = (updated: Stage | undefined) => {
		dispatchStage({ type: 'SET', payload: updated });
	};

	const setLastAction = (action: string) =>
		dispatch({ type: 'UPDATE_LAST_ACTION', payload: action });

	return (
		<WfTemplateContext.Provider
			value={{
				addInputSlot,
				saveTemplate,
				addStage,
				setLastAction,
				lastAction: state.lastAction,
				currentStage: stageState.currentStage,
				setCurrentStage: setCurrentStage,
				loadTemplate,
				template: state?.template,
				updateTemplateStatus,
				cloneTemplate,
				templates: allTemplates as WorkflowTemplate[],
			}}
		>
			{children}
		</WfTemplateContext.Provider>
	);
};

export const useTemplateContext = () => {
	const context = React.useContext(WfTemplateContext);
	if (!context)
		throw new Error('Expected to be in useTemplateContext, but was not');

	return context;
};
