import styled from 'styled-components';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { fsSmol } from '../../../../../core-ui/ui/typography';

const { colors } = themeStore.selectedTheme;

export const TemplatePageWrapper = styled.div`
	width: 100%;
	display: flex;
`;

export const ToggleSidebarButton = styled.a`
	position: absolute;
	// transition: all 0.5s ease-in-out;
	top: 0px
	right: 0px;
	padding: 4px 8px;
	background-color: transparent;
	cursor: pointer;
`;

export const TemplateSidebar = styled.div`
	position: relative;
	background-color: #ffffff;
	transition: all 0.5s ease-in-out;
	// overflow: hidden;
`;

export const TemplateEditorWrapper = styled.div`
	padding-left: 0;
	position: relative;

	.last-actions {
		padding: 1.5em;
		padding-bottom: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		small {
			display: block;
			width: 100%;
		}
	}

	.edit-form {
		padding: 1.5em;
	}
`;

export const StageEditorWrapper = styled.div`
	background-color: var(--background-color);
	display: block;
	position: relative;
	.phases {
		top: 100px;
		right: 10px;
		z-index: 99;
		height: auto;
		width: auto;
		position: fixed;
		width: 160px;
	}
`;

// styled components
export const TemplateHeader = styled.div`
	display: flex;
	padding: 0 1rem
	border-bottom: 1px solid ${colors.lightGrey};
	align-items: center;
`;

export const LastActionLabel = styled.p`
	${fsSmol}
`;
