import {
	faChevronCircleRight,
	faEllipsisV,
	faFileImport,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import {
	Heading,
	Loading,
	PageSubheading,
	ScrollTable,
} from 'components/index';
import { SecondaryButton } from 'components/buttons.styled-components';
import { DropDownMenuItem } from 'components/drop-down-menu.component';
import { RequiresFeatureFlag } from 'utils/core/FeatureFlags';
import { StyledLink } from 'components/dam-assets/components/asset-card.styled-components';
import { NotificationsContext } from 'components/notifications';
import React, { useContext, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Container,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap';
import { useReportBuilder } from 'components/reports/hooks/useReportBuilder';
import { ReportBuilderInstance } from 'components/reports/types';
export const ReportBuilder = () => {
	const { fetchExistingReports, deleteReport } = useReportBuilder();
	const { info } = useContext(NotificationsContext);
	const [reports, setReports] = React.useState<ReportBuilderInstance[]>();

	useEffect(() => {
		fetchExistingReports().then(setReports);
		//eslint-disable-next-line
	}, []);

	const deleteReportCallback = async (id: string) => {
		const successful = await deleteReport(id);
		if (successful) {
			info(
				'Succesfully deleted report ' +
					reports?.find((a) => a._id === id)?.title
			);
			setReports((reports) => reports?.filter((a) => a._id !== id));
		}
	};

	const navigateToCreate = async () =>
		await navigate('/admin/report-builder/create');

	return (
		<Container fluid>
			{reports === undefined && <Loading label="Loading reports.." />}
			<Card>
				<CardHeader>
					<div className={'d-flex justify-content-between'}>
						<Heading>
							{' '}
							<PageSubheading text="ROME" />
							Report Builder{' '}
						</Heading>
						<FontAwesomeIcon icon={faFileImport} />
					</div>
				</CardHeader>
				<CardBody>
					<ScrollTable>
						<thead>
							<tr>
								<th>Report Title</th>
								<th>Reporting Entity</th>
								<th>Created By</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{reports?.map((report) => (
								<tr key={report?._id}>
									<td>{report?.title}</td>
									<td>{report?.selectedPrimaryEntity}</td>
									<td>
										{report?.createdBy?.givenName}{' '}
										{report?.createdBy?.familyName}
									</td>
									<td>
										<div className="d-flex justify-content-between">
											<UncontrolledDropdown>
												<DropdownToggle color="white">
													<FontAwesomeIcon icon={faEllipsisV} />
												</DropdownToggle>
												<DropdownMenu>
													<StyledLink
														to={`/admin/report-builder/${report._id}`}
													>
														<DropDownMenuItem>View</DropDownMenuItem>
													</StyledLink>
													<StyledLink
														to={`/admin/report-builder/edit/${report._id}`}
													>
														<DropDownMenuItem>Edit</DropDownMenuItem>
													</StyledLink>
													<RequiresFeatureFlag flag="assignReporting">
														<DropDownMenuItem
															onClick={() => console.log('Void')}
														>
															Assign report to user
														</DropDownMenuItem>
													</RequiresFeatureFlag>
													<DropDownMenuItem
														onClick={async () =>
															await deleteReportCallback(report._id)
														}
													>
														Delete <FontAwesomeIcon icon={faTrash} />
													</DropDownMenuItem>
												</DropdownMenu>
											</UncontrolledDropdown>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</ScrollTable>
				</CardBody>
				<CardFooter>
					<div className="justify-content-end d-flex">
						<SecondaryButton onClick={navigateToCreate}>
							Create a new report{' '}
							<FontAwesomeIcon icon={faChevronCircleRight} />
						</SecondaryButton>
					</div>
				</CardFooter>
			</Card>
		</Container>
	);
};
