import { MaybeNull } from 'types/globals';
import { throwErr } from './fn.utils';
import { getRandomInt } from './math';

export const randomHue = () => getRandomInt(360);

const pastelShade = (h: number) => `hsl(${h}, 100%, 65%)`;
const darkShade = (h: number) => `hsl(${h}, 100%, 30%)`;

export const toRGBA = (hex: string, alpha: number): string => {
	const [r, g, b] = getRGBfromHEX(hex);
	return `rgba(${r},${g},${b},${alpha})`;
};

export const getRandomPastel = () => pastelShade(randomHue());
export const getRandomDarkColor = () => darkShade(randomHue());

export function getRGBfromHEX(string: string): number[] {
	if (!string) string = '#222';
	const hex = string.replace(/[^0-9a-f]/gi, '');

	switch (hex.length) {
		case 3:
			return hex.split('').map((x) => parseInt(`${x}${x}`, 16));
		case 6:
			return hex.match(/[0-9a-f]{2}/gi)!.map((x) => parseInt(x, 16));
		default:
			return throwErr(`Invalid hex color ${string}`);
	}
}

export function getDifferentPastel(previousString: MaybeNull<string>) {
	if (previousString) {
		const lastColor = parseInt(previousString?.match(/\d+/)![0]);

		const newColor = getRandomPastel();
		const newColorNumber = parseInt(newColor.match(/\d+/)![0]);

		const differenceAmount: Number = Math.abs(lastColor - newColorNumber);
		return differenceAmount >= 25 ? newColor : pastelShade(newColorNumber + 25);
	}

	return getRandomPastel();
}
