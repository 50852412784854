//@ts-nocheck
import { PaddedBadge } from 'components/dashboard.component/components/workflow-health-flag.component';
import { SmallTextMuted } from 'components/index';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { first, isArray, random } from 'lodash';
import React from 'react';
import { Card, CardHeader, FormGroup, Table } from 'reactstrap';
import styled, { StyledComponent } from 'styled-components';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { formatDuration } from '../../../../utils/common';
import { StageStatus } from '../../../../utils/models/StageStatusModel';
import {
	ListColumnCreationOptions,
	makeColumns,
} from '../../../list-column.component';
import OwnerAvatarList from '../../../owner-avatar-list.component';
import StageStatusPill from '../../../stage-status-pill.component';
import { StageType } from '../../workflow-templates/models/StageTypes';
import {
	flattenStages,
	getStageDueDate,
} from '../helpers/workflowStage.helpers';
import { Stage, Workflow } from '../types/workflow.types';
import { Td, Tr, TrSub, TrSubChild } from './stage-table.styled-components';

const { selectedTheme } = themeStore;

const Dot = styled.div<{ color: string }>`
	display: inline-block;
	height: 9px;
	width: 9px;
	border-radius: 100%;
	margin-left: 5px;
	background-color: ${(props) => props.color};
`;

const StageDueDateColumn = styled.span`
	white-space: nowrap;
	font-size: 14px;
`;
const columnOptions: ListColumnCreationOptions<Stage>[] = [
	{ label: 'Name', prop: 'title' },
	{
		label: 'Status',
		prop: (s: Stage) =>
			s?.substages?.length &&
			s.substages
				?.flatMap((s) => s)
				.every((ss) => ss.status === 'completed') ? (
				<StageStatusPill status={StageStatus.completed} />
			) : (
				<StageStatusPill status={s.status as StageStatus} />
			),
	},
	{
		label: 'Duration',
		prop: (s: Stage) => {
			const returnValue: JSX.Element[] = [];
			if (
				// check if stage has completed or queue events as well indicating it would have been rejected.
				s.events?.some(
					(a) => a.newStatus === 'completed' || a.newStatus === 'queue'
				)
			) {
				if (s.expectedDurations?.subsequent)
					returnValue.push(
						<FormGroup className="px-2">
							<SmallTextMuted>
								<>Subsequent</>
							</SmallTextMuted>
							<PaddedBadge color="success">
								{formatDuration(s.expectedDurations?.subsequent)}
							</PaddedBadge>
						</FormGroup>
					);
			}
			returnValue.push(
				<FormGroup className="mx-2" key={`${s._id}_${s.title}`}>
					<SmallTextMuted>First Round</SmallTextMuted>
					<PaddedBadge
						color={returnValue.length === 0 ? 'success' : 'secondary'}
					>
						{!s.expectedDurationHrs
							? 'TBD'
							: formatDuration(s.expectedDurationHrs)}
					</PaddedBadge>
				</FormGroup>
			);
			return <div className="d-flex flex-row">{returnValue.reverse()}</div>;
		},
	},
	{
		label: 'Due',
		prop: (s: Stage) => {
			const returnValue: JSX.Element[] = [];
			if (
				// check if stage has completed or queue events as well indicating it would have been rejected.
				s.events?.some(
					(a) => a.newStatus === 'completed' || a.newStatus === 'queue'
				)
			) {
				if (s.expectedDurations?.subsequent)
					returnValue.push(
						<FormGroup className="px-2">
							<SmallTextMuted>
								<>Subsequent</>
							</SmallTextMuted>
							<StageDueDateColumn>
								<PaddedBadge color="success">
									{getStageDueDate(s, true)}
								</PaddedBadge>
							</StageDueDateColumn>
						</FormGroup>
					);
			}
			returnValue.push(
				<FormGroup className="mx-2" key={`fg_${random()}`}>
					<StageDueDateColumn>
						<SmallTextMuted>First Round</SmallTextMuted>

						<PaddedBadge
							color={returnValue.length === 0 ? 'success' : 'secondary'}
						>
							{getStageDueDate(s, false)}
						</PaddedBadge>
					</StageDueDateColumn>
				</FormGroup>
			);
			return <div className="d-flex flex-row">{returnValue.reverse()}</div>;
		},
	},
	{
		label: `${themeStore._.owner}(s)`,
		prop: (s: Stage) =>
			(
				<OwnerAvatarList displayNames={false} owners={s.owners} size="md" />
			) as JSX.Element,
	},
];

const stageTableColumns = makeColumns(
	columnOptions.map((c) => {
		c.cellWrapper = Td;
		return c;
	})
);

interface StageRowProps {
	stage?: Stage;
	wrapper?: StyledComponent<any, any>;
	dispatch: React.Dispatch<{ type: 'setStage'; payload: Stage }>;
}
const wrapperForStage = (stage: Stage): React.ComponentType<any> => {
	switch (stage.type) {
		case StageType.parallel:
		case StageType.single:
			return React.memo(Tr);
		case StageType.substage:
		case StageType.sideTask:
			return new Array(stage).shift() === stage
				? React.memo(TrSub)
				: React.memo(TrSubChild);
		default:
			return React.memo(Tr);
	}
};

const StageRow = ({ stage, dispatch }: StageRowProps) => {
	const {
		stage: selectedStage,
		setStage: setSelectedStage,
	} = useWorkflowContext();
	const stageToRender = isArray(stage)
		? (first(stage) as Stage)
		: (stage as Stage);

	const Wrapper = wrapperForStage(stage as Stage);
	return (
		<Wrapper
			id={stageToRender._id}
			onClick={() => {
				setSelectedStage(stageToRender as Stage);
				dispatch({ type: 'setStage', payload: stageToRender });
			}}
			className={`${
				stageToRender?._id === (selectedStage as Stage)?._id ? 'selected' : ''
			} ${
				isArray(stage) && stageToRender === first(stage) ? 'parallel-stage' : ''
			}`}
		>
			{stageTableColumns.map((c) => c.render(stageToRender as Stage))}
		</Wrapper>
	);
};

// component
const StageTable = React.memo(
	({
		dispatch,
	}: {
		dispatch: React.Dispatch<{ type: 'setStage'; payload: Stage }>;
	}) => {
		const { workflow } = useWorkflowContext();
		const [shouldShowLegend, setShouldShowLegend] = React.useState(false);
		const rows = React.useMemo(
			() => (
				<tbody>
					{flattenStages(workflow as Workflow).map((stage: Stage) => {
						if (
							stage.events?.some((a) => a.newStatus === 'completed') &&
							stage.status === 'active'
						)
							setShouldShowLegend(true);
						return (
							<StageRow
								key={`stage_${random()}${stage?._id}`}
								dispatch={dispatch}
								stage={stage}
							/>
						);
					})}
				</tbody>
			),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[workflow]
		);

		const sideTaskRows = React.useMemo(
			() =>
				// @ts-ignore
				workflow?.sideTasks?.map((sideTask) => (
					<tbody
						key={`stage_${random()}${sideTask?._id}`}
						id={sideTask._id}
						className="sidetask-body border-0"
					>
						<StageRow dispatch={dispatch} stage={sideTask} />
					</tbody>
				)),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[workflow]
		);

		return (
			<Card className="h-100">
				<CardHeader>
					<div className="d-flex justify-content-between">
						<h2 className="mb-0">{selectedTheme.terms.stage} Overview</h2>
						{shouldShowLegend && (
							<div className="d-flex flex-row">
								<Dot color="green"></Dot>&nbsp;
								<SmallTextMuted>Active</SmallTextMuted>
								<Dot color="grey"></Dot> &nbsp;
								<SmallTextMuted>Inactive</SmallTextMuted>
							</div>
						)}
					</div>
				</CardHeader>
				<Table className="mb-0">
					<thead>
						<tr>{stageTableColumns.map((c) => c.renderHeader())}</tr>
					</thead>
					{rows}
					{!!sideTaskRows?.length && (
						<thead>
							<tr>
								<th colSpan={5}>Side Tasks</th>
							</tr>
						</thead>
					)}
					{sideTaskRows}
				</Table>
			</Card>
		);
	}
);

export default StageTable;
