import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { css } from 'styled-components';

import { ffRegular, fsNormal } from '../ui/typography';
import { focus } from '../ui/ui';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

const tabButton = css`
	${ffRegular};
	${fsNormal};
	background-color: transparent;
	border: none;
	color: ${defaults?.primary};
	cursor: pointer;
	padding: 0.75em 1.5em;

	&.selected {
		border-bottom: 2px solid ${defaults?.primary};
		margin-top: 2px;
	}

	&:hover {
		color: ${defaults?.primaryHighlighted} !important;
		text-decoration: none;
	}

	&:focus {
		${focus};
	}
`;
export default tabButton;
