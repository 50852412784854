import { navigate } from '@reach/router';
import { _logError } from 'utils/common/log';
import React, { FormEvent } from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../../back-link/back-link.component';
import Loading from '../../loading.component';
import { Heading } from '../../ui';
import { useAxios } from '../../../hooks';
import { useFetching } from '../../../hooks/useFetching';
import { NotificationsContext } from '../../notifications';
import { WorkflowTemplate } from '../workflows/types/workflow.types';
import EditTemplateForm from './components/edit-template-form.component';

const TemplateEditView = ({
	workflowTemplateId,
}: {
	workflowTemplateId: string;
}) => {
	const { info, error } = React.useContext(NotificationsContext);
	const templateStore = useAxios<WorkflowTemplate>('templates');
	const [edited, setEdited] = React.useState<WorkflowTemplate>();

	const { isFetching, finishFetching, beginFetching } = useFetching();
	React.useEffect(() => {
		if (isFetching) return;
		if (!edited) {
			beginFetching();
			templateStore
				.findOne(workflowTemplateId)
				.then(setEdited)
				.finally(finishFetching);
		}
	}, [
		isFetching,
		edited,
		beginFetching,
		templateStore,
		workflowTemplateId,
		finishFetching,
	]);
	const updateTemplate = async (event: FormEvent) => {
		event.preventDefault();
		if (edited && edited._id) {
			try {
				await templateStore.updateOne(edited?._id, { ...edited });

				info('Template saved!');
				return navigate(`.`);
			} catch (e) {
				_logError(e);
				error(
					'Sorry, there was an issue with saving the template. Please try again later'
				);
			}
		}
	};

	return (
		<Container>
			<Row className="justify-content-center">
				{!!isFetching && <Loading label="Loading template.." />}
				{!isFetching && (
					<>
						<Col lg={8}>
							<BackLink link=".." title="Template Editor" />
							<div className="d-flex justify-content-between">
								<Heading>Edit {edited?.title}</Heading>
							</div>
						</Col>
						<Col lg={8}>
							<EditTemplateForm
								onChange={(updated) => setEdited(updated)}
								onSubmit={updateTemplate}
								selectedTemplate={edited as WorkflowTemplate}
								buttonLabel="Save changes"
							/>
						</Col>
					</>
				)}
			</Row>
		</Container>
	);
};

export default TemplateEditView;
