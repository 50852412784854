import { useWorkflowContext } from 'context/useWorkflowStore';
import { isEqual } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Card } from 'reactstrap';
import { useAuthContext, User } from 'utils/auth';
import {
	CellLink,
	ListColumn,
	ListTable,
	Loading,
	makeColumns,
} from 'components/index';
import DeleteTemplateButton from '../../../workflow-templates/components/delete-template-button.component';
import { Workflow, WorkflowTemplate } from '../../types';

type Props = {
	templates: WorkflowTemplate[];
	onDelete: (wt: WorkflowTemplate) => void;
	onUpdate: (wt: WorkflowTemplate) => void;
};

export const WorkflowTemplatesTable = ({
	templates,
	onDelete,
	onUpdate,
}: Props) => {
	const { entities: workflows } = useWorkflowContext();
	const { currentUser } = useAuthContext();

	const compareCreator = (t: WorkflowTemplate) => {
		const type = typeof t?.createdBy;
		if (type === 'object')
			return isEqual((t?.createdBy as User)?._id, currentUser?._id);
		if (type === 'string') return isEqual(t?.createdBy, currentUser?._id);
		return false;
	};
	const templateListColumns: ListColumn<WorkflowTemplate>[] = makeColumns([
		{
			label: 'Name',
			prop: (t: WorkflowTemplate) => (
				<CellLink className="cy-wf-template-name" to={`./${t._id}`}>
					{t.title}
				</CellLink>
			),
		},
		{
			label: 'Template #',
			prop: '_id',
		},
		{
			label: 'Created',
			prop: (t) => moment(t.createdAt).toISOString().substring(0, 10),
		},
		{
			label: 'Editing State',
			prop: 'editingState',
		},
		{
			label: 'Used Count',
			prop: (template: WorkflowTemplate) => (
				<>
					{
						workflows
							?.filter((a: Workflow) => !!a.templateUsed)
							.filter((a: Workflow) =>
								typeof a.templateUsed === 'string'
									? a.templateUsed === template._id
									: a.templateUsed._id === template._id
							).length
					}
				</>
			),
		},
		{
			label: '',
			prop: (t: WorkflowTemplate) =>
				compareCreator(t) ? (
					<DeleteTemplateButton
						onUpdate={onUpdate}
						template={t}
						onDelete={(t) => onDelete(t)}
					/>
				) : null,
		},
	]);

	return (
		<Card>
			<ListTable
				columns={templateListColumns}
				rows={templates || []}
				noResultsLabel="Loading Templates..."
			/>
		</Card>
	);
};
