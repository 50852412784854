import React, { useEffect, useState } from 'react';
import * as axios from 'axios';
import { useHeaders } from 'utils/auth';

const SiteSettingsContext = React.createContext({
	settings: {},
});

export const SiteSettingsContextProvider = ({
	children,
}: {
	children: any;
}) => {
	const [siteSettings, setSiteSettings] = useState({});
	const { getHeaders } = useHeaders();

	useEffect(() => {
		axios.default
			.get<{ settings: object }>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/site-settings`,
				getHeaders()
			)
			.then((settings) => setSiteSettings(settings.data.settings));
		//eslint-disable-next-line
	}, []);

	return (
		<SiteSettingsContext.Provider value={{ settings: siteSettings }}>
			{children}
		</SiteSettingsContext.Provider>
	);
};

export const useSiteSettings = () => {
	return React.useContext(SiteSettingsContext);
};
