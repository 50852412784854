import {
	faDesktop,
	faMobileAlt,
	faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteDefaults } from 'context/useThemeContext';
import { capitalizeFirstLetter } from 'utils/common';
import React, { useState } from 'react';
import styled from 'styled-components';
import DesktopPreviewComponent from './DesktopPreviewComponent';
import LoginPreviewComponent from './LoginPreviewComponent';
import MobilePreviewComponent from './MobilePreviewComponent';

type previewProps = {
	selectedTheme: SiteDefaults;
};

export const ThemePreviewComponent = (props: previewProps) => {
	const { selectedTheme } = props;
	const [view, setView] = useState<'desktop' | 'mobile' | 'login'>('desktop');

	const LayoutDiv = styled.div`
		height: 480px;
	`;

	const IconDiv = styled.div`
		display: flex;
		justify-content: center;
	`;

	const Icon = styled(FontAwesomeIcon)`
		cursor: pointer;
	`;

	const PreviewDiv = styled.div`
		width: 100%;
		height: 411px;
		border: 5px solid black;
		border-radius: 4px;
	`;

	return (
		<LayoutDiv>
			<h1>{capitalizeFirstLetter(view)} Preview</h1>
			<IconDiv className="mb-2">
				<Icon
					icon={faDesktop}
					size={'2x'}
					className="mx-2"
					onClick={() => setView('desktop')}
				/>
				<Icon
					icon={faMobileAlt}
					size={'2x'}
					className="mx-2"
					onClick={() => setView('mobile')}
				/>
				<Icon
					icon={faShieldAlt}
					size={'2x'}
					className="mx-2"
					onClick={() => setView('login')}
				/>
			</IconDiv>
			<PreviewDiv>
				{view === 'desktop' && (
					<DesktopPreviewComponent selectedTheme={selectedTheme} />
				)}
				{view === 'mobile' && (
					<MobilePreviewComponent selectedTheme={selectedTheme} />
				)}
				{view === 'login' && (
					<LoginPreviewComponent selectedTheme={selectedTheme} />
				)}
			</PreviewDiv>
		</LayoutDiv>
	);
};
