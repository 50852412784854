import {
	faMinusCircle,
	faPencilRuler,
	faPlus,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'reactstrap';
import { useAuthContext } from 'utils/auth';
import { InteractiveFontAwesomeIcon, SmallTextMuted } from 'components/index';
import { RomeSwal } from 'components/alert';
import { OutlineButton } from 'components/buttons.styled-components';
import RenderWhen from 'components/render-when.component';
import { ButtonLink } from 'components/dam-assets/components/asset-details-sidebar/asset-workflow-props.component';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import { PaddedBadge } from 'components/dashboard.component/components/workflow-health-flag.component';
import React from 'react';
import {
	ButtonGroup,
	CardBody,
	CardFooter,
	CardHeader,
	Input,
	Label,
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	ListGroupItemText,
	Modal,
	ModalBody,
	ModalHeader,
} from 'reactstrap';
import { useTemplateContext } from 'context/useTemplateContext';
import {
	Stage,
	StageTask,
	WorkflowTemplate,
} from 'components/workflow/workflows/types';
import { generateID } from './Stage.helpers';

export const StageTaskDialog = ({
	stage,
	isOpen,
	onClose,
}: {
	stage: Stage;
	isOpen: boolean;
	onClose: () => void;
}) => {
	const [isEditing, setIsEditing] = React.useState<StageTask>({} as StageTask);
	const [isAdding, setIsAdding] = React.useState(false);
	const [addedTask, setAddedTask] = React.useState<StageTask>({} as StageTask);
	const { template, saveTemplate } = useTemplateContext();
	const { currentUser } = useAuthContext();
	const updateTask = (updatedTask: StageTask) => {
		const updatedTemplate = {
			...template,
			stages: template?.stages.map((stage) =>
				stage.substages
					? {
							...stage,
							substages: stage.substages.flatMap((substage) =>
								substage.map((substage) => ({
									...substage,
									tasks: substage?.tasks?.map((task) =>
										task._id === updatedTask._id ? updatedTask : task
									),
								}))
							),
					  }
					: {
							...stage,
							tasks: stage?.tasks?.map((task) =>
								task._id === updatedTask._id ? updatedTask : task
							),
					  }
			),
			sideTasks: !template?.sideTasks
				? []
				: template?.sideTasks?.map((sideTask) => ({
						...sideTask,
						tasks: sideTask?.tasks?.map((task) =>
							task._id === updatedTask._id ? updatedTask : task
						),
				  })),
		} as WorkflowTemplate;

		saveTemplate(updatedTemplate);
		setIsEditing({} as StageTask);
	};

	const deleteStageTask = async (updatedTask: StageTask) => {
		const { isConfirmed } = await RomeSwal.fire({
			title: 'Are you sure you want to delete this task?',
			confirmButtonText: 'Delete',
			showCancelButton: true,
			text: 'This action is not reversible.',
			icon: 'question',
		});

		if (isConfirmed) {
			const updatedTemplate = {
				...template,
				stages: template?.stages.map((stage) =>
					stage.substages
						? {
								...stage,
								substages: stage.substages.flatMap((substage) =>
									substage.map((substage) => ({
										...substage,
										tasks: substage?.tasks?.filter(
											(task) => task._id !== updatedTask._id
										),
									}))
								),
						  }
						: {
								...stage,
								tasks: stage?.tasks?.filter(
									(task) => task._id !== updatedTask._id
								),
						  }
				),
				sideTasks: !template?.sideTasks
					? []
					: template?.sideTasks?.map((sideTask) => ({
							...sideTask,
							tasks: sideTask?.tasks?.filter(
								(task) => task._id !== updatedTask._id
							),
					  })),
			} as WorkflowTemplate;

			saveTemplate(updatedTemplate);
			setIsEditing({} as StageTask);
		}
	};

	const showAddTask = () => {
		setIsAdding(true);
	};

	const saveAddedTask = () => {
		const updatedTask = {
			...addedTask,
			_id: generateID(),
			createdAt: new Date(),
			createdBy: currentUser,
		} as StageTask;
		const stageId = stage?._id;
		const updatedTemplate = {
			...template,
			stages: template?.stages.map((stage) =>
				stage._id === stageId
					? {
							...stage,
							tasks: stage?.tasks
								? [...stage.tasks, updatedTask]
								: [updatedTask],
					  }
					: stage.substages
					? {
							...stage,
							substages: stage.substages.flatMap((substage) =>
								substage.map((substage) =>
									substage._id === stage._id
										? {
												...substage,
												tasks: substage?.tasks
													? [...substage.tasks, updatedTask]
													: [updatedTask],
										  }
										: substage
								)
							),
					  }
					: {
							...stage,
					  }
			),
			sideTasks: !template?.sideTasks
				? []
				: template?.sideTasks?.map((sideTask) =>
						sideTask._id === stageId
							? {
									...sideTask,
									tasks: sideTask?.tasks
										? [...sideTask.tasks, updatedTask]
										: [updatedTask],
							  }
							: sideTask
				  ),
		} as WorkflowTemplate;
		saveTemplate(updatedTemplate).then(() => {
			setAddedTask({} as StageTask);
			setIsAdding(false);
		});
	};

	return (
		<Modal toggle={onClose} size="xl" fade isOpen={isOpen}>
			<div className="position-relative">
				<span
					style={{ top: 10, right: 10 }}
					className="position-absolute btn btn-transparent close"
					data-dismiss="modal"
					onClick={onClose}
				>
					<ButtonLink>Exit task view &times;</ButtonLink>
				</span>
			</div>
			<ModalHeader>Stage "{stage.title}" Tasks Overview </ModalHeader>
			<ModalBody>
				<ButtonLink
					className="py-2"
					onClick={() => (!isAdding ? showAddTask() : setIsAdding(false))}
				>
					{isAdding ? 'Cancel' : 'Add task'}
					&nbsp; <FontAwesomeIcon icon={isAdding ? faTimesCircle : faPlus} />
				</ButtonLink>
				<ListGroup variant="dark">
					<RenderWhen when={!isAdding}>
						{!stage?.tasks?.length && (
							<ListGroupItem>
								<ListGroupItemHeading>
									No tasks have been added
								</ListGroupItemHeading>
								<ListGroupItemText>
									<AnchorSpan
										onClick={() =>
											!isAdding ? showAddTask() : setIsAdding(false)
										}
									>
										{isAdding ? 'Cancel' : 'Add one now'}
									</AnchorSpan>
								</ListGroupItemText>
							</ListGroupItem>
						)}
						{stage?.tasks?.map((task, idx) => (
							<ListGroupItem key={`task_${idx}_${task._id}`}>
								{isEditing?._id !== task._id && (
									<div className="form-horizontal form-group d-flex align-items-center">
										<div
											style={{ fontSize: '22px' }}
											className="d-flex p-3 form-text mr-1"
										>
											<InteractiveFontAwesomeIcon
												onClick={() => deleteStageTask(task)}
												tooltip="Remove Task"
												id={`task_${idx}_${task.label}`}
												color={'red'}
												icon={faMinusCircle}
											/>
										</div>
										<div>
											<ListGroupItemHeading>{task.label}</ListGroupItemHeading>
											<ListGroupItemText>
												<PaddedBadge
													color={task.isRequired ? 'success' : 'danger'}
												>
													{task.isRequired ? 'REQUIRED' : 'NOT REQUIRED'}
												</PaddedBadge>
											</ListGroupItemText>
											<ListGroupItemText>
												<AnchorSpan onClick={() => setIsEditing({ ...task })}>
													Edit
												</AnchorSpan>
											</ListGroupItemText>
										</div>
									</div>
								)}
								{isEditing?._id === task._id && (
									<Card>
										<CardHeader>
											Edit task <FontAwesomeIcon icon={faPencilRuler} />
										</CardHeader>
										<CardBody>
											<ListGroupItemHeading>
												<Input
													type="text"
													defaultValue={isEditing.label}
													onChange={(e) =>
														setIsEditing({
															...isEditing,
															label: e.target.value,
														})
													}
												/>
											</ListGroupItemHeading>
											<ListGroupItemText>
												<div className="d-flex">
													<Label className="pl-3" check>
														<Input
															type={'checkbox'}
															defaultChecked={isEditing?.isRequired}
															onChange={(e) => {
																setIsEditing({
																	...isEditing,
																	isRequired: e.target.checked,
																});
															}}
														/>{' '}
														Is required for completion{' '}
													</Label>
												</div>
											</ListGroupItemText>
										</CardBody>
										<CardFooter>
											<ListGroupItemText>
												<ButtonGroup className="d-flex align-items-center">
													<AnchorSpan
														className="text-primary pr-2"
														onClick={() => setIsEditing({} as StageTask)}
													>
														Cancel{' '}
													</AnchorSpan>
													<OutlineButton
														onClick={() => {
															updateTask(isEditing);
														}}
													>
														Update task
													</OutlineButton>
												</ButtonGroup>
											</ListGroupItemText>
										</CardFooter>
									</Card>
								)}
							</ListGroupItem>
						))}
					</RenderWhen>
					{isAdding && (
						<ListGroupItem style={{ borderBottom: '1px solid darkgrey' }}>
							<Card>
								<CardHeader>Add a stage task</CardHeader>
								<CardBody className="pl-3">
									<div className="d-block">
										<SmallTextMuted>Task description</SmallTextMuted>
										<Input
											type="text"
											onChange={(e) =>
												setAddedTask({ ...addedTask, label: e.target.value })
											}
										/>
									</div>
									<Label check className="pl-3">
										<Input
											type={'checkbox'}
											defaultChecked={addedTask?.isRequired}
											onChange={(e) => {
												setAddedTask({
													...addedTask,
													isRequired: e.target.checked,
												});
											}}
										/>{' '}
										Is required for completion
									</Label>
								</CardBody>
								<CardFooter className="bg-white">
									<ButtonGroup className="d-flex align-items-center">
										<AnchorSpan
											onClick={() => {
												setIsAdding(false);
												setAddedTask({} as StageTask);
											}}
										>
											Cancel{' '}
										</AnchorSpan>
										<AnchorSpan
											className="pl-2 text-primary"
											onClick={saveAddedTask}
										>
											Save task
										</AnchorSpan>
									</ButtonGroup>
								</CardFooter>
							</Card>
						</ListGroupItem>
					)}
				</ListGroup>
			</ModalBody>
		</Modal>
	);
};
