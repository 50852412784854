import React, { FC } from 'react';

function uploadReducer(
	state: File[],
	action: { type: 'add' | 'remove' | 'clear'; payload: File }
) {
	switch (action.type) {
		case 'add':
			return [...state, action.payload];
		case 'remove':
			return [...state.filter((m) => m.name !== action.payload.name)];
		case 'clear':
			return [];
		default:
			return state;
	}
}

function uploadProgressReducer(
	state: UploadProgress,
	action: { type: 'update' | 'clear'; payload?: UploadProgress }
) {
	switch (action.type) {
		case 'update':
			return { ...state, ...(action.payload as UploadProgress) };
		case 'clear':
			return {
				loaded: 0,
				total: 0,
				percent: 0,
			};
		default:
			return state;
	}
}

type UploadProgress = {
	total: number;
	loaded: number;
	percent: number;
};

type UploadContext = {
	assetIds: File[];
	add: (id: File) => void;
	remove: (id: File) => void;
	clear: () => void;
	uploadProgress: UploadProgress;
	updateUploadProgress: (uploadProgress: UploadProgress) => void;
	clearUploadProgress: () => void;
};

const InitialUploadContext = {
	assetIds: Array<File>(),
	add: () => {},
	clear: () => {},
	remove: () => {},
	uploadProgress: {
		total: 0,
		loaded: 0,
		percent: 0,
	},
	updateUploadProgress: () => {},
	clearUploadProgress: () => {},
};

export const UploadContext = React.createContext<UploadContext>(
	InitialUploadContext
);

export const UploadContextProvider: FC<{}> = ({ children }) => {
	const [assetIds, dispatch] = React.useReducer(uploadReducer, []);
	const add = (file: File) => dispatch({ type: 'add', payload: file });
	const clear = () => dispatch({ type: 'clear', payload: {} as File });
	const remove = (file: File) => dispatch({ type: 'remove', payload: file });

	const [uploadProgress, uploadProgressDispatch] = React.useReducer(
		uploadProgressReducer,
		{
			loaded: 0,
			total: 0,
			percent: 0,
		}
	);
	const updateUploadProgress = (uploadProgress: UploadProgress) =>
		uploadProgressDispatch({ type: 'update', payload: uploadProgress });
	const clearUploadProgress = () =>
		uploadProgressDispatch({ type: 'clear', payload: undefined });

	return (
		<UploadContext.Provider
			value={{
				clear,
				assetIds,
				add,
				remove,
				uploadProgress: uploadProgress,
				updateUploadProgress,
				clearUploadProgress,
			}}
		>
			{children}
		</UploadContext.Provider>
	);
};

export const useUpload = () => {
	return React.useContext(UploadContext);
};

/**
 *
 *
 */
