import { SiteDefaults } from 'context/useThemeContext';
import { last } from 'lodash';
import React from 'react';
import { DropdownToggle, NavbarBrand, NavLink } from 'reactstrap';
import { pure } from 'recompose';
import styled from 'styled-components';
const AppLogoElement = styled.div<{ url: string }>`
	background: url(${(props) => props.url}) left center / contain no-repeat;
	height: 3em;
	margin-bottom: 10px;
	margin-top: 10px;
	width: 7em;
`;
export const LogoContainer = styled(NavbarBrand)`
	&:focus {
	}
`;

export const AppLogo = pure(({ defaults }: { defaults: SiteDefaults }) => {
	const { defaultLogo, mobileLogo, account } = defaults;
	const getUrl = (type: 'desktop' | 'mobile') =>
		`${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${account}/${
			type === 'desktop'
				? last(defaultLogo?.split('/'))
				: last(mobileLogo?.split('/'))
		}`;
	const url = getUrl('desktop');
	if ((!defaultLogo && !mobileLogo && !account) || !url) return null;
	return <AppLogoElement url={url} />;
});

export const StyledNavLink = styled(NavLink)`
	&:focus {
	}
`;

export const AvatarMenuToggle = styled(DropdownToggle)`
	&:after {
		position: relative;
		top: -8px;
	}

	&:focus {
	}
`;
