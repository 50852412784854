import { UploadResult, UppyFile } from '@uppy/core';
import { Loading } from 'components/index';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { noop } from '../../../utils/common';
import { _logError } from '../../../utils/common/log';
import { UploadContext } from '../../../context/file-upload-provider.component';
import ConfirmationDialog from '../../modals/confirmation-dialog.component';
import RenderWhen from '../../render-when.component';
import { toFile } from '../../../utils/core/FileUploadProvider';
import { NotificationsContext } from '../../notifications';
import MultipleAssetPicker from './multiple-asset-picker.component';
import { Maybe } from 'types/globals';
export interface AssetUploadDialogProps {
	afterSubmit?: () => void;
}
export const AssetUploadDialog = observer((props: AssetUploadDialogProps) => {
	const { error: showError } = React.useContext(NotificationsContext);
	const { info: showInfo } = React.useContext(NotificationsContext);
	const { afterSubmit = noop } = props;
	const [uppyResult, setResult] = useState<Maybe<UploadResult>>(undefined);
	const [showMetadata] = useState(false);
	const { add: addAssetId } = React.useContext(UploadContext);
	const [submitted, setSubmitted] = useState(false);
	const onFormSubmit = async () => {
		try {
			setSubmitted(true);
			const allFiles = uppyResult?.successful.map((file) =>
				toFile(file)
			) as File[];

			allFiles.forEach(addAssetId);
			if (afterSubmit) {
				afterSubmit();
				showInfo('Your file uploaded successfully.');
			}
		} catch (error) {
			_logError(error);
			showError(
				'An issue occurred while attempting to upload your file. Please try again later.'
			);
		}
	};

	return (
		<>
			<ConfirmationDialog
				confirmText={'Upload'}
				header={'Upload Assets to DAM'}
				onConfirm={() => onFormSubmit()}
			>
				<Form>
					<Row form>
						<Col md={12} className="p-2">
							<RenderWhen when={!showMetadata}>
								<MultipleAssetPicker
									files={uppyResult?.successful as UppyFile[]}
									onSelect={setResult}
								/>
							</RenderWhen>
						</Col>
					</Row>
				</Form>
			</ConfirmationDialog>
			{submitted && <Loading label="Uploading asset..." />}
		</>
	);
});
