import React, { Suspense, useContext } from 'react';
import { Container } from 'reactstrap';
import Loading from '../loading.component';
import { RoleContext } from 'context/PermissionsContext';
import { ReportsTable } from './ReportsTable';

const ReportsIndexView = () => {
	const { canViewByRole } = useContext(RoleContext);

	return canViewByRole('canViewReporting') ? (
		<Suspense fallback={<Loading />}>
			<Container>
				<ReportsTable />
			</Container>
		</Suspense>
	) : null;
};

export default ReportsIndexView;
