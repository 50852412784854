import { toRGBA } from 'utils/common/color.utils';
import { useAxios } from 'hooks';
import React, { useEffect } from 'react';
import * as axios from 'axios';
import storageModel from 'utils/models/StorageModel';
export type SiteDefaults = {
	_id: string;
	account: string;

	defaultLogo: string;
	mobileLogo: string;
	loginCardLogo: string;
	loginBackground: string;

	navbarBackground: string;
	navbarForeground: string;

	primary: string;
	secondary: string;
	primaryHighlighted: string;
	secondaryHighlighted: string;

	pipeline: string;
	focus: string;
	danger: string;

	active: string;
	healthy: string;
	overdue: string;
	blocked: string;
	damRefinements: Object[];
};

type ThemeContextType = {
	defaultLogo: string;
	mobileLogo: string;
	loginBackground: string;

	primary: string;
	secondary: string;
	primaryHighlighted: string;
	secondaryHighlighted: string;

	pipeline: string;
	focus: string;

	active: string;
	healthy: string;
	overdue: string;
	blocked: string;
	defaults: SiteDefaults;
	updateDefaults: (defaults: SiteDefaults) => Promise<SiteDefaults>;
	fetchDefaults: () => Promise<SiteDefaults>;
	updateContext: (siteDefaults: SiteDefaults) => void;
};

// main colors
const primary = '#3c3f42';
const primaryHighlighted = '#767d80';
const secondary = '#416c65';
const secondaryHighlighted = '#62a094';
// messaging colors
const danger = '#dc3545';
const warning = '#dadc35';
const success = '#28a745';
const info = '#17a2b8';
// status colors
const active = info;
const healthy = success;
const overdue = warning;
const blocked = danger;
const pipeline = primary;
// a11y
const focus = toRGBA(primary, 0.6);
const ThemeContext = React.createContext<ThemeContextType>({
	primary,
	primaryHighlighted,
	secondary,
	secondaryHighlighted,
	defaultLogo: '',
	focus,
	loginBackground: '',
	mobileLogo: '',
	pipeline,
	active,
	overdue,
	blocked,
	healthy,
	defaults: {} as SiteDefaults,
	updateDefaults: () => Promise.resolve<SiteDefaults>({} as SiteDefaults),
	fetchDefaults: () => Promise.resolve<SiteDefaults>({} as SiteDefaults),
	updateContext: (siteDefaults: SiteDefaults) => {
		return;
	},
});
export const ThemeContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const siteDefaultAPI = axios.default;
	const api = useAxios<SiteDefaults>('site-defaults');
	const [defaults, setSiteDefaults] = React.useState<SiteDefaults>();
	const fetchDefaults = () =>
		siteDefaultAPI
			.get<SiteDefaults>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/site-defaults/default`
			)
			.then((res) => {
				return res.data;
			});
	useEffect(() => {
		siteDefaultAPI
			.get<SiteDefaults>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/site-defaults/default`
			)
			.then((res) => {
				storageModel.set('SITE_DEFAULTS', JSON.stringify(res.data));
				setSiteDefaults(res.data);
			});
		// eslint-disable-next-line
	}, []);

	const updateDefaults = async (siteDefaults: SiteDefaults) => {
		const updatedDefaults = (await api.updateOne(
			siteDefaults._id,
			siteDefaults
		)) as SiteDefaults;
		setSiteDefaults(updatedDefaults);
		return siteDefaults;
	};

	const updateContext = (siteDefaults: SiteDefaults) =>
		setSiteDefaults(siteDefaults);

	return (
		<ThemeContext.Provider
			value={{
				defaults: defaults as SiteDefaults,
				...defaults!,
				updateDefaults,
				updateContext,
				fetchDefaults,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};
export const useThemeContext = () => {
	const context = React.useContext(ThemeContext);
	if (!context)
		throw new Error('Expected to be in a core-ui context but was not');

	return context;
};
