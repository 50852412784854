import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React, { useState } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

type Props = {
	oldValue: string;
	afterSubmit: (updatedValue: string) => void;
	onCancel: () => void;
	isOpen: boolean;
};

export const EditFieldDialog = (props: Props) => {
	const [updatedValue, setUpdatedValue] = useState(props.oldValue);
	return (
		<ConfirmationDialog
			header={'Update metadata field "' + props.oldValue + '"'}
			onCancel={props.onCancel}
			cancelText={'Cancel'}
			confirmText={'Update Field'}
			onConfirm={() => props.afterSubmit(updatedValue)}
		>
			<Row>
				<Col xl={12}>
					<Label inline>Metadata Field</Label>
					<Input
						type="text"
						defaultValue={updatedValue}
						onChange={(e) => setUpdatedValue(e.target.value)}
					/>
				</Col>
			</Row>
		</ConfirmationDialog>
	);
};
