import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card } from 'reactstrap';
import { generateID } from '../../../../utils/common';
import { InputField, Textarea } from '../../../forms';
import { FormModal } from '../../../Modal';
import { Subtitle } from '../../../ui';
import { useAxios } from '../../../../hooks';
import { NotificationsContext } from '../../../notifications';
import SideNavButton from '../SideNavButton';
import { PanelHeader } from '../user-details.styled-components';
import { PushNotification } from './models/NotificationModel';
import NotificationsTable from './NotificationsTable';

const NotificationsPanel = () => {
	const [showDialog, setShowDialog] = useState(false);
	const form = useForm();
	const pushStore = useAxios<PushNotification>('push-notifications');
	const [pushNotifications, setPushNotifications] = useState<
		PushNotification[]
	>();
	const { info } = useContext(NotificationsContext);
	const [fetching, setFetching] = useState(false);
	React.useEffect(() => {
		if (fetching) return;
		if (!pushNotifications?.length) {
			setFetching(true);
			pushStore
				.findAll()
				.then(setPushNotifications)
				.finally(() => setFetching(false));
		}
	}, [fetching, pushNotifications, pushStore]);

	const createPushNotification = async (notification: PushNotification) => {
		await pushStore.createOne({
			title: notification.title,
			message: notification.message,
			logo: '/logo192.png',
			_id: generateID(),
		});
		info('Successfully created new push notification.');
		setShowDialog(false);
	};

	return (
		<article>
			<PanelHeader>
				<Subtitle className="mb-md-0">Push Notifications</Subtitle>

				<SideNavButton
					label="Add Notification"
					onClick={() => setShowDialog(true)}
				/>
			</PanelHeader>

			<Card className="mt-4">
				<NotificationsTable
					onDelete={(notification) =>
						setPushNotifications(
							pushNotifications?.filter((a) => a._id !== notification._id)
						)
					}
					notifications={pushNotifications as PushNotification[]}
				/>
			</Card>

			<FormModal
				form={form}
				isOpen={showDialog}
				onSubmit={createPushNotification}
				title="Add a new site-wide notification to be broadcasted"
				onClose={() => setShowDialog(false)}
				submitText="Create"
			>
				<InputField
					name="title"
					label="Push Notification Title"
					form={form as any}
					validators={{ required: true }}
				/>
				<Textarea
					name="message"
					type="textarea"
					label="Push Notification Message"
					form={form as any}
					validators={{ required: true }}
				/>
			</FormModal>
		</article>
	);
};

export default NotificationsPanel;
