import { RouteComponentProps } from '@reach/router';
import { Col, Container, Row } from 'reactstrap';
import React, { useState } from 'react';
import { Divider, Heading } from '../ui';
import { StyledLabel } from '../forms/labeled-input.styled-components';
import themeStore from '../../core-ui/models/ThemeStore';

import EditAssetForm from './components/edit-asset-form.component';
import LoadingAssetState from './components/loading-asset-state.component';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faTimesCircle,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { AssetVersion } from '../workflow/workflows/types/workflow.types';
import { useAxios } from '../../hooks';
import { SecondaryButton } from 'components/buttons.styled-components';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import { NotificationsContext } from 'components/notifications';
import { CustomPreviewUploadDialog } from './components/asset-upload-metadata.component';
import { extractPreviewURL } from './components/asset-card.component';
import { useRoleContext } from 'context/PermissionsContext';
import { useAuthContext } from 'utils/auth';
import { useModalCloser, useModalCreator } from 'utils/ModalStack';
import { ReplaceAssetDialog } from './components/ReplaceAssetDialog';
import { UploadResult } from '@uppy/core';
import { toFile } from 'utils/core/FileUploadProvider';
import { Loading } from '../../components/index';
import styled from 'styled-components';

const capitalizedAssetTerm = themeStore._.asset;
export const ImageWrapper = styled.figure`
	position: relative;
`;

export const PositionedText = styled.p<{ x: number; y: number }>`
	position: absolute;
	background-color: #222;
	color: var(--white);
	top: ${(props) => props.y}px;
	left: ${(props) => props.x}px;
`;
const DamAssetEditor = ({
	asset,
	isEditable,
}: {
	asset: AssetVersion;
	isEditable: boolean;
}) => {
	const [version, setVersion] = useState(asset);
	const [assetPreview, setAssetPreview] = useState(extractPreviewURL(asset));
	const { clearExistingPreview, replaceAsset } = useAssetHelper();
	const { info, error: showError } = React.useContext(NotificationsContext);
	const [uploadingCustom, setUploadingCustom] = React.useState(false);
	const modalStack = useModalCreator();
	const modalCloser = useModalCloser();
	const [showSpinner, setShowSpinner] = React.useState(false);

	const replaceAssetCallback = async (file: UploadResult) => {
		setShowSpinner(true);
		const allFiles = file?.successful.map((file) => toFile(file)) as File[];

		const updatedAsset = await replaceAsset(
			version._id,
			allFiles[0]
		).catch((err) =>
			showError('An issue occurred while replacing the asset. Try again later.')
		);
		setVersion(updatedAsset);
		info('Successfully replaced asset file.');
		return navigate(`/admin/dam/assets/${asset._id}`, {
			replace: true,
		}).finally(() => setShowSpinner(false));
	};

	const showReplaceModal = () => {
		modalStack.addModal(
			<ReplaceAssetDialog
				asset={version}
				onCancel={modalCloser.closeModal}
				onFormSubmit={replaceAssetCallback}
			/>
		);
	};

	const uploadCustom = () => {
		setUploadingCustom(true);
	};

	const afterSubmit = () => {
		setUploadingCustom(false);
	};

	const onUploadSuccess = (asset: AssetVersion) => {
		setVersion(asset);
		setAssetPreview(extractPreviewURL(asset));
	};

	const clearPreviewCallback = async () => {
		const updatedAsset = await clearExistingPreview(asset);
		setVersion(updatedAsset);
		setAssetPreview('');
		info('Successfully cleared asset preview!');
	};

	return (
		<Row>
			{uploadingCustom && (
				<CustomPreviewUploadDialog
					asset={version}
					afterSubmit={afterSubmit}
					onSuccess={onUploadSuccess}
				/>
			)}
			<Col xs={12} className="d-flex justify-content-between">
				<div>
					<>
						<span
							className="btn btn-link text-dark p-0"
							style={{ cursor: 'pointer' }}
							title={`${capitalizedAssetTerm} Details`}
							onClick={() =>
								navigate(`/admin/dam/assets/${asset._id}`, { replace: true })
							}
						>
							<FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
							{`Back to ${capitalizedAssetTerm} Details`}
						</span>
						<Heading>{asset.title ? asset.title : asset.fileName}</Heading>
					</>
				</div>
			</Col>
			{showSpinner && <Loading label="Replacing asset, please wait.." />}
			<Col md={8}>
				<EditAssetForm
					showReplaceModal={showReplaceModal}
					hideUpdateButtons={!isEditable}
					onUpdatePreview={(previewURL) => setAssetPreview(previewURL)}
					generatedPreview={assetPreview}
					asset={version}
				/>
			</Col>
			<Col md={4}>
				{version?.previewURL || version?.customPreviewURL ? (
					<>
						<StyledLabel>
							{capitalizedAssetTerm} Preview
							<SecondaryButton
								className="d-flex align-items-center"
								onClick={clearPreviewCallback}
							>
								<FontAwesomeIcon id="clearAssetPreview" icon={faTimesCircle} />
								&nbsp; Clear existing preview
							</SecondaryButton>
						</StyledLabel>
						<Divider />
						{/*
						leaving this commented out as this is what will show the overlay text!
						<ImageWrapper>
							{asset.extractedMetadata.textAnnotations && asset.extractedMetadata.textAnnotations.map(annotated => (
								<PositionedText
									y={average(maxBy(annotated.boundingPoly?.vertices, x => x.y)?.y as number, minBy(annotated.boundingPoly?.vertices, x => x.y)?.y as number)}
									x={average(maxBy(annotated.boundingPoly?.vertices, a => a.x)?.x as number, minBy(annotated.boundingPoly?.vertices, a => a.x)?.x as number)}
								>{annotated.description}</PositionedText>
							))} */}
						<img className="img-fluid" src={assetPreview} alt={version.title} />
						{/* </ImageWrapper> */}
					</>
				) : (
					<>
						<SecondaryButton onClick={uploadCustom}>
							<FontAwesomeIcon icon={faUpload} />
							Upload Custom Preview
						</SecondaryButton>
					</>
				)}
			</Col>
		</Row>
	);
};
const EditDAMAssetView = (props: RouteComponentProps<{ assetId: string }>) => {
	const assetStore = useAxios<AssetVersion>('assets');
	const { currentUser } = useAuthContext();
	const [assetVersion, setAssetVersion] = React.useState<AssetVersion>();
	const [fetching, setFetching] = React.useState(false);
	const { assetId } = props;
	const permissions = useRoleContext();
	React.useEffect(() => {
		if (fetching) return;
		if (!assetVersion) {
			setFetching(true);
			assetStore
				.findOne(assetId as string)
				.then(setAssetVersion)
				.finally(() => setFetching(false));
		}
	}, [assetStore, assetVersion, fetching, assetId]);
	const render = () => {
		if (
			!!permissions.roles &&
			!!permissions.roles.length &&
			currentUser &&
			currentUser._id &&
			!['damReadOnly', 'damEditable'].some((role) =>
				permissions.canViewByRole(role)
			)
		) {
			return (
				<p>
					We're sorry, but you do not have the appropriate permissions to view
					the DAM Asset. Please contact your account manager and request the
					appropriate permissions.
				</p>
			);
		}
		const damAsset = assetVersion;
		return damAsset ? (
			<DamAssetEditor
				isEditable={permissions.canViewByRole('damEditable')}
				asset={damAsset}
			/>
		) : (
			<LoadingAssetState />
		);
	};

	return <Container>{render()}</Container>;
};

export default EditDAMAssetView;
