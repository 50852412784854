import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faFilePdf,
	faFileArchive,
	faFileImage,
	faFilePowerpoint,
	faFileWord,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FilePreview,
	CardTitleLine,
	FileTitle,
	FileSizeLabel,
	FileIcon,
	PrivacyLabel,
} from 'components/dam-assets/components/asset-card.styled-components';
import React from 'react';
import { Card, CardFooter } from 'reactstrap';
import styled from 'styled-components';
import { FileSharingFileDialog } from './FileSharingFileDialog';
import { humanReadableFileSizeSI } from 'utils/common';
import defaultAsset from '../../../assets/images/default-asset.jpg';
import { FileShareAuditDto } from 'context/useFileShare';

type SharedFile = {
	_id: string;
	path: string;
	preview: string;
	metadata: {
		filename: string;
		fileSizeBytes: number;
		type: string;
		ext: string;
	};
};
type Props = {
	setAllEvents: (event: FileShareAuditDto[]) => void;
	file: SharedFile;
	fileShareId: string;
	recipient_id: string;
	recipient_email: string;
	shouldAudit?: boolean;
	isExpired?: boolean;
};
type PreviewProps = {
	src: string;
};
const DefaultAsset = styled.div<PreviewProps>`
	background: url('${(props) => props.src}') center center / cover no-repeat;
	height: 172px;
	position: relative;
	top: 10px;
	margin-top: -1.25em;
`;

const assetIconMap: Record<string, IconDefinition> = Object.freeze({
	'application/pdf': faFilePdf,
	'application/postscript': faFilePdf,
	'application/x-font-otf': faFileArchive,
	'application/x-font-ttf': faFileArchive,
	'application/zip': faFileArchive,
	'image/jpg': faFileImage,
	'image/png': faFileImage,
	'image/tiff': faFileImage,
	'image/vnd.adobe.photoshop': faFileImage,
	ai: faFileImage,
	psd: faFileImage,
	ppt: faFilePowerpoint,
	doc: faFileWord,
	docx: faFileWord,
});

export const FileSharingCard = (props: Props) => {
	const {
		file,
		fileShareId,
		recipient_id,
		recipient_email,
		isExpired,
		setAllEvents,
		shouldAudit,
	} = props;
	const [showDetails, setShowDetails] = React.useState(false);
	const openDetailsModal = () => {
		if (isExpired) return;
		setShowDetails(true);
	};

	const getPreviewSource = () => {
		if (isExpired) {
			return defaultAsset;
		} else {
			return `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/f/${
				file?.preview?.split('/')[file?.preview?.split('/').length - 2]
			}/${file?.metadata?.filename}`;
		}
	};
	return (
		<>
			{showDetails && (
				<FileSharingFileDialog
					setAllEvents={setAllEvents}
					shouldAudit={shouldAudit}
					file_share_id={fileShareId}
					recipient_id={recipient_id}
					recipient_email={recipient_email}
					onClosed={() => setShowDetails(false)}
					file={file}
				/>
			)}
			<Card onClick={() => openDetailsModal()} className="h-100 mt-2">
				<FilePreview>
					<DefaultAsset src={getPreviewSource()} />
				</FilePreview>
				<CardFooter className="bg-white">
					<CardTitleLine>
						<FileTitle>{file.metadata.filename}</FileTitle>
						<FileSizeLabel>
							{humanReadableFileSizeSI(file.metadata.fileSizeBytes)}
						</FileSizeLabel>
					</CardTitleLine>
					<FileIcon>
						<FontAwesomeIcon icon={assetIconMap[file.metadata.type]} />
						<PrivacyLabel className="ml-2"></PrivacyLabel>
					</FileIcon>
				</CardFooter>
			</Card>
		</>
	);
};
