import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const AjaxSpinnerStyle = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	background: rgb(0, 0, 0, 0.5);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999999999;
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		font-size: 100px;
		color: white;
	}
`;
interface AjaxSpinnerProperties {
	IsVisible: boolean;
}
export const AjaxSpinner = (props: any) => {
	return (
		<AjaxSpinnerStyle hidden={!props.isVisible}>
			<FontAwesomeIcon className={'fa-spin'} icon={faSpinner} />
		</AjaxSpinnerStyle>
	);
};

export default AjaxSpinner;
