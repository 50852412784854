import { faBell, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import * as axios from 'axios';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import Loading from 'components/loading.component';
import RenderWhen from 'components/render-when.component';
import { InteractiveFontAwesomeIcon } from 'components/ui';
import { orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import openSocket from 'socket.io-client';
import styled from 'styled-components';
import { useAuthContext, useHeaders, User } from 'utils/auth';
import { forceReload } from 'utils/common/forceReload.util';
import { notification } from '../typings/notification-center';
import NotificationCard from './notificationCard';
import './notificationElements.scss';

const DropdownMenuWrapper = styled.div`
	border-radius: 4px;
	width: 600px;
	@media (max-width: 768px) {
		width: 100%;
	}
`;

const DropdownPage = styled.div`
	min-height: 400px;
	max-height: 72vh;
	overflow-x: scroll;

	/* kill scrollbars from showing */
	/* chrome */
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

export const Counter = styled.span`
	position: absolute;
`;

const NotificationBell = () => {
	const { getHeaders } = useHeaders();
	const { currentUser } = useAuthContext();
	const [notifications, setNotifications] = useState<notification[]>([]);
	const [notificationCount, setNotificationCount] = useState(0);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [dropdownSelectedPage, SetDropdownSelectedPage] = useState('Direct');
	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const [loading, setLoading] = React.useState(false);
	const socketRef = React.useRef<any>();
	useEffect(() => {
		const headers = getHeaders();
		socketRef.current = openSocket(process.env.REACT_APP_ROME_API_ENDPOINT, {
			query: headers.headers.Authorization,
			transports: ['websocket'],
			upgrade: false,
		});

		// listen for incoming message
		socketRef.current.on('notificationCreated', (notification: any) => {
			const incomingMessage = {
				...notification,
			};

			// check if the current user is within the notification sent
			if (
				incomingMessage.users.some((user: string) => currentUser._id === user)
			) {
				setNotifications((notifications) =>
					orderBy(
						[...notifications, incomingMessage],
						(a) => a.createdAt,
						'desc'
					)
				);
			}
		});

		return () => {
			socketRef.current.disconnect();
			// socketRef.current = null;
		};
		//eslint-disable-next-line
	}, []);

	const hasNotViewed = (notification: any) => {
		return (
			!!notification &&
			!!notification.viewedBy &&
			!notification.viewedBy.some((a: any) =>
				typeof a === 'object'
					? (a as User)._id === currentUser._id
					: a === currentUser._id
			)
		);
	};
	//fetch notifications on render
	useEffect(() => {
		setLoading(true);
		const getNotifications = async () => {
			const notificationsCall = await axios.default.get(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/notification-center`,
				getHeaders()
			);

			const notifications = notificationsCall?.data?.sort(
				(a: notification, b: notification) =>
					a.createdAt < b.createdAt ? 1 : -1
			);
			setNotifications(notifications);
		};
		getNotifications().finally(() => setLoading(false));
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		setNotificationCount(notifications?.filter(hasNotViewed).length);
		//eslint-disable-next-line
	}, [notifications]);

	const markNotificationRead = async (notification: notification) => {
		const notificationsCall = await axios.default.get(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/notification-center/view/${notification._id}`,
			getHeaders()
		);
		const updatedData = [
			...notifications.filter((n) => n._id !== notification._id),
			notificationsCall?.data,
		];
		setNotifications(
			updatedData.sort((a: notification, b: notification) =>
				a.createdAt < b.createdAt ? 1 : -1
			)
		);
		setNotificationCount(updatedData?.filter(hasNotViewed).length);
	};

	const handleNotificationClicked = async (notification: notification) => {
		let currentRoute = notification.actions[0].route;
		if (currentRoute.substring(0, 20) === '/admin/file-sharing/') {
			currentRoute = '/admin/filesharing/';
		}
		await markNotificationRead(notification);
		navigate(currentRoute, { replace: true });
		forceReload(currentRoute);
	};

	const clearNotifications = async () => {
		const notificationsCall = await axios.default.get(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/notification-center/clear`,
			getHeaders()
		);
		setNotifications(notificationsCall?.data);
		setNotificationCount(0);
	};

	const removeNotification = async (id: string) => {
		await axios.default.put(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/notification-center/remove/${id}`,
			{},
			getHeaders()
		);
		const updatedData = notifications.filter((a) => a._id !== id);
		setNotifications(updatedData);
		setNotificationCount(updatedData?.filter(hasNotViewed).length);
	};

	const directPage = React.useMemo(
		() => (fn: (id: string) => void) => {
			return notifications?.map((notification) => (
				<NotificationCard
					key={notification._id}
					removeNotification={fn}
					notification={notification}
					handleNotificationClick={handleNotificationClicked}
				/>
			));
		}, //eslint-disable-next-line
		[notifications]
	);

	const watchingPage = () => <p>watchingPage</p>;

	return (
		<Dropdown
			className="notifications-menu"
			isOpen={dropdownOpen}
			toggle={toggle}
		>
			<DropdownToggle
				tag="button"
				className="notifications-menu__toggle"
				style={{ backgroundColor: 'transparent' }}
			>
				<div className="d-flex align-items-center">
					<FontAwesomeIcon
						className={`notifications-menu__icon ${
							!!notificationCount ? 'has-notifications' : ''
						}`}
						icon={faBell}
					/>
					{notificationCount && notificationCount > 0 ? (
						<Counter className="notifications-menu__count">
							{notificationCount > 99 ? '99+' : notificationCount ?? 0}
						</Counter>
					) : null}
				</div>
			</DropdownToggle>
			<DropdownMenu>
				{/* dropdown div */}
				<DropdownMenuWrapper>
					<div
						style={{
							marginLeft: '18px',
							marginTop: '18px',
							marginRight: '18px',
						}}
					>
						<div className="d-flex justify-content-between">
							<h1 style={{ fontWeight: 500 }}>Notifications</h1>
							{notifications?.length > 0 && (
								<AnchorSpan onClick={clearNotifications}>
									{' '}
									Clear Notifications{' '}
									<InteractiveFontAwesomeIcon
										tooltip="Clear notifications"
										id="clearNotificationBtn"
										color="red"
										icon={faTrash}
									/>
								</AnchorSpan>
							)}
						</div>
						{/* page selection bar */}
						<div>
							<div
								style={{
									marginTop: '18px',
									marginBottom: '18px',
									display: 'flex',
									width: '100%',
									borderBottom: '2px',
									borderBottomWidth: '2px',
									borderBottomStyle: 'solid',
									borderBottomColor: 'lightgray',
								}}
							>
								<div
									style={{
										position: 'relative',
									}}
								>
									<span
										onClick={() => SetDropdownSelectedPage('Direct')}
										style={{
											cursor: 'pointer',
											color:
												dropdownSelectedPage === 'Direct' ? 'blue' : 'inherit',
										}}
									>
										Direct
									</span>
									{dropdownSelectedPage === 'Direct' ? (
										<span
											style={{
												height: '2px',
												width: '100%',
												backgroundColor: 'blue',
												position: 'absolute',
												bottom: '-2px',
												left: '0',
											}}
										/>
									) : null}
								</div>
							</div>
						</div>
						<DropdownPage>
							<RenderWhen when={!loading && notifications.length > 0}>
								{dropdownSelectedPage === 'Direct' &&
									directPage(removeNotification)}

								{dropdownSelectedPage === 'Watching' && watchingPage()}
							</RenderWhen>
							<RenderWhen when={loading}>
								<Loading label="Loading notifications..." />
							</RenderWhen>
							<RenderWhen when={notifications?.length === 0 && !loading}>
								<p>No notifications were found.</p>
							</RenderWhen>
						</DropdownPage>
					</div>
				</DropdownMenuWrapper>
			</DropdownMenu>
		</Dropdown>
	);
};

export default NotificationBell;
