import { Stage } from 'components/workflow/workflows/types';
import { map } from 'lodash';
import * as R from 'ramda';

export function generateID() {
	const hex = (x: number) => (~~x).toString(16);
	const randomByte = () => hex(Math.random() * 16);
	return `${hex(Date.now() / 1000)}${' '.repeat(16).replace(/./g, randomByte)}`;
}

export const updateStage = (
	stageId: string,
	updates: Partial<Stage>,
	stages: Stage[]
): Stage[] => {
	if (R.any(R.propEq('_id', stageId), stages)) {
		return stages.map((stage) =>
			R.equals(stage._id, stageId)
				? {
						...stage,
						...updates,
				  }
				: stage
		);
	}

	return stages.map((stage) => {
		if (!stage.substages) return stage;
		return {
			...stage,
			substages: stage.substages.map((substageArray) =>
				updateStage(stageId, updates, substageArray)
			),
		};
	});
};

export const addParallelSubstage = (substage: Stage, stage: Stage) => {
	const u = R.evolve({
		substages: R.append([substage]),
	})(stage);
	return u;
};

export const insertSideTask = (newStage: Stage, stages: Stage[]): Stage[] => {
	if (stages) {
		return [...stages, newStage];
	}

	return [newStage];
};

export const insertStage = (
	prevStage: Stage,
	newStage: Stage,
	stages: Stage[]
): Stage[] => {
	if (R.includes(prevStage, stages)) {
		const oldForwardTransition = prevStage.transitions.find(
			(t) => t.type === 'forward'
		);

		const oldSideTaskTransition = prevStage.transitions.find(
			(t) => t.type === 'sideTask'
		);

		const oldTarget = oldForwardTransition?.targetStage;

		if (oldTarget && oldForwardTransition) {
			newStage.transitions.push(oldForwardTransition);
			prevStage.transitions = [
				...prevStage.transitions.filter((t) => t.type === 'backward'),
				{
					targetStage: newStage._id,
					type: 'forward',
					_id: generateID(),
				},
			];

			if (oldSideTaskTransition) {
				prevStage.transitions.push(oldSideTaskTransition);
			}
		} else {
			prevStage.transitions.push({
				targetStage: newStage._id,
				type: 'forward',
				_id: generateID(),
			});
		}

		return R.insert(R.indexOf(prevStage, stages) + 1, newStage, stages);
	}

	// If the stage to update is not included
	return stages.map((stage) => {
		if (!stage.substages) return stage;

		return {
			...stage,
			substages: stage.substages.map((substageArray) =>
				insertStage(prevStage, newStage, substageArray)
			),
		};
	});
};

export const deleteStage = (stageToDelete: Stage, stages: Stage[]): Stage[] => {
	if (R.includes(stageToDelete, stages)) {
		return R.without(
			[stageToDelete],
			updateTransitionsWhenDeleting(stageToDelete, stages)
		);
	}

	return stages
		.map((stage) => {
			if (!stage.substages) return stage;

			return {
				...stage,
				transitions: stage.transitions?.filter(
					(s) => stageToDelete._id !== s.targetStage
				),
				substages: rejectEmpty(
					stage.substages.map((substageArray) =>
						rejectEmpty(deleteStage(stageToDelete, substageArray))
					)
				),
			};
		})
		.map((stage) => {
			if (stage.substages?.length) {
				return {
					...stage,
					transitions: stage.transitions?.filter(
						(s) => stageToDelete._id !== s.targetStage
					),
					substages: map(stage.substages, (s: Stage[]) =>
						s.map((ss) => ({
							...ss,
							transitions: ss.transitions?.filter(
								(xition) => stageToDelete._id !== xition.targetStage
							),
						}))
					),
				};
			}
			return {
				...stage,
				transitions: stage.transitions?.filter(
					(m) => stageToDelete._id !== m.targetStage
				),
			};
		});
};

const rejectEmpty = (list: any[]) => R.reject(R.isEmpty, list);

export const isLast = <T>(item: T, list: T[]) =>
	R.equals(R.indexOf(item, list), R.length(list) - 1);

export const isLastStageInList = (stage: Stage, stages: Stage[]): boolean => {
	if (R.includes(stage, stages)) return isLast(stage, stages);
	return stages
		.map(({ substages }) =>
			substages?.map((substage) => {
				if (R.includes(stage, substage))
					return isLastStageInList(stage, substage);
				return false;
			})
		)
		.flatMap((s) => s)
		.some((bool) => !!bool);
};

export const isFulfilled = (stage?: Stage): boolean => {
	if (!stage) return false;
	if (!stage.inputSlots) return true;
	if (R.isEmpty(stage.inputSlots)) return true;
	if (
		stage.inputSlots.every(
			({ versions, optional }) => !!optional || !R.isEmpty(versions)
		)
	)
		return true;
	return false;
	// return (
	// 	(!stage?.inputSlots?.length ||
	// 		!!stage.inputSlots?.every(({ versions }: any) => versions.length > 0)) ??
	// 	true
	// );
};

const updateTransitionsWhenDeleting = (
	stageToDelete: Stage,
	stages: Stage[]
) => {
	const indexStageToDelete = stages.findIndex(
		(stage) => stage._id === stageToDelete._id
	);
	const transitions = stageToDelete.transitions;

	return stages.map((stage, index) => {
		if (index === indexStageToDelete) return stage;

		if (indexStageToDelete - 1 === index) {
			return {
				...stage,
				transitions: [
					...stage.transitions.filter(
						(x) => x.targetStage !== stageToDelete._id
					),
					...transitions.filter((x) => x.type === 'forward'),
				],
			};
		}

		if (indexStageToDelete + 1 === index) {
			return {
				...stage,
				transitions: [
					...stage.transitions.filter(
						(x) => x.targetStage !== stageToDelete._id
					),
					...transitions.filter((x) => x.type === 'backward'),
				],
			};
		}

		return stage;
	});
};
