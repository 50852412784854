import * as R from 'ramda';

const MAX_ITEMS_PER_PAGE = 15;

export const getItemsOnPage = (items: any[], page: number, searchTerm = '') => {
	const indexesToShow = R.range(
		page * MAX_ITEMS_PER_PAGE - MAX_ITEMS_PER_PAGE,
		page * MAX_ITEMS_PER_PAGE
	);
	if (!items) return [];
	return items.filter((_, i) => indexesToShow.includes(i));
};

export const getTotalPages = (workflows: any[], maxItems?: number) =>
	Math.ceil(workflows.length / (maxItems ?? MAX_ITEMS_PER_PAGE));
