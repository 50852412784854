export class Multimap<K, V> extends Map<K, V[]> implements Map<K, V[]> {
	add(k: K, v: V): this {
		const entry = this.get(k);
		if (entry) {
			entry.push(v);
			return this;
		} else {
			return this.set(k, [v]);
		}
	}
}
