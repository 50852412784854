import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { RomeSwal } from 'components/alert';
import { isFulfilled } from 'components/workflow/workflow-templates/components/stage-cards/Stage.helpers';
import { useWorkflowContext } from 'context/useWorkflowStore';
import React, { useCallback } from 'react';
import { buildClassList } from '../../../../../utils/common';
import { useModalCreator } from '../../../../../utils/ModalStack';
import { StageStatus } from '../../../../../utils/models/StageStatusModel';
import { Stage, Workflow } from '../../types/workflow.types';
import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
	StyledTooltip,
} from '../action-button.styled-components';
import ApproveStageDialog from './approve-stage-dialog.component';
import { StageActionButtonProps } from './index';

const buildButtonClassList = (isFulfilled: boolean) => {
	let classList = 'action-button approve-stage-button';
	if (isFulfilled) {
		classList = `${classList} success`;
	} else {
		classList = `${classList} disabled`;
	}
	return classList;
};

export const ApproveStageButton = (props: StageActionButtonProps) => {
	const { className } = props;
	const modalStack = useModalCreator();
	const { workflow, stage } = useWorkflowContext();

	const fulfilled = isFulfilled(stage as Stage);
	const buttonId = `approveStage${stage?._id}`;
	const isButtonDisabled =
		!fulfilled || stage?.status === StageStatus.roadblocked;

	const openApprovalModal = useCallback(() => {
		if (
			stage?.tasks
				// @ts-ignore
				?.filter((task) => task.isRequired)
				.every(
					// @ts-ignore
					(task) =>
						(task?.events || [])[(task.events || []).length - 1]?.isCompleted
				)
		) {
			modalStack.addModal(
				<ApproveStageDialog
					workflow={workflow as Workflow}
					stage={stage as Stage}
				/>
			);
		} else {
			RomeSwal.fire({
				title: 'The stage has uncompleted tasks',
				text:
					'You must complete all required stage tasks in order to approve the stage.',
				icon: 'warning',
				confirmButtonText: 'Ok',
			});
		}
	}, [stage, modalStack, workflow]);

	return (
		<ActionButtonContainer
			className={buildClassList('stage-action approve-stage', className)}
		>
			<StyledTooltip target={buttonId}>
				{fulfilled ? 'Approve stage' : 'Stage is missing a required asset'}
			</StyledTooltip>
			<ActionButton
				id={buttonId}
				color=""
				size="sm"
				onClick={openApprovalModal}
				className={buildButtonClassList(fulfilled)}
				disabled={isButtonDisabled}
			>
				<IconWithRightMargin icon={faCheck} />
				Complete
			</ActionButton>
		</ActionButtonContainer>
	);
};
