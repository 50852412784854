export enum LogLevel {
	'info',
	'error',
	'warn',
	'log',
	'verbose',
	'debug',
}
class LoggingProvider {
	private readonly logGroupName = 'RomeWebLogs';

	public logToCloudWatch(message: string, logLevel: LogLevel, ...meta: any) {
		switch (logLevel) {
			case LogLevel.debug:
				console.log(message, ...meta);
				break;
			case LogLevel.error:
				try {
					const meta = JSON.stringify(message);
					console.error(message, [meta, ...meta]);
				} catch {}
				console.error(message);
				console.error(message, ...meta);
				break;
			case LogLevel.log:
				console.info(message, ...meta);
				break;
			case LogLevel.warn:
				try {
					const meta = JSON.stringify(message);
					console.warn(message);
					console.warn(message, [meta, ...meta]);
				} catch {}
				console.warn(message, ...meta);
				break;
			case LogLevel.verbose:
				console.log(message, ...meta);
				break;
			default:
				console.info(message, ...meta);
		}
	}
}

export default LoggingProvider;
