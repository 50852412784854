import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React from 'react';
import { FormGroup, FormText } from 'reactstrap';

type DialogProps = {
	selectedChartYear: number;
	setSelectedChartYear: (number: number) => void;
	onClose: () => void;
	options: string[];
	term: string;
};

export const AssetChartSettingsDialog = (props: DialogProps) => {
	const [stateYear, setStateYear] = React.useState(props.selectedChartYear);

	const onCancel = () => props.onClose();
	const onConfirm = () => {
		props.setSelectedChartYear(stateYear);
		props.onClose();
	};

	return (
		<ConfirmationDialog
			header={`Update ${props.term} creation chart settings`}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={'Update settings'}
			isDisabled={!stateYear}
		>
			<FormGroup>
				<FormText className="pb-1">
					Select a year to see a month-by-month breakdown of created{' '}
					{props.term}s.
				</FormText>
				<select
					className={'form-control'}
					onChange={(e) => setStateYear(+e.target.value)}
				>
					<option>Please select..</option>
					{props.options.map((a) => (
						<option selected={stateYear === +a} key={a} value={a}>
							{a}
						</option>
					))}
				</select>
			</FormGroup>
		</ConfirmationDialog>
	);
};
