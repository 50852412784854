import { css } from 'styled-components';
import { focus } from '../ui/ui';

const unstyledButton = css`
	-webkit-appearance: none;
	background-color: transparent;
	border-radius: 0;
	border: none;
	box-sizing: border-box;
	cursor: pointer;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-align: unset;
	text-decoration: none;

	&:focus {
		${focus}
	}

	&:disabled {
		cursor: not-allowed;
	}
`;
export default unstyledButton;
