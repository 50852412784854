import { _logError } from 'utils/common/log';

/**
 * Returns a array of filtered users that have an account status set to active
 */
export function FilterActiveUsers(allUsers: any) {
	return allUsers
		? allUsers?.filter((user: any) => user.status === 'active')
		: _logError('AllUsers is not defined'); //FIXME: this is a temporary solution for typing, we need to find a better way to do this
}
