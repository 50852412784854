import {
	faChevronDown,
	faChevronUp,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// helpers
import * as R from 'ramda';
import React, { Children, useEffect, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { animated, useSpring } from 'react-spring';
import { C } from 'types/globals';
import { Option, Search } from '..';
// components
import { Menu } from '../../Menu';
import { searchObject } from '../Search/fuzzy-search';
import { setDefaultSelections } from './select.helpers';
// styled components
import { Placeholder, SelectionTag, SelectWrapper } from './select.styles';

type Props = {
	name: string;
	label: string;
	form?: UseFormMethods<Record<string, any>>;
	onSelect?: (selections: Selection[] | Selection) => void;
	children?: OptionComponent | OptionComponent[];
	search?: boolean | string;
	multiple?: boolean;
	defaultValue?: defaultValue | defaultValue[];
	required?: boolean;
	noLabel?: boolean;
};

type defaultValue = string | undefined | null;
export type OptionComponent = React.FunctionComponentElement<Selection>;
export type Selection = {
	label: string;
	value: any;
};

export const Select = ({ form, label, name, ...props }: C<Props>) => {
	const { defaultValue, onSelect = () => {}, multiple, search } = props;
	const [selectOpen, setSelectOpen] = useState(false);
	// prettier-ignore
	const [selections, setSelections] = useState<Selection[]>(setDefaultSelections(props.children, defaultValue));
	// prettier-ignore
	const [searchTerm, setSearchTerm] = useState<string>(typeof search === 'string' ? search : '');

	const labelAnimation = useSpring({ opacity: R.isEmpty(selections) ? 0 : 1 });

	useEffect(() => {
		const isEmpty = !R.isEmpty(selections);
		if (isEmpty) return;
		setSelections(setDefaultSelections(props.children, defaultValue));
		// eslint-disable-next-line
	}, [defaultValue, props.children]);

	const handleSelect = (selection: Selection) => {
		!multiple && setSelectOpen(false);
		setSelections((selections) => {
			const newSelections = removeDuplicates(selection, selections);
			onSelect(multiple ? newSelections : selection);
			return newSelections;
		});
	};

	const removeDuplicates = (selection: Selection, selections: Selection[]) => {
		if (R.includes(selection, selections))
			return R.without([selection], selections); // if duplicate, remove
		if (!multiple) return [selection]; // if single, replace
		return [...selections, selection]; // if multiple, append
	};

	return (
		<SelectWrapper>
			{!props.noLabel && (
				<animated.label
					style={labelAnimation}
					htmlFor={label}
					className="label"
				>
					<span className="label-text">{label}</span>
				</animated.label>
			)}

			{/* This is a hidden input, it exists so that react-hook-form has something to connect to */}
			<input
				style={{ display: 'none' }}
				id={label}
				placeholder={label}
				value={selections?.map((s) => s?.value) || props.defaultValue}
				ref={form && form.register({ required: props.required })}
				name={name}
				readOnly
			/>

			<button
				className="field-button cy-wf-field-button"
				type="button"
				onClick={() => setSelectOpen((o) => !o)}
			>
				{/* Show Placeholder if there are no selections */}
				{R.isEmpty(selections) && (
					<Placeholder>
						{props.required ? '*  ' : ''}
						{label}
					</Placeholder>
				)}

				{/* selections will always be an array.  when not choosing multiple selections, it is simply an array with a single value */}
				{/* we show that value if it exists when multiple isn't chosen*/}
				{selections.map((selection) => (
					<SelectionTag
						className="cy-wf-metadata-select"
						key={selection.value}
						transition={{
							enter: { opacity: 1 },
							from: { opacity: 0 },
							leave: { opacity: 1 },
						}}
						isVisible={R.includes(selection, selections)}
					>
						{selection.label}
						<FontAwesomeIcon
							icon={faTimesCircle}
							className="icon"
							onClick={(e: any) => {
								// remove selection
								setSelections((selections) =>
									selections?.filter((a) => a?.value !== selection?.value)
								);
								onSelect(
									selections?.filter((a) => a?.value !== selection?.value)
								);
								e.preventDefault();
								e.stopPropagation();
							}}
						/>
					</SelectionTag>
				))}

				{/* Icon */}
				<FontAwesomeIcon
					icon={selectOpen ? faChevronUp : faChevronDown}
					className="dropdown-icon"
				/>
			</button>

			{/* Show Error if Required */}
			{form && form.errors[name] && form.errors[name].type === 'required' && (
				<p className="error">please select an option</p>
			)}

			<Menu isVisible={selectOpen} onClose={() => setSelectOpen(false)}>
				<div className="options-menu-container">
					{props.search && <Search onSearch={setSearchTerm} />}

					{Children.map(props.children, ({ props }, i) =>
						searchObject(props, searchTerm) && props.value ? (
							<Option
								key={i}
								selected={R.find(R.propEq('value', props.value), selections)}
								onClick={() =>
									handleSelect({ value: props.value, label: props.label })
								}
								{...props}
							/>
						) : null
					)}
				</div>
			</Menu>
		</SelectWrapper>
	);
};
