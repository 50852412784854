import React, { Reducer } from 'react';

export type State = {
	key: string;
	value: any | any[];
};

export type Page = {
	path: string;
	state: State[];
};

export type PageHistoryState = {
	pages?: Page[];
};

type PageHistoryActionType = 'add' | 'update' | 'delete' | 'clear';
type PageHistoryAction = {
	type: PageHistoryActionType;
	payload?: Page;
};

const PageHistoryContext = React.createContext<{
	pages: Page[];
	updateState: (p: Page, type: PageHistoryActionType) => void;
	clear: () => void;
	getStateForKey: (path: string, key: string) => any | any[];
}>({
	pages: [],
	updateState: () => {
		return;
	},
	clear: () => {
		return;
	},
	getStateForKey: () => {
		return undefined;
	},
});

function pageHistoryReducer(
	state: PageHistoryState,
	action: PageHistoryAction
) {
	switch (action.type) {
		case 'add': {
			return {
				pages: [...(state?.pages || []), { ...(action.payload as Page) }],
			};
		}
		case 'update': {
			const keysToUpdate = action.payload?.state?.map((s) => s.key);
			const page =
				state.pages?.find((p) => p.path === action.payload?.path) ??
				action.payload;
			const pageState = page?.state?.filter(
				(s) => !keysToUpdate?.includes(s.key)
			);
			const newPage = {
				path: page?.path,
				state: [
					...((pageState || []) as State[]),
					...(action.payload?.state as State[]),
				],
			};

			return {
				...state,
				pages: [
					...(state.pages?.filter(
						(p) => p.path !== action.payload?.path
					) as Page[]),
					{ ...newPage } as Page,
				],
			};
		}
		case 'delete': {
			return {
				...state,
				pages: state.pages?.filter((p) => p.path !== action.payload?.path),
			};
		}
		case 'clear': {
			return { ...state, pages: [] };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

export function PageHistoryProvider({
	children,
}: {
	children: React.ReactNode[];
}) {
	const [state, dispatch] = React.useReducer<
		Reducer<PageHistoryState, PageHistoryAction>
	>(pageHistoryReducer, {
		pages: Array<Page>(),
		updateState: () => {
			return;
		},
	} as PageHistoryState);

	const updateState = (page: Page, type: PageHistoryActionType) => {
		dispatch({ type, payload: page });
	};

	const clear = () => dispatch({ type: 'clear' });

	const getStateForKey = (path: string, key: string) => {
		return state.pages
			?.find((p) => p.path === path)
			?.state?.find((s) => s.key === key)?.value;
	};

	return (
		<PageHistoryContext.Provider
			value={{
				pages: state.pages as Page[],
				updateState,
				clear,
				getStateForKey,
			}}
		>
			{children}
		</PageHistoryContext.Provider>
	);
}

export function usePageHistory() {
	const context = React.useContext(PageHistoryContext);
	if (context === undefined) {
		throw new Error('useCount must be used within a PageHistoryProvider');
	}

	return context;
}
