import React from 'react';
import Moment from 'react-moment';
import { Col, Container, Row } from 'reactstrap';
import { isIdentifiableLoaded } from '../../utils/common';
import { EntityPropLabel } from '../entity-details.styled-components';
import { Divider, Heading } from '../ui';
import { useWorkflowContext } from '../../context/useWorkflowStore';
import {
	AssetVersion,
	HasAssetVersion,
	Workflow,
} from '../workflow/workflows/types/workflow.types';
import AssetDetailsTabSet, {
	VersionTabs,
} from './components/asset-details-sidebar/asset-details-tab-set.component';
import AssetPreview from './components/asset-preview/asset-preview.component';
import { useAssetHelper } from './components/helpers/useAssetHelper';
import LoadingAssetState from './components/loading-asset-state.component';

// interface
interface Props {
	workflowId: string;
	versionId: string;
}
function versionDetailsReducer(
	state: {
		workflow?: Workflow | null;
		version?: AssetVersion | null;
		damAsset?: AssetVersion | null;
	},
	action: {
		type: 'setVersion' | 'setWorkflow' | 'setDamAsset';
		wfPayload?: Workflow;
		damAssetPayload?: AssetVersion;
		assetPayload?: AssetVersion;
	}
) {
	switch (action.type) {
		case 'setWorkflow':
			return { ...state, workflow: action.wfPayload };
		case 'setVersion':
			return { ...state, version: action.assetPayload };
		case 'setDamAsset':
			return { ...state, damAsset: action.damAssetPayload };
		default:
			return state;
	}
}
// component
const VersionDetailsView = (props: Props) => {
	const { findOne: findWorkflow } = useWorkflowContext();
	const { assetsByWorkflow, findOne: findAsset } = useAssetHelper();
	const [state, dispatch] = React.useReducer(versionDetailsReducer, {
		workflow: null,
		version: null,
	});
	React.useEffect(() => {
		if (!state.workflow) {
			// @ts-ignore
			findWorkflow(props.workflowId).then((wf) =>
				dispatch({ type: 'setWorkflow', wfPayload: wf })
			);
		}
		if (!state.version) {
			findAsset(props.versionId).then((version) =>
				dispatch({ type: 'setVersion', assetPayload: version })
			);
		}
		if (state && !!state.version && !!state.workflow) {
			assetsByWorkflow(state.version).then((damAsset) => {
				dispatch({
					type: 'setDamAsset',
					damAssetPayload: damAsset as AssetVersion,
				});
			});
		}
	}, [
		assetsByWorkflow,
		findAsset,
		findWorkflow,
		props.versionId,
		props.workflowId,
		state,
	]);
	// render methods
	const renderView = (version: AssetVersion, damAsset?: AssetVersion) => (
		<Row>
			<Col xs={12} className="d-flex justify-content-between">
				<Heading>{version.fileName}</Heading>
			</Col>
			<Col md={8}>
				<Divider />
				<Row className="justify-content-between">
					<Col>
						<EntityPropLabel>
							ID: {version?._id ? version?._id : ''}
						</EntityPropLabel>
					</Col>
					<Col className="text-right">
						<EntityPropLabel>
							<Moment format="MM/DD/YYYY, h:MM A" date={version.createdAt} />{' '}
							version
						</EntityPropLabel>
					</Col>
				</Row>
				<AssetPreview asset={{ asset: version } as HasAssetVersion} />
			</Col>
			<Col md={4}>
				<AssetDetailsTabSet
					wf={state.workflow as Workflow}
					asset={version}
					damAsset={damAsset}
					tabs={VersionTabs}
				/>
			</Col>
		</Row>
	);

	const render = () => {
		const version = state.version;
		if (isIdentifiableLoaded(version as Identifiable)) {
			return renderView(
				version as AssetVersion,
				(state?.damAsset as unknown) as AssetVersion
			);
		} else {
			return <LoadingAssetState />;
		}
	};

	return <Container>{render()}</Container>;
};

export default VersionDetailsView;
