import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { SecondaryButton } from '../../buttons.styled-components';
import { useModalCreator } from '../../../utils/ModalStack';
import ConfirmationDialog from '../../modals/confirmation-dialog.component';
import { NotificationsContext } from '../../notifications';
import { useQueryStoreContext } from 'context/useQueryStore';
import { useAuthContext } from 'utils/auth';

interface Props {
	className?: string;
}

const ClearSavedQueriesButton = (props: Props) => {
	const { info } = React.useContext(NotificationsContext);
	const modalStack = useModalCreator();
	const queryStore = useQueryStoreContext();
	const { currentUser } = useAuthContext();
	const clearQueries = useCallback(() => {
		queryStore.clear();

		info('Queries cleared!');
	}, [queryStore, info]);

	const showDeletionConfirmation = useCallback(() => {
		modalStack.addModal(
			<ConfirmationDialog
				header="Delete all saved queries"
				onConfirm={clearQueries}
			>
				<p>Are you sure you want to delete all saved queries?</p>
				<p>This action cannot be reverted.</p>
			</ConfirmationDialog>
		);
	}, [clearQueries, modalStack]);

	// render methods
	if (!currentUser.isAdmin && !queryStore.numQueries) return null;
	return (
		<SecondaryButton
			className={`clear-queries-button ${props.className}`}
			onClick={showDeletionConfirmation}
		>
			<FontAwesomeIcon icon={faTrash} />
			<span className="ml-2">Delete all queries</span>
		</SecondaryButton>
	);
};

export default ClearSavedQueriesButton;
