import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React, { useState } from 'react';
import { Col, Input, Label, Row } from 'reactstrap';

type AddColumnProps = {
	reportKey: string;
	onUpdate: (header: string) => void;
	onCancel: () => void;
};

export const ReportBuilderAddColumnDialog = (props: AddColumnProps) => {
	const { reportKey, onUpdate, onCancel } = props;
	const [columnHeader, setColumnHeader] = useState(props.reportKey);

	const onConfirm = () => {
		onUpdate(columnHeader);
	};

	return (
		<ConfirmationDialog
			header={`Update ${reportKey} column header`}
			onConfirm={onConfirm}
			onCancel={onCancel}
			confirmText={'Update column header'}
		>
			<Row>
				<Col xl={12}>
					<Label inline>Column Header</Label>
					<Input
						type={'text'}
						defaultValue={columnHeader}
						onChange={(e) => setColumnHeader(e.target.value)}
					/>
				</Col>
			</Row>
		</ConfirmationDialog>
	);
};
