import {
	faArrowAltCircleDown,
	faArrowAltCircleUp,
	faEdit,
	faMinus,
	faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventWithValue } from 'components/workflow/workflows/types';
import { useMetadataContext } from 'context/useMetadataContext';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
	Col,
	FormFeedback,
	Input,
	InputGroup,
	InputGroupAddon,
	Label,
	Row,
	UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';
import { MaybeNull } from 'types/globals';
import { useModalCreator } from 'utils/ModalStack';
import { doesNameableMatch } from '../../../utils/common';
import CircleButton from '../../circle-button.component';
import DownshiftSingleSelect from '../../downshift-select/downshift-single-select.component';
import { SelectionActions } from '../../downshift-select/downshift.interfaces';
import { LabeledInput } from '../../forms';
import RenderWhen from '../../render-when.component';
import TagInput from '../../tag-input/TagInput';
import { Subtitle } from '../../ui';
import {
	EntityMetadata,
	EntityMetadataTemplate,
} from '../../workflow/workflows/types/workflow.types';
import { EditFieldDialog } from './EditFieldValueDialog';
import { EntityMetadataFieldTypeDialog } from './entity-metadata-fieldtype-dialog.component';
import { CircleButtonContainer } from './entity-metadata-form.styled-components';
import { useMetadataEditor } from './existing-metadata-fields.component';

const ManageListInput = styled(LabeledInput)`
	text-decoration: underline;
	cursor: pointer;
`;
const ManageListButton = styled(Input)`
	background-color: #fff !important;
	cursor: pointer;
	text-decoration: underline;
`;

const CircleButtonWrapper = styled.div`
	position: relative;
	bottom: 30px;
	> .cursor {
		cursor: pointer !important;
	}
`;

export const buildEmptyFieldTypeOptionsModel = {
	field: '',
	options: [],
};
const EntityMetadataManagementForm = (props: {
	metadata: EntityMetadata;
	displayTemplateField?: boolean;
	onChange: (e: EntityMetadata) => void;
}) => {
	const { metadata, displayTemplateField, onChange } = props;
	const [editingField, setEditingField] = useState(false);
	const [editedMetadata, setEditedMetadata] = React.useReducer(
		function (
			current: { metadata: EntityMetadata },
			action: { type: 'update'; payload: EntityMetadata }
		) {
			switch (action.type) {
				case 'update':
					return { ...current, metadata: action.payload };
				default:
					return current;
			}
		},
		{ metadata: metadata }
	);
	const {
		setKvPair,
		setMeta,
		setFieldOptions,
		getMetadata,
		addFieldType,
		replaceField,
	} = useMetadataEditor(metadata);

	const { templates } = useMetadataContext();
	const [selectedTemplate, setSelectedTemplate] = useState<
		MaybeNull<EntityMetadataTemplate>
	>();
	const [newKvPair, setNewKvPair] = useState({ key: '', value: '' });
	const [newKvField, setNewKvFieldType] = useState({
		fieldType: '',
		field: '',
	});
	const [newKvOptions, setNewKvOptions] = useState(
		buildEmptyFieldTypeOptionsModel
	);
	const [valid, setValid] = useState(true);
	const ref: RefObject<HTMLInputElement> = useRef(null);

	useEffect(() => {
		if (selectedTemplate) {
			setEditedMetadata({
				type: 'update',
				payload: {
					...getMetadata(),
					values: selectedTemplate.values,
					tags: selectedTemplate?.tags,
					fields: selectedTemplate?.fields,
					fieldOptions: selectedTemplate?.fieldOptions,
					fieldTypes: selectedTemplate.fieldTypes,
				},
			});
			setMeta({
				type: 'update',
				payload: {
					...getMetadata(),
					values: selectedTemplate.values,
					tags: selectedTemplate?.tags,
					fields: selectedTemplate?.fields,
					fieldOptions: selectedTemplate?.fieldOptions,
					fieldTypes: selectedTemplate.fieldTypes,
				},
			});
			onChange({
				...getMetadata(),
				values: selectedTemplate.values,
				tags: selectedTemplate?.tags,
				fields: selectedTemplate?.fields,
				fieldOptions: selectedTemplate?.fieldOptions,
				fieldTypes: selectedTemplate.fieldTypes,
			});
		}
	}, [selectedTemplate, getMetadata, onChange, setMeta]);

	const handleUpdateField = (oldField: string, newField: string) => {
		const updated = replaceField(oldField, newField);
		setEditedMetadata({
			type: 'update',
			payload: updated,
		});
		setMeta({
			type: 'update',
			payload: updated,
		});
		onChange(updated);
	};

	const handleAddField = () => {
		setValid(
			!!newKvOptions.field &&
				!editedMetadata.metadata.fields?.includes(newKvOptions.field)
		);

		if (valid) {
			const { value } = newKvPair;
			// first, update all the metadata template values for field & field type & field options
			const fields = setKvPair(newKvField.field, value).fields;
			const fieldTypes = addFieldType(
				newKvField.fieldType,
				newKvField.field as string
			).fieldTypes;
			const fieldOptions = setFieldOptions(newKvOptions).fieldOptions;
			const values = editedMetadata.metadata.values as Record<string, string>;
			values[newKvField.field] = '';
			setEditedMetadata({
				type: 'update',
				payload: {
					...editedMetadata.metadata,
					fields,
					fieldOptions,
					values,
					fieldTypes,
				} as EntityMetadata,
			});

			setMeta({
				type: 'update',
				payload: {
					...editedMetadata.metadata,
					fields,
					fieldOptions,
					values,
					fieldTypes,
				} as EntityMetadata,
			});
			onChange({
				...editedMetadata.metadata,
				fields,
				fieldOptions,
				values,
				fieldTypes,
			} as EntityMetadata);

			// second, reinitialize empty values for new field & field type & field options
			setNewKvFieldType({ field: '', fieldType: '' });
			setNewKvOptions(buildEmptyFieldTypeOptionsModel);
			setNewKvPair({ key: '', value: '' });

			setValid(true);
		}
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			handleAddField();

			if (ref.current) {
				(event.target as HTMLInputElement).blur();
				ref.current.focus();
			}
		}
	};
	const modalCreator = useModalCreator();
	const handleRemoveField = (field: string) => {
		const newOptions: Record<string, { field: string; options: string[] }> = {};
		const newValues: Record<string, string | string[]> = {};
		if (
			editedMetadata.metadata.fieldOptions &&
			Object.keys(editedMetadata.metadata.fieldOptions).length
		) {
			Object.keys(editedMetadata.metadata.fieldOptions).forEach((key) => {
				if (
					key !== field &&
					editedMetadata.metadata.fieldOptions &&
					editedMetadata.metadata.fieldOptions[key]
				)
					newOptions[key] = editedMetadata.metadata.fieldOptions[key];
			});
		}

		if (editedMetadata.metadata.values) {
			Object.keys(editedMetadata.metadata.values).forEach((key) => {
				if (
					key !== field &&
					editedMetadata.metadata.values &&
					editedMetadata.metadata.values[key]
				)
					newValues[key] = editedMetadata.metadata.values[key];
			});
		}
		setEditedMetadata({
			type: 'update',
			payload: {
				...editedMetadata.metadata,
				fields: editedMetadata.metadata?.fields?.filter((f) => f !== field),
				fieldTypes: editedMetadata.metadata.fieldTypes?.filter(
					(ft) => ft.field !== field
				),
				fieldOptions: newOptions,
				values: newValues,
			},
		});
		setMeta({
			type: 'update',
			payload: {
				...editedMetadata.metadata,
				fields: editedMetadata.metadata?.fields?.filter((f) => f !== field),
				fieldTypes: editedMetadata.metadata.fieldTypes?.filter(
					(ft) => ft.field !== field
				),
				fieldOptions: newOptions,
				values: newValues,
			},
		});
		onChange({
			...editedMetadata.metadata,
			fields: editedMetadata.metadata?.fields?.filter((f) => f !== field),
			fieldTypes: editedMetadata.metadata.fieldTypes?.filter(
				(ft) => ft.field !== field
			),
			fieldOptions: newOptions,
			values: newValues,
		});
	};
	const fieldTypes = [
		{ type: '', label: 'Please Select' },
		{ type: 'date', label: 'Date' },
		{ type: 'singleSelect', label: 'List (Single select)' },
		{ type: 'multiSelect', label: 'List (Multi select)' },
		{ type: 'numeric', label: 'Numeric' },
		{ type: 'openType', label: 'Open Type' },
	];

	const updateFieldType = (fieldType: string, field: string) => {
		setEditedMetadata({
			type: 'update',
			payload: {
				...editedMetadata.metadata,
				fieldTypes: [
					...editedMetadata.metadata?.fieldTypes?.filter(
						(m) => m.field !== field
					),
					{ fieldType, field },
				],
			},
		});
		setMeta({
			type: 'update',
			payload: {
				...editedMetadata.metadata,
				fieldTypes: [
					...editedMetadata.metadata?.fieldTypes?.filter(
						(m) => m.field !== field
					),
					{ fieldType, field },
				],
			},
		});
		onChange({
			...editedMetadata.metadata,
			fieldTypes: [
				...editedMetadata.metadata?.fieldTypes?.filter(
					(m) => m.field !== field
				),
				{ fieldType, field },
			],
		});
	};
	const moveField = (field: string, direction: 'up' | 'down') => {
		if (
			editedMetadata.metadata?.fields &&
			editedMetadata.metadata?.fieldTypes
		) {
			const originalFieldTypeIndex = editedMetadata.metadata?.fieldTypes?.findIndex(
					(ft) => ft.field === field
				),
				originalFieldIndex = editedMetadata.metadata.fields.findIndex(
					(f) => f === field
				),
				fieldTypeIndex =
					direction === 'up'
						? editedMetadata.metadata.fieldTypes?.findIndex(
								(ft) => ft.field === field
						  ) - 1
						: editedMetadata.metadata.fieldTypes?.findIndex(
								(ft) => ft.field === field
						  ) + 1,
				fieldIndex =
					direction === 'up'
						? editedMetadata.metadata.fields?.findIndex((ft) => ft === field) -
						  1
						: editedMetadata.metadata.fields?.findIndex((ft) => ft === field) +
						  1;
			const fieldToSwap = editedMetadata.metadata.fields[fieldIndex];
			const swappingFieldType =
				editedMetadata.metadata.fieldTypes[originalFieldTypeIndex];
			const swappingField = editedMetadata.metadata.fields[originalFieldIndex];
			const fieldTypeToSwap =
				editedMetadata.metadata.fieldTypes[fieldTypeIndex];

			const fields = editedMetadata.metadata.fields.map((fieldValue, index) => {
				return index === fieldIndex
					? swappingField
					: index === originalFieldIndex
					? fieldToSwap
					: fieldValue;
			});
			const fieldTypes = editedMetadata.metadata.fieldTypes.map(
				(fieldType, index) => {
					return index === fieldTypeIndex
						? swappingFieldType
						: index === originalFieldTypeIndex
						? fieldTypeToSwap
						: fieldType;
				}
			);
			setEditedMetadata({
				type: 'update',
				payload: { ...editedMetadata.metadata, fieldTypes, fields },
			});
			setMeta({
				type: 'update',
				payload: { ...editedMetadata.metadata, fieldTypes, fields },
			});
			onChange({ ...editedMetadata.metadata, fieldTypes, fields });
		}
	};

	const [editedField, setEditedField] = useState('');
	const handleMoveField = (field: string, direction: 'up' | 'down') => {
		moveField(field, direction);
	};
	const shouldShowMetadataEditFields = React.useCallback(
		(metadata: EntityMetadata, fieldKey: string) =>
			metadata?.fieldTypes?.find((ft) => ft.field === fieldKey)?.fieldType ===
				'singleSelect' ||
			metadata?.fieldTypes?.find((ft) => ft.field === fieldKey)?.fieldType ===
				'multiSelect',
		[]
	);
	const renderMetadataTemplateSelect = () => {
		if (displayTemplateField !== undefined && !displayTemplateField) {
			return null;
		}

		return (
			<Col md={12} className="mt-3">
				<DownshiftSingleSelect
					label="Metadata Template"
					placeholder="Search by name..."
					selectionState={{
						selection: selectedTemplate,
						options: [...templates],
						searchPredicate: doesNameableMatch,
					}}
					selectionActions={
						{
							select: setSelectedTemplate,
						} as SelectionActions<EntityMetadataTemplate, Displayable>
					}
				/>
			</Col>
		);
	};

	const render = () => (
		<>
			<Row>
				{renderMetadataTemplateSelect()}
				<Col md={12} className="mt-5 mb-1">
					<Subtitle>Metadata</Subtitle>
					<hr />
				</Col>
			</Row>

			<Row>
				<Col md={3} xs={10}>
					<Label>Field Name</Label>
				</Col>
				<Col md={3} xs={10}>
					<Label>Field Type</Label>
				</Col>
				<Col md={3} xs={10}>
					<Label>List Values</Label>
				</Col>
			</Row>
			{editingField && (
				<EditFieldDialog
					isOpen={editingField}
					oldValue={editedField}
					afterSubmit={(updatedField: string) => {
						handleUpdateField(updatedField, editedField);
						setEditingField(false);
						setEditedField('');
					}}
					onCancel={() => {
						setEditingField(false);
						setEditedField('');
					}}
				/>
			)}
			{editedMetadata?.metadata?.fields?.map(
				(fieldKey: string, idx: number) => (
					<Row key={fieldKey} className="mb-3 mb-md-0">
						<Col md={3} xs={10}>
							<InputGroup>
								<input
									className={'form-control'}
									readOnly
									type="text"
									name="key"
									defaultValue={fieldKey}
								/>

								<InputGroupAddon addonType="append">
									<UncontrolledTooltip target="editFieldTooltip">
										Edit metadata field
									</UncontrolledTooltip>
									<span
										className="input-group-text"
										onClick={() => {
											setEditingField(true);
											setEditedField(fieldKey);
										}}
									>
										<FontAwesomeIcon icon={faEdit} id="editFieldTooltip" />
									</span>
								</InputGroupAddon>
							</InputGroup>
						</Col>
						<Col md={3} xs={10}>
							<select
								className={'form-control'}
								id="metadataValue"
								defaultValue={
									editedMetadata?.metadata?.fieldTypes?.find(
										(ft) => ft.field === fieldKey
									)?.fieldType
								}
								onChange={(event: EventWithValue<any>) => {
									updateFieldType(event.target.value, fieldKey);
								}}
							>
								{fieldTypes.map((fieldType) => {
									return (
										<option key={fieldType.type} value={fieldType.type}>
											{fieldType.label}
										</option>
									);
								})}
							</select>
						</Col>
						<Col md={3} xs={10}>
							<RenderWhen
								when={shouldShowMetadataEditFields(
									editedMetadata.metadata,
									fieldKey
								)}
							>
								<ManageListButton
									type={'text'}
									name="value"
									onClick={() => {
										modalCreator.addModal(
											<EntityMetadataFieldTypeDialog
												onChange={(updated) => {
													setEditedMetadata({
														type: 'update',
														payload: {
															...updated,
															fieldOptions: updated?.fieldOptions,
														},
													});
													setMeta({
														type: 'update',
														payload: {
															...updated,
															fieldOptions: updated.fieldOptions,
														},
													});
													onChange({
														...updated,
														fieldOptions: updated?.fieldOptions,
													});
												}}
												header={'Edit List for ' + fieldKey}
												fieldType={
													editedMetadata.metadata?.fieldTypes?.find(
														(m) => m.field === fieldKey
													)?.fieldType as string
												}
												fieldKey={fieldKey}
												metadata={editedMetadata.metadata}
											/>
										);
									}}
									readOnly
									defaultValue={'Manage List'}
								/>
							</RenderWhen>
						</Col>
						<Col md={3} xs={2}>
							<CircleButtonWrapper>
								<CircleButtonContainer>
									<CircleButton
										className="sm cursor"
										color="danger"
										id={`removeMetadataItem${idx}`}
										icon={faMinus}
										onClick={() => handleRemoveField(fieldKey)}
										tooltip="Remove metadata entry"
									/>
									<CircleButton
										disabled={idx === 0}
										onClick={() => handleMoveField(fieldKey, 'up')}
										id={`sortMetadataItemUp${idx}`}
										className="sm cursor"
										icon={faArrowAltCircleUp}
										tooltip="Move list item up"
									/>
									<CircleButton
										disabled={
											editedMetadata.metadata?.fields &&
											idx === editedMetadata?.metadata?.fields?.length - 1
										}
										onClick={() => handleMoveField(fieldKey, 'down')}
										id={`sortMetadataItemDown${idx}`}
										className="sm cursor"
										icon={faArrowAltCircleDown}
										tooltip="Move list item down"
									/>
								</CircleButtonContainer>
							</CircleButtonWrapper>
						</Col>
					</Row>
				)
			)}

			<Row className="mt-3">
				<Col md={3} xs={10}>
					<LabeledInput
						label="Field name"
						type="text"
						name="key"
						id="metadataKey"
						value={newKvField.field}
						onChange={(e) => {
							setNewKvFieldType({ ...newKvField, field: e.target.value });
							setNewKvOptions({ ...newKvOptions, field: e.target.value });
						}}
						onKeyDown={handleInputKeyDown}
						innerRef={ref}
					/>
					{valid === false && (
						<FormFeedback invalid style={{ display: valid ? 'none' : 'block' }}>
							Field has already been added.
						</FormFeedback>
					)}
				</Col>
				<Col md={3} xs={10}>
					<Label className="help-block d-block"> Field Type</Label>
					<select
						className={'form-control'}
						id="metadataValue"
						value={newKvField.fieldType}
						onChange={(e) => {
							setNewKvFieldType({
								...newKvField,
								fieldType: e.target.value as any,
							});
						}}
					>
						{fieldTypes.map((fieldType) => (
							<option key={fieldType.type} value={fieldType.type}>
								{fieldType.label}
							</option>
						))}
					</select>
				</Col>
				<Col md={3} xs={10}>
					<RenderWhen
						when={['singleSelect', 'multiSelect'].includes(
							newKvField.fieldType as string
						)}
					>
						<div id="listButton">
							<ManageListInput
								id="listButton"
								label="Field Value"
								type={'text'}
								name="value"
								value={'Manage List'}
								onKeyDown={handleInputKeyDown}
								readOnly
							/>
						</div>
						<UncontrolledTooltip target="listButton">
							Add field to manage list values
						</UncontrolledTooltip>
					</RenderWhen>
				</Col>

				<Col md={3} xs={2}>
					<CircleButtonContainer>
						<CircleButton
							id="addMetadataItem"
							className="sm"
							icon={faPlus}
							onClick={handleAddField}
							tooltip="Add metadata entry"
						/>
					</CircleButtonContainer>
				</Col>
			</Row>

			<TagInput
				onChange={(updated) => {
					setEditedMetadata({
						type: 'update',
						payload: updated as EntityMetadata,
					});
					setMeta({
						type: 'update',
						payload: updated as EntityMetadata,
					});
					onChange({
						...updated,
					});
				}}
				metadata={editedMetadata.metadata}
			/>
		</>
	);

	return render();
};

export default EntityMetadataManagementForm;
