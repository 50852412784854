import React, { useEffect } from 'react';
import { useHeaders, User } from './AuthContext';
import openSocket from 'socket.io-client';
const ActiveUsersContext = React.createContext<{ activeUsers: User[] }>({
	activeUsers: [],
});

export const ActiveUsersContextProvider = ({ children }: { children: any }) => {
	const { getHeaders } = useHeaders();
	const [activeUsers, setActiveUsers] = React.useState<User[]>([]);
	const socketRef = React.useRef<any>();
	useEffect(() => {
		const headers = getHeaders();
		socketRef.current = openSocket(process.env.REACT_APP_ROME_API_ENDPOINT, {
			query: headers.headers.Authorization,
			transports: ['websocket'],
			upgrade: false,
		});

		// listen for incoming message
		socketRef.current.on('online', (user: any) => {
			// check if the current user is within the notification sent
			setActiveUsers([...activeUsers.filter((a) => a._id !== user._id), user]);
		});

		socketRef.current.on('offline', (userId: any) =>
			setActiveUsers((active) => active.filter((a) => a._id !== userId))
		);
		return () => {
			socketRef.current.disconnect();
		};
		//eslint-disable-next-line
	}, []);

	return (
		<ActiveUsersContext.Provider value={{ activeUsers }}>
			{children}
		</ActiveUsersContext.Provider>
	);
};

export const useActiveUsersContext = () => {
	const context = React.useContext(ActiveUsersContext);
	if (!context)
		throw new Error('Expected to be in active users context but was not.');
	return context;
};
