import styled from 'styled-components';
import { Toast, ToastHeader } from 'reactstrap';

import themeStore from '../../core-ui/models/ThemeStore';
import storageModel from 'utils/models/StorageModel';
import { SiteDefaults } from 'context/useThemeContext';

const { colors } = themeStore.selectedTheme;
const defaults =
	JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	({} as SiteDefaults);

export const NotificationsContainer = styled.div`
	position: fixed;
	z-index: 2;
	right: 2em;
	top: 6.5em;
`;

// styled components
export const NotificationToast = styled(Toast)`
	margin-top: 2em;
	min-width: 200px;
	max-width: 100%;

	&:first-of-type {
		margin-top: 1em;
	}
`;

export const NotificationHeader = styled(ToastHeader)`
	/* ensure class names match NotificationType */
	&.error {
		background-color: ${defaults?.danger};
		color: var(--white);

		.close {
			color: var(--white);
			opacity: 1;
		}
	}
	&.warning {
		background-color: ${colors.warning};
		color: ${colors.darkestGrey};
	}
	&.info {
		background-color: ${defaults?.secondary};
		color: var(--white);

		.close {
			color: var(--white);
			opacity: 1;
		}
	}
`;
