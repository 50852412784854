import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { css } from 'styled-components';

import themeStore from '../models/ThemeStore';

const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
const hoverStyles = css`
	background-color: ${colors.lightGrey} !important;

	&.danger {
		background-color: ${defaults?.danger} !important;
	}

	&.secondary {
		background-color: ${defaults?.secondary} !important;
	}

	&.danger,
	&.secondary {
		color: ${colors.white} !important;

		.svg-inline--fa {
			color: ${colors.white} !important;
		}
	}
`;

const dropDownMenuItem = css`
	list-style: none;
	padding: 0.25em 0.5em;

	&.danger {
		color: ${defaults?.danger};
	}

	&.secondary {
		color: ${defaults?.secondary};
	}

	:not(.dropdown-divider) {
		cursor: pointer;

		&.dropdown-item:hover,
		&:hover {
			${hoverStyles};
		}

		&.dropdown-item:focus {
			${hoverStyles};
			outline: 0;
		}
	}
`;
export default dropDownMenuItem;
