import React from 'react';
import { _logError } from '../../../../../utils/common/log';
import { NotificationsContext } from '../../../../notifications';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import StageDialog from './stage-dialog.component';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import { StatusEnum } from '../../types/workflowStatus.types';
import { Stage, Workflow } from '../../types/workflow.types';
import { flattenStages } from 'components/workflow/workflows/helpers';
import { StageType } from 'components/workflow/workflow-templates/models';

const queueTerm = themeStore._.queue;
const stageTerm = themeStore._.stage;
const workflowTerm = themeStore._.workflow;

const ActivateFirstStageDialog = () => {
	const { setStage, workflow: wf, stage, updateStatus } = useWorkflowContext();
	const { error: showError, info } = React.useContext(NotificationsContext);
	const workflow = React.useMemo(() => wf, [wf]);

	const onSubmit = async (message: string) => {
		if (
			!flattenStages(workflow as Workflow)
				?.filter((stage) => stage.type !== StageType.parallel)
				.every((stage) => stage?.owners?.length > 0)
		) {
			showError(
				`Unable to activate workflow. Every stage does not have an assigned stakeholder. Please correct the issue and try again.`
			);
			return;
		}
		if (stage?.status === StatusEnum.active || !workflow?._id) return;
		try {
			const updatedWf = (await updateStatus(
				StatusEnum.active,
				workflow,
				message,
				stage
			)) as Workflow;
			const updatedStage = updatedWf?.stages?.find(
				(m: Stage) => m._id === stage?._id
			) as Stage;
			setStage(updatedStage);
			info(`${workflowTerm} "${workflow?.title}" has been activated!`);
		} catch (error) {
			_logError(error);
			showError(
				`Unable to update the ${stageTerm.toLowerCase()} status, an issue occurred while saving. Please try again later.`
			);
		}
	};

	return (
		<StageDialog
			header={`Activate stage "${stage?.title}"`}
			context={`Please add some context about the status update from	"${queueTerm.toLowerCase()}" to "active"`}
			onClick={onSubmit}
		/>
	);
};

export default ActivateFirstStageDialog;
