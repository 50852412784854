import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import BackLink from '../../back-link/back-link.component';
import { Heading } from '../../ui';
import AddTemplateForm from './components/add-template-form.component';

const TemplateCreationView = () => (
	<Container>
		<Row className="justify-content-center">
			<Col lg={8}>
				<BackLink link=".." title="Templates" />
				<div className="d-flex justify-content-between">
					<Heading>Create a template</Heading>
				</div>
			</Col>
			<Col lg={8}>
				<AddTemplateForm />
			</Col>
		</Row>
	</Container>
);

export default TemplateCreationView;
