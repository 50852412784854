import { User } from '../auth';
import LoggerService, { LogLevel } from '../core/LoggingProvider';

class ErrorMonitor {
	isInit = false;

	public initialize() {
		if (!process.env.REACT_APP_RAYGUN_API_KEY) {
			return;
		}

		this.isInit = true;
	}

	public setUser(user: User) {
		if (!process.env.REACT_APP_RAYGUN_API_KEY) {
			return;
		}

		if (!this.isInit) {
			throw new Error(
				'Unable to set user before the error logging service is initialized.'
			);
		}
	}

	private readonly _loggerService: LoggerService = new LoggerService();
	public report(content: any) {
		if (!process.env.REACT_APP_RAYGUN_API_KEY) {
			return;
		}

		if (!this.isInit) {
			throw new Error(
				'Unable to log errors before the service is initialized.'
			);
		}

		// irony
		try {
			this._loggerService.logToCloudWatch(
				`ERROR! Error occurred in Rome Web Application, ${content}`,
				LogLevel.error
			);
		} catch (error) {
			console.error('Unable to save error log.', error);
		}
	}
}

const errorMonitor = new ErrorMonitor();
export default errorMonitor;
