/**
 * Filters all elements in an array that match a given substring.
 * @param array an array of random attributes.  can be object/string/number/etc
 * @param searchTerm a string or substring
 */
export const fuzzySearch = (array: any[], searchTerm: string) =>
	array.reduce<any[]>((searchedElements, element) => {
		if (typeof element === 'object' && searchObject(element, searchTerm))
			return [...searchedElements, element];

		if (
			typeof element === 'string' ||
			typeof element === 'number' ||
			typeof element === 'boolean'
		) {
			if (searchElement(element, searchTerm))
				return [...searchedElements, element];
		}

		return searchedElements;
	}, []);

/**
 *  compares every attribute of an object to a string.
 *  If any attributes match the string, the function returns true.
 *  If the attribute is an object, this function is run recursively.
 * @param object any type of object
 * @param searchTerm whatever substring you are wanting to search
 */
export const searchObject = (
	object: object,
	searchTerm: string,
	exact = false
): boolean =>
	!!object
		? Object.values(object).reduce((isMatch, attribute) => {
				// If any attribute in the object has matched, return true
				if (isMatch) return true;

				// If the attribute is an object, recurse
				if (typeof attribute === 'object')
					return searchObject(attribute, searchTerm, exact);

				// if the attribute is a string/bool/num, check if it matches
				if (
					typeof attribute === 'string' ||
					typeof attribute === 'number' ||
					typeof attribute === 'boolean'
				)
					return searchElement(attribute, searchTerm, exact);

				// If it's anything else, ignore it
				return isMatch;
		  }, false)
		: false;

type Element = string | number | boolean;
const searchElement = (element: Element, searchTerm: string, exact = false) =>
	exact
		? sanitize(element) === sanitize(searchTerm)
		: sanitize(element).includes(sanitize(searchTerm));

const sanitize = (element: Element) => element.toString().toLowerCase();
