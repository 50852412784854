import _ from 'lodash';
import { Form } from 'reactstrap';
import React, { FormEvent, useState } from 'react';

import { _logError } from '../../../../utils/common/log';
import { isValidEmail } from '../../../../utils/common';
import { SubmitButton, UncontrolledLabeledInput } from '../../../forms';
import { useModalCloser } from '../../../../utils/ModalStack';

import { NotificationsContext } from '../../../notifications';

import { ShareDialogComponentProps } from './share-dialog-body.component';
import { useAssetHelper } from '../helpers/useAssetHelper';

const EmailForm = (props: ShareDialogComponentProps) => {
	const { error: showError } = React.useContext(NotificationsContext);
	const { asset, assets } = props;

	const assetStore = useAssetHelper();
	const modalStack = useModalCloser();

	const [errorMessage, setErrorMessage] = useState('');

	const sendEmail = async (event: FormEvent) => {
		event.preventDefault();

		// validation
		const form = _.get(event.target, 'form');
		const emailInput = _.get(form, 'email');

		if (!isValidEmail(emailInput.value)) {
			setErrorMessage('Please enter a valid email.');
			return;
		}

		// clear error message
		setErrorMessage('');

		// call api
		try {
			if (assets && assets.length && !asset) {
				await assetStore.shareAssetsToEmail(
					assets.map((a) => a._id),
					emailInput.value
				);
				if (props.onSuccess) {
					props.onSuccess();
				}
			} else if (asset && (!assets || !assets?.length)) {
				await assetStore.shareAssetToEmail(asset?._id, emailInput.value);
				if (props.onSuccess) {
					props.onSuccess();
				}
			}
		} catch (error) {
			_logError(error);

			showError(
				`An issue occurred, unable to send an email at this time. Please try again later.`
			);

			modalStack.closeModal();
		}

		return false;
	};

	const render = () => (
		<Form>
			<UncontrolledLabeledInput
				label="Email"
				placeholder="Enter email to which link will be sent"
				type="email"
				name="email"
				autoComplete="on"
				errorMsg={errorMessage}
			/>
			<SubmitButton onClick={sendEmail} label="Send email" />
		</Form>
	);

	return render();
};

export default EmailForm;
