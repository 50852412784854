import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import { NotificationsContext } from 'components/notifications';
import { QueryModel, useQueryStoreContext } from 'context/useQueryStore';
import React, { useContext, useState } from 'react';
import Moment from 'react-moment';
import {
	Card,
	Col,
	Container,
	DropdownMenu,
	DropdownToggle,
	FormGroup,
	Input,
	Label,
	Row,
	Table,
	UncontrolledDropdown,
} from 'reactstrap';
import { buildSavedQueryUrl } from '../../common/links';
import { DropDownMenuItem } from '../../../components/drop-down-menu.component';
import { Cell, CellLink } from '../../../components/tables.styled-components';
import ClearSavedQueriesButton from '../../../components/dashboard.component/components/clear-saved-queries-button.component';

interface Props {
	queries: QueryModel[];
}

const renderNoQueriesRow = () => (
	<tr>
		<td colSpan={3}>
			<p>You currently have no saved queries.</p>
		</td>
	</tr>
);

const QueriesTable = (props: Props) => {
	const { remove, update } = useQueryStoreContext();
	const { info } = useContext(NotificationsContext);
	const [editedQuery, setEditedQuery] = useState<QueryModel>();
	const [isEditing, setIsEditing] = useState(false);
	const editQueryClick = (q: QueryModel) => {
		setIsEditing(true);
		setEditedQuery(q);
	};

	const deleteQueryClick = (q: QueryModel) => {
		remove(q);
		info('Deleted query successfully.');
	};
	const renderQueryRow = (query: QueryModel) => (
		<tr key={query._id}>
			<Cell>
				<CellLink to={buildSavedQueryUrl(query)}>{query.value}</CellLink>
			</Cell>
			<Cell>{query.type}</Cell>
			<Cell>
				<Moment format="MM/DD/YYYY" date={query.createdAt} />
			</Cell>
			<Cell>
				{isEditing && (
					<ConfirmationDialog
						header={`Edit query "${query.value}"`}
						confirmText="Update Query"
						onCancel={() => {
							setIsEditing(false);
							setEditedQuery(undefined);
						}}
						onConfirm={() => {
							if (editedQuery) {
								update(editedQuery);
								info('Updated query successfully.');
							}
							setIsEditing(false);
							setEditedQuery(undefined);
						}}
					>
						<Container>
							<Row>
								<Col xl={12}>
									<FormGroup inline>
										<Label inline>Query value</Label>
										<Input
											type={'text'}
											onChange={(e) =>
												setEditedQuery({
													...(editedQuery as QueryModel),
													value: e.target.value,
												})
											}
											defaultValue={query.value}
										/>
									</FormGroup>
								</Col>
							</Row>
						</Container>
					</ConfirmationDialog>
				)}
				<UncontrolledDropdown>
					<DropdownToggle color="white">
						<FontAwesomeIcon icon={faEllipsisV} />
					</DropdownToggle>
					<DropdownMenu>
						<DropDownMenuItem onClick={() => editQueryClick(query)}>
							Edit query
						</DropDownMenuItem>
						<DropDownMenuItem
							className="danger"
							onClick={() => deleteQueryClick(query)}
						>
							Delete query
						</DropDownMenuItem>
					</DropdownMenu>
				</UncontrolledDropdown>
			</Cell>
		</tr>
	);

	return (
		<>
			<Card>
				<Table>
					<thead>
						<tr>
							<th>Search Term</th>
							<th>Context</th>
							<th>Creation Date</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{props.queries.length
							? props.queries.map((query) => renderQueryRow(query))
							: renderNoQueriesRow()}
					</tbody>
				</Table>
			</Card>
			{props.queries.length ? (
				<ClearSavedQueriesButton className="mt-4 float-right" />
			) : null}
		</>
	);
};

export default QueriesTable;
