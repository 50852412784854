import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import styled from 'styled-components';
import inputField from '../../core-ui/components/input-field';

import { boxShadowFocus } from '../../core-ui/ui/ui';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const TagInputField = styled.div`
	${inputField}
	padding: 12px;
	position: relative;

	&:focus-within {
		${boxShadowFocus};
		border: 1px solid ${defaults?.secondaryHighlighted} !important;
	}
`;

export const BorderlessInput = styled.input`
	display: inline-block;
	border: none;
	outline: 0 !important;
	text-rendering: optimizeLegibility;
	width: calc(100% - 30px);
	background-color: var(--white);
`;
