import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { useModalCloser } from '../../utils/ModalStack';
import { ModalTitle } from './dialog-modal.styled-components';

interface DialogProps {
	header: string;
	modalSize?: string;
	onClosed?: () => void;
}

const DialogModal = ({
	header,
	modalSize,
	children,
	onClosed,
}: PropsWithChildren<DialogProps>) => {
	const modalStack = useModalCloser();

	const [isOpen, setOpen] = useState(true);
	const close = () => {
		if (onClosed) onClosed();
		setOpen(false);
	};

	return (
		<Modal
			isOpen={isOpen}
			toggle={close}
			unmountOnClose={true}
			onClosed={() => {
				modalStack.closeModal();
				if (onClosed) {
					onClosed();
				}
			}}
			size={modalSize}
		>
			<ModalHeader className="d-block">
				<ModalTitle>{header}</ModalTitle>
				<IconButton
					className="modal__close-button top-right"
					color="error"
					onClick={close}
				>
					<CloseIcon />
				</IconButton>
			</ModalHeader>

			{children}
		</Modal>
	);
};

export default DialogModal;
