import styled from 'styled-components';
import { Input, Label } from 'reactstrap';

import { fsNormal } from '../../core-ui/ui/typography';
import inputField from '../../core-ui/components/input-field';
import select from '../../core-ui/components/select';

export const StyledLabel = styled(Label)`
	${fsNormal};
	margin-bottom: 0.25em;
	margin-top: 0.5em;
`;

export const StyledInput = styled(Input)`
	${inputField};
`;

export const StyledSelect = styled.select`
	${select};
	display: block;
	width: 100%;
`;
