import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import {
	emailString,
	optionalString,
	tolerantDate,
	unixDate,
	urlString,
} from './common';
import { userRolesModel } from '../../components/accounts/models/UserRole.model';

/**
 * Non-OIDC ('custom') claims must be namespaced to avoid collisions
 * https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims
 */
export const customClaimsNamespace = 'https://api.romeportal.com' as const;
const claimKey = (k: string): string => `${customClaimsNamespace}/${k}`;

const ROLES = claimKey('roles');

export const AuthUserModel = types.model('AuthUser', {
	given_name: optionalString,
	family_name: optionalString,
	nickname: optionalString,
	name: optionalString,
	picture: urlString,
	locale: types.optional(types.string, 'en'),
	updated_at: tolerantDate,
	email: emailString,
	email_verified: types.boolean,
	iss: urlString,
	sub: types.string,
	aud: types.string,
	iat: unixDate,
	exp: unixDate,
	nonce: optionalString,
	[ROLES]: types.array(userRolesModel),
});

export type AuthUser = Instance<typeof AuthUserModel>;

export interface AuthUserSnapshot extends SnapshotIn<typeof AuthUserModel> {}
