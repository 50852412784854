import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { capitalizeFirstLetter } from '../../../../utils/common';
import {
	EntityPropLabel,
	EntityPropListItem,
	TagBadge,
} from '../../../entity-details.styled-components';
import { EntityMetadata } from '../../../workflow/workflows/types/workflow.types';
import { map } from 'lodash';

const AssetMetadataProps = (props: { metadata: EntityMetadata }) => {
	const { metadata } = props;
	let metadataFields: Array<any> = [];
	if (metadata && metadata.values) {
		map(metadata.values, (value: string | string[], key: any) => {
			metadataFields.push(
				<EntityPropListItem key={key}>
					<EntityPropLabel>{capitalizeFirstLetter(key)}</EntityPropLabel>
					<p>{Array.isArray(value) ? value.join(', ') : value}</p>
				</EntityPropListItem>
			);
		});
	}

	return (
		<>
			{metadataFields}
			{metadata?.tags?.length ? (
				<EntityPropListItem>
					<EntityPropLabel>
						<FontAwesomeIcon icon={faTag} /> Tags
					</EntityPropLabel>
					{metadata.tags.map((tag: any) => (
						<TagBadge key={tag}>{tag}</TagBadge>
					))}
				</EntityPropListItem>
			) : null}
		</>
	);
};

export default AssetMetadataProps;
