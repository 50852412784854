import styled from 'styled-components';

export const ModalTitle = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 85%;
	display: inline-block;
	text-transform: capitalize;
`;
