import React, { PropsWithChildren } from 'react';
import { JsxFunction } from '../utils/common';
import { StyledCell } from './workflow/workflow-templates/components/template-list-table.styled-components';

type Wrapper = React.JSXElementConstructor<PropsWithChildren<unknown>>;

export interface ListColumn<T> {
	label?: string;
	prop: Extract<keyof T, string> | JsxFunction<T>;

	cellWrapper?: Wrapper;
	headerWrapper?: Wrapper;

	renderHeader(): JSX.Element;

	render(t: T): JSX.Element;
}

class SimpleListColumn<T> implements ListColumn<T> {
	public readonly label: string;
	public readonly prop: Extract<keyof T, string> | JsxFunction<T>;
	public readonly cellWrapper: Wrapper;
	public readonly headerWrapper: Wrapper;

	constructor({
		label = '',
		prop,
		headerWrapper = ({ children }) => <th>{children}</th>, // Is there a built-in constructor we can use instead?
		cellWrapper = StyledCell,
	}: ListColumnCreationOptions<T>) {
		this.label = label;
		this.prop = prop;
		this.cellWrapper = cellWrapper;
		this.headerWrapper = headerWrapper;
	}

	renderHeader() {
		const Wrapper = this.headerWrapper;
		return <Wrapper key={this.label}>{this.label}</Wrapper>;
	}

	render(t: T) {
		const Wrapper = this.cellWrapper;

		return (
			<Wrapper
				// @ts-ignore
				className="cy-wf-task-row" // FIXME: Add className to the type definition for this component
				key={this.label}
			>
				{typeof this.prop === 'string' ? t[this.prop] : this.prop(t)}
			</Wrapper>
		);
	}
}

export function makeColumns<T>(
	columnOptions: ListColumnCreationOptions<T>[]
): ListColumn<T>[] {
	return columnOptions.map((o) => new SimpleListColumn(o));
}

export type ListColumnCreationOptions<T> = Omit<
	ListColumn<T>,
	'render' | 'renderHeader'
>;
