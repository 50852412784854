// @ts-nocheck
import React from 'react';
import { Router } from '@reach/router';
import WorkflowDashboard from '../components/dashboard.component/Dashboard';
import AssetsIndexView from '../components/dam-assets/assets-index.view';
import AssetDetailsView from '../components/dam-assets/asset-details.view';
import EditDAMAssetView from '../components/dam-assets/edit-dam-asset.view';
import VersionsComparisonView from '../components/dam-assets/versions-comparison.view';
import AssetCollectionsIndexView from '../components/dam-asset-collections/asset-collections-index.view';
import AssetCollectionDetailsView from '../components/dam-asset-collections/asset-collection-details.view';
import AssetCollectionEditView from '../components/dam-asset-collections/asset-collection-edit.view';
import AssetCollectionCreationView from '../components/dam-asset-collections/asset-collection-creation.view';
import { EditTemplatePage } from '../components/workflow/workflow-templates/components/template/EditTemplatePage';
import TemplateEditView from '../components/workflow/workflow-templates/template-edit-view';
import TemplateCreationView from '../components/workflow/workflow-templates/template-creation.view';
import VersionDetailsView from '../components/dam-assets/version-details.view';
import WorkflowDetailsView from '../components/workflow/workflows/workflow-details.view';
import WorkflowCreationView from '../components/workflow/workflows/workflow-creation.view';
import WorkflowCollectionDetailsView from '../components/workflow/workflow-collections/workflow-collection-details.view';
import WorkflowCollectionEditView from '../components/workflow/workflow-collections/workflow-collection-edit.view';
import WorkflowCollectionCreationView from '../components/workflow/workflow-collections/workflow-collection-creation.view';
import ReportsIndexView from '../components/reports/reports-index.view';
import QueriesIndexView from '../utils/queries/queries-index.view';
import AdminDashboardView from '../components/admin-dashboard/admin-dashboard.view';
import UserDetailsView from '../components/admin-dashboard/user-details.view';
import MetadataTemplateDetailsView from '../components/metadata/metadata-templates/metadata-template-details.view';
import MetadataTemplateCreationView from '../components/metadata/metadata-templates/metadata-template-creation.view';
import EditProfile from './views/edit-profile.view';
import NotFound from './views/not-found.view';
import TermsView from '../components/terms-and-conditions/terms.view';
import EditPreferences from './views/EditPreferences';
import AssetCollectionCreationWithAssetView from '../components/dam-asset-collections/add-asset-collection-with-asset.view';
import AssetUploadMetadata from '../components/dam-assets/components/asset-upload-metadata.component';
import { WorkflowTemplateProvider } from 'context/useTemplateContext';
import { WorkflowCollectionContextProvider } from 'components/workflow/workflows/hooks/useWorkflowCollectionStore';
import {
	AllWorkflows,
	MyAssignments,
	Roadblocks,
	WorkflowTemplates,
	Projects,
} from 'components/workflow/workflows/pages';
import FileSharing from 'components/file-sharing/components/FileSharing';
import FileSharingPoint from 'components/file-sharing/components/file-sharing-point';
import StatusReport from 'components/reports/status-report.component';
import { ProxyReview } from 'components/proxies/ProxyReview';
import ViewProfile from 'pages/views/ViewProfile.view';
import { ReportBuilderLanding } from 'components/reports/ReportBuilderLanding';
import { ResourceManagementDashboard } from 'components/resource-management/pages/ResourceManagementDashboard';
import { ReportBuilder } from 'components/reports/pages/ReportBuilder';
import { ReportBuilderInstance } from 'components/reports/pages/ReportBuilderInstance';
import { CompareAssetsView } from 'components/dam-assets/components/CompareAssets/CompareAssetsView';
import { BackOffice } from './BackOffice';

import WorkflowTest from 'components/workflow/workflows/components/WorkflowTest';
import AssetTest from '../components/dam-assets/AssetTest';

const RouterGroup = ({ children }) => <>{children}</>;

export const appRoutes = (
	<Router>
		<WorkflowDashboard path="/" />

		<RouterGroup path="dam">
			<RouterGroup path="assets">
				<CompareAssetsView path="/compare/:assetAId/:assetBId" />
				<AssetUploadMetadata path="/edit-custom-upload" />

				<AssetsIndexView path="/" />
				<AssetTest path="test" />

				<AssetDetailsView path=":assetId" />
				<AssetDetailsView path=":assetId/:isAssetVersion" />
				<EditDAMAssetView path=":assetId/edit" />
				<VersionsComparisonView path="comparison/:workflowAId/:versionAId/:workflowBId/:versionBId" />
			</RouterGroup>

			<RouterGroup path="collections">
				<AssetCollectionsIndexView path="/" />
				<AssetCollectionDetailsView path=":collectionId" />
				<AssetCollectionEditView path=":collectionId/edit" />
				<AssetCollectionCreationView path="new" />
				<AssetCollectionCreationWithAssetView path="new/:assetIds" />
			</RouterGroup>
		</RouterGroup>

		<RouterGroup path="workflow">
			<WorkflowDashboard path="/" />

			<RouterGroup path="templates">
				<WorkflowTemplates path="/" />
				<WorkflowTemplateProvider path=":workflowTemplateId">
					<EditTemplatePage path="/" />
					<EditTemplatePage path="/true" />
				</WorkflowTemplateProvider>
				<TemplateEditView path=":workflowTemplateId/edit" />
				<TemplateCreationView path="new" />
			</RouterGroup>

			<RouterGroup path="workflows">
				<WorkflowCollectionContextProvider path="/">
					<AllWorkflows path="/status/:status" />
					<AllWorkflows path="/" />

					<VersionDetailsView path=":workflowId/asset-versions/:versionId" />
					<WorkflowDetailsView path=":workflowId/*" />
					<WorkflowCreationView path="new" />
					<WorkflowTest path="test" />
				</WorkflowCollectionContextProvider>
			</RouterGroup>

			<RouterGroup path="projects">
				<WorkflowCollectionContextProvider path="/">
					<Projects path="/" />
					<WorkflowCollectionDetailsView path=":projectId" />
					<WorkflowCollectionEditView path=":projectId/edit" />
					<WorkflowCollectionCreationView path="new" />
				</WorkflowCollectionContextProvider>
			</RouterGroup>

			<RouterGroup path="assignments">
				<MyAssignments path="/" />
			</RouterGroup>

			<RouterGroup path="roadblocks">
				<Roadblocks path="/" />
			</RouterGroup>
		</RouterGroup>

		<RouterGroup path="reports">
			<ReportsIndexView path="/" />
			<StatusReport
				renderReport={() => {
					console.log('Rendering ');
				}}
				path="/:title/:id"
			/>
		</RouterGroup>

		<RouterGroup path="saved-queries">
			<QueriesIndexView path="/" />
		</RouterGroup>

		<RouterGroup path="proxies">
			<ProxyReview path="/:proxyId" />
		</RouterGroup>

		<RouterGroup path="dashboard">
			<AdminDashboardView path="/*" />
			<UserDetailsView path="/users/:userId" />
			<MetadataTemplateDetailsView path="/metadata-templates/:metadataTemplateId" />
			<MetadataTemplateCreationView path="/metadata-templates/new" />
			<BackOffice path="/back-office" />
		</RouterGroup>

		<RouterGroup path="terms">
			<TermsView path="/" />
		</RouterGroup>

		<EditPreferences path="preferences" />

		<RouterGroup path="profile">
			<ViewProfile path="/:userId" />
			<EditProfile path="/" />
			<EditProfile path="/" />
		</RouterGroup>
		<RouterGroup path="filesharing">
			<FileSharing path="/" />
			<FileSharing path="/:view/:recipient_email" />
			<FileSharingPoint path="/:fileShareId/:recipient_id" />
			<FileSharingPoint path="/:fileShareId/:recipient_id?:doNotAudit" />
		</RouterGroup>

		<RouterGroup path="report-builder">
			<ReportBuilder path="/" />
			<ReportBuilderLanding path="/edit/:reportId" />
			<ReportBuilderInstance path="/:reportId" />
			<ReportBuilderLanding path="/create" />
		</RouterGroup>
		<RouterGroup path="resource-management">
			<ResourceManagementDashboard path="/" />
		</RouterGroup>
		<NotFound default />
	</Router>
);
