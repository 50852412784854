import styled from 'styled-components';
import { OutlineButton } from '../buttons.styled-components';

export const StyledListItem = styled.li`
	display: block;
	width: 100%;
	padding: 0.25rem 0.75rem;
	white-space: nowrap;
	font-size: 14px;
	text-rendering: optimizeLegibility;
`;

export const StyledOutlineButton = styled(OutlineButton)`
	margin-left: 1em;
	padding: 6px 10px;
	line-height: 1;
`;
