import { throwErr } from './fn.utils';

export function parseValidInt(numStr = '', radix = 10): number {
	const int = parseInt(numStr, radix);
	return isNaN(int) ? throwErr(`Invalid number "${numStr}"`) : int;
}

export function percent(amount: number, total: number) {
	return Math.round(100 * (amount / total));
}

export function average(a: number, b: number) {
	return (a + b) / 2;
}

export function topQuartile(a: number, b: number) {
	return average(average(a, b), Math.min(a, b));
}

export function bottomQuartile(a: number, b: number) {
	return average(average(a, b), Math.max(a, b));
}

export function getRandomInt(max: number): number {
	return Math.floor(Math.random() * Math.floor(max));
}
