import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadResult, UppyFile } from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { DragDrop } from '@uppy/react';
import { authProvider } from 'utils/core';
import { FileUploadProvider } from 'utils/core/FileUploadProvider';

import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import {
	generateID,
	noop,
	requiredValue,
	sanitizeForDOMElementId,
} from '../../../utils/common';
import { _logError } from '../../../utils/common/log';
import { AssetVersion } from '../../workflow/workflows/types/workflow.types';
import AssetCard from './asset-card.component';
import {
	DeleteAssetButton,
	DisabledMask,
	DragDropContainer,
} from './asset-picker-styled.components';

interface AssetPickerProps {
	onSelect?: (result: UploadResult) => void;
}

function assetFromUppyFile(file: UppyFile): AssetVersion {
	return {
		_id: '$temp_' + generateID(),

		fileName: file.name,

		type: file.extension,

		fileSizeBytes: file.size,
		path: '',
	} as AssetVersion;
}
const uploadProvider = new FileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);
const AssetPicker = ({ onSelect = noop }: AssetPickerProps) => {
	const { uppy } = uploadProvider;
	const files = uppy.getFiles();
	const [numFiles, setNumFiles] = useState(files.length);

	useEffect(() => {
		uppy.on('complete', onSelect);
		return () => {
			uppy.off('complete', onSelect);
			uppy.reset();
		};
	}, [uppy, onSelect]);

	/* eslint-disable */
	// clear only works when given
	// an empty dependency array
	const clearInputSlot = useCallback((uf: UppyFile) => {
		try {
			uppy.removeFile(uf.id);
			setNumFiles(files.length);
		} catch (error) {
			_logError(error);
		}
	}, []);
	/* eslint-enable */

	const renderAssetCard = () => {
		const buildButtonId = (fileId: string) => {
			return `deleteAsset${sanitizeForDOMElementId(fileId)}`;
		};

		return (
			<Row>
				{files.map((uf: UppyFile) => (
					<Col key={uf.id} className="mb-3">
						<DisabledMask>
							<AssetCard assetVersion={assetFromUppyFile(uf)} />
						</DisabledMask>
						<UncontrolledTooltip target={buildButtonId(uf.id)}>
							Clear this input slot
						</UncontrolledTooltip>
						<DeleteAssetButton
							type="button"
							id={buildButtonId(uf.id)}
							onClick={() => clearInputSlot(uf)}
						>
							<FontAwesomeIcon icon={faTimes} size="sm" />
						</DeleteAssetButton>
					</Col>
				))}
			</Row>
		);
	};

	const renderDropArea = () => (
		<DragDropContainer>
			<DragDrop
				uppy={uppy}
				locale={{
					strings: {
						dropHereOr: 'Drop here or %{browse}',
						browse: 'browse',
					},
				}}
			/>
		</DragDropContainer>
	);

	const render = () => {
		// files.length is the source of truth,
		// but its value changing doesnt trigger renders
		if (files.length !== numFiles) {
			setNumFiles(files.length);
		}

		return numFiles ? renderAssetCard() : renderDropArea();
	};

	return render();
};

export default AssetPicker;
