import React, { PropsWithChildren, useCallback } from 'react';
import { Button, ModalBody, ModalFooter } from 'reactstrap';

import { useModalCloser } from '../../utils/ModalStack';
import DialogModal from './dialog-modal.component';
import { isThenable } from '../../utils/common';
import RenderWhen from '../render-when.component';

interface ConfirmationDialogProps {
	header: string;
	onConfirm: () => void | Promise<void>;
	shouldWait?: boolean;
	cancelText?: string;
	onCancel?: () => void | Promise<void>;
	confirmText?: string;
	size?: 'sm' | 'md' | 'lg';
	isDisabled?: boolean;
}

const ConfirmationDialog = (
	props: PropsWithChildren<ConfirmationDialogProps>
) => {
	const modalStack = useModalCloser();
	const {
		children,
		header,
		onConfirm,
		shouldWait,
		cancelText,
		onCancel,
		confirmText,
		isDisabled,
	} = props;

	const confirm = useCallback(() => {
		const result = onConfirm();
		if (shouldWait && isThenable(result)) {
			result.then(() => modalStack.closeModal());
		} else {
			modalStack.closeModal();
		}
	}, [modalStack, onConfirm, shouldWait]);
	const cancel = useCallback(() => {
		if (onCancel) {
			onCancel();
			modalStack.closeModal();
		} else {
			modalStack.closeModal();
		}
	}, [modalStack, onCancel]);
	const render = () => (
		<DialogModal header={header} onClosed={props.onCancel}>
			<ModalBody>{children}</ModalBody>
			<ModalFooter>
				<RenderWhen when={!confirmText}>
					<Button
						style={{ cursor: isDisabled ? 'not-allowed' : 'normal' }}
						disabled={isDisabled}
						onClick={confirm}
						color="danger"
						size="sm"
					>
						Yes
					</Button>
				</RenderWhen>
				<RenderWhen when={!!confirmText}>
					<Button
						style={{ cursor: isDisabled ? 'not-allowed' : 'normal' }}
						disabled={isDisabled}
						onClick={confirm}
						color="danger"
						size="sm"
					>
						{confirmText}
					</Button>
				</RenderWhen>
				<RenderWhen when={!cancelText}>
					<Button size="sm" onClick={cancel}>
						Cancel
					</Button>
				</RenderWhen>
				<RenderWhen when={!!cancelText}>
					<Button size="sm" onClick={cancel}>
						{props.cancelText}
					</Button>
				</RenderWhen>
			</ModalFooter>
		</DialogModal>
	);

	return render();
};

export default ConfirmationDialog;
