import { ModalBody } from 'reactstrap';
import React from 'react';

import DialogModal from '../../../modals/dialog-modal.component';
import ShareDialogBody from './share-dialog-body.component';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { AssetVersion } from '../../../workflow/workflows/types/workflow.types';
import { useAssetHelper } from '../helpers/useAssetHelper';
import AsyncDispatcher from 'components/async-dispatcher.component';

const assetTerm = themeStore._.asset;

interface Props {
	assets: (AssetVersion | string)[];
	url?: string;
}
const renderErrorState = () => (
	<>
		<p>
			We're currently unable to obtain a link which can be used to share this{' '}
			{assetTerm?.toLowerCase()}.
		</p>
		<p>
			Please try again later, or contact a system administrator if the issue
			persists.
		</p>
	</>
);

const ShareMultipleAssetsDialog = (props: Props) => {
	const { assets } = props;
	const { zipMultiple } = useAssetHelper();
	const payload = assets.map((a: AssetVersion | string) =>
		(a as AssetVersion)?._id ? (a as AssetVersion)?._id : a
	);
	return (
		<DialogModal header={`Share these ${assetTerm}s`}>
			<ModalBody>
				<AsyncDispatcher loader={() => zipMultiple(payload)}>
					{{
						success: (result: { url: string }) => (
							<ShareDialogBody
								assets={assets as AssetVersion[]}
								link={result.url}
							/>
						),
						error: renderErrorState,
					}}
				</AsyncDispatcher>
			</ModalBody>
		</DialogModal>
	);
};

export default ShareMultipleAssetsDialog;
