import React, { useEffect } from 'react';
import * as d3 from 'd3';
import './DonutStyles.scss';
type DonutProps = {
	data: Array<{ label: string; value: number }>;
};
export const DonutChart = (props: DonutProps) => {
	const { data: seedData } = props;

	// Wrap function to handle labels with longer text
	// function wrap(text: any, width: any) {
	// 	text.each(function (textElm: unknown) {
	// 		var text = d3.select('svg text'),
	// 			words = text.text().split(/\s+/).reverse(),
	// 			word,
	// 			line: any[] = [],
	// 			lineNumber = 0,
	// 			lineHeight = 1.1, // ems
	// 			y = text.attr('y'),
	// 			dy = parseFloat(text.attr('dy')),
	// 			tspan = text
	// 				.text(null)
	// 				.append('tspan')
	// 				.attr('x', 0)
	// 				.attr('y', y)
	// 				.attr('dy', dy + 'em') as any;
	// 		console.log('tspan: ' + tspan);
	// 		while ((word = words.pop())) {
	// 			line.push(word);
	// 			if (tspan) {
	// 				const length = tspan?.text(line!.join(' ')).length;
	// 				if (length > 90) {
	// 					line.pop();
	// 					tspan.text(line.join(' '));
	// 					line = [word];
	// 					tspan = text
	// 						.append('tspan')
	// 						.attr('x', 0)
	// 						.attr('y', y)
	// 						.attr('dy', ++lineNumber * lineHeight + dy + 'em')
	// 						.text(word);
	// 				}
	// 			}
	// 		}
	// 	});
	// }
	const drawDonut = React.useCallback(() => {
		if (d3.select('#donut svg')) document.querySelector('#donut svg')?.remove();
		// Define size & radius of donut pie chart
		const width = 280,
			height = 280,
			radius = Math.min(width, height) / 2;

		// Define arc colours
		const colour = d3.scaleOrdinal(d3.schemeCategory10);

		// Determine size of arcs
		const arc = d3
			.arc()
			.innerRadius(radius - 130)
			.outerRadius(radius - 10);

		// Create the donut pie chart layout
		const pie = d3
			.pie()

			.value(function (d) {
				return (d as any)['value'];
			})
			.sort(null);

		// Append SVG attributes and append g to the SVG
		const svg = d3
			.select('#donut')
			.append('svg')
			.attr('width', width)
			.attr('height', height)
			.append('g')
			.attr('transform', 'translate(' + radius + ',' + radius + ')');

		// Define inner circle
		svg
			.append('circle')
			.attr('cx', 0)
			.attr('cy', 0)
			.attr('r', 100)
			.attr('fill', '#fff');

		// Calculate SVG paths and fill in the colours
		const g = svg
			.selectAll('.arc')
			.data(pie(seedData as any))
			.enter()
			.append('g')
			.attr('class', 'arc')

			// Make each arc clickable
			.on('click', function (d, i) {});

		// Append the path to each g
		g.append('path')
			.attr('d', arc as any)
			.attr('fill', function (d, i) {
				return colour(i.toString());
			});

		// Append text labels to each arc
		g.append('text')
			.attr('transform', function (d) {
				return 'translate(' + arc.centroid(d as any) + ')';
			})
			.attr('dy', '.35em')
			.style('text-anchor', 'middle')
			.attr('fill', '#fff')
			.text(function (d, i) {
				return `${seedData[i].label}: ${seedData[i].value}`;
			});
		//eslint-disable-next-line
	}, [seedData]);

	useEffect(() => {
		window.addEventListener('resize', drawDonut);

		drawDonut();
		return () => {
			window.removeEventListener('resize', drawDonut);
		};
	}, [seedData, drawDonut]);

	return (
		<div
			id="donut"
			className="d-flex justify-content-center align-items-center"
		></div>
	);
};
