import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { RomeSwal } from 'components/alert';
import { SecondaryButton } from 'components/buttons.styled-components';
import { NotificationsContext } from 'components/notifications';
import {
	InputSlot,
	Stage,
	WorkflowTemplate,
} from 'components/workflow/workflows/types';
import { useTemplateContext } from 'context/useTemplateContext';
import { flattenDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { CardBody, Col, Container, Row } from 'reactstrap';
import { generateID } from 'utils/common';
import {
	InputSlotCard,
	InputSlotImage,
} from '../template-stage-edit-slots.styled-components';
import { AttachmentSlotForm } from './AttachmentSlotForm';
import { AttachmentSlotContainer } from './template-stage-card.styled-components';

type Props = {
	stage: Stage;
};

export const EditAttachmentSlots = ({ stage }: Props) => {
	const { template, saveTemplate } = useTemplateContext();
	const { info } = useContext(NotificationsContext);
	const [attachmentToEdit, setAttachmentToEdit] = useState<InputSlot>();
	const [addingSlot, setAddingSlot] = useState(false);

	const deleteSlot = async (inputSlot: InputSlot) => {
		if (!stage) return;
		if (!stage.inputSlots) return;

		const updatedStage = {
			...stage,
			inputSlots: stage.inputSlots.filter((slot) => slot._id !== inputSlot._id),
		};

		const updatedTemplate = {
			...(template as WorkflowTemplate),
			stages: template?.stages.map((stage) => {
				if (stage._id === updatedStage?._id) return updatedStage;

				if (
					stage?.substages &&
					stage.substages?.length &&
					flattenDeep(stage?.substages).some(
						(substage) => substage._id === updatedStage?._id
					)
				) {
					return {
						...stage,
						substages: stage.substages.map((substage) => {
							return substage.map((substageGroup) =>
								substageGroup._id === updatedStage?._id
									? updatedStage
									: substageGroup
							);
						}),
					};
				}

				return stage;
			}) as Stage[],
			sideTasks: template?.sideTasks?.map((sT) => {
				return sT._id === stage._id ? updatedStage : sT;
			}) as Stage[],
		};
		await saveTemplate(updatedTemplate);
		info('Attachment Slot Deleted');
	};

	const editAttachmentSlot = async (attachmentSlot: InputSlot) => {
		if (!attachmentSlot.label) return;

		const updatedStage = {
			...stage,
			inputSlots: stage.inputSlots
				? [
						...stage.inputSlots.filter(
							(slot) => slot._id !== attachmentSlot._id
						),
						attachmentSlot,
				  ]
				: [attachmentSlot],
		};

		const updatedTemplate = {
			...(template as WorkflowTemplate),
			stages: template?.stages.map((stage) => {
				if (stage._id === updatedStage?._id) return updatedStage;

				if (
					stage?.substages &&
					stage.substages?.length &&
					flattenDeep(stage?.substages).some(
						(substage) => substage._id === updatedStage?._id
					)
				) {
					return {
						...stage,
						substages: stage.substages.map((substage) => {
							return substage.map((substageGroup) =>
								substageGroup._id === updatedStage?._id
									? updatedStage
									: substageGroup
							);
						}),
					};
				}

				return stage;
			}) as Stage[],
			sideTasks: template?.sideTasks?.map((sT) => {
				return sT._id === stage._id ? updatedStage : sT;
			}) as Stage[],
		};
		await saveTemplate(updatedTemplate);
		info('Attachment slot updated');
		setAttachmentToEdit(undefined);
	};

	const addAttachmentSlot = async (attachmentSlot: InputSlot) => {
		if (!attachmentSlot.label) return;

		setAddingSlot(false);
		const freshSlot = { ...attachmentSlot, _id: generateID() };

		const updatedStage = {
			...stage,
			inputSlots: stage.inputSlots
				? [...stage.inputSlots, freshSlot]
				: [freshSlot],
		};

		const updatedTemplate = {
			...(template as WorkflowTemplate),
			stages: template?.stages.map((stage) => {
				if (stage._id === updatedStage?._id) return updatedStage;

				if (
					stage?.substages &&
					stage.substages?.length &&
					flattenDeep(stage?.substages).some(
						(substage) => substage._id === updatedStage?._id
					)
				) {
					return {
						...stage,
						substages: stage.substages.map((substage) => {
							return substage.map((substageGroup) =>
								substageGroup._id === updatedStage?._id
									? updatedStage
									: substageGroup
							);
						}),
					};
				}

				return stage;
			}) as Stage[],
			sideTasks: template?.sideTasks?.map((sT) => {
				return sT._id === stage._id ? updatedStage : sT;
			}) as Stage[],
		};
		await saveTemplate(updatedTemplate);
		info('Attachment slot added');
	};

	if (!stage.inputSlots) return null;
	return (
		<AttachmentSlotContainer>
			{!addingSlot && (
				<>
					<Row>
						{/* <h2>Current Attachment Slots</h2> */}
						{stage.inputSlots.map((inputSlot) => (
							<Col xs="3">
								<InputSlotCard key={inputSlot._id} className="pl-2 pr-2 pt-1">
									<p> {inputSlot.label} </p>
									<InputSlotImage>
										<FontAwesomeIcon icon={faFileUpload} />
									</InputSlotImage>
									<CardBody className="d-flex justify-content-between p-0">
										<IconButton
											color="primary"
											onClick={() => setAttachmentToEdit(inputSlot)}
										>
											<EditIcon />
										</IconButton>

										<IconButton
											color="error"
											title="Delete input slot"
											aria-label="Delete input slot"
											onClick={async () => {
												const { isConfirmed } = await RomeSwal.fire({
													icon: 'question',
													title: 'Are you sure?',
													text: 'Deleting the input slot cannot be reversed.',
													confirmButtonText: 'Delete',
													showCancelButton: true,
													cancelButtonText: 'Cancel',
												});
												if (isConfirmed) await deleteSlot(inputSlot);
											}}
										>
											<DeleteForeverIcon />
										</IconButton>
									</CardBody>
								</InputSlotCard>
							</Col>
						))}
					</Row>
					<Row>
						<Col>
							<SecondaryButton
								style={{ maxWidth: '200px' }}
								onClick={() => setAddingSlot(true)}
							>
								Add New
							</SecondaryButton>
						</Col>
					</Row>
					<Row>
						<Col>
							{attachmentToEdit && (
								<div className="edit-input-slot">
									{/* <h2>Edit Attachment</h2> */}
									<AttachmentSlotForm
										onCancel={() => setAttachmentToEdit(undefined)}
										attachmentSlot={attachmentToEdit}
										onSubmit={editAttachmentSlot}
									/>
								</div>
							)}
						</Col>
					</Row>
				</>
			)}

			{addingSlot && (
				<Container>
					<h2>Add Attachment</h2>
					<Row>
						<AttachmentSlotForm
							onCancel={() => setAddingSlot(false)}
							onSubmit={addAttachmentSlot}
						/>
					</Row>
				</Container>
			)}
		</AttachmentSlotContainer>
	);
};
