import React, { FormEvent, useState } from 'react';
import { navigate } from '@reach/router';
import { _logError } from '../../../../utils/common/log';
import { useAxios } from '../../../../hooks';

import { NotificationsContext } from '../../../notifications';
import themeStore from '../../../../core-ui/models/ThemeStore';
import { WorkflowCollection } from '../../workflows/types/workflow.types';
import WorkflowCollectionForm from './workflow-collection-form.component';
import { useWorkflowCollectionContext } from 'components/workflow/workflows/hooks/useWorkflowCollectionStore';

// constants
const collectionTerm = themeStore._.workflowCollection;

//interface
interface EditWorkflowCollectionFormProps {
	collection: WorkflowCollection;
}

// component
const EditWorkflowCollectionForm = (props: EditWorkflowCollectionFormProps) => {
	const { collection } = props;
	const { updateContext } = useWorkflowCollectionContext();
	const { info, error: showError } = React.useContext(NotificationsContext);

	const [editedCollection, setEditedCollection] = useState(collection);
	const collectionStore = useAxios<WorkflowCollection>('projects');
	const onSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			const updatedCollection = (await collectionStore.updateOne(
				collection._id,
				editedCollection
			)) as WorkflowCollection;
			updateContext(updatedCollection);
			setEditedCollection(updatedCollection as WorkflowCollection);
			info(`${collectionTerm} saved!`);

			return navigate(`/admin/workflow/projects/${updatedCollection._id}`);
		} catch (error) {
			_logError(error);

			showError(
				`An issue occurred while updating ${collectionTerm.toLowerCase()}. Please try again later.`
			);
		}
	};

	return (
		<WorkflowCollectionForm
			onChange={(updated) => setEditedCollection(updated)}
			selectedCollection={collection}
			onSubmit={onSubmit}
		/>
	);
};

export default EditWorkflowCollectionForm;
