import { useThemeContext } from 'context/useThemeContext';
import React from 'react';
import { useDownshift } from '../../context/downshift-options.context';
import {
	ItemOption,
	ItemOptionList,
} from './downshift-select.styled-components';
import { DownshiftBaseProps, InDownshift } from './downshift.interfaces';

interface DownshiftOptionItemProps<Option extends Displayable>
	extends InDownshift<any, any> {
	itemOption: Option;
	index: number;
}

const DownshiftOptionListItem = <Option extends Displayable>(
	props: DownshiftOptionItemProps<Option>
) => {
	const { itemOption, index, downshift } = props;
	const { defaults } = useThemeContext();
	const { getItemProps, highlightedIndex } = downshift;

	return (
		<ItemOption
			tabindex="0"
			{...getItemProps({
				key: `${Math.floor(100 * Math.random())}`,
				index,
				item: itemOption,
				style: {
					backgroundColor:
						highlightedIndex === index
							? defaults?.primaryHighlighted
							: 'var(--white)',
				},
			})}
		>
			{downshift.itemToString(itemOption)}
		</ItemOption>
	);
};

interface DownshiftOptionsListProps<Option extends Displayable>
	extends Pick<DownshiftBaseProps<any, Option>, 'renderOption'> {}

export const DownshiftOptionsList = <
	Item extends Displayable,
	Option extends Displayable
>(
	props: DownshiftOptionsListProps<Option>
) => {
	const { downshift, options, searchPredicate } = useDownshift<Item, Option>();
	const { getMenuProps, isOpen } = downshift;

	const list =
		options?.filter((item) => searchPredicate(downshift.inputValue, item)) ||
		[];

	return isOpen ? (
		<ItemOptionList {...getMenuProps()}>
			{list && list.length > 0 ? (
				list.map((item, index) => (
					<DownshiftOptionListItem
						downshift={downshift}
						key={item && (item as any)['_id'] ? (item as any)._id : index}
						itemOption={item}
						index={index}
						{...props}
					/>
				))
			) : (
				<ItemOption>
					<i>No options</i>
				</ItemOption>
			)}
		</ItemOptionList>
	) : null;
};
