import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { css } from 'styled-components';
import { focus } from '../ui/ui';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const anchor = css`
	// display: inline-block;
	// background-color: transparent;
	// border-radius: 0;
	// border: none;
	// color: ${defaults?.secondary};
	// cursor: pointer;
	// margin: 0;
	// padding: 0;
	// text-decoration: none;

	&:hover {
		// background-color: transparent;
		// color: ${defaults?.secondaryHighlighted};
		// text-decoration: underline;
	}

	&:focus {
		${focus};
	}
`;

export const underlinedAnchor = css`
	${anchor};
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
`;

export default anchor;
