import React, { useEffect, useState } from 'react';
import { UseFormMethods, ValidationRule } from 'react-hook-form';
import { animated, useSpring } from 'react-spring';
import { InputError } from './InputError';
import { InputTypes } from './types';
export declare type ValidatorOptions = Partial<{
	required: any | ValidationRule<boolean>;
	min: ValidationRule<number | string>;
	max: ValidationRule<number | string>;
	maxLength: ValidationRule<number | string>;
	minLength: ValidationRule<number | string>;
	pattern: ValidationRule<RegExp>;
	validate: any | Record<string, any>;
	valueAsNumber: boolean;
	valueAsDate: boolean;
	setValueAs: (value: any) => any;
}>;

type InputProps = {
	form: UseFormMethods<Record<string, any>>;
	label: string;
	name: string;
	type?: InputTypes;
	autoFocus?: boolean;
	autoComplete?: boolean;
	defaultValue?: string;
	className?: string;
	validators: ValidatorOptions;
} & React.HTMLProps<HTMLInputElement>;

type Props = InputProps;

/**
 * Input Component That allows for easy, but complex validation
 *
 * @example
 * const exampleForm = () => {
 *  const form = useForm()
 *  const onSubmit =
 *
 *  return (
 *    <form onSubmit={form.handleSubmit(onSubmit)}>
 *      // required field
 *      <Input form={form} name="Required Field" required />
 *      // number field with min/max validation
 *      <Input form={form} type="number" name="Number Field" min={3} max={5} />
 *      // text field with length validation
 *      <Input form={form} name="Text Field" minLength={3} maxLength={5} />
 *      // text area field
 *      <Input form={form} name="Text Field" type="textarea" />
 *      // field with custom validation
 *      <Input form={form} name="Custom Validation"
 *        validate={{
 *           the key is the message shown if the current value of the field does not pass the predicate
 *          'Greater Than 3': currFieldValue => currFieldValue > 3,
 *          'Must Equal Test': currFieldValue => currFieldValue === 'test',
 *        }}
 *       />
 *      // You can input custom messages instead of the default for base validation
 *      <Input form={form} name="Custom Messages"
 *        pattern={{ value: /[hello|goodbye]/}, message: 'must be hello or goodbye' }}
 *        required={{ value: true, message: 'NEED VALUE PLZ' }}
 *       />
 *    </form>
 *  )
 * }
 */
export const Input = ({
	form,
	label,
	name,
	autoComplete,
	autoFocus,
	type,
	...props
}: Props) => {
	const [labelShowing, setLabelShowing] = useState(false);
	const labelAnimation = useSpring({ opacity: !labelShowing ? 0 : 1 });
	const { register, watch, errors } = form;

	useEffect(() => {
		if (watch(name).length === 0) {
			setLabelShowing(false);
			return;
		} else {
			setLabelShowing(true);
		}
	}, [watch, name]);

	const { validators } = props;

	// prettier-ignore
	const { min, max, minLength, maxLength, required, pattern, validate } = validators

	const inputProps = {
		id: label,
		ref: register({
			...(min ? { min } : {}),
			...(max ? { max } : {}),
			...(maxLength ? { maxLength } : {}),
			...(minLength ? { minLength } : {}),
			...(required ? { required } : {}),
			...(pattern ? { pattern } : {}),
			...(validate ? { validate } : {}),
		}),
		name: name,
		placeholder: `${validators.required ? '* ' : ''} ${label}`,
		autoFocus: autoFocus,
		autoComplete: autoComplete ? 'on' : 'off',
		defaultValue: props.defaultValue,
	};

	return (
		<div className={props.className}>
			<animated.label htmlFor={label} style={labelAnimation}>
				{/* <span className="required-icon">{validators.required && '*'}</span> */}
				{label}
			</animated.label>

			{type === 'textarea' ? (
				<textarea {...inputProps} />
			) : (
				<input
					className="input cy-input"
					{...inputProps}
					type={type ? type : 'text'}
				/>
			)}

			<InputError
				className="error"
				fieldValue={watch(name)}
				error={errors[name]}
				validators={validators}
			/>
		</div>
	);
};
