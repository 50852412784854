import { UploadResult } from '@uppy/core';
import React, { FormEvent, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import { flattenStages } from 'components/workflow/workflows/helpers';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { noop } from '../../../utils/common/fn.utils';
import { useStateFromEvent } from '../../../utils/common/hooks';
import { _logError } from '../../../utils/common/log';
import { StyledInput } from '../../forms/forms.styled-components';
import { SubmitButton } from '../../forms/submit-button.component';
import TagInput from '../../tag-input/TagInput';
import { resultToFile } from '../../../utils/core/FileUploadProvider';
import { NotificationsContext } from '../../notifications';
import themeStore from '../../../core-ui/models/ThemeStore';
import WorkflowStageInputSlotSelect from '../../workflow/workflows/components/workflow-stage-details/workflow-stage-input-slot-select.component';
import {
	EntityMetadata,
	Stage,
	Workflow,
} from '../../workflow/workflows/types/workflow.types';
import { SelectedInputSlot } from '../../workflow/workflows/workflow.contexts';
import { SelectedMetadata } from '../asset.contexts';
import AssetMetadataForm from './asset-metadata-form.component';
import AssetPicker from './asset-picker.component';
import { useAssetHelper } from './helpers/useAssetHelper';
import { Maybe } from 'types/globals';

export interface AssetUploadFormProps {
	afterSubmit?: () => void;
	stage: Stage;
	workflow: Workflow;
	slot: any;
	hideMetadata?: boolean;
}

export const AssetUploadForm = (props: AssetUploadFormProps) => {
	const { warn, error: showError } = React.useContext(NotificationsContext);
	const { setWorkflow, setStage } = useWorkflowContext();
	const { afterSubmit = noop } = props;
	const metadata = SelectedMetadata.presentValue;
	const slot = SelectedInputSlot.presentValue;
	const [editedMeta, setEditedMeta] = useState(metadata);
	const { uploadFileForWorkflow: uploadFile } = useAssetHelper();
	const [uppyResult, setResult] = useState<Maybe<UploadResult>>(undefined);
	const [message, setMessage] = useStateFromEvent('');

	const isMetadataReadonly = !!process.env
		.REACT_APP_WORKFLOW_ASSET_METADATA_ISREADONLY;
	const { stage, workflow } = props;

	const label = themeStore._.asset;

	const onFormSubmit = async (event: FormEvent) => {
		event.preventDefault();
		const file = resultToFile(uppyResult);

		if (!file) {
			warn('You must select a file.');
			return;
		}
		if (!slot) {
			warn('You must select a slot.');
			return;
		}

		try {
			const updatedWorkflow = (await uploadFile(
				file,
				{
					message,
					metadata: editedMeta as EntityMetadata,
				},
				workflow as Workflow,
				stage as Stage,
				slot
			)) as Workflow;
			const updatedStage = flattenStages(updatedWorkflow)?.find(
				(m) => m._id === stage._id
			) as Stage;
			setStage(updatedStage);
			setWorkflow(updatedWorkflow);
			afterSubmit();
		} catch (error) {
			_logError(error);
			showError(
				'An issue occurred while attempting to upload your file. Please try again later.'
			);
		}
	};

	return (
		<Form>
			<Row form>
				<Col md={12}>
					{props.stage.inputSlots.length > 1 ? (
						<WorkflowStageInputSlotSelect stage={props.stage} />
					) : (
						<p>
							Uploading {label?.toLowerCase()} to "
							{props.stage?.inputSlots[0].label}" slot
						</p>
					)}
				</Col>
				<Col className="p-2">
					<AssetPicker onSelect={setResult} />
				</Col>

				{/* hide metadata form if flagged */}
				{!props.hideMetadata && (
					<Col md={6} className="p-2">
						<AssetMetadataForm readonly={isMetadataReadonly} />
						<TagInput
							onChange={(updated) => setEditedMeta(updated as EntityMetadata)}
							metadata={editedMeta as EntityMetadata}
						/>
					</Col>
				)}

				<Col md={12}>
					<StyledInput
						className="mt-4"
						type="textarea"
						name="message"
						value={message}
						placeholder={`${label} notes...`}
						onChange={setMessage}
					/>
				</Col>
			</Row>

			<SubmitButton label="Submit" onClick={onFormSubmit} />
		</Form>
	);
};
