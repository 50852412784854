import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { first } from 'lodash';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useModalCreator } from '../../../../../utils/ModalStack';
import { InputSlot, Stage } from '../../types/workflow.types';
import {
	OutlinedCircleButton,
	StyledTooltip,
} from '../action-button.styled-components';
import { StageActionButtonProps } from './index';
import UploadStageAssetDialog, {
	UploadAssetDialogProps,
} from './upload-stage-asset-dialog.component';

export const UploadStageAssetButton = (props: StageActionButtonProps) => {
	const { stage } = props;
	const modalStack = useModalCreator();

	const openAssetUploadModal = useCallback(
		({ stage, selectedSlot, workflow }: UploadAssetDialogProps) => {
			modalStack.addModal(
				<UploadStageAssetDialog
					workflow={workflow}
					stage={stage}
					selectedSlot={selectedSlot as InputSlot}
				/>
			);
		},
		[modalStack]
	);

	if (R.isEmpty(stage.inputSlots)) return null;
	return (
		<>
			<StyledTooltip target="fileUploadBtn">Upload an asset</StyledTooltip>
			<OutlinedCircleButton
				id="fileUploadBtn"
				className="cy-wf-file-upload-btn"
				color=""
				size="sm"
				onClick={() =>
					openAssetUploadModal({
						stage: (stage as unknown) as Stage,
						selectedSlot: first(stage?.inputSlots),
						workflow: props.workflow,
					})
				}
			>
				<FontAwesomeIcon icon={faPaperclip} />
			</OutlinedCircleButton>
		</>
	);
};
