import { useAxios } from 'hooks';
import React, { useCallback, useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Nullable } from 'types/globals';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { doesNameableMatch } from '../../../../../utils/common';
import { _logError } from '../../../../../utils/common/log';
import DownshiftSingleSelect from '../../../../downshift-select/downshift-single-select.component';
import { SelectionActions } from '../../../../downshift-select/downshift.interfaces';
import { SubmitButton } from '../../../../forms';
import { NotificationsContext } from '../../../../notifications';
import { Workflow, WorkflowCollection } from '../../types/workflow.types';

const collectionTerm = themeStore._.workflowCollection;
const workflowTerm = themeStore._.workflow;
type Props = {
	workflow: Workflow;
};
const AddWorkflowToCollectionForm = (props: Props) => {
	const { workflow } = props;
	const { info, error: showError } = React.useContext(NotificationsContext);
	const [workflowCollections, setWorkflowCollections] = useState<
		WorkflowCollection[]
	>([]);

	const collectionStore = useAxios<WorkflowCollection>('projects');

	const { updateCollection } = useWorkflowContext();
	React.useEffect(
		() => {
			collectionStore.findAll().then(setWorkflowCollections);
		},
		//eslint-disable-next-line
		[]
	);

	const [selectedCollection, setSelectedCollection] = useState<
		Nullable<WorkflowCollection>
	>();

	const onSubmit = useCallback(async () => {
		if (selectedCollection) {
			try {
				await updateCollection(workflow as Workflow, selectedCollection._id);
				info(`Added to ${collectionTerm.toLowerCase()}!`);
			} catch (e) {
				_logError(e);
				showError(
					`An error occurred while adding this ${workflowTerm} to a ${collectionTerm}. Please try again later.`
				);
			}
		}
	}, [workflow, info, showError, updateCollection, selectedCollection]);

	return (
		<Form className="mb-5">
			<FormGroup>
				<DownshiftSingleSelect
					label={`Add to ${collectionTerm}`}
					placeholder="Search by name..."
					selectionState={{
						selection: selectedCollection,
						options: [...(workflowCollections as WorkflowCollection[])],
						searchPredicate: doesNameableMatch,
					}}
					selectionActions={
						{
							select: setSelectedCollection,
						} as SelectionActions<WorkflowCollection, WorkflowCollection>
					}
				/>
			</FormGroup>
			<SubmitButton onClick={onSubmit} label="Add" className="mt-0" />
		</Form>
	);
};

export default AddWorkflowToCollectionForm;
