import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert } from 'reactstrap';

interface Props {
	text: string;
	color: string;
}

const AssetViewerWarning = (props: Props) => (
	<Alert color={props.color}>
		<p className="mb-0">
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				aria-label="warning icon"
				className="mr-2"
			/>
			{props.text}
		</p>
	</Alert>
);

export default AssetViewerWarning;
