import { faList, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { ViewButtonWrapper, ViewButton } from './ToggleCollectionView.styles';

interface Props {
	className?: string;
	currentView: 'cards' | 'list';
	toggle: (viewType: 'cards' | 'list') => void;
	cards?: boolean;
}

export const ToggleCollectionView = ({
	className,
	currentView,
	toggle,
	cards,
}: Props) => {
	const toggleCard = () => toggle('cards');
	const toggleList = () => toggle('list');
	return (
		<ViewButtonWrapper className={'index-view-toggler ' + className || ''}>
			{cards && (
				<>
					<UncontrolledTooltip target="toggleCardView">
						Card View
					</UncontrolledTooltip>
					<ViewButton
						id="toggleCardView"
						className={currentView === 'cards' ? 'active' : ''}
						onClick={toggleCard}
					>
						<FontAwesomeIcon icon={faThLarge} />
					</ViewButton>
				</>
			)}
			<>
				<UncontrolledTooltip target="toggleListView">
					List View
				</UncontrolledTooltip>
				<ViewButton
					id="toggleListView"
					className={currentView === 'list' ? 'active' : ''}
					onClick={toggleList}
				>
					<FontAwesomeIcon icon={faList} />
				</ViewButton>
			</>
		</ViewButtonWrapper>
	);
};
