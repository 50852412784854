import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';

import App from './App';
import errorMonitor from './utils/third-parties/error-monitor.service';
import * as axios from 'axios';
import { navigate } from '@reach/router';
import { saveNode } from 'utils/common';
import { RomeSwal } from 'components/alert';
const serviceWorker = require('./serviceWorker');
errorMonitor.initialize();
function retryFailedRequest(err: any) {
	if (err.status === 500 && err.config && !err.config.__isRetryRequest) {
		err.config.__isRetryRequest = true;
		return axios.default(err.config);
	}
	throw err;
}
axios.default.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error && error.response && error?.response?.status) {
			if (401 === error.response.status) {
				saveNode('rome_auth', {
					accessToken: null,
					expiresAt: -1,
					idToken: null,
					authUser: null,
				});

				// handle error: inform user, go to login, etc
				navigate('/auth', { state: 'Unauthorized' });
			}
		} else {
			RomeSwal.fire({
				icon: 'error',
				title: 'An error occurred',
				html:
					'Sorry, an error occurred trying to process your request. If the error continues, please let us know.',
			});
			retryFailedRequest(error);
			return Promise.reject(error);
		}
	}
);
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
