import React, { ReactElement } from 'react';
import { StageType } from '../../../workflow-templates/models';
import { Stage } from '../../types/workflow.types';
import { CardDetails } from './workflow-stage-card.styled-components';

interface StageCardDetailsProps {
	children?: ReactElement;
	stage?: Stage;
}

const StageCardDetails = (props: StageCardDetailsProps) => {
	const { children, stage } = props;

	if (stage?.type !== StageType.parallel) {
		return <CardDetails>{children}</CardDetails>;
	} else return null;
};

export default StageCardDetails;
