import React, { useCallback, useContext, useState } from 'react';
import { Form, FormGroup, ModalBody } from 'reactstrap';
import { _logError } from '../../../../utils/common/log';
import { SubmitButton } from '../../../forms';
import DialogModal from '../../../modals/dialog-modal.component';

import { NotificationsContext } from '../../../notifications';
import { useModalCloser } from '../../../../utils/ModalStack';
import themeStore from '../../../../core-ui/models/ThemeStore';
import CollectionWorkflowSelect from './collection-workflow-select.component';
import { difference, toArray } from 'lodash';
import {
	Workflow,
	WorkflowCollection,
} from '../../workflows/types/workflow.types';
import { useAxios } from '../../../../hooks';
import { useWorkflowContext } from 'context/useWorkflowStore';

const EditCollectionWorkflowsDialog = ({
	selectedCollection,
	onChange,
}: {
	selectedCollection: WorkflowCollection;
	onChange: (wc: WorkflowCollection) => void;
}) => {
	const modalStack = useModalCloser();
	const { info, error: showError } = useContext(NotificationsContext);
	const collectionStore = useAxios<WorkflowCollection>('projects');
	const { entities: workflows } = useWorkflowContext();
	const wfStore = useAxios<Workflow>('workflows');
	const [editedCollection, setEditedCollection] = useState(selectedCollection);
	const onFormSubmit = useCallback(async () => {
		try {
			const updatedCollection = (await collectionStore.updateOne(
				selectedCollection._id,
				editedCollection
			)) as WorkflowCollection;
			const { workflows: workflowsBefore } = editedCollection;

			setEditedCollection(updatedCollection);
			onChange(updatedCollection);
			const {
				workflows: workflowsAfter,
			} = updatedCollection as WorkflowCollection;

			const beforeIds = toArray(workflowsBefore).map(
				(m: any, idx) => m
			) as string[];
			const afterIds = toArray(workflowsAfter).map((m: any) => m as string);

			const added = difference(beforeIds, afterIds);
			const removed = difference(beforeIds, afterIds);

			added.forEach(async (wid) => {
				const workflow = workflows.find((m: Workflow) => m._id === wid);
				if (workflow) {
					const edited = {
						...workflow,
						workflowCollection: selectedCollection,
					};
					await wfStore.updateOne(edited._id, edited);
				}
			});
			removed.forEach(async (wid) => {
				const workflow = workflows.find((m: Workflow) => m._id === wid);
				if (workflow) {
					const edited = { ...workflow, workflowCollection: undefined };
					await wfStore.updateOne(edited._id, edited);
				}
			});

			modalStack.closeModal();
			info(`${themeStore._.workflowCollection} saved!`);
		} catch (error) {
			_logError(error);
			modalStack.closeModal();

			showError(
				`An issue occurred while saving ${themeStore._.workflowCollection.toLowerCase()}. Please try again later.`
			);
		}
		//eslint-disable-next-line
	}, [
		workflows,
		selectedCollection,
		editedCollection,
		info,
		wfStore,
		collectionStore,
		modalStack,
		showError,
	]);

	return (
		<DialogModal header={`Add ${themeStore._.workflow.toLowerCase()}s`}>
			<ModalBody>
				<Form>
					<FormGroup>
						<CollectionWorkflowSelect
							onChange={(updated) =>
								setEditedCollection({
									...editedCollection,
									workflows: updated.workflows,
								})
							}
							collection={editedCollection}
						/>
					</FormGroup>
					<SubmitButton onClick={onFormSubmit} label="Save" />
				</Form>
			</ModalBody>
		</DialogModal>
	);
};

export default EditCollectionWorkflowsDialog;
