import {
	faCar,
	faChartArea,
	faChartPie,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useThemeContext } from 'context/useThemeContext';
import { PageSubheading } from 'components/index';
import { RMCarousel } from 'components/resource-management/components/RMCarousel';
import LineChart from 'components/resource-management/components/RMLineChart';
import { RMProgressBar } from 'components/resource-management/components/RMProgressBar';
import {
	RMResourcesCard,
	useResourceSettings,
} from 'components/resource-management/components/RMResourcesCard';
import { RMUserList } from 'components/resource-management/components/RMUserList';
import { RMWorkflowHealthBarChart } from 'components/resource-management/components/RMWorkflowHealthBarChart';
import { ResourceSettingsDialog } from 'components/resource-management/dialogs/ResourceSettingsDialog';
import { useWorkflowContext } from 'context/useWorkflowStore';
import { AssetVersion, Workflow } from 'components/workflow/workflows/types';
import { useAxios } from 'hooks';
import { uniqBy } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	Row,
} from 'reactstrap';
import { useAuthContext, useGroupContext } from 'utils/auth';
import { useLocalStorage } from 'utils/core/hooks/usePersistedState';
import { UserGroup } from '../../accounts/types';
import { DonutChart } from '../components/DonutChart';

export const ResourceManagementDashboard = () => {
	const { entities: users } = useAuthContext();
	const { entities: groups } = useGroupContext();
	const { entities: workflows } = useWorkflowContext();
	const [included, setIncluded] = useResourceSettings();

	const { defaults } = useThemeContext();

	const [
		includeGroupsWithResources,
		setIncludeGroupsWithResources,
	] = React.useState(included);
	const [assetCreationData, setAssetCreationData] = React.useState<any[]>([]);
	const [wfCreationData, setWfCreationData] = React.useState<any[]>([]);

	const setInclude = (val: boolean) => {
		setIncludeGroupsWithResources(val);
		setIncluded(val);
		setShowSettings(false);
	};

	const calcs = {
		resourcesTotal: () =>
			includeGroupsWithResources
				? groups?.length + users?.length
				: users?.length,
		resourcesArchived: () =>
			users?.filter((u) => u.status === 'disabled').length,
		resourcesActive: () =>
			includeGroupsWithResources
				? groups?.length + users?.filter((a) => a.status === 'active')?.length
				: users?.filter((a) => a.status === 'active')?.length,
	};

	const [
		assetCreationYearSetting,
		setAssetCreationYearSetting,
	] = useLocalStorage('assetCreationYearSetting', 2021);
	const [assetChartYear, setAssetChartYear] = React.useState(
		assetCreationYearSetting
	);

	const [wfCreationYearSetting, setWfCreationYearSetting] = useLocalStorage(
		'wfCreationYearSetting',
		2021
	);
	const [wfChartYear, setWfChartYear] = React.useState(wfCreationYearSetting);

	const [assets, setAssets] = React.useState<AssetVersion[]>();
	const loader = useAxios<AssetVersion>('assets');
	const load = async () => {
		return await loader.findAll();
	};
	const groupMemberData = React.useMemo(() => {
		if (groups) {
			const hasMembers = (group: UserGroup) => group?.members?.length;
			return groups.filter(hasMembers).map((a) => ({
				value: a.members.length,
				label: a.title,
			})) as Array<{ label: string; value: number }>;
		}
		return [] as Array<{ label: string; value: number }>;
	}, [groups]);

	const regenerateAssetData = (entities: AssetVersion[]) => {
		const chartData = [];

		for (let i = 0; i < 12; i++) {
			const createdMonth = new Date(assetChartYear, i, 1);
			const assetsWithinMonth = entities.filter(
				(a) =>
					moment(a.createdAt).month() === i &&
					moment(a.createdAt).year() === assetChartYear
			);
			const value = assetsWithinMonth.length;

			chartData.push({
				label: createdMonth.toLocaleDateString(),
				value: value,
				tooltipContent: `${value} Assets`,
			});
		}
		setAssetCreationData(chartData);
	};

	const regenerateWorkflowData = (entities: Workflow[]) => {
		const chartData = [];

		for (let i = 0; i < 12; i++) {
			const createdMonth = new Date(wfChartYear, i, 1);
			const assetsWithinMonth = entities.filter(
				(a) =>
					moment(a.createdAt).month() === i &&
					moment(a.createdAt).year() === wfChartYear
			);
			const value = assetsWithinMonth.length;

			chartData.push({
				label: createdMonth.toLocaleDateString(),
				value: value,
				tooltipContent: `${value} Workflows`,
			});
		}
		setWfCreationData(chartData);
	};

	const [showSettings, setShowSettings] = React.useState(false);
	const carouselItems: Array<any> = uniqBy(
		users
			?.filter((u) => !!u?.expertise || !!u?.title)
			.map((u) => (u?.expertise ? u?.expertise : u?.title))
			.map((title, index) => ({
				title,
				count: users?.filter(
					(a) => (a?.expertise ? a?.expertise : a?.title) === title
				).length,
				active: index === 1,
			})) || [],
		(u) => u.title
	);

	useEffect(() => {
		if (assets) {
			regenerateAssetData(assets);
		}
		//eslint-disable-next-line
	}, [assets, assetChartYear]);

	useEffect(() => {
		if (workflows) {
			regenerateWorkflowData(workflows);
		}
		//eslint-disable-next-line
	}, [workflows, wfChartYear]);

	const yearOptions = React.useMemo(() => {
		if (assets) {
			return uniqBy(assets, (a) =>
				new Date(a.createdAt).getFullYear().toString()
			).map((a) => new Date(a.createdAt).getFullYear().toString());
		}
	}, [assets]);

	const workflowYearOptions = React.useMemo(() => {
		if (workflows) {
			// @ts-ignore
			return uniqBy(
				workflows,
				(a) =>
					// @ts-ignore
					new Date(a.createdAt).getFullYear().toString()
				// @ts-ignore
			).map((a) => new Date(a.createdAt).getFullYear().toString());
		}
	}, [workflows]);

	useEffect(() => {
		load().then(setAssets);
		//eslint-disable-next-line
	}, []);
	return (
		<>
			{showSettings && (
				<ResourceSettingsDialog
					setShowSettings={setShowSettings}
					setInclude={setInclude}
					initial={includeGroupsWithResources}
				/>
			)}
			<Container className="rome-dashboard" fluid>
				<Row>
					<Col xl={9}>
						<Row>
							<Col xl={4}>
								<RMResourcesCard
									onIncludeChange={(newVal) => setShowSettings(newVal)}
									title={'Resources'}
									total={calcs.resourcesTotal()}
									archived={calcs.resourcesArchived()}
									icon={faUser}
									active={calcs.resourcesActive()}
								/>
							</Col>
							<Col xl={4}>
								<RMResourcesCard
									onIncludeChange={(newVal) => setShowSettings(newVal)}
									title={'Workflows'}
									total={workflows?.length}
									archived={
										// @ts-ignore
										workflows?.filter((a) => a.status === 'cancelled')?.length
									}
									icon={faChartArea}
									active={
										// @ts-ignore
										workflows?.filter((a) => a.status === 'active')?.length
									}
								/>
							</Col>
							<Col xl={4}>
								<RMCarousel items={carouselItems} />
							</Col>
						</Row>

						<Row>
							<Col xl={4}>
								<Card className="rome-card">
									<CardHeader>
										<PageSubheading text={'Resource Utilization'} />
										<FontAwesomeIcon
											className="header-icon"
											style={{ color: defaults?.primary }}
											icon={faCar}
										/>
									</CardHeader>
									<CardBody>
										<Container>
											<Row>
												<Col xl={12}>
													<RMProgressBar color={'yellow'} percent={'70'} />
												</Col>
											</Row>
										</Container>
									</CardBody>
									<CardFooter />
								</Card>
								<Card className="rome-card">
									<CardHeader>
										<PageSubheading text={'Active Assets'} />
										<FontAwesomeIcon
											className="header-icon"
											style={{ color: defaults?.primary }}
											icon={faChartArea}
										/>
									</CardHeader>
									<CardBody>
										<Container>
											<Row>
												<Col xl={12}>
													{assets?.length && (
														<RMProgressBar
															color={'blue'}
															percent={
																Math.round(
																	(assets?.filter((a) => !a?.archived)?.length /
																		assets?.length) *
																		100
																)?.toString() as string
															}
														/>
													)}
												</Col>
											</Row>
										</Container>
									</CardBody>
									<CardFooter />
								</Card>
							</Col>
							<Col xl={8}>
								<RMWorkflowHealthBarChart />
							</Col>
						</Row>

						<Row>
							<Col xl={6}>
								<LineChart
									term={'workflow'}
									selectedChartYear={wfChartYear}
									setSelectedChartYear={(year) => {
										setWfChartYear(year);
										setWfCreationYearSetting(year);
									}}
									yearOptions={workflowYearOptions as string[]}
									yLabel={'# Workflows created'}
									xLabel={'Month Created'}
									header={'Workflow Creation Breakdown '}
									width={520}
									height={400}
									data={wfCreationData}
								/>
							</Col>
							<Col xl={6}>
								<LineChart
									term={'asset'}
									selectedChartYear={assetChartYear}
									setSelectedChartYear={(year) => {
										setAssetCreationYearSetting(year);
										setAssetChartYear(year);
									}}
									yearOptions={yearOptions as string[]}
									yLabel={'# Assets created'}
									xLabel={'Month Created'}
									header={'Asset Creation Breakdown '}
									width={520}
									height={400}
									data={assetCreationData}
								/>
							</Col>
						</Row>
					</Col>

					<Col xl={3}>
						<RMUserList />
						<Card className="rome-card">
							<CardHeader>
								<PageSubheading text="User Groups broken down by member count" />
								<FontAwesomeIcon
									className="header-icon"
									style={{ color: defaults?.primary }}
									icon={faChartPie}
								/>
							</CardHeader>
							<CardBody>
								<DonutChart data={groupMemberData} />
							</CardBody>
							<CardFooter></CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};
