import * as axios from 'axios';
import { SiteDefaults, useThemeContext } from 'context/useThemeContext';
import { AssetPreviewUpload } from 'components/dam-assets/components/AssetPreviewUpload';
import { CustomSketchPicker } from 'components/sketch-picker/SketchPicker';
import themeStore from 'core-ui/models/ThemeStore';
import { last, omit, startCase } from 'lodash';
import React, { useState } from 'react';
import { Col, Container, Label, Row } from 'reactstrap';
import styled, { css } from 'styled-components';
import { useAuthContext, useHeaders } from 'utils/auth';
import { ThemePreviewComponent } from './components/ThemePreviewComponent';
export const EditTheme = () => {
	const { defaults, updateDefaults, updateContext } = useThemeContext();
	const [currentSelection, setCurrentSelection] = useState(defaults);

	const primaryStyle = css`
		font-size: 14px;
		display: inline-block;
		-webkit-appearance: none;
		border-color: initial;
		border-image: initial;
		border-radius: 4px;
		border-style: initial;
		border-width: 0px;
		box-sizing: border-box;
		color: #ffffff;
		cursor: pointer;
		line-height: inherit;
		margin: 0px;
		padding: 8px 16px;
		text-align: center;
		-webkit-text-decoration: none;
		text-decoration: none;
		margin: 1.5em 0;
		background: ${defaults.primary};
		&:hover {
			background: ${defaults.primaryHighlighted};
		}
	`;

	const PrimaryButton = styled.button`
		${primaryStyle}
	`;

	const Image = styled.img`
		height: 200px !important;
		width: 200px !important;
	`;

	const onChange = (property: string, updated: { hex: string }) =>
		setCurrentSelection({ ...currentSelection, [property]: updated.hex });

	const onFormSubmit = async (type: string, file: File) => {
		const url = `${process.env.REACT_APP_ROME_API_ENDPOINT}/site-defaults/upload/${type}`;
		console.log('try to save the file to the api');
		const formData = new FormData();
		[file]?.forEach((result) => {
			const file = result;
			formData.append('file', file, file.name);
		});
		const response = await axios.default.post<SiteDefaults>(
			url,
			formData,
			getHeaders()
		);
		updateContext(response?.data);
		return response?.data;
	};
	const saveTheme = async () => {
		let updatedInstance = currentSelection;
		if (desktopFile) {
			const savedDesktopDefaults = await onFormSubmit('desktop', desktopFile);
			updatedInstance = {
				...updatedInstance,
				defaultLogo: savedDesktopDefaults?.defaultLogo,
			};
		}

		if (mobileFile) {
			const savedMobileDefaults = await onFormSubmit('mobile', mobileFile);
			updatedInstance = {
				...updatedInstance,
				mobileLogo: savedMobileDefaults?.mobileLogo,
			};
		}

		if (loginBgFile) {
			const loginSavedDefaults = await onFormSubmit('login-bg', loginBgFile);
			updatedInstance = {
				...updatedInstance,
				loginBackground: loginSavedDefaults?.loginBackground,
			};
		}

		if (loginCardFile) {
			const cardSavedDefaults = await onFormSubmit(
				'loginCardLogo',
				loginCardFile
			);
			updatedInstance = {
				...updatedInstance,
				loginCardLogo: cardSavedDefaults?.loginCardLogo,
			};
		}
		const saveResult = await updateDefaults(updatedInstance);
		themeStore.setSelectedColors(saveResult);
	};

	const clearSelection = () => {
		setCurrentSelection(defaults);
		window.location.hash = `?clear=true`;
	};
	const { getHeaders } = useHeaders();
	const { currentUser } = useAuthContext();
	const [desktopFile, setDesktopFile] = useState<File>();
	const [mobileFile, setMobileFile] = useState<File>();
	const [loginBgFile, setLoginBgFile] = useState<File>();
	const [loginCardFile, setLoginCardFile] = useState<File>();
	const logoSection = () => {
		const type = ['login-bg', 'mobile', 'desktop', 'loginCardLogo'];
		const updateImageByType = (type: string, img: string) => {
			if (type === 'login-bg')
				setCurrentSelection({ ...currentSelection, loginBackground: img });
			if (type === 'mobile')
				setCurrentSelection({ ...currentSelection, mobileLogo: img });
			if (type === 'desktop')
				setCurrentSelection({ ...currentSelection, defaultLogo: img });
			if (type === 'loginCardLogo')
				setCurrentSelection({ ...currentSelection, loginCardLogo: img });
		};

		const getImageProp = (
			type: 'login-bg' | 'mobile' | 'desktop' | 'loginCardLogo'
		) => {
			if (type === 'login-bg')
				return currentSelection?.loginBackground?.includes('blob')
					? currentSelection?.loginBackground
					: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
							currentUser?.account
					  }/${last(currentSelection?.loginBackground?.split('/'))}`;
			if (type === 'mobile')
				return currentSelection?.mobileLogo?.includes('blob')
					? currentSelection?.mobileLogo
					: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
							currentUser?.account
					  }/${last(currentSelection?.mobileLogo?.split('/'))}`;
			if (type === 'desktop')
				return currentSelection?.defaultLogo?.includes('blob')
					? currentSelection?.defaultLogo
					: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
							currentUser?.account
					  }/${last(currentSelection?.defaultLogo?.split('/'))}`;
			if (type === 'loginCardLogo')
				return currentSelection?.loginCardLogo?.includes('blob')
					? currentSelection.loginCardLogo
					: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
							currentUser?.account
					  }/${last(currentSelection?.loginCardLogo?.split('/'))}`;
		};
		return (
			<>
				{type.map((t) => (
					<React.Fragment key={`theme-selection-${t}`}>
						<Col sm={3}>
							<Row>
								<Label style={{ fontSize: 13.75 }} className="mr-2 mt-2">
									{startCase(t)}
								</Label>
								<AssetPreviewUpload
									label={'Upload file'}
									onFileSelected={(file: File) => {
										if (t === 'desktop') setDesktopFile(file);
										if (t === 'mobile') setMobileFile(file);
										if (t === 'login-bg') setLoginBgFile(file);
										if (t === 'loginCardLogo') setLoginCardFile(file);
									}}
									onPreviewGenerated={(preview) =>
										updateImageByType(t, preview)
									}
								/>
							</Row>
							<Row>
								<Image
									className={'img-thumbnail'}
									src={`${getImageProp(
										t as 'login-bg' | 'mobile' | 'desktop' | 'loginCardLogo'
									)}`}
									alt=""
								/>
							</Row>
						</Col>
					</React.Fragment>
				))}
			</>
		);
	};

	if (!defaults) return null;

	return (
		<Container fluid>
			<Row>
				<Col xl={4}>
					{Object.keys(
						omit(currentSelection, [
							'_id',
							'__v',
							'defaultLogo',
							'mobileLogo',
							'loginBackground',
							'loginCardLogo',
							'account',
						])
					).map((key) => (
						<Row key={key}>
							<CustomSketchPicker
								label={key}
								color={(currentSelection as any)[key]}
								onChange={(updated) => onChange(key, updated)}
							/>
						</Row>
					))}
				</Col>
				<Col className="d-none d-sm-none d-md-none d-lg-block" xl={8}>
					<ThemePreviewComponent selectedTheme={currentSelection} />
				</Col>
			</Row>
			<Row className="my-2">{logoSection()}</Row>
			<Row className="justify-content-end">
				<PrimaryButton className="mx-2" onClick={async () => await saveTheme()}>
					Save Theme
				</PrimaryButton>
				<PrimaryButton className="mx-2" onClick={() => clearSelection()}>
					Clear Selection
				</PrimaryButton>
			</Row>
		</Container>
	);
};
