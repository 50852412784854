import { Avatar } from 'components/avatar.component';
import OwnerAvatarList from 'components/owner-avatar-list.component';
import {
	flattenStages,
	getActiveStageNames,
	getProjectName,
} from 'components/workflow/workflows/helpers';
import { useWorkflowCollectionContext } from 'components/workflow/workflows/hooks/useWorkflowCollectionStore';
import { uniqBy } from 'lodash';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { useAuthContext, User } from 'utils/auth';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import WorkflowHealthFlag from '../../../../dashboard.component/components/workflow-health-flag.component';
import {
	CellLink,
	ListColumn,
	ListTable,
	makeColumns,
	ScrollCard,
	WorkflowStatusPill,
} from '../../../../index';
import { Flattenable, Workflow } from '../../types/workflow.types';
import { WorkflowDueDate } from '../WorkflowDueDate';

type Props = {
	workflows?: Workflow[];
	isFetching: boolean;
};

export const WorkflowsTable = ({ workflows, isFetching }: Props) => {
	const { entities: collections } = useWorkflowCollectionContext();
	const { currentUser: current } = useAuthContext();
	const filter = (col: ListColumn<Workflow>) =>
		workflows?.some(shouldShowBackground) ? true : col.label !== 'Proxying For';
	const shouldShowBackground = (workflow: Workflow) =>
		current?.proxyingFor &&
		current?.proxyingFor?.picture &&
		flattenStages(workflow as Flattenable)?.some((stage) =>
			stage?.owners?.some((owner) => owner._id === current?.proxyingFor?._id)
		);
	const getOwnersFromWorkflow = (w: Workflow) => {
		const flat = flattenStages(w, true);
		return uniqBy(
			flat.flatMap((stage) => stage?.owners),
			(a) => a?._id
		);
	};
	const workflowListColumns: ListColumn<Workflow>[] = makeColumns([
		{
			label: 'Proxying For',
			prop: (w: Workflow) => (
				<React.Fragment>
					{shouldShowBackground(w) && (
						<Row>
							<Col sm={12}>
								<figure
									className={
										shouldShowBackground(w)
											? `workflow-table-background-image`
											: ``
									}
									style={{
										verticalAlign: 'super',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{current.proxyingFor && (
										<Avatar user={current?.proxyingFor as User} />
									)}
								</figure>
							</Col>
						</Row>
					)}
				</React.Fragment>
			),
		},
		{
			label: 'Name',
			prop: (w: Workflow) => (
				<React.Fragment>
					<CellLink
						className="workflow-table-name-link"
						to={`/admin/workflow/workflows/${w._id}/stages`}
					>
						{w.title}
					</CellLink>
				</React.Fragment>
			),
		},
		{
			label: `In ${themeStore._.workflowCollection}`,
			prop: (workflow: Workflow) => (
				<p>{collections ? getProjectName(workflow, collections) : '—'}</p>
			),
		},
		{
			label: 'Stakeholder(s)',
			prop: (w: Workflow) => (
				<OwnerAvatarList owners={getOwnersFromWorkflow(w)} />
			),
		},
		{
			label: `Active ${themeStore._.stage.toLowerCase()}(s)`,
			prop: (w: Workflow) => <p>{getActiveStageNames(w)}</p>,
		},
		{
			label: 'Original Target Completion Date',
			prop: (w: Workflow) => <WorkflowDueDate workflow={w} />,
		},
		{
			label: 'Status',
			prop: ({ status }: Workflow) => <WorkflowStatusPill status={status} />,
		},
		{
			label: 'Health',
			prop: (w: Workflow) =>
				w.status === 'active' ? <WorkflowHealthFlag workflow={w} /> : <p>—</p>,
		},
	]).filter(filter);

	return (
		<ScrollCard>
			<ListTable
				columns={workflowListColumns}
				rows={workflows || []}
				noResultsLabel={
					!isFetching && workflows?.length === 0
						? 'No workflows found.'
						: 'Loading workflows...'
				}
			/>
		</ScrollCard>
	);
};
