import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageSubheading } from 'components/index';
import { WorkflowHealthSettingsDialog } from 'components/resource-management/dialogs/WorkflowHealthSettingsDialog';
import {
	isHealthy,
	isOverdue,
	isPipeline,
	isRoadblocked,
} from 'components/workflow/workflows/helpers';
import { useWorkflowContext } from 'context/useWorkflowStore';
// import { WorkflowHealthSettingsDialog } from '../resource-management/dialogs/WorkflowHealthSettingsDialog';
import themeStore from 'core-ui/models/ThemeStore';
import React, { useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { useLocalStorage } from 'utils/core/hooks/usePersistedState';
import { BarChart, ChartRenderer } from './RMBarChart';

export type WorkflowHealthSettings = {
	showHealthy?: boolean;
	showActive?: boolean;
	showOverdue?: boolean;
	showCancelled?: boolean;
	showBlocked?: boolean;
	showPaused?: boolean;
	showPipeline?: boolean;
};

export const RMWorkflowHealthBarChart = () => {
	const { colors } = themeStore.selectedTheme;
	const { entities: workflows } = useWorkflowContext();

	const [workflowHealthSettings, setWorkflowHealthSettings] = useLocalStorage(
		'RMWorkflowHealthSettings',
		{
			showHealthy: true,
			showActive: true,
			showOverdue: true,
			showCancelled: true,
			showPaused: true,
			showBlocked: true,
			showPipeline: true,
		} as WorkflowHealthSettings
	);

	const [settingsState, setSettingsState] = React.useState(
		workflowHealthSettings
	);
	const [settingsChanged, setSettingsChanged] = React.useState(false);
	const [hasRendered, setHasRendered] = React.useState(false);
	const [showSettings, setShowSettings] = React.useState(false);

	const setSettingsCb = (settings: WorkflowHealthSettings) => {
		setSettingsState(settings);
		setWorkflowHealthSettings(settings);
		setSettingsChanged(true);
	};

	const shouldShowSetting = (setting: {
		label: string;
		value: number;
		color: string;
	}) => {
		switch (setting.label) {
			case 'Healthy':
				return settingsState.showHealthy;
			case 'Active':
				return settingsState.showActive;
			case 'Overdue':
				return settingsState.showOverdue;
			case 'Cancelled':
				return settingsState.showCancelled;
			case 'Blocked':
				return settingsState.showBlocked;
			case 'Paused':
				return settingsState.showPaused;
			case 'Pipeline':
				return settingsState.showPipeline;
		}
	};

	useEffect(() => {
		if (
			!hasRendered &&
			document.getElementById('barchart') &&
			!!workflows?.length
		) {
			// Initialize chart
			const data = [
				{
					label: 'Active',
					value:
						// @ts-ignore
						(workflows?.filter((a) => a.status === 'active')?.length /
							workflows?.length) *
						100,
					color: colors?.active,
				},
				{
					label: 'Healthy',
					value:
						(workflows?.filter(isHealthy)?.length / workflows?.length) * 100,
					color: `#92c352`,
				},
				{
					label: 'Overdue',
					value:
						(workflows?.filter(isOverdue)?.length / workflows?.length) * 100,
					color: colors?.overdue,
				},
				{
					label: 'Cancelled',
					value:
						// @ts-ignore
						(workflows?.filter((w) => w.status === 'cancelled')?.length /
							workflows?.length) *
						100,
					color: colors?.danger,
				},
				{
					label: 'Blocked',
					value:
						(workflows?.filter(isRoadblocked)?.length / workflows?.length) *
						100,
					color: colors?.blocked,
				},
				{
					label: 'Paused',
					value:
						// @ts-ignore
						(workflows?.filter((w) => w.status === 'paused')?.length /
							workflows?.length) *
						100,
					color: colors?.blocked,
				},
				{
					label: 'Pipeline',
					value:
						(workflows?.filter(isPipeline)?.length / workflows?.length) * 100,
					color: colors?.pipeline,
				},
			]?.filter(shouldShowSetting);
			const renderer = new ChartRenderer(
				new BarChart(document.getElementById('barchart'), data, '#barchart')
			);
			renderer.setData(data);
			renderer.render();
			setHasRendered(true);
		} else if (settingsChanged) {
			// Reinitialize chart w/ new settings
			const data = [
				{
					label: 'Active',
					value:
						// @ts-ignore
						(workflows?.filter((a) => a.status === 'active')?.length /
							workflows?.length) *
						100,
					color: colors?.active,
				},
				{
					label: 'Healthy',
					value:
						(workflows?.filter(isHealthy)?.length / workflows?.length) * 100,
					color: `#92c352`,
				},
				{
					label: 'Overdue',
					value:
						(workflows?.filter(isOverdue)?.length / workflows?.length) * 100,
					color: colors?.overdue,
				},
				{
					label: 'Cancelled',
					value:
						// @ts-ignore
						(workflows?.filter((w) => w.status === 'cancelled')?.length /
							workflows?.length) *
						100,
					color: colors?.danger,
				},
				{
					label: 'Blocked',
					value:
						(workflows?.filter(isRoadblocked)?.length / workflows?.length) *
						100,
					color: colors?.blocked,
				},
				{
					label: 'Paused',
					value:
						// @ts-ignore
						(workflows?.filter((w) => w.status === 'paused')?.length /
							workflows?.length) *
						100,
					color: colors?.blocked,
				},
				{
					label: 'Pipeline',
					value:
						(workflows?.filter(isPipeline)?.length / workflows?.length) * 100,
					color: colors?.pipeline,
				},
			]?.filter(shouldShowSetting);
			const renderer = new ChartRenderer(
				new BarChart(document.getElementById('barchart'), data, '#barchart')
			);
			renderer.setData(data);
			renderer.render();
			setSettingsChanged(false);
		}
		//eslint-disable-next-line
	}, [workflows, settingsChanged]);

	return (
		<>
			{showSettings && (
				<WorkflowHealthSettingsDialog
					onClosed={() => setShowSettings(false)}
					setSettings={setSettingsCb}
					settings={workflowHealthSettings}
				/>
			)}
			<Card className={'rome-card h-100'}>
				<CardHeader>
					<PageSubheading text="Workflow Health Breakdown" />
					<FontAwesomeIcon icon={faCog} onClick={() => setShowSettings(true)} />
				</CardHeader>
				<CardBody>
					<div id="barchart"></div>
				</CardBody>
				<CardFooter></CardFooter>
			</Card>
		</>
	);
};
