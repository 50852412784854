import { _logError } from './log';

export function findAsObject<T extends object>(key: string): T | null {
	try {
		const itemStr = localStorage.getItem(key);
		if (itemStr) {
			return JSON.parse(itemStr);
		}
	} catch (err) {
		_logError(err);
	}
	return null;
}

export function loadAsObject<T extends object>(key: string): T {
	const maybeObj = findAsObject<T>(key);
	if (maybeObj) {
		return maybeObj;
	} else {
		throw new Error(`Missing item: key "${key}" does not exist`);
	}
}

export function saveNode(key: string, node: any) {
	return saveObject(key, node);
}

export function saveObject(key: string, obj: object): void {
	saveLocally(key, JSON.stringify(obj));
}

export function saveLocally(key: string, thing: any) {
	localStorage.setItem(key, thing);
}
