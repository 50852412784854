import styled, { css } from 'styled-components';
import { fsMedium } from '../../../core-ui/ui/typography';
import { CardBody } from 'reactstrap';
import { Avatar } from '../../avatar.component';
import { Link } from '@reach/router';
import themeStore from '../../../core-ui/models/ThemeStore';
import anchor from '../../../core-ui/components/anchor';
import { fsNormal } from '../../../core-ui/ui/typography';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const HelpBlock = styled.div`
	width: 100%;
	display: block;
	margin-bottom: 5px;
`;

export const Cell = styled.td`
	${fsMedium};
	&& {
		vertical-align: middle;
	}
`;

export const CenteredCell = styled.td`
	text-align: center;
`;

const baseImageProps = css`
	display: inline-block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
`;

export const Image = styled.div`
	${baseImageProps};
	height: 80px;
	width: 80px;
`;

export const PushNotificationImage = styled.div`
	${baseImageProps}
	width: 40px;
	height: 40px;
`;

// @ts-ignore
export const UserAvatar = styled(Avatar)`
	min-height: 5em;
	min-width: 5em;
	margin-bottom: 1.5em;
`;

export const CardBodyFlex = styled(CardBody)`
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
`;

export const PanelHeader = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 1.5em;
	padding-top: 1.5em;
	border-top: 1px solid ${colors.grey};

	@media only screen and (min-width: 768px) {
		flex-direction: row;
		align-items: flex-end;
		border-top: none;
		margin-top: 0;
		padding-top: 0;
	}
`;

export const AdminNavLink = styled(Link)`
	${anchor}
	${fsNormal}
	border-bottom: 1px solid transparent;
	transition: all 0.3s ease-in-out;

	&:hover {
		border-bottom: 1px solid ${defaults?.primaryHighlighted};
		text-decoration: none;
	}

	&.selected {
		border-bottom: 1px solid ${defaults?.primaryHighlighted};
		color: ${defaults?.primaryHighlighted};

		&:hover {
			border-bottom: 1px solid transparent;
		}
	}
`;
