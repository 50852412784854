import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { useAuthContext, User } from 'utils/auth';
import { InteractiveFontAwesomeIcon } from 'components';
import moment from 'moment';
import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import { notification as Notification } from '../typings/notification-center';

const NotifcationWrapper = styled.div<HTMLProps<HTMLDivElement>>`
	display: flex;
	border-radius: 4px;
	cursor: pointer;
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 12px;
	padding-bottom: 12px;

	&.viewed {
		opacity: 0.7;
	}
	:hover {
		background-color: lightgray;
	}
`;

const NotifcationTitle = styled.p`
	font-weight: 600;
`;

const NotificationBody = styled.p``;

const NotificationCard = (props: {
	notification: Notification;
	handleNotificationClick: Function;
	removeNotification: (id: string) => void;
}) => {
	const { notification, handleNotificationClick } = props;
	const { currentUser } = useAuthContext();

	const timeSinceSent = (createdAt: string) => {
		return moment(createdAt).fromNow();
	};

	const hasViewed = (notification: any) => {
		return (
			!!notification &&
			!!notification.viewedBy &&
			notification.viewedBy.some((a: any) =>
				typeof a === 'object'
					? (a as User)._id === currentUser._id
					: a === currentUser._id
			)
		);
	};

	const [showDelete, setShowDelete] = React.useState<{
		id?: string;
		show: boolean;
	}>({ id: undefined, show: false });

	return (
		<>
			<NotifcationWrapper
				onMouseEnter={() => setShowDelete({ id: notification._id, show: true })}
				onMouseLeave={() =>
					setShowDelete((sd) => ({ id: undefined, show: false }))
				}
				className={hasViewed(notification) ? 'viewed' : ''}
				onClick={() => handleNotificationClick(notification)}
			>
				<div
					style={{
						marginRight: '12px',
					}}
				>
					<img
						alt={notification.title}
						src={notification.img}
						style={{
							height: '30px',
							width: '30px',
							borderRadius: '50%',
						}}
					/>
				</div>
				{/* img */}
				<div className="d-flex flex-column notification-card">
					<NotifcationTitle>{notification.title}</NotifcationTitle>
					<NotificationBody>{notification.text}</NotificationBody>
					{timeSinceSent(notification.createdAt)}
					<div
						className="position-absolute"
						style={{
							right: `10%`,
							display: showDelete?.id === notification._id ? 'block' : 'none',
						}}
					>
						<InteractiveFontAwesomeIcon
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								props.removeNotification(notification._id);
							}}
							color="red"
							icon={faTimesCircle}
							tooltip="Remove notification"
							id={'removeNotificationBtn' + notification._id}
						/>
					</div>
				</div>

				{/* content */}
				{/* title */}
				{/* text ellipsied  */}

				{/* time since recieved */}
			</NotifcationWrapper>
		</>
	);
};

export default NotificationCard;
