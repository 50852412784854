const pushServerPublicKey = process.env.REACT_APP_VAPID_KEY as string;
/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
	return 'serviceWorker' in navigator && 'PushManager' in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
	return await Notification.requestPermission();
}
/**
 * shows a notification
 */
function sendNotification({
	text,
	img,
	title,
}: {
	text: string;
	img: string;
	title: string;
}) {
	navigator.serviceWorker.getRegistration().then((reg) => {
		console.log(reg);
		reg?.showNotification(title);
	});
}

function urlBase64ToUint8Array(base64String: string): Uint8Array {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
/**
 *
 */
function registerServiceWorker() {
	return navigator.serviceWorker.register('/sw.js');
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
	if (await getUserSubscription()) return await getUserSubscription();
	//wait for service worker installation to be ready
	const serviceWorker = await navigator.serviceWorker.ready;
	// subscribe and return the subscription
	return await serviceWorker.pushManager.subscribe({
		userVisibleOnly: true,
		applicationServerKey: urlBase64ToUint8Array(pushServerPublicKey as string),
	});
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
	//wait for service worker installation to be ready, and then
	return navigator.serviceWorker.ready
		.then(function (serviceWorker) {
			return serviceWorker.pushManager.getSubscription();
		})
		.then(function (pushSubscription) {
			return pushSubscription;
		});
}

export {
	isPushNotificationSupported,
	askUserPermission,
	registerServiceWorker,
	sendNotification,
	createNotificationSubscription,
	getUserSubscription,
};
