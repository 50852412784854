import React from 'react';
import { SiteDefaults } from 'context/useThemeContext';
import styled, { css } from 'styled-components';
import { last } from 'lodash';
import { Table } from 'reactstrap';

type previewProps = {
	selectedTheme: SiteDefaults;
};

const DesktopPreviewComponent = (props: previewProps) => {
	const { selectedTheme } = props;

	const logo = selectedTheme?.defaultLogo?.includes('blob')
		? selectedTheme?.defaultLogo
		: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
				selectedTheme?.account
		  }/${last(selectedTheme?.defaultLogo.split('/'))}`;

	const PreviewNavBar = styled.div`
		background-color: ${selectedTheme.navbarBackground};
		width: 100%;
		height: 60px;
		padding: 10px;
		display: flex;
	`;

	const PreviewAppLogo = styled.div`
		background-color: green;
		height: 40px;
		width: 70px;
		background: url(${logo}) left center / contain no-repeat;
	`;

	const PreviewNavBarText = styled.p`
		color: ${selectedTheme.navbarForeground};
		margin: 10px 2px 10px 2px;
	`;

	const PreviewMainScreen = styled.div`
		width: 100%;
	`;

	const PreviewTable = styled(Table)`
		width: 50% !important;
	`;

	const PreviewPrimaryTextLink = styled.p`
		cursor: pointer;
		color: ${selectedTheme?.primary};
		text-decoration: underline;

		&:hover {
			text-decoration: none;
			color: ${selectedTheme?.primaryHighlighted};
		}
	`;

	const secondaryStyle = css`
		font-size: 14px;
		display: inline-block;
		-webkit-appearance: none;
		border-color: initial;
		border-image: initial;
		border-radius: 4px;
		border-style: initial;
		border-width: 0px;
		box-sizing: border-box;
		color: #ffffff;
		cursor: pointer;
		line-height: inherit;
		margin: 0px;
		padding: 8px 16px;
		text-align: center;
		-webkit-text-decoration: none;
		text-decoration: none;
		margin: 1.5em 0;
		background: ${selectedTheme.secondary};
		&:hover {
			background: ${selectedTheme.secondaryHighlighted};
		}
	`;

	const primaryStyle = css`
		font-size: 14px;
		display: inline-block;
		-webkit-appearance: none;
		border-color: initial;
		border-image: initial;
		border-radius: 4px;
		border-style: initial;
		border-width: 0px;
		box-sizing: border-box;
		color: #ffffff;
		cursor: pointer;
		line-height: inherit;
		margin: 0px;
		padding: 8px 16px;
		text-align: center;
		-webkit-text-decoration: none;
		text-decoration: none;
		margin: 1.5em 0;
		background: ${selectedTheme.primary};
		&:hover {
			background: ${selectedTheme.primaryHighlighted};
		}
	`;

	const SecondaryButton = styled.button`
		${secondaryStyle}
	`;

	const PrimaryButton = styled.button`
		${primaryStyle}
	`;

	// const PreviewPrimaryButton = styled(PrimaryButton)`
	// 	background-color: ${selectedTheme.primary};
	// `;

	return (
		<>
			<PreviewNavBar>
				<PreviewAppLogo />
				<PreviewNavBarText className="ml-4">Workflow</PreviewNavBarText>
				<PreviewNavBarText>Dam</PreviewNavBarText>
				<PreviewNavBarText>Filesharing</PreviewNavBarText>
			</PreviewNavBar>
			<PreviewMainScreen>
				<div className="d-flex">
					<PreviewTable>
						<th>Stage</th>
						<th>Due Date</th>
						<th>Expected duration</th>
						<tbody>
							<tr>
								<td>
									<PreviewPrimaryTextLink>
										Moonrise Kingdom
									</PreviewPrimaryTextLink>
								</td>
								<td>10/21/2034:0500CST</td>
								<td>1200 Hrs</td>
							</tr>
						</tbody>
					</PreviewTable>
					<div>
						<PrimaryButton className="mx-2"> Primary Button </PrimaryButton>
						<SecondaryButton> SecondaryButton </SecondaryButton>
					</div>
				</div>
			</PreviewMainScreen>
		</>
	);
};

export default DesktopPreviewComponent;
