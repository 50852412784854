import moment from 'moment';
import { flattenDeep, isArray } from 'lodash';
import * as T from '../types/workflow.types';
import { StageStatus } from '../../../../utils/models/StageStatusModel';
import { flattenStages } from './workflowStage.helpers';

const sumExpectedDurationHours = (stages: T.Stage[]) =>
	!stages
		? 0
		: stages
				.map((stage) => stage.expectedDurationHrs || 0)
				.reduce((acc, curr) => acc + curr);

const sumSubstageExpectedDurationHours = (stages: T.Stage[]) =>
	!stages
		? 0
		: stages
				.map((stage) => {
					if (!stage.substages) return 0;
					return Math.max(
						...flattenDeep(stages.filter(({ substages }) => substages)).map(
							(s) => s.expectedDurationHrs || 0
						)
					);
				})
				.reduce((acc, curr) => acc + curr, 0);

export const getActivationDate = (workflow: T.Workflow) =>
	workflow?.stages &&
	workflow?.stages[0]?.events
		?.filter((s) => s.newStatus === 'active')
		.map((s) => s.createdAt)[0];

const getFirstActivatedStage = (workflow: T.Workflow, flatStages: T.Stage[]) =>
	flatStages?.filter((stage: T.Stage) => stage.status === 'active')[0];

const getDaysUntilDue = ({ stages }: T.Workflow) =>
	sumExpectedDurationHours(stages!) + sumSubstageExpectedDurationHours(stages!);

export const getDueDateBasedOnCurrentStages = (workflow: T.Workflow) => {
	const flatStages = flattenStages(workflow as T.Flattenable) ?? [];
	const firstActiveStage = getFirstActivatedStage(workflow, flatStages);
	if (!firstActiveStage) return;
	const firstActiveStageIndex = flatStages?.findIndex(
		(stage) => stage._id === firstActiveStage._id
	);
	const stagesToSum = flatStages?.slice(firstActiveStageIndex);
	if (stagesToSum) {
		const today = new Date();
		const dueDate = moment(today).add(
			sumExpectedDurationHours(stagesToSum),
			'hours'
		);

		return dueDate.toISOString().substring(0, 10);
	}
};

export const getDueDate = (workflow: T.Workflow) =>
	moment(getActivationDate(workflow))
		.add(getDaysUntilDue(workflow), 'hours')
		.toISOString()
		.substring(0, 10);

export const isOverdue = (workflow: T.Workflow) => {
	return (
		!isRoadblocked(workflow) &&
		workflow?.status === 'active' &&
		moment(new Date()).isAfter(
			moment(getActivationDate(workflow)).add(
				getDaysUntilDue(workflow),
				'hours'
			)
		)
	);
};

export const isRoadblocked = (workflow: T.Workflow) =>
	workflow.status === 'active' &&
	!!flattenStages(workflow)?.some((stage: T.Stage[] | T.Stage) =>
		isArray(stage)
			? stage.some((substage) => substage.status === 'roadblocked')
			: stage.status === 'roadblocked'
	);

export const isPipeline = (workflow: T.Workflow) => {
	if (!workflow) return false;
	if (
		workflow.status === 'pipeline' &&
		flattenStages(workflow)?.every((stages: T.Stage[] | T.Stage) =>
			isArray(stages)
				? stages.every(({ status }) => status === 'queue')
				: stages.status === 'queue'
		)
	)
		return true;
	return false;
};

export const isCompleted = (workflow: T.Workflow) =>
	workflow.status === 'completed';

export const isCancelled = (workflow: T.Workflow) =>
	workflow.status === 'cancelled';

export const isPaused = (workflow: T.Workflow) => workflow.status === 'paused';

export const isHealthy = (workflow: T.Workflow) =>
	workflow.status === 'active' &&
	!!getDueDate(workflow) &&
	!isOverdue(workflow) &&
	!isRoadblocked(workflow);

export const hasActivatedStage = (workflow: T.Workflow) =>
	workflow?.stages && workflow?.stages[0]?.status === StageStatus.active;

export const isActive = (workflow: T.Workflow) =>
	!isCompleted(workflow) && workflow.status === 'active';
