import React, { useState } from 'react';
import { UserGroup } from '../components/accounts/types';
import { useAuthContext } from '../utils/auth/index';
import * as axios from 'axios';
import { useHeaders } from './AuthContext';

type GroupContext = {
	groupsForCurrentUser: UserGroup[];
	entities: UserGroup[];
	owners: Owner[];
	updateOne: (group: UserGroup) => Promise<UserGroup>;
	createOne: (group: UserGroup) => Promise<UserGroup>;
	deleteOne: (id: string) => Promise<UserGroup>;
};

export const GroupContext = React.createContext<GroupContext>({
	groupsForCurrentUser: [],
	entities: [],
	owners: [],
	updateOne: () => Promise.resolve({} as UserGroup),
	deleteOne: () => Promise.resolve({} as UserGroup),
	createOne: () => Promise.resolve({} as UserGroup),
});

type Owner = {
	_id: string;
	title: string;
	type: 'AccountUser' | 'AccountGroup';
};

export const GroupContextProvider = ({
	children,
}: {
	children: React.ReactNode[];
}) => {
	const { currentUser, entities: users } = useAuthContext();
	const [groups, setGroups] = useState<UserGroup[]>();
	const [all, setAll] = useState<UserGroup[]>();
	const [owners, setOwners] = useState<Owner[]>([]);
	const [fetching, setFetching] = React.useState(false);

	React.useEffect(() => {
		if (!groups) return;
		if (!users) return;
		if (owners.length) return;
		const userOwners: Owner[] = users.map((user) => ({
			_id: user._id,
			type: 'AccountUser',
			title: user.givenName + ' ' + user.familyName,
		}));
		const groupOwners: Owner[] = groups.map((group) => ({
			_id: group._id,
			type: 'AccountGroup',
			title: group.title,
		}));

		setOwners([...userOwners, ...groupOwners]);
	}, [groups, users, owners.length]);

	const { getHeaders } = useHeaders();
	const headers = getHeaders();

	const groupStore = {
		getGroups: async (): Promise<UserGroup[]> => {
			if (headers && headers.headers.Authorization) {
				const response = await axios.default.get<UserGroup[]>(
					`${process.env.REACT_APP_ROME_API_ENDPOINT}/groups`,
					headers
				);
				return response.data as UserGroup[];
			} else return [] as UserGroup[];
		},
	};

	React.useEffect(() => {
		if (!!groups || fetching) return;
		if (currentUser?._id) {
			setFetching(true);
			groupStore
				.getGroups()
				.then(function (groups) {
					setAll(groups);
					setGroups(
						groups.filter(
							(group) =>
								group.members.some(
									(member) => member._id === currentUser?.proxyingFor?._id
								) ||
								group.members.some((member) => member._id === currentUser?._id)
						) || []
					);
				})
				.finally(() => setFetching(false));
		}
	}, [groups, fetching, currentUser, groupStore]);

	const updateOne = async (group: UserGroup) => {
		if (headers) {
			const response = await axios.default.patch<UserGroup>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/groups/${group._id}`,
				group,
				headers
			);

			if (groups?.some((g) => g._id === group._id)) {
				setGroups([
					...groups.filter((g) => g._id !== response.data._id),
					response.data,
				]);
			}

			if (all?.some((g) => g._id === response.data._id)) {
				setAll([
					...all.filter((g) => g._id !== response.data._id),
					response.data,
				]);
			}

			return response.data;
		}
		return Promise.resolve({} as UserGroup);
	};

	const createOne = async (created: UserGroup) => {
		if (headers) {
			const res = await axios.default.post<UserGroup>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/groups`,
				{
					...created,
				},
				headers
			);

			if (res.data.members.some((member) => member._id === currentUser._id)) {
				setGroups([...groups, res.data]);
			}

			setAll([...all, res.data]);
			return res.data;
		}
		return {} as UserGroup;
	};

	const deleteOne = async (id: string) => {
		if (headers) {
			const res = await axios.default.delete<UserGroup>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/groups/${id}`,
				headers
			);

			if (groups?.some((g) => g._id === id)) {
				setGroups([...groups.filter((g) => g._id !== id)]);
			}

			if (all?.some((g) => g._id === id)) {
				setAll([...all.filter((g) => g._id !== id)]);
			}

			return res.data;
		}
		return {} as UserGroup;
	};

	return (
		<GroupContext.Provider
			value={{
				groupsForCurrentUser: groups || [],
				entities: all as UserGroup[],
				updateOne,
				deleteOne,
				createOne,
				owners,
			}}
		>
			{children}
		</GroupContext.Provider>
	);
};
