import { ControllerStateAndHelpers, GetInputPropsOptions } from 'downshift';
import React, { useEffect } from 'react';
import { Maybe } from 'types/globals';
import { useDownshift } from '../../context/downshift-options.context';
import { getName, noop } from '../../utils/common';
import {
	BorderlessInput,
	TagInputField,
} from '../tag-input/tag-input.styled-components';
import {
	ClearSelectionButton,
	DownshiftToggleButton,
} from './downshift-buttons.component';
import { DownshiftSingleSelectProps } from './downshift-single-select.component';

interface DownshiftSingleInputProps<
	Item extends Displayable,
	Option extends Displayable
> extends DownshiftSingleSelectProps<Item, Option> {
	getInputOptions?: (
		downshift: ControllerStateAndHelpers<Option>
	) => Maybe<GetInputPropsOptions>;
}

export const DownshiftSingleInputField = <
	Item extends Displayable,
	Option extends Displayable
>(
	props: DownshiftSingleInputProps<Item, Option>
) => {
	const {
		label,
		placeholder = `Select ${label?.toLowerCase()} by name...`,
		getInputOptions = noop,
	} = props;

	const { downshift, selection } = useDownshift<Item, Option>();

	// Make sure the input is reset to empty when we are changing selection contexts.
	// Todo find better way to achieve this.
	useEffect(
		() => {
			downshift.reset({ inputValue: '' });
		},
		// Reset when the selection changes. Do NOT make downshift a dependency
		// of this effect else an infinite loop occurs.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selection]
	);

	const inputProps = downshift.getInputProps(getInputOptions(downshift));
	if (selection) {
		inputProps.value = getName(selection as Item);
	}
	return (
		<TagInputField>
			<BorderlessInput
				className="cy-wf-template-dropdown-input"
				{...(inputProps as React.InputHTMLAttributes<HTMLInputElement>)}
				placeholder={placeholder}
			/>

			{selection && (
				<ClearSelectionButton
					onClick={() =>
						props?.selectionActions.unselect &&
						props?.selectionActions?.unselect({} as Item)
					}
				/>
			)}
			{!selection && <DownshiftToggleButton />}
		</TagInputField>
	);
};
