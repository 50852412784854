import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
	connectCurrentRefinements,
	CurrentRefinementsProvided,
} from 'react-instantsearch-core';
import RenderWhen from '../render-when.component';
import { StyledOutlineButton } from './refinement-list.styled-components';
import { useSearchHistory } from 'utils/useSearchHandler';
interface ClearRefinementProps extends CurrentRefinementsProvided {
	clearRefiningCallback: (isRefining: boolean) => void;
}
const ClearRefinementsButton = ({
	items,
	refine,
	clearRefiningCallback,
}: ClearRefinementProps) => {
	const [, setSearchHistory] = useSearchHistory();
	return (
		<RenderWhen when={items.length > 0}>
			<StyledOutlineButton
				onClick={() => {
					// Weird type mismatch in algolia typedefs:
					// `Refinement` != `RefinementValue`
					// but it works anyway

					/* Adding filtering out of archived refinement due to request for Archived to stay defaulted at false */
					refine(items);
					clearRefiningCallback(false);
					setSearchHistory((history: any) => ({
						refinements: [],
						searchTerm: '',
					}));
				}}
			>
				<FontAwesomeIcon icon={faTimes} className="mr-2" />
				Clear filters
			</StyledOutlineButton>
		</RenderWhen>
	);
};

export const CustomClearRefinements = connectCurrentRefinements(
	ClearRefinementsButton
);
