import React from 'react';
import { Container } from 'reactstrap';
import { StageEvent } from '../../types/workflow.types';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { Timeline } from '../timeline/timeline.component';
import { useWorkflowContext } from 'context/useWorkflowStore';

const collectionTerm = themeStore._.workflowCollection.toLowerCase();
const noAccessLogsMessage = (
	<p>{`There are no logs for this ${collectionTerm} yet.`}</p>
);
type Props = {
	events: StageEvent[];
};
const AccessLogsPanel = (props: Props) => {
	const { events } = props;
	const { workflow } = useWorkflowContext();
	const hasEvents = events?.length;

	return (
		<Container fluid>
			{hasEvents ? (
				<Timeline
					events={events}
					workflow={workflow}
					displayStageTitle={true}
				/>
			) : (
				noAccessLogsMessage
			)}
		</Container>
	);
};

export default AccessLogsPanel;
