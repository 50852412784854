import DownshiftMultiSelect, {
	DownshiftMultiSelectProps,
} from 'components/downshift-select/downshift-multi-select.component';
import { orderBy, property } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Nullable } from 'types/globals';
import { useGroupContext } from 'utils/auth';
import { FilterActiveUsers } from 'utils/core/FilterActiveUsers';
import { useAuthContext, User } from '../../../../utils/auth';
import { UserGroup } from '../../../accounts/types';
import { BaseWorkflowOwner } from '../../workflows/types/workflow.types';

const OwnerSelectWrapper = styled.div`
	div[role='combobox'] {
		max-height: 500px;
		overflow-y: auto;
	}
`;
export const BaseOwnerSelect = ({
	options,
	owners,
	onChange,
	label,
}: {
	options?: any[];
	owners?: BaseWorkflowOwner[];
	onChange: React.Dispatch<{ type: 'update'; payload: BaseWorkflowOwner[] }>;
	label?: string;
}) => {
	const { entities: allUsers } = useAuthContext();
	const { entities: allGroups } = useGroupContext();

	const allActiveUsers = FilterActiveUsers(allUsers);

	const [edited, setEdited] = React.useState(owners);
	const addOwner = React.useCallback(
		(owner: User | UserGroup) => {
			// Check if group owner already exists
			const isGroupOwner = allGroups.some((m) => m._id === owner._id);

			// If Group owner exists return the group of users with the new users added to it otherwise return an array containing the group
			if (isGroupOwner) {
				setEdited((edited) =>
					edited && edited?.length
						? [
								...edited?.filter((a) => a._id !== owner._id),
								{ type: 'AccountGroup', _id: owner._id } as BaseWorkflowOwner,
						  ]
						: [{ type: 'AccountGroup', _id: owner._id } as BaseWorkflowOwner]
				);
				// If the workflow stakeholders have changed update the list of stakeholders
				onChange({
					type: 'update',
					payload:
						edited && edited?.length
							? [
									...edited?.filter((a) => a._id !== owner._id),
									{
										type: 'AccountGroup',
										_id: owner._id,
									} as BaseWorkflowOwner,
							  ]
							: [
									{
										type: 'AccountGroup',
										_id: owner._id,
									} as BaseWorkflowOwner,
							  ],
				});
			} else {
				setEdited((edited) =>
					edited && edited?.length
						? [
								...edited?.filter((a) => a._id !== owner._id),
								{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner,
						  ]
						: [{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner]
				);
				onChange({
					type: 'update',
					payload:
						edited && edited?.length
							? [
									...edited?.filter((a) => a._id !== owner._id),
									{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner,
							  ]
							: [{ type: 'AccountUser', _id: owner._id } as BaseWorkflowOwner],
				});
			}
		},
		[edited, onChange, allGroups]
	);

	const removeOwner = React.useCallback(
		(owner: User | UserGroup) => {
			const isGroup = allGroups.some((g) => g._id === owner._id);
			if (isGroup) {
				const groupId = owner._id;
				if (edited) {
					setEdited([...edited?.filter((owner) => owner._id !== groupId)]);
					onChange({
						type: 'update',
						payload: [...edited?.filter((owner) => owner._id !== groupId)],
					});
				}
			} else {
				const userId = owner._id;
				if (edited) {
					setEdited([...edited?.filter((e) => e._id !== userId)]);
					onChange({
						type: 'update',
						payload: [...edited?.filter((owner) => owner._id !== userId)],
					});
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[edited, onChange, allGroups, allActiveUsers]
	);

	const findGroupOrUser = React.useMemo(() => {
		return (owner: BaseWorkflowOwner) => {
			if (owner.type === 'AccountGroup') {
				if (allGroups.some((m) => m._id === owner._id))
					return allGroups.find((m) => m._id === owner._id);
			} else if (owner.type === 'AccountUser') {
				if (allUsers.some((m) => m._id === owner._id)) {
					const user = allUsers.find((m) => m._id === owner._id);
					return user;
				} else {
					const user = allActiveUsers.find((m: any) => m._id === owner._id);
					return user;
				}
			}
		};
	}, [allUsers, allActiveUsers, allGroups]);

	const isNotAlreadyOwner = React.useMemo(
		() => (existingOwner: User | UserGroup) =>
			!(edited || [])?.some((owner) => owner?._id === existingOwner._id),
		[edited]
	);
	const doesOwnerExist = React.useMemo(() => {
		return (owner: BaseWorkflowOwner) =>
			[...(allGroups || []), ...(allUsers || [])].some(
				(u) =>
					u._id === owner._id &&
					allActiveUsers.filter((user: any) => user.status === 'active')
			);
	}, [allGroups, allUsers, allActiveUsers]);

	const selectOptions = React.useMemo(() => {
		if (!options || !options.length) {
			return [
				...(allGroups ? allGroups : []),
				...(allActiveUsers ? allActiveUsers : []),
			];
		} else if (options) {
			return [...options];
		}
	}, [allActiveUsers, options, allGroups]);

	const searchPredicate = (val: Nullable<string>, opt: any) => {
		if (!val) return true;
		if (property('members')(opt)) {
			const title = property('title')(opt) as string;
			return title.toLowerCase().indexOf(val?.toLowerCase()) > -1;
		} else if (property('givenName')(opt)) {
			const first = property('givenName')(opt) as string;
			const last = property('familyName')(opt) as string;
			return [first, last].some(
				(value) => value.toLowerCase().indexOf(val.toLowerCase()) > -1
			);
		}
		return false;
	};

	const selectProps: DownshiftMultiSelectProps<any, any> = {
		label: label || 'Stakeholders',
		selectionState: {
			selection: orderBy(
				edited?.filter(doesOwnerExist).map(findGroupOrUser),
				(option) =>
					option && (option as User).givenName
						? (option as User).givenName
						: option && option['title']
			),
			options: orderBy(
				selectOptions?.filter(isNotAlreadyOwner) as any[],
				(option) =>
					option && (option as User).givenName
						? (option as User).givenName
						: option && option['title']
			),
			searchPredicate,
		},
		selectionActions: {
			select: addOwner,
			unselect: removeOwner,
		},
	};

	return (
		<>
			<OwnerSelectWrapper>
				<DownshiftMultiSelect {...selectProps} />{' '}
			</OwnerSelectWrapper>
		</>
	);
};

export default BaseOwnerSelect;
