import React from 'react';
import styled from 'styled-components';
import { ModalProps } from './Modal';
import { Mask } from './Mask';
import { DangerButton, SecondaryButton } from '../../buttons.styled-components';
import { getModalTransition } from '../modal.helpers';
import { Dialog } from '../modal.styles';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const Content = styled.div`
	h2 {
		text-align: center;
		font-size: 20px;
	}

	.children-content {
		padding: 10px 0;
	}

	.action-buttons {
		display: flex;
		justify-content: center;
		padding-top: 15px;
	}
`;

type Props = {
	onConfirm: () => void | Promise<void>;
	onCancel: () => void;
	cancelText?: string;
	confirmText?: string;
	size?: 'md' | 'sm' | 'lg';
} & Omit<ModalProps, 'type' | 'onClose'>;

export const ConfirmationDialog = ({
	isOpen,
	title,
	onConfirm,
	onCancel,
	...props
}: Props) => {
	return (
		<>
			<Mask isVisible={isOpen} />

			<Dialog isVisible={isOpen} transition={getModalTransition()}>
				<Content>
					<button className="close" onClick={onCancel}>
						&times;
					</button>
					<ModalHeader>
						<h2 className="modal-title">{title}</h2>
					</ModalHeader>
					<ModalBody>
						<div className="children-content">{props.children}</div>
					</ModalBody>
					<ModalFooter>
						<div className="action-buttons">
							<SecondaryButton onClick={onConfirm}>
								{props.confirmText ? props.confirmText : 'Confirm'}
							</SecondaryButton>
							<DangerButton onClick={onCancel}>
								{props.cancelText ? props.cancelText : 'Cancel'}
							</DangerButton>
						</div>
					</ModalFooter>
				</Content>
			</Dialog>
		</>
	);
};
