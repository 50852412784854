import storageModel from 'utils/models/StorageModel';
import { SiteDefaults } from 'context/useThemeContext';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

interface RefinementItem {
	label: string;
	value: string[];
	count: number;
	isRefined: boolean;
}

export interface RefinementSettings {
	attribute: string;
	label: string;
	searchable: boolean;
	limit: number;
	transformItems?: (items: Array<RefinementItem>) => Array<RefinementItem>;
}

// ! unused
// const existingFileTypes = {
// 	'application/pdf': 'PDF (.pdf)',
// 	'application/zip': 'ZIP Archive (.zip)',
// 	'image/jpeg': 'JPEG image (.jpg, .jpeg)',
// 	'application/x-zip-compressed': 'Compressed ZIP (.zip)',
// 	'application/x-font-otf': 'OTF Font (.otf)',
// 	'application/postscript': 'PostScript (.ps)',
// 	'binary/octet-stream': 'Binary file',
// 	'image/vnd.adobe.photoshop': 'Adobe Photoshop (.psd)',
// 	'image/tiff': 'TIFF image (.tiff)',
// 	'application/x-font-ttf': 'TTF Font (.ttf)',
// };

// !unused?
// const isKnownMimeType = (
// 	mimetype: string
// ): mimetype is keyof typeof existingFileTypes => {
// 	return mimetype in existingFileTypes;
// };

// ! unused?
// const transformMimeType = (item: string): string => {
// 	if (!isKnownMimeType(item)) {
// 		return item;
// 	} else {
// 		return existingFileTypes[item];
// 	}
// };

// ! unused?
// const transformFileType = (
// 	items: Array<RefinementItem>
// ): Array<RefinementItem> => {
// 	return items.map((item) => {
// 		item.label = transformMimeType(item.label);
// 		return item;
// 	});
// };

export const transformArchived = (
	items: Array<RefinementItem>
): Array<RefinementItem> => {
	return items.map((item) => {
		item.label = item.label === 'false' ? 'Active' : 'Archived';
		return item;
	});
};

// Hardcoded for now, todo make configurable.
export const damAssetRefinementSettings: readonly RefinementSettings[] = defaults?.damRefinements?.map(
	(settings) => {
		const defaultss = {
			searchable: false,
			limit: 24,
		};
		return Object.assign(defaultss, settings) as RefinementSettings;
	}
);

// Hardcoded for now, todo make configurable.
export const damAssetFolderRefinementSettings: readonly RefinementSettings[] = defaults?.damRefinements?.map(
	(settings) => {
		const defaultss = {
			searchable: false,
			limit: 1000,
		};
		return Object.assign(defaultss, settings) as RefinementSettings;
	}
);
