import React from 'react';
import { UserGroup } from './accounts/types';
import { OwnerAvatarWrapper } from './workflow/workflow-templates/components/stage-cards/template-stage-card.styled-components';
import {
	BaseWorkflowOwner,
	GroupOwner,
	UserOwner,
} from './workflow/workflows/types/workflow.types';
import GroupAvatar from './group-avatar.component';
import {
	CollaboratorAvatarLg,
	CollaboratorAvatarMd,
	CollaboratorAvatarSm,
	CollaboratorAvatarXs,
} from './ui';
import { useAuthContext, User } from '../utils/auth';
import { useGroupContext } from 'utils/auth';
import { toArray, uniqBy } from 'lodash';
import { StyledLink } from 'components/dam-assets/components/asset-card.styled-components';

type AvatarSize = 'xs' | 'sm' | 'md' | 'lg';
type Ownable = {
	owners: BaseWorkflowOwner[];
};
interface OwnerAvatarListProps
	extends Ownable,
		React.HTMLAttributes<HTMLDivElement> {
	size?: AvatarSize;
	displayNames?: boolean;
}

interface OwnerAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
	size?: AvatarSize;
	owner: UserOwner | GroupOwner;
}

const avatarsBySize = {
	xs: CollaboratorAvatarXs,
	sm: CollaboratorAvatarSm,
	md: CollaboratorAvatarMd,
	lg: CollaboratorAvatarLg,
} as const;

export const OwnerAvatar = (props: OwnerAvatarProps) => {
	const { owner, size = 'lg', ...avatarProps } = props;
	const group = (owner as GroupOwner) as UserGroup;
	const Avatar = avatarsBySize[size];
	if (owner?.type === 'AccountUser') {
		return (
			<StyledLink to={`/admin/profile/${owner?._id}`}>
				<Avatar
					size={size as AvatarSize}
					user={owner as User}
					{...avatarProps}
				/>{' '}
			</StyledLink>
		);
	} else if (owner?.type === 'AccountGroup') {
		return <GroupAvatar size={size} group={group as UserGroup} />;
	}
	return null;
};

const OwnerAvatarList = (props: OwnerAvatarListProps) => {
	const { owners, size = 'lg' } = props;
	const { entities: users } = useAuthContext();
	const { entities: groups } = useGroupContext();

	const listOwnerNames = (owners: BaseWorkflowOwner[]) =>
		owners?.length === 0 || !owners
			? 'No stakeholders assigned'
			: owners.map((owner, idx) => {
					if (owner?.type === 'AccountUser') {
						return (
							<StyledLink
								to={`/admin/profile/${owner?._id}`}
								key={idx}
								className="d-flex mb-1 text-nowrap"
							>{`${
								users?.find((m) => m._id === owner?._id)?.givenName || 'Deleted'
							} ${
								users?.find((m) => m._id === owner?._id)?.familyName || 'User'
							}${idx === owners.length - 1 ? '' : ', '}`}</StyledLink>
						);
					} else if (owner?.type === 'AccountGroup') {
						return (
							<span key={idx} className="d-flex text-nowrap">{`${
								groups?.find((m) => m._id === owner?._id)?.title
							}${idx === owners.length - 1 ? '' : ', '}`}</span>
						);
					}
					return null;
			  });

	const findOwner = (owner: BaseWorkflowOwner) => {
		if (
			!users?.some((m) => m._id === owner?._id) &&
			!groups?.some((m) => m._id === owner?._id)
		)
			return owner;

		return owner?.type === 'AccountUser'
			? ({
					type: 'AccountUser',
					...users?.find((user) => user._id === owner?._id),
			  } as UserOwner)
			: ({
					type: 'AccountGroup',
					...groups?.find((m) => m._id === owner?._id),
			  } as GroupOwner);
	};
	if (!owners) return null;
	return (
		<OwnerAvatarWrapper>
			{uniqBy(toArray(owners), (owner) => owner?._id).map(
				(owner: any, idx: number) => (
					<OwnerAvatar
						key={idx}
						owner={findOwner(owner) as UserOwner | GroupOwner}
						size={size}
					/>
				)
			)}
			{props.displayNames && (
				<small key={'ownersKey'} className="d-flex pl-2">
					{listOwnerNames(props.owners)}
				</small>
			)}
		</OwnerAvatarWrapper>
	);
};

export default OwnerAvatarList;
