import { Instance, types } from 'mobx-state-tree';
import { ExtractProps } from 'mobx-state-tree/dist/types/complex-types/model';
import { Infer } from 'types/globals';

/**
 * @deprecated
 * Instead, access the property on the ThemeTermsModel instance.
 */
export enum Terms {
	baseWorkflow = 'baseWorkflow',
	workflow = 'workflow',
	workflowCollection = 'workflowCollection',
	job = 'job',
	phase = 'phase',
	stage = 'stage',
	owner = 'owner',
	assetBase = 'assetBase',
	asset = 'asset',
	assetCollection = 'assetCollection',
	queue = 'queue',
}

export const ThemeTermsModel = types.model('ThemeTerms', {
	baseWorkflow: types.optional(types.string, 'Workflow'),
	template: types.optional(types.string, 'Template'),
	workflow: types.optional(types.string, 'Workflow'),
	workflowCollection: types.optional(types.string, 'Project'),
	job: types.optional(types.string, 'Job'),
	phase: types.optional(types.string, 'Phase'),
	stage: types.optional(types.string, 'Stage'),
	owner: types.optional(types.string, 'Stakeholder'),
	assetBase: types.optional(types.string, 'DAM'),
	asset: types.optional(types.string, 'Asset'),
	assetCollection: types.optional(types.string, 'Collection'),
	queue: types.optional(types.string, 'Pipeline'),
	report: types.optional(types.string, 'Report'),
});

export interface ThemeTermsModel extends Infer<typeof ThemeTermsModel> {}
export interface ThemeTerms extends Instance<ThemeTermsModel> {}

export type ThemeTerm = keyof ExtractProps<ThemeTermsModel>;

export default ThemeTermsModel;
