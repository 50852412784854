import { useSiteSettings } from 'hooks/useSiteSettings';
import { get } from 'lodash';
import React from 'react';

export const featureFlags = {
	assetSelectionToolbar: {
		// NOTE: available in assets index and workflow > assets tab
		view: true,
		compare: true,
		downloadSingle: true,
		downloadMultiple: true, // TODO: RRR-225
		shareSingle: true,
		shareMultiple: true, // TODO: RRR-252
		trash: true, // TODO: RRR-224
	},
	assetSharing: {
		shareToUser: false,
		shareToEmail: true,
	},
	deleteUser: true,
	assetCollections: {
		detailsView: {
			toolbar: {
				edit: true,
				delete: true, // TODO: RRR-209
			},
		},
	},
} as const;

export const RequiresFeatureFlag = ({
	flag,
	defaultValue,
	children,
}: {
	flag: string;
	children: any;
	defaultValue?: boolean;
}) => {
	const FeatureFlags = useSiteSettings();
	const defaultVal = Boolean(defaultValue);
	return get(FeatureFlags.settings, flag, defaultVal) ? <>{children}</> : null;
};
