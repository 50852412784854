import { Link } from '@reach/router';
import styled from 'styled-components';

import { focus } from '../../core-ui/ui/ui';
import { fsNormal } from '../../core-ui/ui/typography';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

// styled components
export const Container = styled.div`
	padding-bottom: 0.5em;
`;

export const StyledLink = styled(Link)`
	${fsNormal};
	color: ${defaults?.secondary};
	text-decoration: none;

	&:hover {
		color: ${defaults?.secondaryHighlighted};
		text-decoration: none;
	}

	&:focus {
		${focus};
	}

	.button-label {
		vertical-align: middle;
	}

	.button-icon {
		vertical-align: middle;
	}
`;
