import { navigate, useParams } from '@reach/router';
import { ProxyRequest, useAuthContext } from 'utils/auth';
import { Subheading, Heading, Loading } from '../../components/index';
import React, { useEffect } from 'react';
import {
	Badge,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	FormGroup,
	FormText,
	Input,
	Label,
	Row,
	UncontrolledTooltip,
} from 'reactstrap';
import moment from 'moment';
import { StyledChangeFormButton } from 'pages/views/Register/components';
import { SecondaryButton } from 'components/buttons.styled-components';
import { NotificationsContext } from 'components/notifications';
import { DeclineProxyModal } from './DeclineProxyModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faPencilAlt,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { CustomFontAwesomeIcon } from 'components/file-sharing/styled';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import styled from 'styled-components';
const CustomDatePicker = styled.input``;

export const ProxyReview = () => {
	const { info } = React.useContext(NotificationsContext);
	const { currentUser } = useAuthContext();
	const { proxyId } = useParams();
	const {
		fetchProxyRequest,
		updateProxyRequest,
		deleteProxyRequest,
	} = useAuthContext();

	const [request, setRequest] = React.useState<ProxyRequest>();
	const [loading, setLoading] = React.useState(false);
	const [showModal, setShowModal] = React.useState(false);

	const [isEditMode, setIsEditMode] = React.useState(false);

	const [editingState, setEditingState] = React.useState<{
		startDate: Date | undefined;
		endDate: Date | undefined;
		message?: string;
	}>({
		startDate: request?.proxyStartDate,
		message: request?.message,
		endDate: request?.proxyEndDate,
	});

	useEffect(() => {
		if (proxyId) {
			const finishLoad = () => setLoading(false);
			setLoading(true);
			fetchProxyRequest(proxyId).then(setRequest).finally(finishLoad);
		}
		//eslint-disable-next-line
	}, [proxyId]);

	if (!proxyId) return null;

	const onDeleteRequest = async () => {
		if (await deleteProxyRequest(request?._id as string)) {
			return navigate('/admin');
		}
	};

	const onDateChanged = async () => {
		if (request && editingState.startDate && editingState.endDate) {
			const updated = await updateProxyRequest({
				...request,
				proxyStartDate: editingState.startDate,
				proxyEndDate: editingState.endDate,
				message: editingState.message,
			});
			setRequest(updated);
			setIsEditMode(false);
			info('Updated proxy request succesfully.');
		}
	};

	const getBadgeColor = () => {
		if (request?.action === 'pending') return 'warning';
		if (request?.action === 'accepted') return 'success';
		if (request?.action === 'declined') return 'danger';
	};

	const onApproveRequest = async () => {
		const updatedRequest = { ...request, action: 'accepted' } as ProxyRequest;
		const updatedProxyRequest = await updateProxyRequest(updatedRequest);

		setRequest(updatedProxyRequest);

		info('Successfully updated the Proxy request.');
		return await navigate('/admin');
	};

	const onDeclineRequest = async () => {
		const updatedRequest = { ...request, action: 'declined' } as ProxyRequest;
		await updateProxyRequest(updatedRequest);
		info('Successfully declined the Proxy request.');
		return await navigate('/admin');
	};

	const isEditable = () => {
		if (request) {
			return request?.proxyingUser?._id === currentUser?._id;
		}
		return false;
	};
	return (
		<Container>
			<Subheading>ROME</Subheading>
			<Heading>Proxy Request Details</Heading>

			{loading && <Loading />}
			<Card>
				{request && loading === false && (
					<>
						<CardHeader>
							<small>REQUEST ID: {request?._id}</small>
							{currentUser?._id === request?.createdBy?._id && (
								<>
									<UncontrolledTooltip target={'fa-trash-icon'}>
										Delete Request
									</UncontrolledTooltip>
									<span
										id="fa-trash-icon"
										style={{ cursor: 'pointer', fontSize: 20 }}
										className={
											'd-flex align-items-center justify-content-end float-right'
										}
										onClick={onDeleteRequest}
									>
										<FontAwesomeIcon icon={faTrash} />
									</span>
								</>
							)}
						</CardHeader>
						<CardBody>
							<Row>
								<Col xl={12}>
									<FormGroup>
										<Label>Status</Label>
										<Badge color={getBadgeColor()} className="ml-2">
											{request?.action === 'pending'
												? 'Awaiting approval'
												: request?.action === 'declined'
												? 'Denied'
												: request?.action === 'accepted'
												? 'Accepted'
												: 'NA'}
										</Badge>
									</FormGroup>
								</Col>
								<Col xl={4}>
									<FormGroup>
										<Label className="w-100">Requester</Label>
										<p>
											{request?.createdBy?.givenName} &nbsp;
											{request?.createdBy?.familyName}
										</p>
									</FormGroup>
								</Col>

								<Col xl={4}>
									<FormGroup>
										<Label className="w-100">Coverage Requested By</Label>
										<p>
											{request?.proxyingUser?.givenName} &nbsp;
											{request?.proxyingUser?.familyName}
										</p>
									</FormGroup>
								</Col>
								<Col xl={4} />
								<Col xl={12}>
									<FormGroup>
										<Label className="border-bottom  w-100">
											Coverage Requested Between
										</Label>
										<Row>
											<Col xs={9} md={5}>
												<Subheading>START</Subheading>

												{isEditMode && (
													<CustomDatePicker
														className="form-control"
														type={'datetime-local'}
														onChange={(e: React.ChangeEvent<any>) => {
															const dateChangeValue = e.target.value;
															setEditingState({
																...editingState,
																startDate: new Date(dateChangeValue) as Date,
															});
														}}
														defaultValue={moment(
															request?.proxyStartDate
														).format('YYYY-MM-DDTHH:mm:ss')}
													/>
												)}
												{isEditMode === false && (
													<p>
														{moment(request?.proxyStartDate)
															.toDate()
															.toLocaleDateString()}
														<i>
															&nbsp;
															{moment(request?.proxyStartDate)
																.toDate()
																.toLocaleTimeString()}
														</i>
													</p>
												)}
											</Col>
											<Col xs={9} md={5}>
												<Subheading>END</Subheading>
												{isEditMode === false && (
													<p>
														{moment(request?.proxyEndDate)
															.toDate()
															.toLocaleDateString()}
														<i>
															&nbsp;
															{moment(request?.proxyEndDate)
																.toDate()
																.toLocaleTimeString()}
														</i>
													</p>
												)}

												{isEditMode && (
													<CustomDatePicker
														className="form-control"
														type={'datetime-local'}
														onChange={(e: React.ChangeEvent<any>) => {
															const dateChangeValue = e.target.value;
															setEditingState({
																...editingState,
																endDate: new Date(dateChangeValue) as Date,
															});
														}}
														defaultValue={moment(request?.proxyEndDate).format(
															'YYYY-MM-DDTHH:mm:ss'
														)}
													/>
												)}
											</Col>
											{!isEditMode && (
												<Col xs={1} className={'d-flex align-items-center'}>
													<UncontrolledTooltip target={'fa-pencil-icon'}>
														Edit Request
													</UncontrolledTooltip>
													<CustomFontAwesomeIcon
														id={'fa-pencil-icon'}
														onClick={() => {
															setIsEditMode(true);
															setEditingState({
																startDate: new Date(request?.proxyStartDate),
																endDate: new Date(request?.proxyEndDate),
																message: request?.message,
															});
														}}
														icon={faPencilAlt}
													/>
												</Col>
											)}
											{isEditMode && (
												<Col xs={2} className={'d-flex align-items-center'}>
													<UncontrolledTooltip target="fa-times-icon">
														Cancel
													</UncontrolledTooltip>
													<UncontrolledTooltip target="fa-check-icon">
														Save Proxy
													</UncontrolledTooltip>
													<AnchorSpan
														style={{
															marginRight: 5,
															marginTop: 5,
															fontSize: 18,
														}}
														id={'fa-times-icon'}
														onClick={() => {
															setEditingState((editingState) => ({
																startDate: request.proxyStartDate,
																endDate: request.proxyEndDate,
																message: request.message,
															}));
															setIsEditMode(false);
														}}
													>
														<FontAwesomeIcon icon={faTimes} />
													</AnchorSpan>
													<AnchorSpan
														style={{ marginTop: 5, fontSize: 18 }}
														id={'fa-check-icon'}
														onClick={onDateChanged}
														className="ml-1"
													>
														<FontAwesomeIcon icon={faCheck} />
													</AnchorSpan>
												</Col>
											)}
										</Row>
										<hr />
										<Row>
											{(!isEditMode ||
												(isEditMode &&
													currentUser?._id !== request?.createdBy?._id)) && (
												<Col sm={12} xl={6}>
													<Subheading>Requestor Notes</Subheading>
													<p>{request?.message}</p>
												</Col>
											)}
											{isEditMode &&
												currentUser?._id === request?.createdBy?._id && (
													<Col sm={12} xl={12}>
														<Subheading>Requestor Notes</Subheading>
														<Input
															style={{ height: 200 }}
															type={'textarea'}
															onChange={(e) =>
																setEditingState({
																	...editingState,
																	message: e.target.value,
																})
															}
															defaultValue={request?.message}
														/>
													</Col>
												)}
										</Row>
										<hr />
										<FormText>
											By accepting the proxy request, you are accepting all the
											users assignments for the duration of the proxy.
										</FormText>
									</FormGroup>
								</Col>
							</Row>
						</CardBody>
						{isEditable() && (
							<CardFooter>
								<SecondaryButton onClick={onApproveRequest}>
									Approve Proxy
								</SecondaryButton>
								<StyledChangeFormButton onClick={() => setShowModal(true)}>
									Decline Proxy
								</StyledChangeFormButton>
							</CardFooter>
						)}
					</>
				)}
			</Card>

			{showModal && (
				<DeclineProxyModal
					onConfirm={onDeclineRequest}
					onCancel={() => setShowModal(false)}
				/>
			)}
		</Container>
	);
};
