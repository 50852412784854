import React from 'react';
import { FieldError } from 'react-hook-form';
import { ValidatorOptions } from './Input';

type Props = {
	error: FieldError;
	fieldValue: string | number;
	validators: ValidatorOptions;
	className?: string;
};

export const InputError = ({
	error,
	fieldValue,
	validators,
	...props
}: Props) => {
	const { minLength, maxLength, max, min } = validators;

	const hasError = (validatorType: keyof ValidatorOptions) =>
		error && error.type && error.type === validatorType;

	const message = (type: keyof ValidatorOptions) => {
		// @ts-ignore
		if (validators[type].message) return validators[type].message;

		if (type === 'required') return 'please fill out this field';

		if (type === 'pattern') return 'invalid input';

		if (type === 'min' && min && typeof min === 'number')
			return `please enter a number more than ${min + 1}`;

		if (type === 'max' && max && typeof max === 'number')
			return `please enter a number less than ${max + 1}`;

		if (typeof fieldValue === 'number') return;

		if (type === 'minLength' && minLength && typeof minLength === 'number')
			return `please enter ${minLength - fieldValue.length} more characters`;

		if (type === 'maxLength' && maxLength && typeof maxLength === 'number')
			return `please enter ${fieldValue.length - maxLength} less characters`;
	};

	return (
		<div className={props.className}>
			{hasError('required') && <p>{message('required')}</p>}
			{hasError('min') && <p>{message('min')}</p>}
			{hasError('max') && <p>{message('max')}</p>}
			{hasError('maxLength') && <p>{message('maxLength')}</p>}
			{hasError('minLength') && <p>{message('minLength')}</p>}
			{hasError('pattern') && <p>{message('pattern')}</p>}

			{Object.entries(validators['validate'] || []).map(
				([message, validator]) =>
					fieldValue && (validator as any)(fieldValue) && <p>{message}</p>
			)}
		</div>
	);
};
