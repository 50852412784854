import styled from 'styled-components';

export const OwnerButton = styled.button`
	display: flex;
	border-radius: 4px;
	border: 1px solid transparent;
	pointer: cursor;
	align-items: center;
	background-color: var(--card-background);

	&:hover {
		border: 1px solid var(--darkGrey);
		transition: all 0.25s;
	}
`;
