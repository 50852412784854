import { _logError } from 'utils/common/log';
import { NotificationsContext } from 'components/notifications';
import React from 'react';
import { useModalCloser } from 'utils/ModalStack';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import { Stage, Workflow } from '../../types/workflow.types';
import { StatusEnum } from '../../types/workflowStatus.types';
import StageDialog from './stage-dialog.component';

const BypassStageDialog = ({ stage }: { stage: Stage }) => {
	const { info, error: showError } = React.useContext(NotificationsContext);
	const wfHelper = useWorkflowContext();
	const dialogCloser = useModalCloser();
	return (
		<StageDialog
			header={`Bypassing stage "${stage.title}"`}
			context="Please add context about why this stage is bypassed."
			warning="Are you sure you want to bypass this stage?"
			onClick={async (message: string) => {
				try {
					await wfHelper.updateStatus(
						StatusEnum.completed,
						wfHelper.workflow as Workflow,
						message,
						stage ?? wfHelper.stage
					);
					dialogCloser.closeModal();
					info('Updated stage successfully');
				} catch (e) {
					_logError(e);
					showError(
						'Sorry, an error occurred trying to bypass the stage. Please try again later.'
					);
				}
			}}
		/>
	);
};

export default BypassStageDialog;
