import React, { useCallback, useState } from 'react';
import {
	AssetCollection,
	AssetVersion,
} from '../../workflow/workflows/types/workflow.types';
import { useAxios } from 'hooks';
import { Loading } from 'components/index';
import { DownshiftMulti } from 'components/new-downshift/DownshiftSelect';

const CollectionAssetSelect = ({
	collection,
	onChange,
}: {
	collection: AssetCollection;
	onChange: (ac: AssetCollection) => void;
}) => {
	const assetStore = useAxios<AssetVersion>('assets');
	const [allAssets, setAllAssets] = useState<AssetVersion[]>();
	React.useEffect(() => {
		assetStore.findAll().then(setAllAssets);
		// eslint-disable-next-line
	}, []);
	const [editedCollection, setEditedCollection] = useState(collection.assets);

	const doesNotAlreadyContain = useCallback(
		(asset: AssetVersion) => editedCollection.indexOf(asset._id) === -1,
		[editedCollection]
	);

	const onSelectChange = (
		updatedSelections: Array<{ label: string; value: string }>
	) => {
		setEditedCollection([...updatedSelections.map((o) => o.value)]);
		onChange({
			...collection,
			assets: [...updatedSelections.map((o) => o.value)],
		});
	};

	return !allAssets ? (
		<Loading
			label="Loading assets.."
			alignItems="center"
			justifyContent="center"
		/>
	) : (
		<DownshiftMulti
			selected={
				editedCollection?.map((a) => ({
					value: a,
					label: allAssets.find((m) => m._id === a)?.fileName,
				})) as Array<{ value: string; label: string }>
			}
			options={allAssets
				.filter(doesNotAlreadyContain)
				.map((asset) => ({ value: asset._id, label: asset.fileName }))}
			labelText="Assets"
			onChange={onSelectChange}
		/>
	);
};

export default CollectionAssetSelect;
