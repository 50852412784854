import { navigate } from '@reach/router';
import auth0, {
	Auth0DecodedHash,
	Auth0Error,
	AuthOptions,
	AuthorizeOptions,
	CrossOriginLoginOptions,
} from 'auth0-js';
import { computed } from 'mobx';
import { applySnapshot, Instance, types } from 'mobx-state-tree';
import React, { useState } from 'react';
import { Infer } from 'types/globals';
import { findAsObject, requiredValue, saveNode } from '../utils/common';
import { _logError, _logWarning } from '../utils/common/log';
import { AuthUserModel } from '../utils/models/AuthUserModel';

type AuthUser = {
	given_name: string;
	family_name: string;
	nickname: string;
	name: string;
	picture: string;
	locale: string;
	updated_at: Date;
	email: string;
	email_verified: boolean;
	iss: string;
	sub: string;
	aud: string;
	iat: Date;
	exp: Date;
	nonce: string;
};

const domain: string = requiredValue(process.env.REACT_APP_AUTH0_DOMAIN);
const clientID: string = requiredValue(process.env.REACT_APP_AUTH0_CLIENT_ID);

const auth0Options: AuthOptions = {
	domain: domain,
	audience: `https://api.romeportal.com`, // maybe make this configurable
	clientID: clientID,
	redirectUri: `${window.location.protocol}//${window.location.host}/callback`,
	responseType: 'id_token token',
	scope: 'openid profile email',
};

const auth0Instance = new auth0.WebAuth(auth0Options);

const authStorageKey = 'rome_auth';
type AuthState = {
	authUser?: AuthUser;
	idToken?: string;
	accessToken?: string;
	refreshToken?: string;
	expiresAt?: number;
};
function authStoreReducer(
	state: AuthState,
	action: { type: 'setStorage' | 'clear'; payload?: AuthState }
) {
	switch (action.type) {
		case 'setStorage':
			return { ...state, ...(action?.payload as AuthState) };
		case 'clear':
			return {
				...state,
				authUser: undefined,
				idToken: '',
				accessToken: '',
				expiresAt: -1,
				refreshToken: '',
			};
	}
}

export const NewAuthStore = () => {
	const [authUser, setAuthUser] = useState<AuthUser>();
	const [idToken, setIDToken] = useState<String>('');
	const [accessToken, setAccessToken] = useState<string>('');
	const [refreshToken, setRefreshToken] = useState<string>('');
	const [expiresAt, setExpiresAt] = useState<Number>(-1);
	const isAuthenticated = (): boolean => {
		return !!idToken && !!authUser && Date.now() < expiresAt;
	};

	const [authState, dispatchAuth] = React.useReducer(authStoreReducer, {
		authUser: undefined,
		accessToken: '',
		expiresAt: -1,
		refreshToken: '',
		idToken: '',
	});

	const setSession = (tokens: any) => {
		const authUser = AuthUserModel.create({
			...tokens.idTokenPayload,
		});

		dispatchAuth({
			type: 'setStorage',
			payload: {
				idToken: tokens.idToken,
				accessToken: tokens.accessToken,
				expiresAt: Date.now() + tokens.expiresIn * 1000,
				authUser: authUser,
			},
		});
		setAuthed(true);
		setAuthUser(authUser);
		saveNode(authStorageKey, {
			idToken: tokens.idToken,
			accessToken: tokens.accessToken,
			expiresAt: Date.now() + tokens.expiresIn * 1000,
			authUser: authUser,
		});

		return navigate('/admin').then(() => true);
	};

	const [authenticating] = useState(false);
	const [authed, setAuthed] = useState(false);

	const handleAuthentication = async () => {
		if (authProvider.isAuthenticated) return navigate('/admin');
		if (
			localStorage.getItem('rome_auth') &&
			JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
		)
			return await navigate('/admin');

		return new Promise((resolve, reject) => {
			auth0Instance.parseHash(async (err, authResult) => {
				if (err) {
					return reject(err);
				} else if (authResult && !hasRequiredFields(authResult)) {
					_logWarning(authResult);
					return reject(Error('Missing Auth0 properties from result'));
				}

				if (authResult) setSession(authResult);
				await navigate('/admin');
				return resolve(true);
			});
		});
	};
	const clearSession = () => {
		setIDToken('');
		setAccessToken('');
		setExpiresAt(-1);
		setAuthUser({} as AuthUser);
		setRefreshToken('');
		setAuthed(false);
		saveNode(authStorageKey, {
			idToken: '',
			accessToken: '',
			expiresAt: -1,
			authUser: {},
		});
	};
	return {
		authState,
		dispatchAuth,
		setSession,
		clearSession,
		isAuthenticated,
		accessToken,
		authUser,
		auther: auth0Instance,
		hasRequiredFields,
		refreshToken,
		expiresAt,
		authed,
		idToken,
		handleAuthentication,
		authenticating,
	};
};
export type Auth0Result = Required<
	Pick<
		Auth0DecodedHash,
		'accessToken' | 'idToken' | 'idTokenPayload' | 'expiresIn'
	>
>;
const hasRequiredFields = (adh: Auth0DecodedHash): adh is Auth0Result =>
	!!adh.accessToken &&
	!!adh.idToken &&
	!!adh.idTokenPayload &&
	!!adh.idTokenPayload.exp;

export class AuthProvider {
	@computed
	public get accessToken(): string | null {
		return this.authStore.accessToken;
	}

	@computed
	public get idToken(): string | null {
		return this.authStore.idToken;
	}

	@computed
	public get authUser(): AuthUser | null {
		return this.authStore.authUser;
	}

	@computed
	get isAuthenticated() {
		return this.authStore.isAuthenticated;
	}
	private sessionRenewal?: number;

	constructor(
		private readonly auth0Instance: auth0.WebAuth,
		public readonly authStore: AuthStore
	) {
		this.scheduleRenewal();
	}

	public get auth0Service() {
		return auth0Instance;
	}

	private scheduleRenewal = (): void => {
		if (this.authStore.isAuthenticated) {
			const expiryTolerance = 60000;
			// @ts-ignore
			this.sessionRenewal = setTimeout(
				this.renewSession,
				this.authStore.expiresAt - Date.now() - expiryTolerance
			);
		}
	};

	public renewSession = () =>
		this.auth0Instance.checkSession(
			{
				...auth0Options,
				usePostMessage: true,
			},
			(err: null | Auth0Error, response: any) => {
				if (err) {
					_logError(err);
				} else {
					this.authStore.setSession(response);
					this.scheduleRenewal();
				}
			}
		);

	signIn = (options?: AuthorizeOptions) => {
		this.auth0Instance.authorize(options);
	};

	signInWithOffice365 = () => this.signIn({ connection: 'ROMEAzureAD' });

	signInWithGoogle = () => this.signIn({ connection: 'google-oauth2' });

	signInWithEmailPassword = ({
		email,
		password,
	}: Pick<CrossOriginLoginOptions, 'email' | 'password'>) => {
		this.auth0Instance.login({ email, password }, (err) => {
			_logError(err);
			window.location.hash = err?.error_description as string;
		});
	};
	handleAuthentication = () =>
		new Promise((resolve, reject) => {
			this.auth0Instance.parseHash((err, authResult) => {
				if (err) {
					return reject(err);
				}

				if (!authResult) {
					return reject(Error('No result from Auth0'));
				}

				if (!hasRequiredFields(authResult)) {
					_logWarning(authResult);
					return reject(Error('Missing Auth0 properties from result'));
				}
				this.authStore.setSession(authResult);
				return resolve();
			});
		});

	signOut = () => {
		this.authStore.clearSession();

		this.auth0Instance.logout({
			returnTo: window.location.origin,
		});

		return navigate('/auth');
	};
}

const AuthStoreModelInferred = types
	.model('AuthStore', {
		authUser: types.maybeNull(AuthUserModel),
		idToken: types.maybeNull(types.string),
		accessToken: types.maybeNull(types.string),
		refreshToken: types.maybeNull(types.string),
		expiresAt: types.optional(types.number, -1),
	})
	.views((self) => ({
		get isAuthenticated(): boolean {
			return !!self.idToken && !!self.authUser && Date.now() < self.expiresAt;
		},
	}))
	.actions((self) => ({
		setSession(tokens: Auth0Result): void {
			self.idToken = tokens.idToken;
			self.accessToken = tokens.accessToken;
			self.expiresAt = Date.now() + tokens.expiresIn * 1000;

			self.authUser = AuthUserModel.create({
				...tokens.idTokenPayload,
			});

			saveNode(authStorageKey, self);
		},

		clearSession(): void {
			applySnapshot(self, {});
			saveNode(authStorageKey, self);
		},
	}));

export interface AuthStoreModel extends Infer<typeof AuthStoreModelInferred> {}
export const AuthStoreModel: AuthStoreModel = AuthStoreModelInferred;

export interface AuthStore extends Instance<AuthStoreModel> {}

export let authStore: AuthStore;
try {
	authStore = AuthStoreModel.create(findAsObject(authStorageKey) ?? {});
} catch (err) {
	_logError(err);
	authStore = AuthStoreModel.create({});
}

export const authProvider = new AuthProvider(auth0Instance, authStore);
export const AuthContext = React.createContext(authProvider);
