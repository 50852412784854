import React, { useRef, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import { fromEvent } from 'rxjs';
import { takeWhile, tap, filter } from 'rxjs/operators';
import styled from 'styled-components';

import { setPosition } from './Menu.helpers';
import { MenuProps } from './Menu.types';
import { useKeybind } from '../hooks';

const Menu = ({ isVisible, onClose, ...props }: MenuProps) => {
	const menuRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const divElement = menuRef?.current;
		const clickOutsideMenu$ = fromEvent(document, 'mousedown')
			.pipe(
				takeWhile(() => isVisible),
				filter(() => isVisible),
				filter(() => !!divElement),
				filter((event) => !divElement?.contains(event.target as Node)),
				filter(
					(event) => !divElement?.parentElement?.contains(event.target as Node)
				),
				tap(onClose)
			)
			.subscribe();
		return () => {
			clickOutsideMenu$.unsubscribe();
		};
	}, [isVisible, onClose]);

	useEffect(() => {
		const divElement = menuRef?.current;
		if (divElement) setPosition(divElement);
	}, [menuRef]);

	useKeybind('Escape', onClose, menuRef);

	const transitions = useTransition(isVisible, null, {
		config: {
			friction: 32,
			mass: 1,
			tension: 1000,
		},
		enter: {
			transform: 'translate3d(0, 0px, 0)',
			opacity: 1,
		},
		from: {
			transform: 'translate3d(0, -15px, 0)',
			opacity: 0,
		},
		leave: {
			transform: 'translate3d(0, -15px, 0)',
			opacity: 0,
		},
	});

	const menuWidth = props.menuWidth ? { width: props.menuWidth } : {};
	const defaultStyles = {
		...props.style,
		...menuWidth,
	};
	return (
		<>
			{transitions.map(
				({ item: showAnimation, props: animation, key }) =>
					showAnimation && (
						<animated.div
							aria-expanded={isVisible}
							className={props.className}
							key={key}
							style={{ ...animation, ...defaultStyles }}
							ref={menuRef}
							onClick={(e) => e.stopPropagation()}
						>
							{props.children}
						</animated.div>
					)
			)}
		</>
	);
};

export default styled(Menu)`
	background: white;
	border-radius: 5px;
	border: 1px solid lightgrey;
	box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.05),
		0 1px 1px 1px rgba(0, 0, 0, 0.05), 0 2px 3px -1px rgba(0, 0, 0, 0.075);
	position: absolute;
	z-index: 5;
	min-width: fit-content;

	&:after {
		bottom: -2em;
		content: '';
		left: -2em;
		position: absolute;
		right: -2em;
		top: -2em;
		pointer-events: none;
	}
`;
