import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { useThemeContext } from 'context/useThemeContext';
import { ScrollCard, ScrollTable } from 'components/index';
import { RomeSwal } from 'components/alert';
import { SecondaryButton } from 'components/buttons.styled-components';
import { StyledLink } from 'components/dam-assets/components/asset-card.styled-components';
import { AnchorSpan } from 'components/dashboard.component/components/proxy-panel.styled.component';
import { join, keysIn } from 'lodash';
import { NotificationsContext } from 'components/notifications';
import React, { useContext } from 'react';
import { ButtonGroup, Container } from 'reactstrap';
import { generateID } from 'components/workflow/workflow-templates/components/stage-cards/Stage.helpers';
import { useWorkflowContext } from '../../../context/useWorkflowStore';
import { Workflow, WorkflowTemplate } from './types';

export const SKUPanel = () => {
	const { workflow, updateOne } = useWorkflowContext();
	const { info } = useContext(NotificationsContext);
	const { defaults } = useThemeContext();
	const [rows, setRows] = React.useState<any[]>(
		workflow?.skuMetadata?.rows ?? []
	);

	const cloneSKU = async (row: {
		fields: string[];
		values: Record<string, string | string[]>;
	}) => {
		const newRow = {
			fields: row.fields,
			values: row.values,
			_id: generateID(),
		};
		setRows((rows) => [...rows, newRow]);
		const updatedRows = [...rows, newRow];
		const updatedWorkflow = {
			...workflow,
			skuMetadata: { ...workflow?.skuMetadata, rows: updatedRows },
		} as Workflow;
		await updateOne(updatedWorkflow._id, updatedWorkflow);
		return await navigate(
			`/admin/workflow/workflows/${workflow?._id}/skus/${newRow?._id}`
		);
	};

	const addRow = async () => {
		const newRow = {
			fields: keysIn(
				(workflow?.templateUsed as WorkflowTemplate).metadata?.values
			),
			values: {},
			_id: generateID(),
		};

		setRows((rows) => [...rows, newRow]);
		const updatedRows = [...rows, newRow];
		const updatedWorkflow = {
			...workflow,
			skuMetadata: { ...workflow?.skuMetadata, rows: updatedRows },
		} as Workflow;
		await updateOne(updatedWorkflow._id, updatedWorkflow);
		return await navigate(
			`/admin/workflow/workflows/${workflow?._id}/skus/${newRow?._id}`
		);
	};

	const deleteRow = async (row: any) => {
		const { isConfirmed } = await RomeSwal.fire({
			icon: 'question',
			iconColor: defaults?.danger,
			title: 'Are you sure?',
			text: 'Deleting the SKU can not be undone.',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel',
			showCancelButton: true,
		});
		if (!isConfirmed) return;
		setRows((rows) => rows.filter((a) => a._id !== row._id));
		const updatedWorkflow = {
			...workflow,
			skuMetadata: { rows: rows.filter((a) => a._id !== row._id) },
		} as Workflow;

		await updateOne(updatedWorkflow._id, updatedWorkflow);
		info('Successfully deleted row.');
	};

	const getValue = (row: any, key: string) => {
		if (!row.values) return '';

		if (row.values && row.values[key]) {
			return Array.isArray(row?.values![key])
				? join(row?.values![key], ',')
				: row?.values![key];
		}
	};
	return (
		<Container fluid>
			<ScrollCard>
				<ScrollTable className="w-100">
					<thead>
						<tr>
							<th style={{ width: '5%' }}></th>
							{(
								(workflow?.templateUsed as WorkflowTemplate)?.skuMetadata
									?.fields ?? []
							).map((field, idx) => (
								<th key={idx}>{field}</th>
							))}
							<th></th>
						</tr>
					</thead>
					<tbody>
						{rows.map((row) => {
							return (
								<tr key={row._id} style={{ verticalAlign: 'middle' }}>
									<td>
										<ButtonGroup about={'editing-sku'}>
											<AnchorSpan
												style={{ whiteSpace: 'nowrap', marginRight: 5 }}
												onClick={() => cloneSKU(row)}
											>
												Clone
											</AnchorSpan>
											<div
												style={{
													width: 1,
													height: `100%`,
													borderRight: `1px solid #ccc`,
												}}
											/>
											<StyledLink
												to={`/admin/workflow/workflows/${workflow?._id}/skus/${row?._id}`}
											>
												{<>Edit</>}
											</StyledLink>
										</ButtonGroup>
									</td>
									{(
										(workflow?.templateUsed as WorkflowTemplate)?.skuMetadata
											?.fields ?? []
									).map((key) => {
										return (
											<td
												style={{
													padding: 5,
													paddingTop: 10,
													paddingBottom: 10,
												}}
												key={key}
											>
												<>{getValue(row, key)}</>
											</td>
										);
									})}
									<td>
										<FontAwesomeIcon
											style={{ cursor: 'pointer' }}
											onClick={() => deleteRow(row)}
											icon={faTimesCircle}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<tr>
							<td>
								<SecondaryButton onClick={addRow}>Add SKU</SecondaryButton>
							</td>
						</tr>
					</tfoot>
				</ScrollTable>
			</ScrollCard>
		</Container>
	);
};
