import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageSubheading } from 'components/index';
import { AssetChartSettingsDialog } from 'components/resource-management/dialogs/AssetChartSettingsDialog';
import { useThemeContext } from 'context/useThemeContext';
import * as d3 from 'd3';
import React, { useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { generateID } from 'utils/common';

const LineChart = ({
	data,
	width,
	yearOptions,
	selectedChartYear,
	setSelectedChartYear,
	height,
	header,
	xLabel,
	yLabel,
	term,
}: {
	data: any;
	width: number;
	yearOptions: string[];
	selectedChartYear: number;
	setSelectedChartYear: (number: number) => void;
	height: number;
	header: string;
	xLabel: string;
	yLabel: string;
	term: 'asset' | 'workflow';
}) => {
	const { defaults } = useThemeContext();
	const instanceId = generateID();
	const chartWidth = width,
		chartHeight = height;
	useEffect(() => {
		drawChart();
		window.addEventListener('resize', drawChart);
		return () => {
			window.removeEventListener('resize', drawChart);
		};
		//eslint-disable-next-line
	}, [data, defaults]);

	function drawChart() {
		if (data && defaults && data.every((a: any) => a.label)) {
			if (d3.select(`#container-${instanceId} svg`)) {
				document.querySelector(`#container-${instanceId} svg`)?.remove();
			}
			const lineOpacity = '0.25';
			const lineOpacityHover = '0.85';
			const otherLinesOpacityHover = '0.1';
			const lineStroke = '1.5px';
			const lineStrokeHover = '2.5px';

			const circleOpacity = '0.85';
			const circleOpacityOnLineHover = '0.25';
			const circleRadius = 3.65;
			const circleRadiusHover = 7;
			// set the dimensions and margins of the graph
			const margin = { top: 20, right: 20, bottom: 50, left: 70 },
				width = chartWidth - margin.left - margin.right,
				height = chartHeight - margin.top - margin.bottom;

			// parse the date / time

			// set the ranges
			const x = d3.scaleTime().range([0, width]);
			const y = d3.scaleLinear().range([height, 0]);

			// define the line
			const valueline = d3
				.line()
				.x(function (d: any) {
					return x(d.date);
				})
				.y(function (d: any) {
					return y(d.value);
				});

			// append the svg obgect to the body of the page
			// appends a 'group' element to 'svg'
			// moves the 'group' element to the top left margin
			const svg = d3
				.select(`#container-${instanceId}`)
				.append('svg')
				.style('overflow', 'visible')

				.attr('width', width + margin.left + margin.right)
				.attr('height', height + margin.top + margin.bottom)
				.append('g')
				.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
			/* Format Data */
			const mapped = data.map((a: any) => ({
				date: new Date(a.label),
				value: a.value,
				...a,
			}));

			// Scale the range of the data
			x.domain(
				d3.extent(mapped, function (d: any) {
					return d.date;
				}) as any
			);
			y.domain([
				0,
				d3.max(mapped, function (d: any) {
					return d.value;
				}) as any,
			]);

			// Add the valueline path.
			svg
				.append('path')
				.data([mapped])
				.attr('class', 'line')
				.attr('stroke-width', 2)
				.attr('stroke', defaults?.primary)
				.attr('fill', 'none')
				.attr('d', valueline);

			svg
				.selectAll('.line-group')
				.data(mapped)
				.enter()
				.append('g')
				.attr('class', 'line-group')
				.on('mouseover', function (d, i: any) {
					svg
						.append('text')
						.attr('class', 'title-text')
						.style('fill', defaults?.secondary)
						.style('background', '#fff')
						.text(i.tooltipContent)
						.attr('text-anchor', 'middle')
						.attr('x', (width - margin.bottom) / 2)
						.attr('y', 5);
				})
				.on('mouseout', function (d) {
					svg.select('.title-text').remove();
				})
				.append('path')
				.attr('class', 'line')
				.style('stroke', (d, i) => defaults?.primary)
				.style('opacity', lineOpacity)
				.on('mouseover', function (d) {
					d3.selectAll('.line').style('opacity', otherLinesOpacityHover);
					d3.selectAll('.circle').style('opacity', circleOpacityOnLineHover);
					d3.select(this)
						.style('opacity', lineOpacityHover)
						.style('stroke-width', lineStrokeHover)
						.style('cursor', 'pointer');
				})
				.on('mouseout', function (d) {
					d3.selectAll('.line').style('opacity', lineOpacity);
					d3.selectAll('.circle').style('opacity', circleOpacity);
					d3.select(this)
						.style('stroke-width', lineStroke)
						.style('cursor', 'none');
				});

			/* Add circles in the line */
			svg
				.selectAll('circle-group')
				.data(mapped)
				.enter()
				.append('g')
				.style('fill', (d, i) => defaults?.secondary)
				.selectAll('circle')
				.data(mapped)
				.enter()
				.append('g')
				.attr('class', 'circle')
				.on('mouseover', function (d, i: any) {
					d3.select(this)
						.style('cursor', 'pointer')
						.append('text')
						.attr('class', 'text')
						.style('font-size', '14px')
						.attr('fill', defaults?.primary)
						.attr('fill-rule', 'evenodd')
						.text(`${i.tooltipContent}`)
						.attr('x', (d: any) => x(d.date))
						.attr('y', (d: any) => y(d.value) - 15);
				})
				.on('mouseout', function (d) {
					d3.select(this)
						.style('cursor', 'none')
						// @ts-ignore
						.transition()
						.duration(300)
						.selectAll('.text')
						.remove();
				})
				.append('circle')
				.attr('cx', (d: any) => x(d.date))
				.attr('cy', (d: any) => y(d.value))
				.attr('r', circleRadius)
				.style('opacity', circleOpacity)
				.on('mouseover', function (d) {
					d3.select(this)
						// @ts-ignore
						.transition()
						.duration(300)
						.attr('r', circleRadiusHover);
				})
				.on('mouseout', function (d) {
					// @ts-ignore
					d3.select(this).transition().duration(300).attr('r', circleRadius);
				});

			// Add the x Axis
			svg
				.append('g')
				.attr('transform', 'translate(0,' + height + ')')

				// @ts-ignore
				.call(d3.axisBottom(x).tickFormat(d3.timeFormat('%b') as any));

			// Add the y Axis
			// @ts-ignore
			svg.append('g').call(d3.axisLeft(y));
		}
	}

	const [showSettings, setShowSettings] = React.useState(false);
	return (
		<>
			{showSettings && (
				<AssetChartSettingsDialog
					term={term}
					options={yearOptions}
					onClose={() => setShowSettings(false)}
					selectedChartYear={selectedChartYear}
					setSelectedChartYear={setSelectedChartYear}
				/>
			)}
			<Card className="h-100 rome-card">
				<CardHeader>
					<PageSubheading text={header} />
					<FontAwesomeIcon
						icon={faCog}
						style={{ cursor: 'pointer' }}
						onClick={() => setShowSettings(true)}
					/>
				</CardHeader>
				<CardBody>
					<label
						style={{
							fontSize: 12,
							position: 'absolute',
							left: -15,
							top: '50%',
							transform: 'rotate(-90deg)',
						}}
					>
						{yLabel}
					</label>
					<label
						style={{
							position: 'absolute',
							fontSize: 12,
							bottom: 40,
							left: '35%',
						}}
					>
						{xLabel}
					</label>
					<div id={`container-${instanceId}`} style={{ overflow: 'hidden' }} />
				</CardBody>
				<CardFooter></CardFooter>
			</Card>
		</>
	);
};

export default LineChart;
