import { Link } from '@reach/router';
import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import styled from 'styled-components';
import anchor from '../core-ui/components/anchor';
import { fsNormal } from '../core-ui/ui/typography';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const Cell = styled.td`
	${fsNormal};
`;

export const CellLink = styled(Link)`
	${anchor};
	// color: ${defaults?.secondary};
	// text-decoration: underline;

	&:hover {
		// text-decoration: none;
		// color: ${defaults?.secondaryHighlighted};
	}
`;
