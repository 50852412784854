import React, { useState } from 'react';
import styled from 'styled-components';

import { buildClassList, generateID } from '../../../utils/common';
import defaultAsset from '../../../assets/images/default-asset.jpg';
import { AssetVersion } from '../../workflow/workflows/types/workflow.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { StyledLink } from './asset-card.styled-components';
import { extractPreviewURL } from './asset-card.component';

// styled components
const DefaultAsset = styled.div`
	background: url(${defaultAsset}) center center / cover no-repeat;
	height: 172px;
	position: relative;
	top: 10px;
	margin-top: -1.25em;
`;

const DefaultZipAsset = styled.div`
	padding: 3.15rem;
	.asset {
		display: flex;
		align-items: center;
		background: url(/images/zip.svg) center center / cover no-repeat;
		height: 70px;
		position: relative;
		top: 10px;
		margin-top: -1.35em;
		width: 70px;
		margin-left: auto;
		margin-right: auto;
	}
`;

const Thumbnail = styled.img`
	display: table;
	box-shadow: 0 0.125rem 0.5625rem rgba(0, 0, 0, 0.13);
	height: 150px;
	margin-left: auto;
	margin-right: auto;
	width: auto;
	max-width: 100%;
	margin: 0 auto;
	transition: transform 0.2s;
`;

// interface
interface Props {
	className?: string;
	src?: string;
	alt?: string;
	asset: AssetVersion;
	hideCustomPreview?: boolean;
}
export const SupportedMimetypes = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp',
	'image/tiff',
	'application/pdf',
	'application/eps',
	'application/ghostscript',
	'application/postscript',
	'image/vnd.adobe.photoshop',
] as const;
export const ViewableMimetypes = [
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp',
	'image/tiff',
	'application/pdf',
	'application/eps',
	'application/ghostscript',
	'application/postscript',
] as const;

export const supportsCustomPreview = (asset: AssetVersion) =>
	!SupportedMimetypes.some((type) => asset.type === type);

export const PREVIEW_MAX_SIZE_BYTES = 200e6;
export type SupportedMimetypes = typeof SupportedMimetypes[number];
// component
const AssetCardThumbnail = (props: Props) => {
	const [isValid, setValid] = useState(true);
	const { src, alt, className, asset, hideCustomPreview } = props;
	const showMissingPreview = !extractPreviewURL(asset) && !hideCustomPreview;
	const previewTarget = `missingPreview${generateID()}`;
	const renderMissingPreview = () =>
		showMissingPreview ? (
			<>
				<div style={{ position: 'relative', left: 150, bottom: 60 }}>
					<UncontrolledPopover
						trigger={'hover'}
						placement={'left'}
						positionFixed={true}
						target={previewTarget}
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
						}}
					>
						<PopoverHeader>Missing Preview</PopoverHeader>
						<PopoverBody>
							<StyledLink to={`/admin/dam/assets/${asset?._id}/edit`}>
								Add a preview
							</StyledLink>
						</PopoverBody>
					</UncontrolledPopover>
					<FontAwesomeIcon id={previewTarget} icon={faEyeSlash} />
				</div>
			</>
		) : null;

	if (isValid && src) {
		return (
			<Thumbnail
				onError={() => setValid(false)}
				className={buildClassList('card-thumbnail', className)}
				src={src}
				alt={alt}
			/>
		);
	} else if (asset?.type === 'application/zip') {
		return (
			<DefaultZipAsset>
				{renderMissingPreview()}
				<div className="asset"></div>
			</DefaultZipAsset>
		);
	} else {
		return (
			<div className={'position-relative'}>
				{renderMissingPreview()}

				<DefaultAsset />
			</div>
		);
	}
};

export default AssetCardThumbnail;
