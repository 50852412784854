import { SiteDefaults } from 'context/useThemeContext';
import React from 'react';
import styled from 'styled-components';
import storageModel from 'utils/models/StorageModel';
import { fsNormal } from '../../core-ui/ui/typography';

// const { colors } = themeStore.selectedTheme;
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const ItemOptionList = styled.ul`
	${fsNormal};
	background-color: var(--card-background-color);
	color: var(--text-color);
	border-radius: 0 0 4px 4px;
	border: 1px solid var(--darkGrey);
	margin-top: -3px;
	padding-inline-start: 0 !important;
	padding: 8px 0;
`;

export const ItemOption = styled.li`
	cursor: pointer;
	list-style: none;
	padding: 0.25em 0.5em;

	&:hover {
		background-color: ${defaults?.secondaryHighlighted} !important;
		color: var(--white);
	}
`;

export const DownshiftButton = styled.button`
	display: flex;
	position: absolute;
	align-items: center;
	border: none;
	bottom: 0;
	cursor: pointer;
	flex-direction: column;
	background: transparent;
	height: 47px;
	justify-content: center;
	outline: none;
	right: 11px;
	svg {
		fill: #222;
	}
	&:focus {
		outline: none;
	}
`;

export const ArrowIcon = (isOpen: { isOpen: boolean }) => (
	<svg
		viewBox="0 0 20 20"
		preserveAspectRatio="none"
		width={16}
		fill="transparent"
		stroke={'#ccc'}
		strokeWidth="1.1px"
		transform={isOpen.isOpen ? 'rotate(180)' : 'rotate(0)'}
	>
		<path d="M1,6 L10,15 L19,6" />
	</svg>
);

export const XIcon = () => (
	<svg
		viewBox="0 0 20 20"
		preserveAspectRatio="none"
		width={12}
		fill="transparent"
		stroke={'#222'}
		strokeWidth="1.1px"
	>
		<path d="M1,1 L19,19" />
		<path d="M19,1 L1,19" />
	</svg>
);
