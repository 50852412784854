import { APIClient } from './APIClient';
import { AuthProvider, authProvider } from '../../context/AuthProvider';
import { getEnv, IAnyStateTreeNode } from 'mobx-state-tree';
import { requiredValue, throwErr } from '../common';
import { FileUploadProvider } from './FileUploadProvider';
import { SessionTimeoutService } from './SessionTimeoutService';
import LoggerService from './LoggingProvider';
import InsightsProvider from './InsightsProvider';
import ServiceWorkerProvider from './ServiceWorkerProvider';
import { MultipleFileUploadProvider } from './MultipleFileUploadProvider';

export * from './APIClient';
export * from '../../context/AuthProvider';
const loggerService = new LoggerService();
const romeApiClient = new APIClient(authProvider, loggerService);
const insightsProvider = new InsightsProvider();
const swProvider = new ServiceWorkerProvider();
const uploadProvider = new FileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);
const multipleFileUploadProvider = new MultipleFileUploadProvider(
	requiredValue(process.env.REACT_APP_ROME_API_ENDPOINT),
	authProvider
);

const sessionTimeoutProvider = new SessionTimeoutService(authProvider);

export interface RootEnv {
	readonly authProvider: AuthProvider;
	readonly apiClient: APIClient;
	readonly uploadProvider: FileUploadProvider;
	readonly multipleFileUploadProvider: MultipleFileUploadProvider;
	readonly sessionTimeoutProvider: SessionTimeoutService;
	readonly loggerService: LoggerService;
	readonly insightsProvider: InsightsProvider;
	readonly swProvider: ServiceWorkerProvider;
}

export const rootEnv: RootEnv = Object.freeze({
	authProvider: authProvider,
	apiClient: romeApiClient,
	uploadProvider,
	sessionTimeoutProvider,
	loggerService,
	insightsProvider,
	swProvider,
	multipleFileUploadProvider,
});

interface EnvGetter<T> {
	(node: IAnyStateTreeNode): T;
}

const envGetter = <K extends keyof RootEnv>(key: K): EnvGetter<RootEnv[K]> => (
	node
) =>
	getEnv<RootEnv>(node)[key] ??
	throwErr(
		'Node is not part of the tree! Make sure to attach before getting env.'
	);

export const getClient = envGetter('apiClient');
export const getAuthProvider = envGetter('authProvider');
export const getUploader = envGetter('uploadProvider');
export const getLogger = envGetter('loggerService');
export const getSessionTimeoutService = envGetter('sessionTimeoutProvider');
export const getInsightsProvider = envGetter('insightsProvider');
export const getSwProvider = envGetter('swProvider');
export const getMultipleFileUploadProvider = envGetter(
	'multipleFileUploadProvider'
);
