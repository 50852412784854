import {
	faCog,
	faSignOutAlt,
	faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import { useThemeContext } from 'context/useThemeContext';
import React, { lazy, Suspense } from 'react';
import {
	Collapse,
	Container,
	DropdownMenu,
	Nav,
	Navbar,
	NavbarToggler,
	NavItem,
	Progress,
	UncontrolledDropdown,
} from 'reactstrap';
import styled from 'styled-components';
import { toRGBA } from 'utils/common/color.utils';
import { authProvider } from 'utils/core';
import { useUpload } from '../../context/file-upload-provider.component';
import { RoleContext } from '../../context/PermissionsContext';
import {
	default as themeStore,
	default as ThemeStore,
} from '../../core-ui/models/ThemeStore';
import { useAuthContext } from '../../utils/auth';
import { useToggler } from '../../utils/common/hooks';
import { useModalCloser, useModalCreator } from '../../utils/ModalStack';
import { Avatar } from '../avatar.component';
import { AssetUploadDialog } from '../dam-assets/components/asset-upload-dialog.component';
import { DropDownMenuItem, DropDownToggle } from '../drop-down-menu.component';
import RenderWhen from '../render-when.component';
import { Link } from '../ui';
import {
	AppLogo,
	AvatarMenuToggle,
	LogoContainer,
	StyledNavLink,
} from './admin-navigation.styled-components';

import NotificationBell from 'components/notification-center/components/notificationBell';

const AdminNavigation = React.memo(
	({ editThemeView }: { editThemeView?: boolean }) => {
		const { uploadProgress } = useUpload();
		const auth = authProvider;
		const userStore = useAuthContext();
		const { canViewByRole } = React.useContext(RoleContext);
		const [isOpen, toggle] = useToggler(false);
		const modalStack = useModalCreator();
		const { defaults } = useThemeContext();
		const modalCloser = useModalCloser();

		const afterSubmit = React.useCallback(() => {
			modalCloser.closeModal();
			return navigate('/admin/dam/assets/edit-custom-upload');
		}, [modalCloser]);

		const openUploadDialog = React.useCallback(() => {
			modalStack.addModal(<AssetUploadDialog afterSubmit={afterSubmit} />);
		}, [modalStack, afterSubmit]);

		// useEffect(() => {
		// 	if (userStore.currentUser._id)
		// 		// todo Fix email in user schema
		// 		initializeIntercom(userStore.currentUser as any);
		// 	return () => shutdownIntercom();
		// }, [auth.isAuthenticated, userStore.currentUser]);

		const CustomNavbar = styled(Navbar)`
			background-color: ${defaults?.navbarBackground};
			color: ${defaults?.navbarForeground};

			.nav-item a,
			.nav-item button {
				color: ${defaults?.navbarForeground};

				&:hover {
					color: ${toRGBA(defaults?.navbarForeground, 0.6)};
				}
			}

			a.dropdown-item,
			button.dropdown-item {
				color: ${defaults?.primary};
			}
		`;

		const NavbarToggle = styled(NavbarToggler)`
			/* ANIMATED X */
			border: none;

			&[aria-expanded='true'] {
				.icon-bar {
					&:nth-of-type(1) {
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
						-webkit-transform-origin: 10% 10%;
						-ms-transform-origin: 10% 10%;
						transform-origin: 10% 10%;
					}
					&:nth-of-type(2) {
						opacity: 0;
						filter: alpha(opacity=0);
					}
					&:nth-of-type(3) {
						-webkit-transform: rotate(-45deg);
						-ms-transform: rotate(-45deg);
						transform: rotate(-45deg);
						-webkit-transform-origin: 10% 90%;
						-ms-transform-origin: 10% 90%;
						transform-origin: 10% 90%;
					}
				}
			}
			.icon-bar {
				width: 22px;
				display: block;
				height: 2px;
				background-color: ${defaults?.primary};
				-webkit-transition: all 0.2s;
				transition: all 0.2s;
				& + .icon-bar {
					margin-top: 4px;
				}
				&:nth-of-type(1) {
					-webkit-transform: rotate(0);
					-ms-transform: rotate(0);
					transform: rotate(0);
				}
				&:nth-of-type(2) {
					opacity: 1;
					filter: alpha(opacity=100);
				}
				&:nth-of-type(3) {
					-webkit-transform: rotate(0);
					-ms-transform: rotate(0);
					transform: rotate(0);
				}
			}
		`;
		/* 		const NotificationBell = lazy(() =>
			import('components/notification-center/components/notificationBell')
		); */
		const render = () => {
			const currentUser = userStore.currentUser;
			if (!defaults) return null;
			return (
				<>
					{currentUser && currentUser?._id ? (
						<>
							<CustomNavbar expand="md">
								<Container fluid className="px-5">
									<LogoContainer tag={Link} to="/admin">
										<AppLogo defaults={defaults} />
									</LogoContainer>
									<NavbarToggle
										onClick={toggle}
										aria-expanded={isOpen}
										aria-label="Toggle navigation"
									>
										<div
											style={{ border: '1px solid transparent' }}
											className="navbar-toggle x"
											data-toggle="collapse"
											data-target="#navbar-collapse-x"
										>
											<div
												style={{ border: '1px solid transparent' }}
												className="navbar-toggle x"
												data-toggle="collapse"
												data-target="#navbar-collapse-x"
											>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
												<span className="icon-bar"></span>
											</div>
										</div>
									</NavbarToggle>
									<Collapse isOpen={isOpen} navbar id="navbar-collapse-x">
										<Nav className="mr-auto" navbar>
											<UncontrolledDropdown nav inNavbar>
												<RenderWhen
													when={[
														'wfReadonly',
														'wfEditable',
														'canEditRoadblocks',
														'canViewRoadblocks',
														'canViewAssignments',
													].some(canViewByRole)}
												>
													<DropDownToggle
														nav
														caret
														className="cy-workflows-nav-link"
													>
														{themeStore._.baseWorkflow}
													</DropDownToggle>

													<DropdownMenu>
														{[
															'wfTemplatesReadonly',
															'wfTemplatesEditable',
														].some(canViewByRole) && (
															<DropDownMenuItem
																className="cy-workflows-template-link"
																tag={Link}
																to="workflow/templates"
															>
																Templates
															</DropDownMenuItem>
														)}
														{[
															'wfTemplatesReadonly',
															'wfTemplatesEditable',
														].some(canViewByRole) && (
															<DropDownMenuItem
																className="cy-workflows-link"
																tag={Link}
																to="workflow/workflows"
															>
																{`${themeStore._.workflow}s`}
															</DropDownMenuItem>
														)}
														{[
															'wfCollectionsEditable',
															'wfCollectionsReadonly',
														].some(canViewByRole) && (
															<DropDownMenuItem
																tag={Link}
																to="workflow/projects"
															>
																{`${themeStore._.workflowCollection}s`}
															</DropDownMenuItem>
														)}
														{canViewByRole('canViewAssignments') && (
															<DropDownMenuItem
																tag={Link}
																to="workflow/assignments"
															>
																My Assignments
															</DropDownMenuItem>
														)}
														{canViewByRole('canViewRoadblocks') && (
															<DropDownMenuItem
																tag={Link}
																to="workflow/roadblocks"
															>
																Roadblocks
															</DropDownMenuItem>
														)}
													</DropdownMenu>
												</RenderWhen>
											</UncontrolledDropdown>
											{editThemeView === false && (
												<UncontrolledDropdown nav inNavbar>
													<RenderWhen
														when={[
															'damReadOnly',
															'damEditable',
															'damUploadable',
														].some(canViewByRole)}
													>
														<DropDownToggle nav caret>
															{themeStore._.assetBase}
														</DropDownToggle>
														<DropdownMenu>
															{['damReadOnly', 'damEditable'].some(
																canViewByRole
															) && (
																<DropDownMenuItem tag={Link} to="dam/assets">
																	{`${themeStore._.asset}s`}
																</DropDownMenuItem>
															)}

															{canViewByRole('canViewDamCollections') && (
																<DropDownMenuItem
																	tag={Link}
																	to="dam/collections"
																>
																	{`${themeStore._.assetCollection}s`}
																</DropDownMenuItem>
															)}

															{canViewByRole('damUploadable') && (
																<DropDownMenuItem
																	onClick={() => openUploadDialog()}
																>
																	{`Upload`}
																</DropDownMenuItem>
															)}
														</DropdownMenu>
													</RenderWhen>
												</UncontrolledDropdown>
											)}

											{editThemeView === false &&
												canViewByRole('canViewReporting') && (
													<NavItem>
														<StyledNavLink tag={Link} to="reports">
															{themeStore._.report}s
														</StyledNavLink>
													</NavItem>
												)}

											{editThemeView === false &&
												canViewByRole('canViewFileSharing') && (
													<NavItem>
														<StyledNavLink tag={Link} to="filesharing">
															File Sharing
														</StyledNavLink>
													</NavItem>
												)}

											{/* {editThemeView === false &&
												canViewByRole('canViewResourceManagement') && 
												(
													<NavItem>
														<StyledNavLink tag={Link} to="resource-management">
															Resource Management
														</StyledNavLink>
													</NavItem>
												)} */}
										</Nav>
										{editThemeView === false && (
											<Nav className="ml-auto" navbar>
												{canViewByRole('canViewAdmin') && (
													<NavItem>
														<StyledNavLink tag={Link} to="dashboard">
															Admin
														</StyledNavLink>
													</NavItem>
												)}

												<UncontrolledDropdown
													nav
													inNavbar
													className="navbar__user-menu"
												>
													<AvatarMenuToggle nav caret>
														<Avatar user={currentUser} />
													</AvatarMenuToggle>
													<Suspense fallback={<div>Loading...</div>}>
														<NotificationBell />
													</Suspense>
													<DropdownMenu right>
														<DropDownMenuItem
															style={{
																color:
																	ThemeStore.selectedTheme.colors.primary +
																	' !important',
															}}
															tag={Link}
															to="profile"
														>
															<small>
																<FontAwesomeIcon
																	icon={faCog}
																	size={'1x'}
																	className="mr-2"
																	color={
																		ThemeStore.selectedTheme.colors.primary
																	}
																/>
															</small>
															Edit profile
														</DropDownMenuItem>
														<DropDownMenuItem divider />
														<DropDownMenuItem
															className="admin-menu-item"
															tag={Link}
															to="preferences"
														>
															<small>
																<FontAwesomeIcon
																	icon={faUserCog}
																	size={'1x'}
																	className="mr-2"
																	color={
																		ThemeStore.selectedTheme.colors.primary
																	}
																/>
															</small>
															My preferences
														</DropDownMenuItem>
														<DropDownMenuItem divider />
														<DropDownMenuItem
															className="admin-menu-item"
															onClick={() => {
																auth.signOut();
															}}
														>
															<small>
																<FontAwesomeIcon
																	icon={faSignOutAlt}
																	size={'1x'}
																	className="mr-2"
																	color={
																		ThemeStore.selectedTheme.colors.primary
																	}
																/>
															</small>
															Logout
														</DropDownMenuItem>
													</DropdownMenu>
												</UncontrolledDropdown>

												{/*<NavItem>*/}
												{/*	<NavLink tag={Link} to="#">*/}
												{/*		<img src={cart}*/}
												{/*		     className="Cart"*/}
												{/*		     alt="cart"*/}
												{/*		/>*/}
												{/*	</NavLink>*/}
												{/*</NavItem>*/}
											</Nav>
										)}
									</Collapse>
								</Container>
							</CustomNavbar>
							{uploadProgress && uploadProgress.total > 0 && (
								<Progress
									animated
									color="success"
									value={uploadProgress.percent}
								/>
							)}
						</>
					) : null}
				</>
			);
		};
		return render();
	}
);

export default AdminNavigation;
