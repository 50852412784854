import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteDefaults } from 'context/useThemeContext';
import { getRGBfromHEX } from 'utils/common';
import { SubmitButton } from 'components';
import { StyledNavLink } from 'components/admin-navigation/admin-navigation.styled-components';
import { MutedButton } from 'components/buttons.styled-components';
import { last } from 'lodash';
import React from 'react';
import {
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Container,
} from 'reactstrap';
import styled from 'styled-components';
import { Logo } from 'pages/views/login.view.styled';
import {
	StyledInput,
	StyledChangeFormButton,
} from 'pages/views/Register/components';
import googleBrand from '../../assets/images/google.svg';
import office365Brand from '../../assets/images/office-365.svg';

type previewProps = {
	selectedTheme: SiteDefaults;
};

const LoginPreviewComponent = (props: previewProps) => {
	const { selectedTheme } = props;
	const rgb = getRGBfromHEX(selectedTheme?.secondary);
	const background = selectedTheme?.loginBackground?.includes('blob')
		? selectedTheme?.loginBackground
		: `${process.env.REACT_APP_ROME_API_ENDPOINT}/preview/site/${
				selectedTheme?.account
		  }/${last(selectedTheme?.loginBackground.split('/'))}`;

	const BackgroundDiv = styled.div`
		height: 100%;
		width: 100%;
		background-size: cover;
		background-image: linear-gradient(
				to top,
				rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.7) 0%,
				rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.1) 100%
			),
			url(${background});
	`;

	const StyledContainer = styled(Container)`
		-webkit-transform: scale(0.5);
		-moz-transform: scale(0.5);
		-ms-transform: scale(0.5);
		transform: scale(0.5);
	`;

	return (
		<BackgroundDiv className="d-flex align-items-center">
			<StyledContainer>
				<Row className="justify-content-center">
					<Col lg={9} md={9}>
						<Card>
							<CardHeader className="py-5 text-center">
								<Logo defaults={selectedTheme} type={'loginCardLogo'} />
								<div className="btn-wrapper text-center">
									<Row>
										<Col>
											<MutedButton type="button" className="btn-light">
												<span className="d-inline-block">
													<img
														alt="Google"
														src={googleBrand}
														className="w-50"
													/>
												</span>
												<span className="d-inline-block">
													<span className="mb-0">Sign in with Google</span>
												</span>
											</MutedButton>
										</Col>
										<Col>
											<MutedButton type="button" className="btn-light">
												<span className="d-inline-block">
													<img
														alt="Office 365"
														src={office365Brand}
														className="img-fluid"
														style={{
															height: 20,
														}}
													/>
												</span>
												<span
													className="d-inline-block"
													style={{ verticalAlign: 'top' }}
												>
													<span className="mb-0">Sign in with Office 365</span>
												</span>
											</MutedButton>
										</Col>
									</Row>
								</div>
							</CardHeader>
							<CardBody className="px-lg-5 py-lg-5">
								<div className="text-center text-muted mb-4">
									<small>Sign in with credentials</small>
								</div>
								<FormGroup className="mb-3">
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faEnvelope} />
											</InputGroupText>
										</InputGroupAddon>
										<StyledInput
											name="email"
											placeholder="Email"
											type="email"
											autoComplete="on"
										/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<FontAwesomeIcon icon={faLock} />
											</InputGroupText>
										</InputGroupAddon>
										<StyledInput
											name="password"
											placeholder="Password"
											type="password"
											autoComplete="on"
										/>
									</InputGroup>
								</FormGroup>
								<div className="d-flex align-items-center justify-content-between">
									<div className="mt-2 custom-control custom-control-alternative custom-checkbox">
										<input
											className="custom-control-input"
											id=" customCheckLogin"
											type="checkbox"
										/>
										<label
											className="custom-control-label"
											htmlFor=" customCheckLogin"
										>
											<p className="text-muted">Remember me</p>
										</label>
									</div>
									<StyledNavLink>Reset password</StyledNavLink>
								</div>
								<div className="text-center">
									<SubmitButton label="Sign in" />
									<StyledChangeFormButton type="button">
										Go to Register
									</StyledChangeFormButton>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</StyledContainer>
		</BackgroundDiv>
	);
};

export default LoginPreviewComponent;
