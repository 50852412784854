import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { css } from 'styled-components';
import { primaryButton } from './primary-button';

const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
export const secondaryButton = css`
	${primaryButton};

	&& {
		svg {
			fill: var(--white);
		}

		background-color: ${defaults?.secondary};

		&:hover {
			// background-color: ${defaults?.secondaryHighlighted};
		}
	}
`;

export const outlinedSecondaryButton = css`
	${secondaryButton};

	&& {
		background-color: transparent;
		color: ${defaults?.secondary};
		border: 1px solid ${defaults?.secondary};

		&:hover {
			color: #ffffff;
			// background-color: ${defaults?.secondary};
		}
	}
`;
