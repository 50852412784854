import { types } from 'mobx-state-tree';
import { enumValues } from '../../../utils/common/enum.utils';

export enum UserStatus {
	invited = 'invited',
	active = 'active',
	disabled = 'disabled',
	registered = 'registered',
}
export enum UserAssetViewType {
	folderView = 'folderView',
	searchView = 'searchView',
}

export const userStatusBadgeColors: Record<UserStatus, string> = {
	[UserStatus.disabled]: 'danger',
	[UserStatus.active]: 'success',
	[UserStatus.invited]: 'info',
	[UserStatus.registered]: 'info',
};

export const getUserStatusBadgeColor = (status: string): string => {
	switch (status) {
		case UserStatus.disabled:
			return 'danger';
		case UserStatus.active:
			return 'success';
		case UserStatus.invited:
			return 'info';
		case UserStatus.registered:
			return 'info';
		default:
			return '';
	}
};

const userStatuses = enumValues(UserStatus);
const userAssetViewTypes = enumValues(UserAssetViewType);

export const userStatusModel = types.enumeration('UserStatus', userStatuses);
export const userAssetViewTypeModel = types.enumeration(
	'UserAssetViewType',
	userAssetViewTypes
);
