import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Stage } from '../../types/workflow.types';

import { Hr } from './workflow-stage-card.styled-components';
type Props = {
	stage?: Stage;
};
const StageInstructions = (props: Props) => {
	const { stage } = props;
	if (stage?.instructions) {
		return (
			<>
				<p className="py-2">
					<FontAwesomeIcon icon={faAlignLeft} className="mr-4" />
					{stage?.instructions}
				</p>
				<Hr />
			</>
		);
	} else return null;
};

export default StageInstructions;
