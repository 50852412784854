import { orderBy } from 'lodash';
import * as R from 'ramda';
import { Workflow } from 'components/workflow/workflows/types';
import { getDueDate } from './workflowDate.helpers';

export const alphabetizeBy = (property: string, workflows: any[]) =>
	orderBy(workflows, (a) => a[property], 'asc');
export const reverseAlphabetizeBy = (property: string) => <T>(list: T[]) =>
	R.reverse(alphabetizeBy(property, list));

export const sortByDueDateAsc = (a: Workflow, b: Workflow) =>
	new Date(getDueDate(a)).getTime() - new Date(getDueDate(b)).getTime();
export const sortByDueDateDesc = (a: Workflow, b: Workflow) =>
	new Date(getDueDate(b)).getTime() - new Date(getDueDate(a)).getTime();

// sort alpha by property but case insensitive and ignore punctuation
export const stageAlphaSortByWorkflowTitle = (
	stages: any[],
	reverse: boolean = false
) => {
	let sorted = stages.sort((a, b) =>
		a.title.localeCompare(b.workflowTitle, 'en', { numeric: true })
	);
	if (reverse) {
		sorted = sorted.reverse();
	}
	return sorted;
};

// sort alpha by property but case insensitive and ignore punctuation
export const alphaSortByWorkflowTitle = (
	workflows: any[],
	reverse: boolean = false
) => {
	let sorted = workflows.sort((a, b) =>
		a.title.localeCompare(b.title, 'en', { numeric: true })
	);
	if (reverse) {
		sorted = sorted.reverse();
	}
	return sorted;
};

// fixed up version of due date sorting that handles TBDs better
export const sortByDueDate = (stages: any[], reverse: boolean = false) => {
	console.log(stages);
	let sorted = stages.sort((a, b) => a.dueDate.localeCompare(b.dueDate));
	return sorted;
};
