import { navigate } from '@reach/router';
import * as axios from 'axios';
import { NotificationsContext } from 'components/notifications';
import { useFetching } from 'hooks/useFetching';
import { RegisteredUser } from 'pages/views/Register/Register';
import React, { Reducer } from 'react';
import { Maybe } from 'types/globals';
import { UserRole } from '../components/accounts/models/UserRole.model';
import { UserStatus } from '../components/accounts/models/UserStatus.model';
export type ProxyRequest = {
	_id: string;
	createdBy: User;
	createdAt: Date;
	proxyingUser?: User;
	proxyStartDate: Date;
	proxyEndDate: Date;
	cancelled: boolean;
	updatedAt: Date;
	message?: string;
	__v: number;
	action: 'accepted' | 'declined' | 'pending';
};
export type User = {
	account: string;
	addressLine1: string;
	addressLine2: string;
	authIds?: string[];
	city?: string;
	country?: string;
	createdAt?: Date;
	department?: string;
	email: string;
	expertise: string;
	notificationsRead?: [];
	status: UserStatus;
	familyName: string;
	givenName: string;
	impersonating?: boolean;
	impersonatingUser?: string;
	location: string;

	// PREFERENCES
	notifyWhenAssignedStakeHolder?: boolean;
	notifyWhenAssignmentRequiresAttention?: boolean;
	notifyWhenNewUserRegisters?: boolean;
	notifyWhenWorkflowOverdue?: boolean;
	notifyWhenWorkflowRoadblockResolved?: boolean;
	notifyWhenWorkflowRoadblocked?: boolean;

	persistSearchFilters?: boolean;

	phone: string;
	picture: string;
	proxyRequest?: ProxyRequest;
	proxyingFor?: User | undefined;
	receivePushNotifications?: boolean;
	referenceEmail: string;
	referenceName: string;
	referencePhone: string;
	role: UserRole;
	title: string;
	state: string;
	updatedAt?: Date;
	zip?: string;
	_id: string;
	__v?: number;
	// non-api properties that are set based on other properties of the user in the dispatch of reducer
	name?: string;
	isAdmin?: boolean;

	isLevelOneApprover: boolean;
	isLevelTwoApprover: boolean;

	isApprovedLevelOne: boolean;
};
type UserContextState = {
	entities: User[];
	currentUser: User;
	loggedInUser: User;
	shouldFetch: boolean;
	updateState: (state: Partial<UserContextState>) => void;
};
type UserContextActionType = 'SET_USER' | 'SHOULD_FETCH' | 'SET_LOGGED_IN';
type UserContextAction = {
	type: UserContextActionType;
	payload?: User;
};

export const useHeaders = () => {
	const token = localStorage.getItem('rome_auth') as string;

	const headers = React.useMemo(
		() =>
			token && JSON.parse(token)?.accessToken
				? {
						headers: {
							Authorization: `Bearer ${JSON.parse(token).accessToken}`,
						},
				  }
				: { headers: {} },
		[token]
	);

	const getHeaders = React.useCallback(() => {
		let authHeaders = { headers: headers.headers };
		if (
			!!authHeaders?.headers?.Authorization?.includes('null') ||
			(!authHeaders?.headers?.Authorization &&
				!!JSON.parse(localStorage.getItem('rome_auth') as string)?.accessToken)
		) {
			authHeaders = {
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem('rome_auth') as string).accessToken
					}`,
				},
			};
		}
		return authHeaders;
	}, [headers.headers]);

	return { getHeaders };
};

function userContextReducer(
	state: UserContextState,
	action: UserContextAction
) {
	switch (action.type) {
		case 'SET_USER':
			return {
				...state,
				currentUser: {
					...action.payload,
					isAdmin: [UserRole.SuperAdmin, UserRole.RomeDevelopers].some(
						(a) => a === action?.payload?.role
					),
					name: `${action?.payload?.givenName} ${action?.payload?.familyName}`,
				} as User,
				shouldFetch: !action.payload?._id,
			};
		case 'SHOULD_FETCH':
			return { ...state, shouldFetch: true };
		case 'SET_LOGGED_IN':
			return {
				...state,
				loggedInUser: {
					...(action.payload as User),
					isAdmin: [UserRole.SuperAdmin, UserRole.RomeDevelopers].some(
						(a) => a === action?.payload?.role
					),
				},
			};
		default:
			return state;
	}
}
type UserContext = {
	currentUser: User;
	updateState: (state: Partial<UserContextState>) => void;
	shouldFetch: boolean;
	fetchUser: () => void;
	entities: User[];
	createPasswordResetLink: (userId: string) => Promise<string>;
	registerUser: (r: RegisteredUser, password: string) => Promise<boolean>;
	refreshCurrentUser: (updatedUser: User) => void;
	loggedInUser: User;
	refreshEntity: (user: User, shouldRemove?: boolean) => void;
	handleUserRegistration: (
		user: User,
		action: 'approve' | 'reject',
		lvlTwo: true | false
	) => Promise<boolean>;
	getPasswordResetLink: (emailAddress: string) => Promise<boolean>;
	changePassword: (newPassword: string) => Promise<boolean>;
	updateUserProxySettings: (
		patchedUser: {
			proxyStartDate: string;
			proxyEndDate: string;
			proxyingUser: string;
			message: string;
		},
		userToProxy: string
	) => Promise<User>;

	fetchProxyRequest: (id: string) => Promise<ProxyRequest>;
	updateProxyRequest: (request: ProxyRequest) => Promise<ProxyRequest>;
	deleteProxyRequest: (id: string) => Promise<boolean>;
};
export const UserContext = React.createContext<UserContext>({
	currentUser: {} as User,
	shouldFetch: false,
	updateState: () => {},
	fetchUser: () => {
		return;
	},
	entities: [],
	createPasswordResetLink: () => Promise.resolve(''),
	registerUser: () => Promise.resolve(true),
	refreshCurrentUser: (updatedUser: User) => {
		return;
	},
	refreshEntity: (user: User) => {
		return;
	},
	updateUserProxySettings: () => Promise.resolve({} as User),
	loggedInUser: {} as User,
	handleUserRegistration: () => Promise.resolve(true),
	getPasswordResetLink: (emailAddress: string) => Promise.resolve(false),
	changePassword: (newPw: string) => Promise.resolve(false),
	fetchProxyRequest: (id: string) => Promise.resolve({} as ProxyRequest),
	updateProxyRequest: (request: ProxyRequest) =>
		Promise.resolve({} as ProxyRequest),
	deleteProxyRequest: (id: string) => Promise.resolve(false),
});

export const UserContextProvider = ({ children }: { children: any }) => {
	const [state, dispatch] = React.useReducer<
		Reducer<UserContextState, UserContextAction>
	>(userContextReducer, {
		currentUser: {} as User,
		shouldFetch: false,
		updateState: () => {},
		entities: [],
		loggedInUser: {} as User,
	});

	const { getHeaders } = useHeaders();

	const { error: showError } = React.useContext(NotificationsContext);
	const [currentUser, setCurrentUser] = React.useState<User>();
	const [entities, setEntities] = React.useState<User[]>();

	const handleUserRegistration = async (
		user: User,
		action: 'approve' | 'reject',
		lvlTwo: boolean
	) => {
		if (getHeaders() && getHeaders().headers.Authorization) {
			const response = await axios.default.put<Maybe<User>>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/${user._id}/${action}/${lvlTwo}`,
				{},
				getHeaders()
			);

			if (response.data) {
				refreshEntity(response.data);
			} else {
				refreshEntity(user, true);
			}
			return true;
		}
		return false;
	};

	const refreshEntity = (updatedEntity: User, shouldRemove = false) => {
		if (shouldRemove) {
			setEntities((entities) => [
				...(entities || [])?.filter((m) => m._id !== updatedEntity._id),
			]);
			return;
		}

		setEntities((entities) => [
			...(entities || [])?.filter((entity) => entity._id !== updatedEntity._id),
			updatedEntity,
		]);
	};

	const updateUserProxySettings = async (
		updated: {
			proxyStartDate: string;
			proxyEndDate: string;
			proxyingUser: string;
			message: string;
		},
		userToProxy: string
	) => {
		const response = await axios.default.post<User>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/proxyUser`,
			{
				proxyingUser: userToProxy,
				proxyStartDate: updated.proxyStartDate,
				proxyEndDate: updated.proxyEndDate,
				message: updated.message,
			},
			getHeaders()
		);

		if (response.data) {
			refreshEntity(response.data);
		}
		return response.data;
	};

	const fetchProxyRequest = async (id: string) => {
		const response = await axios.default.get<ProxyRequest>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/proxy/${id}`,
			getHeaders()
		);

		return response.data;
	};

	const deleteProxyRequest = async (id: string) => {
		const response = await axios.default.delete(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/proxy/${id}`,
			getHeaders()
		);

		await refreshCurrentUser({
			...(currentUser as User),
			proxyRequest: undefined,
		});

		return response.status === 200;
	};

	const updateProxyRequest = async (updatedRequest: ProxyRequest) => {
		if (updatedRequest.action === 'declined') {
			const effectedUser = entities?.find(
				(a) => a._id === updatedRequest.createdBy._id
			) as User;
			const updated = { ...effectedUser, proxyRequest: undefined };

			refreshEntity(updated);

			const updatedCurrent = {
				...(currentUser as User),
				proxyingFor: undefined,
			};
			userStore.updateState(updatedCurrent);
		} else if (updatedRequest.action === 'accepted') {
			const updated = {
				...(currentUser as User),
				proxyingFor: updatedRequest.createdBy as User,
			};
			userStore.updateState(updated);
		} else {
			// not a approval or decline action
			if (currentUser?.proxyRequest?._id) {
				userStore.updateState({
					...(currentUser as User),
					proxyRequest: {
						...(currentUser.proxyRequest as ProxyRequest),
						proxyStartDate: updatedRequest.proxyStartDate,
						proxyEndDate: updatedRequest.proxyEndDate,
						message: updatedRequest.message,
					},
				});
			}
		}

		const response = await axios.default.patch<ProxyRequest>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/proxy/${updatedRequest._id}`,
			updatedRequest,
			getHeaders()
		);
		// not an action taken on the approve / deny, check if created by

		return response?.data;
	};

	const createPasswordResetLink = async (userId: string) => {
		if (getHeaders() && getHeaders().headers.Authorization) {
			const response = await axios.default.get<string>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/auth/passwordreset/${userId}`,
				getHeaders()
			);
			return response.data;
		}

		return '';
	};

	const registerUser = async (u: RegisteredUser, password: string) => {
		const res = await axios.default.post(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/registerUser`,
			{ ...u, password }
		);

		return !!res.data;
	};

	const fetchAll = React.useCallback(async () => {
		if (getHeaders() && !!getHeaders().headers.Authorization) {
			const res = await axios.default.get<User[]>(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/users`,
				getHeaders()
			);
			if (res?.status === 401 || !res) {
				showError('User is unauthorized to view ROME');
				navigate('/auth', { state: 'Unauthorized' });
			} else {
				return res.data;
			}
		}
	}, [getHeaders, showError]);

	React.useEffect(() => {
		if (
			!entities?.length &&
			getHeaders() &&
			!!getHeaders().headers.Authorization
		) {
			fetchAll().then(setEntities);
		}
	}, [getHeaders, entities, fetchAll]);

	const userStore = {
		getUser: async (): Promise<Maybe<User>> => {
			if (getHeaders() && !!getHeaders().headers.Authorization) {
				const response = await axios.default.get<User>(
					`${process.env.REACT_APP_ROME_API_ENDPOINT}/auth/userinfo`,
					getHeaders()
				);
				if (!response?.data) {
					navigate('/auth');
				} else if (response?.data?.status === 'registered') {
					navigate('/needs-approval');
				}
				return response?.data;
			}
		},
		updateState: (updatedUser: User) =>
			dispatch({ type: 'SET_USER', payload: updatedUser }),
	};

	const { isFetching, beginFetching, finishFetching } = useFetching();

	const fetchUser = () => {
		if (isFetching) return;
		if (!!getHeaders()?.headers?.Authorization && !state.currentUser?._id) {
			beginFetching();
			userStore
				.getUser()
				.then((currentuser) => {
					dispatch({ type: 'SET_LOGGED_IN', payload: currentuser });

					if (currentuser?.impersonating && currentuser?.impersonatingUser) {
						setCurrentUser(
							entities?.find((m) => m._id === currentuser.impersonatingUser)
						);
						dispatch({
							type: 'SET_USER',
							payload: entities?.find(
								(m) => m._id === currentuser.impersonatingUser
							),
						});
					} else {
						setCurrentUser(currentuser);
						dispatch({ type: 'SET_USER', payload: currentuser as User });
					}
				})
				.finally(finishFetching);
		}
	};

	React.useEffect(() => {
		if (isFetching) return;
		if (getHeaders()?.headers?.Authorization && !state.currentUser?._id) {
			beginFetching();
			userStore
				.getUser()
				.then(function (u) {
					dispatch({ type: 'SET_LOGGED_IN', payload: u });
					if (u?.impersonating && u?.impersonatingUser) {
						setCurrentUser(
							entities?.find((m) => m._id === u.impersonatingUser)
						);
						dispatch({
							type: 'SET_USER',
							payload: entities?.find((m) => m._id === u.impersonatingUser),
						});
					} else {
						setCurrentUser(u);
						dispatch({ type: 'SET_USER', payload: u as User });
					}
				})
				.finally(finishFetching);
		}
		// eslint-disable-next-line
	}, [
		state.shouldFetch,
		beginFetching,
		finishFetching,
		isFetching,
		state.currentUser,
		getHeaders,
		userStore,
		refreshEntity,
	]);

	const refreshCurrentUser = async (updatedCurrent: User) => {
		const current = await userStore.getUser();
		const response = await axios.default.patch<User>(
			`${process.env.REACT_APP_ROME_API_ENDPOINT}/users/${current?._id}`,
			{ ...current, impersonating: false, impersonatingUser: '' },
			getHeaders()
		);
		setEntities((entities) => [
			...(entities || [])?.filter((m) => m._id !== updatedCurrent._id),
			{ ...response.data },
		]);
		setCurrentUser(response.data);
		dispatch({ type: 'SET_USER', payload: response.data });
	};

	const getPasswordResetLink = async (emailAddress: string) => {
		const route = `${process.env.REACT_APP_ROME_API_ENDPOINT}/auth/reset/password/by-email/${emailAddress}`;
		const response = await axios.default.get<boolean>(route, getHeaders());
		return response.data;
	};

	const changePassword = async (newPassword: string) => {
		const route = `${process.env.REACT_APP_ROME_API_ENDPOINT}/auth/changePassword`;
		const body = { password: newPassword };
		const response = await axios.default.put<User>(route, body, getHeaders());
		return !!response.data;
	};

	const getCurrentUser = React.useMemo(
		() => (user: User) => {
			if (
				entities &&
				entities.length &&
				user &&
				user.impersonating &&
				user.impersonatingUser
			) {
				return entities.find((uE) => uE._id === user.impersonatingUser);
			}

			return state.currentUser ?? currentUser;
		},
		[entities, currentUser, state.currentUser]
	);

	return (
		<UserContext.Provider
			value={{
				currentUser: getCurrentUser(state.currentUser ?? currentUser) as User,
				updateState: state.updateState,
				shouldFetch: state.shouldFetch,
				fetchUser,
				entities: entities as User[],
				createPasswordResetLink,
				registerUser,
				refreshCurrentUser,
				loggedInUser: state.loggedInUser,
				refreshEntity,
				handleUserRegistration,
				getPasswordResetLink,
				changePassword,
				fetchProxyRequest,
				updateProxyRequest,
				updateUserProxySettings,
				deleteProxyRequest,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
