import { SiteDefaults } from 'context/useThemeContext';
import storageModel from 'utils/models/StorageModel';
import { Container } from 'reactstrap';
import styled from 'styled-components';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;

export const PaginationWrapper = styled(Container)`
	.wrapper {
		padding: 2rem 0;
		display: flex;
		justify-content: center;

		button {
			transition: all 0.3s ease-in-out;

			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
				text-decoration: none;
			}
		}

		.active {
			background-color: ${defaults?.secondary};
			border-color: ${defaults?.secondary};
			color: #fff;

			&:hover {
				background-color: ${defaults?.secondary};
			}
		}
		.sides {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
	.separator {
		width: 1rem;
		margin: 0 0.25rem;
	}
	.page-item {
		white-space: nowrap;
		background-color: transparent;
		border: none;
		height: 2.5rem;
		width: 2.5rem;
		margin: 0 0.5rem;
		border-radius: 50%;
		font-weight: 600;
		color: ${defaults?.secondary};
		&:hover {
			text-decoration: underline;
		}
		&:focus {
			outline: 0;
		}
	}
`;
// pagination.module.scss
// Colour variables used: $primary, $white
