import { types } from 'mobx-state-tree';

const ThemeImagesModel = types.model('ThemeImages', {
	desktopLogo: types.optional(types.string, '/themes/default/logo-desktop.svg'),
	mobileLogo: types.optional(types.string, '/themes/default/logo-mobile.svg'),
	loginBackground: types.optional(types.string, '/themes/default/login-bg.jpg'),
	loginCardLogo: types.optional(types.string, ''),
});

export default ThemeImagesModel;
