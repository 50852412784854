import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiteDefaults } from 'context/useThemeContext';
import { SecondaryButton } from 'components/buttons.styled-components';
import storageModel from 'utils/models/StorageModel';
import React, { useState } from 'react';
import styled from 'styled-components';
const defaults = (JSON.parse(storageModel.get('SITE_DEFAULTS') as string) ||
	{}) as SiteDefaults;
const StyledAssetPreviewUpload = styled.main`
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	body {
		font-family: 'Roboto', sans-serif;
		font-size: 16px;
		line-height: 1;
	}
	.container {
		max-width: 1100px;
		padding: 0 20px;
		margin: 0 auto;
	}
	.panel {
		max-width: 500px;
		text-align: center;
	}
	.button_outer {
		background: ${defaults?.secondary};
		border-radius: 10px;
		text-align: center;
		display: inline-block;
		transition: 0.2s;
		position: relative;
		overflow: hidden;
	}
	.btn_upload {
		padding: 9px 14px;
		color: #fff;
		text-align: center;
		position: relative;
		display: inline-block;
		overflow: hidden;
		z-index: 3;
		cursor: pointer !important;
		white-space: nowrap;
	}
	.btn_upload input {
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
		width: 100%;
		height: 105%;
		cursor: pointer !imoprtant;
		opacity: 0;
	}
	.file_uploading {
		width: 100%;
		height: 10px;
		margin-top: 20px;
		background: #ccc;
	}
	.file_uploading .btn_upload {
		display: none;
	}
	.processing_bar {
		position: absolute;
		left: 0;
		top: 0;
		width: 0;
		height: 100%;
		border-radius: 30px;
		background: ${defaults?.primary};
		transition: 3s;
	}
	.file_uploading .processing_bar {
		width: 100%;
	}
	.success_box {
		display: none;
		width: 50px;
		height: 50px;
		position: relative;
	}
	.success_box:before {
		content: '';
		display: block;
		width: 9px;
		height: 18px;
		border-bottom: 6px solid #fff;
		border-right: 6px solid #fff;
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		position: absolute;
		left: 17px;
		top: 10px;
	}
	.file_uploaded .success_box {
		display: inline-block;
	}
	.file_uploaded {
		margin-top: 0;
		width: 50px;
		background: #83ccd3;
		height: 50px;
	}
	.uploaded_file_view {
		max-width: 300px;
		margin: 40px auto;
		text-align: center;
		position: relative;
		transition: 0.2s;
		opacity: 0;
		border: 2px solid #ddd;
		padding: 15px;
	}
	.file_remove {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: block;
		position: absolute;
		background: #aaa;
		line-height: 30px;
		color: #fff;
		font-size: 12px;
		cursor: pointer;
		right: -15px;
		top: -15px;
	}
	.file_remove:hover {
		background: #222;
		transition: 0.2s;
	}
	.uploaded_file_view img {
		max-width: 100%;
	}
	.uploaded_file_view.show {
		opacity: 1;
	}
	.error_msg {
		text-align: center;
		color: #f00;
	}
`;

type Props = {
	onPreviewGenerated: (previewURL: string) => void;
	onFileSelected: (fileData: File) => void;

	label: string;
};

export const AssetPreviewUpload = (props: Props) => {
	const [outerClassList, setOuterClassList] = useState('button_outer');
	const [errorMsg, setErrorMsg] = useState('');
	const { label } = props;
	const [uploadValue, setUploadValue] = React.useState();
	const reference = React.useRef();
	React.useEffect(() => {
		console.log(reference?.current);
		const q = window.location.hash;

		if (q.includes('?clear')) {
			setUploadValue(undefined);
			window.location.hash = '';
		}
	}, []);

	const uploadChange = (e: any) => {
		setUploadValue(e.target.value);
		var ext = e?.target?.value?.split('.').pop().toLowerCase();
		if (['gif', 'png', 'jpg', 'jpeg', 'tif'].indexOf(ext) === -1) {
			setErrorMsg(
				'Invalid extension. Asset previews can only be png, jpg, jpeg, gif, or tiff.'
			);
		} else {
			setErrorMsg('');
			setOuterClassList(`${outerClassList} file_uploading`);
			var uploadedFile = URL.createObjectURL(e.target.files[0]);
			props.onFileSelected(e.target.files[0]);
			props.onPreviewGenerated(uploadedFile);
		}
	};
	const render = () => (
		<StyledAssetPreviewUpload className="main_full">
			<div className="container">
				<div className="panel">
					<div>
						<SecondaryButton className="btn_upload">
							<input
								ref={(ref) => (reference.current = ref as any)}
								type="file"
								onChange={uploadChange}
								id="upload_file"
								name=""
								value={uploadValue}
							/>
							{label} <FontAwesomeIcon icon={faUpload} />
						</SecondaryButton>
					</div>
				</div>
				<div className="error_msg">{errorMsg}</div>
			</div>
		</StyledAssetPreviewUpload>
	);
	return render();
};
