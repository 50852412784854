import { faStepForward } from '@fortawesome/free-solid-svg-icons';
import { allStagesHaveOwner } from 'components/workflow/workflows/helpers';
import { useWorkflowContext } from 'context/useWorkflowStore';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { useAuthContext, useGroupContext } from 'utils/auth';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import { useModalCreator } from '../../../../../utils/ModalStack';
import { StageStatus } from '../../../../../utils/models/StageStatusModel';
import { Stage, Workflow, WorkflowTemplate } from '../../types/workflow.types';
import {
	ActionButton,
	ActionButtonContainer,
	IconWithRightMargin,
} from '../action-button.styled-components';
import ActivateFirstStageDialog from './activate-first-stage-dialog.component';

const workflowTerm = themeStore._.workflow.toLowerCase();

const ActivateFirstStageButton = () => {
	const { stage, workflow } = useWorkflowContext();
	const modalStack = useModalCreator();
	const { currentUser } = useAuthContext();
	const { groupsForCurrentUser } = useGroupContext();
	// const ORG = process.env.REACT_APP_ORG;
	const all_stages_have_owners = allStagesHaveOwner(
		workflow as Workflow | WorkflowTemplate
	);

	const canForceActivateStage = (stage: Stage) => {
		return (
			workflow?.createdBy?._id === currentUser._id ||
			(groupsForCurrentUser &&
				groupsForCurrentUser?.some((group) =>
					stage.owners.some((owner) => owner._id === group._id)
				)) ||
			(stage.owners && stage.owners.some((m) => m._id === currentUser._id)) ||
			currentUser.isAdmin
		);
	};
	const openActivateFirstStageModal = () => {
		modalStack.addModal(<ActivateFirstStageDialog />);
	};
	const message = React.useMemo(
		() =>
			all_stages_have_owners
				? `Activate "${stage?.title}" to begin ${workflowTerm}`
				: `All applicable stages must have atleast one stakeholder to activate the ${workflowTerm}`,
		[all_stages_have_owners, stage]
	);
	if (
		!canForceActivateStage(stage as Stage) ||
		stage?.status === StageStatus.active ||
		stage?.status === StageStatus.roadblocked
	) {
		return null;
	}

	const buttonId = `activateFirstStage${stage?._id}`;

	return (
		<ActionButtonContainer className="stage-action activate-first-stage">
			<UncontrolledTooltip target={buttonId}>{message}</UncontrolledTooltip>
			<div
				id={buttonId}
				style={{ cursor: !all_stages_have_owners ? 'not-allowed' : 'default' }}
			>
				<ActionButton
					style={{
						pointerEvents: !all_stages_have_owners ? 'none' : 'default',
					}}
					color="secondary"
					size="sm"
					className="action-button activate-first-stage-button solid"
					onClick={openActivateFirstStageModal}
					disabled={!all_stages_have_owners}
				>
					<IconWithRightMargin icon={faStepForward} />
					Begin {workflowTerm}
				</ActionButton>
			</div>
		</ActionButtonContainer>
	);
};

export default ActivateFirstStageButton;
