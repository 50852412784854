type MirroredEnum<E extends string> = {
	[K in E]: E;
};

export function enumKeys<E extends object>(x: E): Array<keyof E> {
	return Object.keys(x) as Array<keyof E>;
}

export function enumValues<T extends string>(x: any): Array<T> {
	return Object.values(x);
}
