import React from 'react';
import styled from 'styled-components';

import { Subheading } from './ui';

const StyledSubheading = styled(Subheading)`
	font-size: 11px;
`;

export const PageSubheading = (props: { text: string }) => (
	<StyledSubheading className="page-subheading text-muted alt-font">
		{props.text}
	</StyledSubheading>
);

export default PageSubheading;
