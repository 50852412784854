import * as R from 'ramda';
import pipe from 'lodash/fp/flow';
import { Children } from 'react';
import { isEqual } from 'lodash';
export interface SelectedOption {
	value: string;
	label: string;
}

type defaultValue = string | undefined | null;

export const setDefaultSelections = (
	children: any,
	defaultValues?: defaultValue[] | defaultValue
) => {
	if (defaultValues === null) return []; // if none, return empty array
	if (typeof defaultValues === 'undefined') return []; // if none, return empty array
	if (typeof defaultValues === 'string')
		return getDefaultSelections([defaultValues], children);
	const sanitizedValues = R.filter(R.is(String), defaultValues) as string[];
	return getDefaultSelections(sanitizedValues, children);
};

export const getDefaultSelections = (defaultValues: string[], children: any) =>
	// {
	// 	return Children.toArray(children)
	// 		.filter(
	// 			R.pipe(
	// 				R.propOr({}, 'props'),
	// 				R.propOr('', 'value'),
	// 				R.includes(R.__, defaultValues)
	// 			)
	// 		)
	// 		.map(R.pipe(R.propOr({}, 'props'), R.pick(['value', 'label'])));
	// };
	R.pipe(
		R.map((value: string) =>
			Children.toArray(children)
				.map(({ props }: any) => props)
				.filter((item) => isEqual(item.value, value))
		),
		R.flatten
	)(defaultValues);

export const isDup = <T extends object>(list: T[]) => (element: T) =>
	R.any(R.equals(element), list);

export const isDupBy = <T extends object>(array: T[], property: keyof T) => (
	selection: T
): boolean =>
	pipe(
		R.pluck(property as string),
		R.any(R.equals(selection[property]))
	)(array) as boolean;
