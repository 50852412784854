import { navigate } from '@reach/router';
import React, { FormEvent, useCallback, useState } from 'react';
import { useAuthContext } from '../../../utils/auth';
import { generateID } from '../../../utils/common';
import { buildEmptyAssetMetadata } from '../../dam-assets/components/helpers/useAssetHelper';
import { useAxios } from '../../../hooks';
import {
	BaseWorkflowOwner,
	AssetCollection,
} from '../../workflow/workflows/types/workflow.types';
import AssetCollectionForm from './asset-collection-form.component';

const AddAssetCollectionForm = () => {
	const { currentUser } = useAuthContext();
	const collectionStore = useAxios<AssetCollection>('collections');
	const emptyCollection = {
		assets: [],
		createdAt: new Date(),
		_id: generateID(),
		metadata: buildEmptyAssetMetadata,
		owners: [
			{
				_id: currentUser._id,
				type: 'AccountUser',
			} as BaseWorkflowOwner,
		],
		title: '',
	} as AssetCollection;
	const [collectionRef, setCollectionRef] = useState(emptyCollection);

	const addCollection = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			const createdCollection = await collectionStore.createOne({
				...collectionRef,
				createdBy: currentUser._id,
			});

			return navigate(`/admin/dam/collections/${createdCollection?._id}`);
		},
		[collectionStore, collectionRef, currentUser._id]
	);

	return (
		<AssetCollectionForm
			onChange={(updated) => {
				setCollectionRef(updated);
			}}
			selectedCollection={collectionRef}
			onSubmit={addCollection}
		/>
	);
};
export default AddAssetCollectionForm;
