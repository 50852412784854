import styled from 'styled-components';
import { DropdownItem, DropdownToggle } from 'reactstrap';

import { focus } from '../core-ui/ui/ui';
import dropdownMenuItem from '../core-ui/components/drop-down-menu';

export const DropDownToggle = styled(DropdownToggle)`
	&:focus {
		// ${focus}
	}
`;

export const DropDownMenuItem = styled(DropdownItem)`
	${dropdownMenuItem}
`;
