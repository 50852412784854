import React from 'react';
import { EditPreferencesToggleSwitch } from './EditPreferencesToggleSwitch';
import { HelpBlock } from './HelpBlock.styled';
import { NotificationsContext } from '../../../components/notifications';

import { UserRole } from '../../../components/accounts/models/UserRole.model';
import { useAuthContext, User } from '../../../utils/auth';

type Props = {
	user: User;
	preferencesEnabled: boolean;
};

export const RomeDeveloperPreferences = ({
	user,
	preferencesEnabled,
}: Props) => {
	const { info, error } = React.useContext(NotificationsContext);
	const { currentUser, loggedInUser, refreshCurrentUser } = useAuthContext();
	const updateLoggedInUser = async () => {
		try {
			refreshCurrentUser({
				...currentUser,
				impersonatingUser: '',
				impersonating: false,
			});
			info('No longer impersonating user.');
		} catch (err) {
			error(
				`An issue occurred while updating your preferences. Please try again later.`
			);
		}
	};

	if (
		user.role !== UserRole.RomeDevelopers &&
		user.role !== UserRole.SuperAdmin
	)
		return null;
	return (
		<>
			<HelpBlock style={{ fontSize: 16 }}>Rome Developer Preferences</HelpBlock>
			<EditPreferencesToggleSwitch
				preferencesEnabled={preferencesEnabled}
				enabled={loggedInUser.impersonating}
				onStateChanged={async (e: boolean) => {
					await updateLoggedInUser();
				}}
				label="Impersonate User"
			/>
		</>
	);
};
