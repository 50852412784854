import React from 'react';
import { Row } from 'reactstrap';

import { NoResultsWrapper } from '../../../filters-list.styled-components';
import { WorkflowCollection } from '../../workflows/types/workflow.types';

import WorkflowCollectionCard from './workflow-collection-card.component';

interface Props {
	collections?: Array<WorkflowCollection>;
}

const renderNoResults = () => (
	<Row>
		<NoResultsWrapper>
			<p>No results found</p>
		</NoResultsWrapper>
	</Row>
);

const FilteredWorkflowCollectionList = (props: Props) => {
	const renderCollectionList = (list: Array<WorkflowCollection>) => (
		<>
			{list.map((collection) => (
				<WorkflowCollectionCard key={collection._id} collection={collection} />
			))}
		</>
	);

	const renderFilteredCollectionList = () => {
		const { collections } = props;
		return collections && collections?.length
			? renderCollectionList(collections)
			: renderNoResults();
	};

	// component
	return renderFilteredCollectionList();
};

export default FilteredWorkflowCollectionList;
