import React, { useContext } from 'react';
import { _logError } from '../../../../../utils/common/log';
import { NotificationsContext } from '../../../../notifications';
import themeStore from '../../../../../core-ui/models/ThemeStore';
import StageDialog from './stage-dialog.component';
import { useWorkflowContext } from '../../../../../context/useWorkflowStore';
import { StatusEnum } from '../../types/workflowStatus.types';
import { Stage, Workflow } from '../../types/workflow.types';
import { getNextStage } from '../../helpers/workflowStage.helpers';
import { isFulfilled } from 'components/workflow/workflow-templates/components/stage-cards/Stage.helpers';

interface Props {
	stage: Stage;
	workflow: Workflow;
}

const ApproveStageDialog = ({ stage, workflow }: Props) => {
	const { updateStatus } = useWorkflowContext();
	const { error: showError } = useContext(NotificationsContext);
	const onSubmit = async (message: string) => {
		const fulfilled = isFulfilled(stage);
		if (fulfilled) {
			try {
				await updateStatus(StatusEnum.completed, workflow, message, stage);
			} catch (error) {
				_logError(error);
				showError(
					'Unable to complete the stage, an issue occurred while saving. Please try again later.'
				);
			}
		}
	};

	return (
		<StageDialog
			successMessage={'Successfully approved ' + stage.title + '.'}
			header={`Approving stage "${stage.title}"`}
			context="You may add context about the approval (optional)."
			warning={
				getNextStage(stage, workflow.stages)
					? 'Are you sure you want to complete this stage?'
					: `Completing this stage also completes this ${themeStore._.workflow.toLowerCase()}.`
			}
			onClick={onSubmit}
			messageRequired={false}
		/>
	);
};

export default ApproveStageDialog;
