export type Statuses =
	| 'queue'
	| 'active'
	| 'roadblocked'
	| 'completed'
	| 'pipeline'
	| 'blocked'
	| 'paused'
	| 'cancelled'
	| 'healthy'
	| 'overdue'
	| string;

export enum StatusEnum {
	'active' = 'active',
	'completed' = 'completed',
	'pipeline' = 'pipeline',
	'paused' = 'paused',
	'roadblocked' = 'roadblocked',
	'cancelled' = 'cancelled',
	'queue' = 'queue',
}
export type WorkflowStatus = Extract<
	Statuses,
	'active' | 'completed' | 'pipeline' | 'paused' | 'cancelled' | string
>;

export type StatusHealth = Extract<Statuses, 'healthy' | 'overdue' | 'blocked'>;

export type StatusDisplay = Extract<
	Statuses,
	'pipeline' | 'blocked' | 'completed'
>;
