import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import {
	CardBodyFlex,
	UserAvatar,
} from '../../components/admin-dashboard/components/user-details.styled-components';
import EditUserForm from '../../components/admin-dashboard/components/users/EditUserForm';
import { camelCaseToSentenceCase } from '../../utils/common';

import { getUserRoleName } from '../../components/accounts/models/UserRole.model';
import { useGroupContext, User } from '../../utils/auth';
import { useParams } from '@reach/router';
import { Loading } from 'components/index';
import { useAxios } from 'hooks';
import GroupAvatar from 'components/group-avatar.component';
import Moment from 'react-moment';
import BackLink from 'components/back-link/back-link.component';

const ViewProfile = () => {
	const userStore = useAxios<User>('users');
	const { userId } = useParams();
	const [viewing, setViewing] = useState<User>();
	const { entities: userGroups } = useGroupContext();

	useEffect(() => {
		if (userId && !viewing) {
			userStore.findOne(userId).then(setViewing);
		}
	}, [userId, userStore, viewing]);

	const groups = React.useMemo(() => {
		if (viewing && userGroups) {
			return userGroups.filter((g) =>
				g.members.some((m) => m._id === viewing._id)
			);
		}
		return [];
	}, [viewing, userGroups]);

	return (
		<Container>
			{!!viewing && (
				<>
					<Row>
						<BackLink link="/admin" title="to dashboard" />
					</Row>
					<Row>
						<Col md={4} className="mt-4 mt-md-0">
							<Card className="h-100">
								<CardBodyFlex>
									<UserAvatar size="xl" user={viewing as User} />
									<h1>{`${viewing?.givenName} ${viewing?.familyName}`}</h1>
									<p>
										{camelCaseToSentenceCase(
											getUserRoleName((viewing as User).role)
										)}
									</p>
								</CardBodyFlex>
							</Card>
						</Col>
						<Col md={8} className="mt-4 mt-md-0">
							<Card className="h-100">
								<CardHeader>
									<h2 className="mb-0">User details</h2>
								</CardHeader>
								<CardBody>
									{viewing && !!viewing._id && (
										<EditUserForm
											isReadonly={true}
											showChangePassword={false}
											showRole={false}
											selectedUser={viewing}
											onSubmit={async (payload: any) => {
												return;
											}}
										/>
									)}
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-4">
							<Card className="h-100">
								<CardHeader>
									<h2 className="mb-0">User groups</h2>
								</CardHeader>
								<CardBody>
									{groups.map((g) => (
										<div className="mb-2" key={g._id}>
											<GroupAvatar size="lg" group={g} />
											<p className="d-inline ml-3">{g.title}</p>
										</div>
									))}
								</CardBody>
							</Card>
						</Col>
						<Col md={6} className="mt-4">
							<Card className="h-100">
								<CardHeader>
									<h2 className="mb-0">User activity</h2>
								</CardHeader>
								<CardBody>
									<ul>
										<li>
											<p>
												Added{' '}
												<Moment
													format="MMM DD, YYYY"
													date={(viewing as User).createdAt}
												/>
											</p>
										</li>
									</ul>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</>
			)}

			{viewing === undefined && <Loading label="Loading user..." />}
		</Container>
	);
};

export default ViewProfile;
