import styled, { css } from 'styled-components';
import { Transition } from '../Animations';

const baseStyles = css`
	border: 1px solid #eee;
	max-width: 500px;
	padding: 2em;
	position: fixed;
	background-color: #ffffff;

	width: 100%;
	z-index: 100;
	overflow: hidden;
	box-shadow: -2px 0px 10px rgb(0 0 0 / 10%);
`;

export const ModalContent = styled.div`
	position: relative;
	h2 {
		font-size: 22px;
	}

	.button__close {
		position: absolute;
		font-size: 1em;
		top: -16px;
		right: -16px;

		&:hover {
			color: $danger !important;
		}

		:focus,
		:active {
			outline: none;
		}
	}
`;

export const Dialog = styled(Transition)`
	${baseStyles};
	border-radius: 10px;
	top: 50%;
	left: 50%;
`;

export const RightPanel = styled(Transition)`
	${baseStyles};
	top: 0rem;
	right: 0;
	z-index: 100;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
`;

export const LeftPanel = styled(Transition)`
	${baseStyles};
	top: 0;
	left: 0;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
`;

export const TopPanel = styled(Transition)`
	${baseStyles};
	top: 0;
	left: 30%;
`;
