import { useWorkflowContext } from 'context/useWorkflowStore';
import React, { FormEvent, useState } from 'react';
import { Form } from 'reactstrap';
import { useAuthContext } from '../../../../utils/auth';
import { _logError } from '../../../../utils/common/log';
import { NotificationsContext } from '../../../notifications';
import { Stage } from '../types/workflow.types';
import {
	Avatar,
	CommentContainer,
	CommentInput,
} from './comment-card.styled-components';

type Props = {
	stage?: Stage;
};

const CommentForm = (props: Props) => {
	const { info, error: showError } = React.useContext(NotificationsContext);
	const { addComment } = useWorkflowContext();
	const initialFormState: any = {
		message: '',
	};
	const [stage, setStage] = useState(props.stage);
	const [comment, setComment] = useState(initialFormState);
	const { currentUser } = useAuthContext();

	const onChange = (event: any) => {
		const { value } = event.target;
		setComment({ message: value });
	};

	const commentFormSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!comment.message.trim()) {
			return;
		}

		if (!stage?.substages?.length) {
			try {
				const updatedStage = await addComment(comment.message.trim());
				setStage(updatedStage);
				setComment({ message: '' });
				info('Saved successfully');
			} catch (error) {
				_logError(error);
				showError(
					`Unable to post your comment, an issue occurred while saving. Please try again later.`
				);
			}
		}
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			commentFormSubmit(event).catch(_logError);
		}
	};

	return (
		<CommentContainer>
			<Avatar user={currentUser} />
			<Form onSubmit={commentFormSubmit} className="w-100">
				<CommentInput
					className="cy-wf-comment-input"
					style={{ marginLeft: 10 }}
					type="textarea"
					name="message"
					value={comment.message}
					placeholder="Write a comment..."
					onChange={onChange}
					onKeyDown={onKeyDown}
				/>
			</Form>
		</CommentContainer>
	);
};

export default CommentForm;
