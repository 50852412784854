import React, { useContext, useState } from 'react';
import { ScrollCard, ScrollTable } from '../../../ui';

import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DropDownMenuItem } from '../../../drop-down-menu.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { _logError } from '../../../../utils/common/log';
import { FormModal, ConfirmationDialog } from '../../../Modal';
import { InputField, Textarea } from '../../../forms';
import { useForm } from 'react-hook-form';
import { PushNotificationImage, Cell, CenteredCell } from '../admin.styles';
import { PushNotification } from './models/NotificationModel';
import { useAxios } from '../../../../hooks';
import { NotificationsContext } from '../../../notifications';
import { getTotalPages, PaginationContainer } from 'components/index';
import * as axios from 'axios';
import { useHeaders } from 'utils/auth';
type Props = {
	notifications: Array<PushNotification>;
	onDelete: (notification: PushNotification) => void;
};

const NotificationsTable = ({ notifications, onDelete }: Props) => {
	const { getHeaders } = useHeaders();
	const [editModalShowing, setEditModalShowing] = useState(false);
	const [deleteModalShowing, setDeleteModalShowing] = useState(false);
	const [notification, setNotification] = useState<PushNotification>();
	const pushStore = useAxios<PushNotification>('push-notifications');
	const { info, error, warn } = useContext(NotificationsContext);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const editForm = useForm();

	const paginate = (
		array: Array<PushNotification>,
		page_size: number,
		page_number: number
	) => {
		// human-readable page numbers usually start with 1, so we reduce 1 in the first argument
		return array.slice((page_number - 1) * page_size, page_number * page_size);
	};
	const currentPageData = React.useMemo(() => {
		if (notifications && currentPage)
			return paginate(notifications as PushNotification[], 15, currentPage);
		return [];
	}, [currentPage, notifications]);

	type FormData = Pick<PushNotification, 'message' | 'title'>;
	const updatePushNotification = async ({ message, title }: FormData) => {
		await pushStore
			.updateOne(notification?._id as string, {
				...notification,
				message,
				title,
			})
			.then(() => info('Successfully Updated Notification'))
			.catch(() => error('Error updating notification. Try again later.'));
		setEditModalShowing(false);
	};

	const deletePushNotification = async () => {
		if (!notification) return;
		setDeleteModalShowing(false);
		await pushStore.deleteOne(notification._id);
		info('Successfully deleted push notification.');
		onDelete(notification);
	};

	const broadcastNotification = async (notification: PushNotification) => {
		try {
			const response = await axios.default.post(
				`${process.env.REACT_APP_ROME_API_ENDPOINT}/pushsubscription/broadcast/site-wide`,
				{ title: notification.title, text: notification.message },
				getHeaders()
			);
			if (response.data) {
				await pushStore.updateOne(notification._id, {
					...notification,
					broadcasted: true,
				});
				info('Successfully broadcasted site-wide notification.');
			} else {
				warn(
					'An issue occurred while broadcasting the notification to all subscribers. Please try again later.'
				);
			}
		} catch (e) {
			error(
				'Sorry, an error occurred trying to update notification. Please try again later.'
			);
			_logError(e);
		}
	};

	return (
		<>
			{/* Edit Modal */}
			<FormModal
				form={editForm}
				isOpen={editModalShowing}
				title="Edit Notification"
				onClose={() => setEditModalShowing(false)}
				onSubmit={updatePushNotification}
			>
				<InputField
					validators={{ required: false }}
					name="title"
					label="Title"
					defaultValue={notification?.title || ''}
					form={editForm as any}
				/>
				<Textarea
					validators={{ required: false }}
					name="message"
					type="textarea"
					label="Message"
					defaultValue={notification?.message}
					form={editForm as any}
				/>
			</FormModal>

			{/* Delete Modal */}
			<ConfirmationDialog
				title="Delete Push Notification"
				isOpen={deleteModalShowing}
				onConfirm={deletePushNotification}
				onCancel={() => setDeleteModalShowing(false)}
				confirmText="Delete Notification"
			>
				<p>
					Are you sure you want to delete the notification: "
					{notification?.title}."
				</p>
				<p>
					This action will delete the message, preventing it from being
					broadcasted in the future.
				</p>
			</ConfirmationDialog>

			{/* Table */}
			<ScrollCard>
				<ScrollTable>
					<thead>
						<tr>
							<CenteredCell>Title</CenteredCell>
							<CenteredCell>Has been broadcasted</CenteredCell>
							<CenteredCell>Notification Message</CenteredCell>
							<CenteredCell>Notification Logo</CenteredCell>
							<CenteredCell></CenteredCell>
						</tr>
					</thead>
					<tbody>
						{!notifications?.length && (
							<tr>
								<Cell colSpan={3}>
									<p>No notifications</p>
								</Cell>
							</tr>
						)}
						{!!currentPageData?.length &&
							currentPageData.map((currNotification, index) => (
								<tr style={{ fontSize: 14 }} key={currNotification._id}>
									<CenteredCell>{currNotification.title}</CenteredCell>
									<CenteredCell>
										{currNotification.broadcasted ? 'Yes' : 'No'}
									</CenteredCell>
									<CenteredCell>{currNotification.message}</CenteredCell>
									<CenteredCell>
										<PushNotificationImage style={{ width: 40 }}>
											<img
												alt="logo"
												src={`${currNotification.logo}` || `/image192.png`}
												className="img-fluid"
											/>
										</PushNotificationImage>
									</CenteredCell>
									<td>
										<UncontrolledDropdown>
											<DropdownToggle color="white">
												<FontAwesomeIcon icon={faEllipsisV} />
											</DropdownToggle>
											<DropdownMenu>
												<DropDownMenuItem
													onClick={() =>
														broadcastNotification(currNotification)
													}
												>
													Broadcast notification
												</DropDownMenuItem>
												<DropDownMenuItem
													onClick={() => {
														setNotification(currNotification);
														setEditModalShowing(true);
													}}
												>
													Edit notification
												</DropDownMenuItem>
												<DropDownMenuItem
													className="danger"
													onClick={() => {
														setNotification(currNotification);
														setDeleteModalShowing(true);
													}}
												>
													Delete notification
												</DropDownMenuItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									</td>
								</tr>
							))}
					</tbody>
				</ScrollTable>

				{/* Pagination */}
				{notifications && getTotalPages(notifications) > 1 && (
					<div className="d-flex justify-content-center">
						<PaginationContainer
							totalPages={getTotalPages(notifications)}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
						/>
					</div>
				)}
			</ScrollCard>
		</>
	);
};

export default NotificationsTable;
