import LoggerService, { LogLevel } from '../core/LoggingProvider';

interface LogOptions {
	save?: boolean;
	display?: boolean;
}

const loggerService: LoggerService = new LoggerService();
const defaultErrorOptions = Object.freeze({
	save: true,
	display: true,
});

const defaultWarningOptions = Object.freeze({
	save: false,
	display: true,
});

export function _logError(
	error: any,
	options: LogOptions = defaultErrorOptions
) {
	log(console.error.bind(console), error, options, LogLevel.error);
}

export function _logWarning(
	message: any,
	options: LogOptions = defaultWarningOptions
) {
	log(console.warn.bind(console), message, options, LogLevel.warn);
}

function log(
	logMethod: (content: any) => void,
	content: any,
	options: LogOptions,
	logLevel?: LogLevel
) {
	// check whether to save log or not
	if (options && options.save !== undefined) {
		loggerService.logToCloudWatch(content, logLevel as LogLevel);
	}
}
