import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationDialog from 'components/modals/confirmation-dialog.component';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

type DeclineModalProps = {
	onConfirm: () => void;
	onCancel: () => void;
};

export const DeclineProxyModal = (props: DeclineModalProps) => {
	const { onConfirm, onCancel } = props;

	return (
		<ConfirmationDialog
			header={'Decline Proxy Coverage'}
			onConfirm={onConfirm}
			onCancel={onCancel}
		>
			<Container>
				<Row className={'d-flex align-items-center'}>
					<Col xl={1}>
						<div>
							<FontAwesomeIcon icon={faExclamationCircle} size={'lg'} />
						</div>
					</Col>
					<Col xl={11}>
						<p>
							Declining the Proxy Request will remove the request entirely. This
							action is not reversable. Continue?
						</p>
					</Col>
				</Row>
			</Container>
		</ConfirmationDialog>
	);
};
