export class ServiceWorkerProvider {
	private static readonly publicVapidKey: string =
		process.env.VAPID_KEY ||
		'BBvesuC-uyr3HVZ5O_K7k1jqJLqcuBedq6u51KZtYL1lvZH9Z7ifDIyB_EkJs1H7M4SRvM-9Pt8pNxsCohuzRMM';

	public unregisterCurrentUser(): Promise<boolean> | boolean {
		if (Notification.permission === 'granted') {
			return new Promise<boolean>((res, rej) => {
				try {
					navigator.serviceWorker.getRegistration().then(function (reg) {
						reg?.unregister().then((unregistered) => res(unregistered));
					});
				} catch {
					return rej(false);
				}
			});
		} else return false;
	}

	async initializePushNotifications(): Promise<NotificationPermission> {
		// request user grant to show notification
		return await Notification.requestPermission();
	}

	async createNotificationSubscription() {
		//wait for service worker installation to be ready
		const serviceWorker = await navigator.serviceWorker.ready;
		// subscribe and return the subscription
		return await serviceWorker.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: this.urlBase64ToUint8Array(
				ServiceWorkerProvider.publicVapidKey
			),
		});
	}

	public async registerCurrentUser(): Promise<boolean> {
		const _this = this;

		try {
			const result = await this.initializePushNotifications();
			if (result === 'granted') {
				return new Promise((res, rej) => {
					try {
						navigator.serviceWorker
							.getRegistration()
							.then(async function (reg) {
								await reg?.pushManager.subscribe({
									userVisibleOnly: true,
									applicationServerKey: _this.urlBase64ToUint8Array(
										ServiceWorkerProvider.publicVapidKey
									),
								});
								return res(true);
							});
					} catch (e) {
						console.error(e);
					}
				});
			} else {
				return false;
			}
		} catch (e) {
			throw e;
		}
	}

	sendPushNotification(pushNotification: {
		title: string;
		message: string;
		logo: string;
	}): void {
		navigator.serviceWorker.getRegistration().then(function (reg) {
			reg?.showNotification(pushNotification.title, {
				icon: pushNotification.logo,
				body: pushNotification.message,
				actions: [
					{
						action: 'open',
						title: 'Open ROME',
						icon: 'https://static.thenounproject.com/png/171127-200.png',
					},
					{
						action: 'dismiss',
						title: 'Dismiss',
					},
				],
			});
		});
	}

	urlBase64ToUint8Array(base64String: string): Uint8Array {
		const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
		const base64 = (base64String + padding)
			.replace(/-/g, '+')
			.replace(/_/g, '/');

		const rawData = window.atob(base64);
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i);
		}
		return outputArray;
	}
}
export default ServiceWorkerProvider;
