import { UploadResult } from '@uppy/core';
import { Loading } from 'components/index';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Form, Row, Col } from 'reactstrap';
import { AssetVersion } from 'components/workflow/workflows/types/workflow.types';
import { _logError } from '../../../utils/common/log';
import ConfirmationDialog from '../../modals/confirmation-dialog.component';
import RenderWhen from '../../render-when.component';
import { toFile } from '../../../utils/core/FileUploadProvider';
import { NotificationsContext } from '../../notifications';
import AssetPicker from './asset-picker.component';
import {
	buildEmptyAssetMetadata,
	useAssetHelper,
} from './helpers/useAssetHelper';
import { Maybe } from 'types/globals';
export interface AssetUploadDialogProps {
	afterSubmit?: () => void;
	asset: AssetVersion;
}
export const AddAssetVersionDialog = observer(
	(props: AssetUploadDialogProps) => {
		const { error: showError } = React.useContext(NotificationsContext);
		const { info: showInfo } = React.useContext(NotificationsContext);
		const { uploadVersion } = useAssetHelper();
		const [uppyResult, setResult] = useState<Maybe<UploadResult>>(undefined);
		const [showMetadata] = useState(false);
		const [submitted, setSubmitted] = useState(false);
		const onFormSubmit = async () => {
			try {
				setSubmitted(true);
				const allFiles = uppyResult?.successful.map((file) =>
					toFile(file)
				) as File[];

				const uploadData = {
					metadata: buildEmptyAssetMetadata,
					message: '',
				};
				await uploadVersion(allFiles[0], uploadData, props.asset);
				showInfo('Uploaded asset version successfully');
			} catch (error) {
				_logError(error);
				showError(
					'An issue occurred while attempting to upload your file. Please try again later.'
				);
			}
		};

		return (
			<>
				<ConfirmationDialog
					confirmText={'Upload'}
					header={'Upload Assets to DAM'}
					onConfirm={() => onFormSubmit()}
				>
					<Form>
						<Row form>
							<Col md={12} className="p-2">
								<RenderWhen when={!showMetadata}>
									<AssetPicker onSelect={setResult} />
								</RenderWhen>
							</Col>
						</Row>
					</Form>
				</ConfirmationDialog>
				{submitted && <Loading label="Uploading asset..." />}
			</>
		);
	}
);
