import React, { useState, useEffect } from 'react';
import { ToastBody } from 'reactstrap';
import { NotificationHeader, NotificationToast } from './Notification.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimesCircle,
	faExclamationTriangle,
	faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

export type NotificationType = 'error' | 'info' | 'warning';

export type NotificationProps = {
	type: NotificationType;
	heading?: string;
	text: string;
	expirationInMs?: number;
};

export const Notification = (props: NotificationProps) => {
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		setTimeout(() => setIsVisible(false), props.expirationInMs || 6000);
	}, [props.expirationInMs]);

	const dismiss = () => setIsVisible(false);

	const getIcon = (type: NotificationType) => {
		if (type === 'error') return faTimesCircle;
		if (type === 'warning') return faExclamationTriangle;
		return faCheckCircle;
	};

	const getHeading = (type: NotificationType, heading?: string) => {
		if (heading) return heading;
		if (type === 'error') return 'Error';
		if (type === 'warning') return 'Warning';
		return 'Info';
	};

	return (
		<NotificationToast isOpen={isVisible}>
			<NotificationHeader
				className={`notification-header ${props.type}`}
				icon={<FontAwesomeIcon icon={getIcon(props.type)} />}
				toggle={dismiss}
			>
				{getHeading(props.type, props.heading)}
			</NotificationHeader>
			<ToastBody className="overflow-hidden text-ellipsis d-inline">
				<p className={'px-2'}>{props.text}</p>
			</ToastBody>
		</NotificationToast>
	);
};

export default Notification;
