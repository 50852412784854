import { Stage } from 'components/workflow/workflows/types';
import { useTemplateContext } from 'context/useTemplateContext';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { SelectedTemplateStage } from '../../template.contexts';
import { AddStageButton } from '../stage-cards/AddStageButton';
import * as H from '../stage-cards/Stage.helpers';
import { defaultStage, TemplateStageCard } from '../stage-cards/StageCard';
import { StageForm } from '../stage-cards/StageForm';
import TemplatePhaseKey from '../template-phase-key.component';

export const StageEditor = ({ editable }: { editable: boolean }) => {
	const {
		template,
		currentStage,
		setCurrentStage,
		saveTemplate,
	} = useTemplateContext();

	const [isInsertingSideTask, setIsInsertingSideTask] = useState(false);

	const insertSideTask = async (newStageData: Partial<Stage>) => {
		if (!template) return;

		const newStage: Stage = {
			...defaultStage('sideTask'),
			...newStageData,
		};
		await saveTemplate({
			...template,
			sideTasks: H.insertSideTask(newStage, template.sideTasks || []),
		});
		setIsInsertingSideTask(false);
	};

	if (!template) return null;

	return (
		<SelectedTemplateStage.Provider value={[currentStage, setCurrentStage]}>
			<Row>
				<div className="phases">
					<TemplatePhaseKey template={template} />
				</div>
			</Row>{' '}
			<Row>
				<Col sm={5}>
					<AddStageButton
						editable={editable}
						title={'Add new side task'}
						openCreateStageModal={() => setIsInsertingSideTask(true)}
						isLastStage
						action="create side task"
					/>
					{template &&
						template.sideTasks?.map((stage) => (
							<div
								style={{ maxWidth: 295, paddingBottom: 30 }}
								className={`m-auto ${stage._id}`}
								key={stage._id}
								id={stage._id}
							>
								<TemplateStageCard
									// key={stage._id}
									template={template}
									stage={stage}
									editable={editable}
								/>
							</div>
						))}
				</Col>
				<Col sm={1}></Col>
				<Col sm={6}>
					{template &&
						template.stages.map((stage) => (
							<div
								style={{ marginLeft: -15, maxWidth: 500 }}
								className={stage._id}
								key={stage._id}
								id={stage._id}
							>
								<TemplateStageCard
									className="lg"
									key={stage._id}
									template={template}
									stage={stage}
									editable={editable}
									id={`stage-card-${stage._id}`}
								/>
							</div>
						))}
				</Col>
			</Row>{' '}
			{/* <TemplateEditorCanvas /> */}
			<StageForm
				title="Insert Side Task"
				isOpen={isInsertingSideTask}
				onSubmit={insertSideTask}
				closeModal={() => setIsInsertingSideTask(false)}
				stage={defaultStage('sideTask')}
			/>
		</SelectedTemplateStage.Provider>
	);
};
