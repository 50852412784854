import { User } from 'context/AuthContext';
import React from 'react';
import styled from 'styled-components';
import '../styles/avatar-styles.scss';
import { UserStatus } from './accounts/models/UserStatus.model';

export type AvatarSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export interface AvatarProps {
	user: User;
	size?: AvatarSize | number;
	className?: string;
	hideOnlineStatus?: boolean;
}

export const ProvidedAvatar = styled.div<AvatarProps>`
	background-image: ${(props) =>
		props.user.status === 'disabled'
			? 'url(/images/red-circle.png); z-index:1;'
			: ''};
	background-size: ${(props) =>
		props.user.status === 'disabled' ? 'contain' : ''};  
	display: flex;
	min-width: ${(props) => (props.size ? props.size : 2.5)}em;
	height: ${(props) => (props.size ? props.size : 2.5)}em;
	border-radius: 50%;
	font-weight: 700;
	color: white;
	align-items: center;
	font-family: 'Roboto Slab', serif;
	justify-content: center;
	border: 1px solid #fff;
	background-color: white;
	background-image: url('${(props) => props.user?.picture}');
	background-size: cover;
	background-position: center center;
	margin-right: 0;
  `;

export interface GeneratedAvatarProps {
	user: User;
	backgroundColor: string;
	size?: AvatarSize | number;
}
export const GeneratedAvatar = styled.div<GeneratedAvatarProps>`
	background-image: ${(props) =>
		props.user.status === 'disabled' ? 'url(/images/red-circle.gif)' : ''};
	background-size: ${(props) =>
		props.user.status === 'disabled' ? 'contain' : ''};
	background-color: ${(props) => props.backgroundColor};
	display: flex;
	min-width: ${(props) => (props.size ? props.size : 2.5)}em;
	height: ${(props) => (props.size ? props.size : 2.5)}em;
	border-radius: 50%;
	font-weight: 700;
	color: white;
	align-items: center;
	font-family: 'Roboto Slab', serif;
	justify-content: center;
	border: 1px solid #fff;
	margin-right: 0;
`;

export const Avatar = (props: AvatarProps) => {
	const { user, size } = props;
	if (!user?._id) return null;
	const getSizeToUse = () => {
		if (!size) return 2.5;
		if (size === 'xs') return 1;
		if (size === 'sm') return 1.25;
		if (size === 'md') return 2.5;
		if (size === 'lg') return 2.5;
		if (size === 'xl') return 3.5;
	};

	const VALID_URL_REGEX = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	);

	if (
		(user && user?.picture?.search('gravatar') !== -1) ||
		!VALID_URL_REGEX.test(user?.picture)
	) {
		let id: string | number = user?._id?.charAt(user._id.length - 1);

		id = isNaN(+id) ? id.charCodeAt(0) - 97 : id;
		let background_color: string;
		switch (true) {
			case id > 5:
				background_color = '#0F2347';
				break;
			case id > 10:
				background_color = '#1C3F6E';
				break;
			case id > 15:
				background_color = '#A23541';
				break;
			case id > 20:
				background_color = '#5AACCF';
				break;
			default:
				background_color = '#80C271';
				break;
		}
		return (
			<div
				style={{ height: 'min-content', position: 'relative' }}
				title={user && user?.givenName + ' ' + user?.familyName}
			>
				<GeneratedAvatar
					size={getSizeToUse()}
					backgroundColor={background_color}
					user={user}
					className="user-avatar"
				>
					{user?.givenName?.charAt(0)}
					{user?.familyName?.charAt(0)}

					{user.status === UserStatus.disabled && (
						<div
							className="disabled-user material-icons"
							style={{ fontSize: `${getSizeToUse()}em` }}
						>
							block
						</div>
					)}
				</GeneratedAvatar>
			</div>
		);
	}

	return (
		<div
			style={{ height: 'min-content', position: 'relative' }}
			title={user && user.givenName + ' ' + user.familyName}
		>
			<ProvidedAvatar
				size={getSizeToUse()}
				user={user}
				className={
					`size-${getSizeToUse()} user-avatar` +
					(user.status === UserStatus.disabled ? ' disabled-user' : '')
				}
			>
				{user.status === UserStatus.disabled && (
					<div
						className="disabled-user material-icons"
						style={{ fontSize: `${getSizeToUse()}em` }}
					>
						block
					</div>
				)}
			</ProvidedAvatar>
		</div>
	);
};
